import styled, { css } from 'styled-components';

const RoundedButtonStyle = css`
  color: white;
  min-height: 32px;
  line-height: 32px;
  background: #5c8df3;
  position: relative;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Lato, Arial, 'sans-serif';
  font-weight: bold;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  
  &:before {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    background: rgba(255,255,255,0.4);
    display: ${(props) => (props.disabled ? 'unset' : 'none')};
  }
  
  &:hover:after {
    display: ${(props) => (props.disabled ? 'none' : 'unset')};
    content: '';
    background: rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  &:focus {
    outline: 0;
  }
`;

export const RoundedButton = styled.button`
  ${RoundedButtonStyle};
  border: none;
`;

export const RoundedButtonLink = styled.a`
  ${RoundedButtonStyle};
  text-decoration: none;
`;

export const ButtonIcon = styled.div`
  width: 24px;
  align-items: center;
  margin-right: 4px;

  & * {
    vertical-align: middle;
  }
`;

export const ButtonLabel = styled.div`
`;
