import gql from 'graphql-tag';

const uploadFileMutation = gql`
  mutation uploadFile($eventToken: String!, $file: Upload!, $uniqueToken: String!) {
    uploadFile(input: {
      eventToken: $eventToken,
      file: $file,
      uniqueToken: $uniqueToken,
    }) {
      success
      attachment {
        id
        fileName
        contentType
        fileUrl
      }
    }
  }
`;

export default uploadFileMutation;
