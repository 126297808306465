import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector, batch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { ExportToCsv } from 'export-to-csv';

import {
  EVENT_NAME, PAGE_NAMES,
} from 'appenv';
import fetchAllContacts from 'network/fetchAllContacts';
import {
  fetchContactList,
  selectAllContacts,
  ContactsSlice,
} from 'models/contacts';
import BackToTopButton from 'components/BackToTopButton';
import Footer from 'components/footer/Footer';
import { UserSessionContext } from 'components/UserSession';
import ContactListContainer from './ContactListContainer';
import ContactsHeader from './ContactsHeader';

const RootContainer = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 960px) {
    background-color: #F0F2F5;
  }

  @media (max-width: 960px) {
    background-color: #F0F1F2;
  }
`;

const PageContentContainer = styled.div`
  flex: 1 0 auto;
`;

const tableHeaderText = [
  'ID',
  'Name',
  'Email',
  'Role',
  'Description',
  'Date Added',
];

const ContactsPageContainer = () => {
  const [scrollableTarget, setScrollableTarget] = useState();
  const pageWrapperRef = useRef();
  const dispatch = useDispatch();
  const { userSession } = useContext(UserSessionContext);
  const contacts = useSelector(selectAllContacts);
  const {
    initialLoading,
    hasNextPage,
  } = useSelector((state) => state.contacts);

  const formattedContacts = () => (contacts.map((contact) => (
    [
      contact.id,
      contact.contact.name,
      contact.contact.email,
      contact.contact.role,
      contact.userDescription,
      contact.createdAt,
    ]
  )));

  const exportContactData = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      title: 'test csv',
      useTextFile: false,
      useBom: true,
      filename: 'Business Contacts',
      headers: tableHeaderText,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(formattedContacts());
  };

  const fetchMore = useCallback(() => {
    dispatch(fetchContactList(userSession?.rongcloudUserId));
  }, [dispatch]);

  useEffect(() => {
    setScrollableTarget(pageWrapperRef.current);
  }, []);

  useEffect(() => {
    batch(() => {
      dispatch(ContactsSlice.actions.resetContacts());
      fetchMore();
    });
    console.log('formattedContacts', formattedContacts());
  }, [dispatch, fetchMore]);

  return (
    <RootContainer ref={pageWrapperRef}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${PAGE_NAMES.contact} | ${EVENT_NAME}`}</title>
      </Helmet>
      <PageContentContainer>
        <ContactsHeader
          contactCount={contacts?.length ?? 0}
          exportContactData={exportContactData}
        />
        <ContactListContainer
          contactItems={contacts}
          scrollableTarget={scrollableTarget}
        />
      </PageContentContainer>
      <Footer hashState="/contacts" />
      <BackToTopButton scrollableTarget={scrollableTarget} />
    </RootContainer>
  );
};

export default ContactsPageContainer;
