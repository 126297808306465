import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoDataIcon from 'assets/pitchNoData.svg';
import FeaturedAttachmentBlock from 'components/booth/FeaturedAttachmentBlock';

const RootContainer = styled.div`
  width: auto;
  max-width: 100%;
  margin: 20px;
  letter-spacing: 0.3px;
`;

const Placeholder = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const Text = styled.div`
  line-height: 24px;
  color: #484848;
  font-weight: bold;
  margin-top: 20px;
`;

const BoothFeaturedPitch = ({ attachments, upgraded, trackingBooth }) => (
  <RootContainer>
    {
      ({ bookmarkedAttachments, toggleBookmark }) => (
        attachments.length > 0 ? attachments.map((attachment) => (
          <FeaturedAttachmentBlock
            trackingBooth={trackingBooth}
            key={attachment.id}
            attachments={attachment.files || []}
            webinar={attachment.webinar || []}
            thumbnail={attachment.thumbnail}
            title={attachment.title}
            upgraded={upgraded}
            description={attachment.description}
            bookmarkedAttachments={bookmarkedAttachments}
            onBookmarkToggle={(attachedBookmark) => toggleBookmark(attachedBookmark)}
          />
        )) : (
          <Placeholder>
            <img src={NoDataIcon} alt="coming soon" />
            <Text>
              Coming Soon!
            </Text>
          </Placeholder>
        )
      )
    }
  </RootContainer>
);

BoothFeaturedPitch.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  upgraded: PropTypes.bool.isRequired,
  trackingBooth: PropTypes.string.isRequired,
};

export default BoothFeaturedPitch;
