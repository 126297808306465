import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { searchResultsSelector } from 'models/searchResults';
import { UserSessionContext } from 'components/UserSession';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import AllSearchResultContainer from 'components/search/AllSearchResultContainer';
import SearchResultContainer from 'components/search/SearchResultContainer';
import ParticipantSearchResultContainer from 'components/search/ParticipantSearchResultContainer';
import EmptyStatePlaceholder from 'components/search/EmptyStatePlaceholder';

const RootContainer = styled.div`
  flex: 1 0 auto;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 36px;
`;

const StyledEmptyStatePlaceholder = styled(EmptyStatePlaceholder)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
`;

const SearchResultTabContentContainer = ({
  activeTab,
  showResult,
  fetchMore,
  scrollableTarget,
}) => {
  const {
    initialLoading,
  } = useSelector((s) => s.searchResults);
  const {
    exhibitors,
    webinars,
    participants,
  } = useSelector(searchResultsSelector);

  const { canSee } = useContext(UserSessionContext);

  const resultsPresent = (exhibitors && exhibitors.length > 0)
    || (webinars && webinars.length > 0)
    || (participants && participants.length > 0);

  if (showResult) {
    if (initialLoading) {
      return (
        <RootContainer>
          <CircularLoadingIndicator size={32} />
        </RootContainer>
      );
    }
    if (!resultsPresent) {
      return (
        <RootContainer>
          <StyledEmptyStatePlaceholder />
        </RootContainer>
      );
    }
  }

  return (
    <RootContainer>
      {
        activeTab === 0 && (
          <AllSearchResultContainer
            showResult={showResult}
            exhibitors={exhibitors}
            webinars={webinars}
            participants={participants}
            fetchMore={fetchMore}
            scrollableTarget={scrollableTarget}
          />
        )
      }
      {
        activeTab === 1 && showResult && (
          <SearchResultContainer
            type="exhibitor"
            results={exhibitors}
          />
        )
      }
      {
        canSee('webinar') && activeTab === 2 && showResult && (
          <SearchResultContainer
            type="webinar"
            results={webinars}
          />
        )
      }
      {
        canSee('participant') && activeTab === 3 && showResult && (
          <ParticipantSearchResultContainer
            participants={participants}
            fetchMore={fetchMore}
            scrollableTarget={scrollableTarget}
          />
        )
      }
    </RootContainer>
  );
};

SearchResultTabContentContainer.propTypes = {
  activeTab: PropTypes.number.isRequired,
  showResult: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  scrollableTarget: PropTypes.instanceOf(Element),
};

SearchResultTabContentContainer.defaultProps = {
  scrollableTarget: document.body,
};

export default SearchResultTabContentContainer;
