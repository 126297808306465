import { gql } from 'graphql-request';
import {
  CommonLocalizableString, CommonLocalizableVideo, CommonLocalizableImage, CommonLocalizableFile,
} from 'network/common/cmsFragment';
import { fetchAllPage } from 'network/common/cmsUtils';

interface FetchBoothDesignElementsByIdProps {
  boothId: string;
  locales: string[];
}

const fetchBoothDesignElementsByIdQuery = gql`
  ${CommonLocalizableString}
  ${CommonLocalizableVideo}
  ${CommonLocalizableImage}
  ${CommonLocalizableFile}

  query fetchBoothDesignElementsByIdQuery(
    $boothId: ID!
    $locales: [Locale!]!
    $cursor: String
    $count: Int
  ) {
    node(id: $boothId) {
      ... on Booth {
        id
        designElements(after: $cursor, first: $count) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            name
            kind
            color
            actionUrl
            image {
              ...CommonLocalizableImage
            }
            video {
              ...CommonLocalizableVideo
            }
            contentRichtextHtml {
              ...CommonLocalizableString
            }
            thumbnailImage {
              ...CommonLocalizableImage
            }
            pdf {
              ...CommonLocalizableFile
            }
          }
        }
      }
    }
  }
`;

const fetchBoothDesignElementsById = async (config: FetchBoothDesignElementsByIdProps) => {
  const pages = await fetchAllPage((data: any) => data.node.designElements.pageInfo, fetchBoothDesignElementsByIdQuery, config);

  const designElements = pages
    .reduce((sum, data) => [...sum, ...(data.node.designElements.nodes || [])], []);

  const result = {
    id: pages[0].node.id,
    designElements: { nodes: designElements },
  };

  return result;
};

export default fetchBoothDesignElementsById;
