import { ja as jaCommon } from './common';
import { ja as jaLobby } from './lobby';
import { ja as jaLanding } from './landing';

export default {
  ja: {
    common: jaCommon,
    lobby: jaLobby,
    landing: jaLanding,
  },
};
