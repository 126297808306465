import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import Booth618Container from './components/Booth618Container';
import BoothDescription from '../../components/BoothDescription';
import LogoDesk from '../../components/desk/LogoDesk';
import BoothPullUpBanner from '../../components/BoothPullUpBanner';
import BoothSaleContainer from '../../components/BoothSaleContainer';
import BoothChair from '../../components/BoothChair';
import LightBackground618 from '../../components/background/LightBackground618';
import WhiteShelf from '../../components/brochureShelf/WhiteShelf';
import 'react-image-lightbox/style.css';

const Booth618BContainer = styled(Booth618Container)`
  margin-bottom: ${({ needOffset }) => (needOffset ? '110px' : 'inherit')};
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 355px;
  left: 250px;
  height: 250px;
  width: 430px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const ChairContainer = styled.div`
  position: absolute;
  width: 160px;
  height: 200px;
  bottom: -50px;
  left: -150px;
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
  z-index: 999;
`;

const PosterContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/577/content/poster-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 200px;
  position: absolute;
  z-index: 100;
  top: ${({ site }) => (site === 'right' ? '215px' : '215px')};
  left: ${({ site }) => (site === 'right' ? '' : '30px')};
  right: ${({ site }) => (site === 'right' ? '5px' : '')};
`;

const BackgroundContainer = styled.div`
  position: absolute;
  height: 460px;
  width: 920px;
`;

const DescriptionContainer = styled.div`
  z-index: 900;
  position: absolute;
  top: 100px;
  width: 350px;
  left: calc((100% - 350px)/2);
`;

const BrochureContainer = styled.div`
  position: absolute;
  bottom: -10px;
  z-index: 100;
  right: 0px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const Booth618TemplateB = ({ booth, handleClick, showRepsImg }) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const pullUpThumbnail = booth.verticalBannerFullImg;
  const pullUpFile = booth.verticalBannerFile;
  const pullUpFileType = booth.verticalBannerFileType?.split('/')[0];

  return (
    <Booth618BContainer needOffset={showRepsImg}>
      <BackgroundContainer>
        <LightBackground618 boothColor={booth.boothColor || '#695E8A'} />
      </BackgroundContainer>
      <DescriptionContainer>
        <BoothDescription
          handleClick={handleClick}
          name={booth.name}
          boothNumber={booth.boothNumber}
          description={booth.description}
        />
      </DescriptionContainer>
      <LogoContainer enableClick={!!(booth.logoDirectLink || booth.website)} onClick={() => handleClick('logo')}>
        <LogoDesk
          deskColor={booth.boothColor || '#695E8A'}
          logo={booth.logo}
        />
      </LogoContainer>
      <PosterContainer site="left">
        <BoothPullUpBanner
          pullUpBanner={pullUpThumbnail}
          mainSrc={pullUpFile}
          isVideo={pullUpFileType === 'video'}
          setShowBannerModal={setShowBannerModal}
        />
      </PosterContainer>
      <BrochureContainer enableClick={!!booth.attachments.length} onClick={() => handleClick('attachment')}>
        <WhiteShelf hasAttachment={!!booth.attachments.length} />
      </BrochureContainer>
      <ChairContainer>
        <BoothChair color={booth.boothColor || '#695E8A'} />
      </ChairContainer>
      {
        showRepsImg && (
          <SaleContainer id="sale">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={pullUpFile}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
    </Booth618BContainer>
  );
};

Booth618TemplateB.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth618TemplateB.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth618TemplateB;
