import React, {
  createRef, ElementRef, useCallback, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Scrollbars } from 'react-custom-scrollbars';
import { IconButton } from '@material-ui/core';
import { LiveWebinarListOptions } from 'pages/webinar/live/LiveWebinarListOptions';


const RootContainer = styled.div`
  overflow: hidden;
  position: relative;
`;

const StyledScrollbars = styled(Scrollbars)`
  width: 100% !important;
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const StyledChevronButton = styled(IconButton)<any>`
  z-index: 110;
  display: ${({ active }) => (active === 'true' ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  cursor: pointer;

  &:hover {
    background: rgba(72, 72, 72, 0.25);
  }
`;

const StyledChevronLeftButton = styled((
  { keepTimeLineSpace, children, ...props },
) => <StyledChevronButton {...props}>{children}</StyledChevronButton>)`
  left: ${({ keepTimeLineSpace }) => (keepTimeLineSpace ? '75px' : '45px')};
  transform: translate(-50%, -50%);
`;

const StyledChevronRightButton = styled(StyledChevronButton)`
  right: 45px;
  transform: translate(50%, -50%);
`;

interface HorizontalScrollbarHiderProps {
  children: ElementRef<any>;
  minHeight?: number;
  maxHeight?: number;
  options?: LiveWebinarListOptions;
}

const MultiTracksHorizontalScrollbarHider = ({
  children, minHeight = 0, maxHeight = 500, options,
}: HorizontalScrollbarHiderProps) => {
  const speakerListRef = createRef<any>();
  const [chevronLeftActive, setChevronLeftActive] = useState(false);
  const [chevronRightActive, setChevronRightActive] = useState(false);

  const onChevronLeftClick = () => {
    speakerListRef.current.view.scrollBy({
      top: 0,
      left: -speakerListRef.current.getClientWidth() / 2,
      behavior: 'smooth',
    });
  };

  const onChevronRightClick = () => {
    speakerListRef.current.view.scrollBy({
      top: 0,
      left: speakerListRef.current.getClientWidth() / 2,
      behavior: 'smooth',
    });
  };

  const isChevronLeftActive = useCallback(() => {
    if (!speakerListRef.current) return false;
    return speakerListRef.current.getScrollLeft() > 0;
  }, [speakerListRef]);

  const isChevronRightActive = useCallback(() => {
    if (!speakerListRef.current) return false;
    return speakerListRef.current.getScrollLeft() + speakerListRef.current.getClientWidth() < speakerListRef.current.getScrollWidth();
  }, [speakerListRef]);

  useEffect(() => {
    function handleResize() {
      setChevronLeftActive(isChevronLeftActive());
      setChevronRightActive(isChevronRightActive());
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isChevronLeftActive, isChevronRightActive, setChevronLeftActive, setChevronRightActive]);

  const setupChevrons = useCallback(() => {
    setChevronLeftActive(isChevronLeftActive());
    setChevronRightActive(isChevronRightActive());
  }, [isChevronLeftActive, isChevronRightActive]);


  useEffect(() => {
    setupChevrons();
  }, [setupChevrons]);

  return (
    <RootContainer>
      <StyledChevronLeftButton
        active={`${chevronLeftActive}`}
        onClick={onChevronLeftClick}
        keepTimeLineSpace={options.timeLine.enable}
      >
        <ChevronLeftIcon />
      </StyledChevronLeftButton>
      <StyledScrollbars
        ref={speakerListRef}
        autoHeight
        autoHeightMin={minHeight}
        autoHeightMax={maxHeight}
        autoHide
        style={{ width: 'auto' }}
        onScrollStop={setupChevrons}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
        renderTrackVertical={() => <div />}
      >
        <ListContainer ref={speakerListRef}>
          {
            children
          }
        </ListContainer>
      </StyledScrollbars>
      <StyledChevronRightButton
        active={`${chevronRightActive}`}
        onClick={onChevronRightClick}
      >
        <ChevronRightIcon />
      </StyledChevronRightButton>
    </RootContainer>
  );
};

export default MultiTracksHorizontalScrollbarHider;
