/* eslint-disable react/destructuring-assignment */
import React from 'react';
import styled from 'styled-components';
import { Booth } from 'models/exhibitors';

import AutoSizer from 'react-virtualized-auto-sizer';
import ListComponent from './ListComponent';

const BoothListContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  width: 100%;
`;

const LazyBoothList = ({
  exhibitors = [],
  isDesktop,
  isFiltered,
  hasFilter,
}: {
  isDesktop: boolean;
  isFiltered: boolean;
  exhibitors: Booth[];
  hasFilter: boolean;
}) => (
  <BoothListContainer>
    <AutoSizer itemCount={exhibitors.length}>
      {({ height, width }) => (
        <ListComponent
          height={height}
          width={width}
          isDesktop={isDesktop}
          hasFilter={hasFilter}
          isFiltered={isFiltered}
          exhibitors={exhibitors}
        />
      )}
    </AutoSizer>
  </BoothListContainer>
);

export default LazyBoothList;
