import React, { ReactElement } from 'react';
import styled from 'styled-components';

const AspectRatioContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 49.56%;
`;

const AssetContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & .react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat;
  }
`;

const CustomSlide = ({ children }: { children: ReactElement }) => (
  <AspectRatioContainer>
    <AssetContainer>
      {children}
    </AssetContainer>
  </AspectRatioContainer>
);

export default CustomSlide;
