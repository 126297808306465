import React from 'react';
import PropTypes from 'prop-types';

const Warning = ({ className }) => (
  <svg className={className} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.625 28.125C18.625 28.6223 18.8225 29.0992 19.1742 29.4508C19.5258 29.8025 20.0027 30 20.5 30C20.9973 30 21.4742 29.8025 21.8258 29.4508C22.1775 29.0992 22.375 28.6223 22.375 28.125C22.375 27.6277 22.1775 27.1508 21.8258 26.7992C21.4742 26.4475 20.9973 26.25 20.5 26.25C20.0027 26.25 19.5258 26.4475 19.1742 26.7992C18.8225 27.1508 18.625 27.6277 18.625 28.125Z" fill="#E2654D" />
    <path d="M19.25 16.25V23.4375C19.25 23.6094 19.3906 23.75 19.5625 23.75H21.4375C21.6094 23.75 21.75 23.6094 21.75 23.4375V16.25C21.75 16.0781 21.6094 15.9375 21.4375 15.9375H19.5625C19.3906 15.9375 19.25 16.0781 19.25 16.25Z" fill="#E2654D" />
    <path d="M37.832 33.4375L21.582 5.3125C21.3399 4.89453 20.9219 4.6875 20.5 4.6875C20.0781 4.6875 19.6563 4.89453 19.418 5.3125L3.16798 33.4375C2.68751 34.2734 3.28908 35.3125 4.25001 35.3125H36.75C37.7109 35.3125 38.3125 34.2734 37.832 33.4375ZM7.22658 32.3477L20.5 9.37109L33.7734 32.3477H7.22658Z" fill="#E2654D" />
  </svg>
);

Warning.propTypes = {
  className: PropTypes.string,
};

Warning.defaultProps = {
  className: '',
};

export default Warning;
