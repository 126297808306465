import React, { useMemo } from 'react';
import convertStreamableLink from 'utils/streamableLinkUtils';
import ReactPlayer from 'react-player';
import { Stream } from '@cloudflare/stream-react';
import styled from 'styled-components';
import { selectLocale } from 'models/localization';
import { useSelector } from 'react-redux';
import { WebinarPropType } from '../../models/types/webinars';

const CloudFlareVideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const EmbeddedVideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 24px;
`;

const WebinarMedia = ({ webinar }) => {
  const locale = useSelector(selectLocale);
  const translation = webinar.translations[locale] || {};
  const streamableContent = useMemo(() => convertStreamableLink(
    // eslint-disable-next-line camelcase
    translation.cloudflare_stream_id
    || translation.url,
  ), [translation.cloudflare_stream_id, translation.url]);

  if (streamableContent.type === 'cfStream') {
    return !!streamableContent.id && (
      <EmbeddedVideoContainer>
        <CloudFlareVideoWrapper>
          <Stream
            controls
            autoplay
            src={streamableContent.id}
            height="100%"
            width="100%"
          />
        </CloudFlareVideoWrapper>
      </EmbeddedVideoContainer>
    );
  }

  return !!streamableContent.url && (
    <EmbeddedVideoContainer>
      <ReactPlayer
        url={streamableContent.url}
        width="100%"
        height="100%"
        controls
        playsinline
        config={{
          youtube: {
            playerVars: {
              autoplay: 1,
            },
          },
          file: {
            attributes: {
              autoPlay: true,
              disablePictureInPicture: true,
              controlsList: 'nodownload noremoteplayback',
            },
          },
        }}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
    </EmbeddedVideoContainer>
  );
};

WebinarMedia.propTypes = {
  webinar: WebinarPropType.isRequired,
};

export default WebinarMedia;
