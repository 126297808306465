import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <path
    d="M24.0874 9.1813L21.6428 7.57162C21.619 7.55714 21.5917 7.54956 21.5638 7.54974C21.4784 7.54974 21.4996 7.66971 21.4996 7.7558V9.16719C21.4996 9.5278 21.1192 9.87782 20.7614 9.87782H13.6755C13.5044 9.87559 13.3412 9.80551 13.2217 9.68298C13.1022 9.56045 13.0362 9.39549 13.0383 9.22435V3.46024C13.0368 3.28947 13.103 3.12506 13.2224 3.00298C13.3419 2.88091 13.5048 2.81112 13.6755 2.80889H20.7614C21.1185 2.80889 21.4982 3.16173 21.4996 3.52164V4.93303C21.4996 4.96125 21.4163 5.24494 21.4311 5.26894C21.4742 5.34374 21.5694 5.36773 21.6428 5.32398L24.0605 3.72841C24.0837 3.71417 24.1103 3.7066 24.1375 3.70653C24.2214 3.70653 24.2899 3.77569 24.2899 3.86249L24.3174 9.04581C24.3174 9.07404 24.3104 9.10227 24.2962 9.12626C24.2863 9.14376 24.273 9.1591 24.257 9.17138C24.2411 9.18366 24.2228 9.19262 24.2034 9.19775C24.1839 9.20288 24.1636 9.20406 24.1437 9.20124C24.1238 9.19842 24.1046 9.19164 24.0874 9.1813ZM16.6952 18.0985C18.252 16.5417 19.2117 15.1861 22.6407 17.9439C26.0704 20.7018 23.4367 22.5387 21.9286 24.046C20.1891 25.787 13.7038 24.1378 7.29466 17.7294C0.886249 11.3181 -0.760841 4.83352 0.977284 3.09399C2.48606 1.58521 4.32439 -1.04985 7.08013 2.38124C9.83657 5.81303 8.48093 6.77136 6.92628 8.32812C5.8374 9.41489 8.10762 12.1657 10.483 14.5396C12.8583 16.9136 15.6063 19.1824 16.6952 18.0985Z"
  />,
  'VideoCall',
  { viewBox: '0 0 25 25' },
);
