import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import WatchNowIcon from 'components/icons/WatchNow';
import ActionButton from './ActionButton';

const StyledBtnText = styled.div`
  margin-left: 10px;
`;

const ComingSoonButton = () => {
  const { t } = useTranslation();
  return (
    <ActionButton disabled>
      <WatchNowIcon color="#FFF" />
      <StyledBtnText>
        {t('webinar.session.coming_soon')}
      </StyledBtnText>
    </ActionButton>
  );
};

export default ComingSoonButton;
