/**
 * Hex color code #FFF / #FFFFFF / FFF / FFFFFF to rgb [255,255,255]
 */
export function hexToRGB(hex: string): number[] {
  let transformedHex = hex;
  if (transformedHex.startsWith('#')) {
    transformedHex = transformedHex.slice(1);
  }
  if (transformedHex.length === 3) {
    transformedHex = transformedHex.split('').map((char) => char + char).join('');
  }
  return transformedHex.match(/.{1,2}/g).map((it) => parseInt(it, 16));
}

/**
 * RGBA array mix with background color to RGB
 */
export function rgbaToRGB(rgba: number[], backgroundRGB: number[] = [255, 255, 255]) {
  const alpha = rgba[3];
  const rgb = rgba.slice(0, -1);
  return rgb.map((value, index) => value * alpha + backgroundRGB[index] * (1 - alpha));
}
