import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const videoCallQuitMutation = `
  mutation videoCallParticipantQuit($participantId: String!, $roomToken: String!) {
    videoCallParticipantQuit(input: {
      participantId: $participantId,
      roomToken: $roomToken
    }) {
      success
    }
  }  
`;

const quitVideoCallRoom = (participantId: string, roomToken: string): Promise<any> => request(
  API_URL,
  videoCallQuitMutation,
  {
    participantId,
    roomToken,
  },
).then(({ videoCallParticipantQuit }) => videoCallParticipantQuit);

export default quitVideoCallRoom;
