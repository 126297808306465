import { en as enCommon, ja as jaCommon } from './common';
import { en as enLanding, ja as jaLanding } from './landing';
import { en as enLobby, ja as jaLobby } from './lobby';

export default {
  en: {
    common: enCommon,
    landing: enLanding,
    lobby: enLobby,
  },
  ja: {
    common: jaCommon,
    landing: jaLanding,
    lobby: jaLobby,
  },
};
