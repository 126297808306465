import React from 'react';
import styled from 'styled-components';
import { selectWebinarTracksMap } from 'models/webinars';
import { LocalizationState, selectLocalizationState } from 'models/localization';
import { useSelector } from 'react-redux';
import { BREAKPOINT } from 'pages/webinar/constants';
import { LiveWebinarMultiTracksOptions } from 'pages/webinar/live/LiveWebinarListOptions';

const RootContainer = styled.div`
  padding: 80px 16px 24px 24px;
  width: 305px;
  height: 100%;
  border-right: #DBDEE2 1px solid;
  flex: 0 0 auto;

  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

const TrackContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  color: #484848;

  &::before {
    width: 16px;
    height: 16px;
    background: ${(props) => props.color};
    content: "";
    margin-right: 8px;
    border-radius: 50%;
  }
`;

const LiveWebinarTracksList = ({ multiTrackOptions }: { multiTrackOptions: LiveWebinarMultiTracksOptions }) => {
  const webinarTracksMap = useSelector(selectWebinarTracksMap());
  const tracks = multiTrackOptions.multiTracksId.map((id) => webinarTracksMap[id]);
  const localization: LocalizationState = useSelector(selectLocalizationState);
  return (
    <RootContainer>
      {
        tracks.filter((it) => !!it).map((track) => (
          <TrackContainer color={track.color} key={track.id}>
            {track.translations[localization.locale].name}
          </TrackContainer>
        ))
      }
    </RootContainer>
  );
};

export default LiveWebinarTracksList;
