import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import { Booth } from 'models/cms/booths';
import { BoothContext } from 'pages/booths/BoothProfileContainer';
import leftBannerSvg from 'assets/booths/cuhk925/left_banner.svg';
import rightBannerSvg from 'assets/booths/cuhk925/right_banner.svg';

import BoothContainer from '../../../components/BoothContainer';
import OrangeShelf from '../../../components/brochureShelf/OrangeShelf';
import GenericCMSBoothBanner from '../../../components/GenericCMSBoothBanner';
import BoothSaleContainer from '../BoothSaleContainer';
import LogoDesk from '../../../components/desk/LogoDesk';
import BoothBackground from './BoothBackground';

const TopBannerContainer = styled.div`
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 315px;
  height: 190px;
  top: 245px;
  left: calc((90% - 315px) / 2);
  z-index: 2;
`;

const TopBannerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ShowDeskContainer = styled.div<{ enableClick: boolean }>`
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 450px;
  width: 300px;
  left: calc((90% - 300px) / 2);
  z-index: 2;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: -50px;
`;

const LeftBannerBgContainer = styled.div<{ bg: any }>`
  width: 200px;
  height: 430px;
  padding: 6.4px 9px 9px;
  position: absolute;
  bottom: -83px;
  left: -132px;
  z-index: 2;
  background: url(${(props) => props.bg}) no-repeat;
`;

const LeftBannerContainer = styled.div`
  height: 378px;
  background-color: #FFF;
  width: 172px;
  left: 6px;
  position: relative;
`;

const DisplayBoardBgContainer = styled.div<{ bg: any }>`
  width: 170px;
  height: 318px;
  padding: 6.4px 9px 9px;
  position: absolute;
  bottom: -30px;
  left: 68px;
  z-index: 2;
  background: url(${(props) => props.bg}) no-repeat;
`;

const DisplayBoardContainer = styled.div`
  width: 154px;
  height: 200px;
  position: relative;
  background-color: #FFF;
  left: -4px;
`;

const BrochureContainer = styled.div<{ enableClick: boolean }>`
  bottom: -90px;
  right: 195px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
  position: absolute;
  width: 125px;
  z-index: 2;
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  bottom: -100px;
  z-index: 2;
`;

const CenterBannerContainer = styled.div`
  position: relative;
  transform: scale(0.7, 0.7);
  left: -35px;
`;

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 900px;
`;

export interface BannerProps {
  booth: Booth;
  handleClick: (type: string) => void;
  isPreview?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  booth,
  handleClick = () => null,
  isPreview = false,
}: BannerProps) => {
  const {
    leftColor, rightColor,
    leftBanner, displayBoard, topBanner, centerBanner,
  } = useMemo(() => {
    const elements = (booth.designElements || []);
    return ({
      leftColor: (elements.find((designNode) => designNode.name === 'left_color') || {}).color,
      rightColor: (elements.find((designNode) => designNode.name === 'right_color') || {}).color,
      leftBanner: elements.find((designNode) => designNode.name === 'left_banner'),
      displayBoard: elements.find((designNode) => designNode.name === 'left_banner'),
      topBanner: elements.find((designNode) => designNode.name === 'top_banner'),
      centerBanner: elements.find((designNode) => designNode.name === 'middle_banner'),
    });
  }, [booth.designElements]);
  const boothForMatomo = useContext(BoothContext);
  const showRightRepresentative = useMemo(() => !isPreview && !!(booth.designElements || []).find((designNode) => designNode.name === 'right_representative'), [isPreview, booth.designElements]);
  const defaultColor = '#FDF793';

  const onBannerClick = (key) => {
    window.trackingEvent('Booth', key, `${boothForMatomo.id} - ${boothForMatomo.boothNameForMatomo}`);
    switch (key) {
      case 'Banner 3_Click':
        if (booth.website) {
          window.open(booth.website, '_blank');
        }
        break;
      default:
        break;
    }
  };

  return (
    <RootContainer>
      <BoothContainer needOffset={!isPreview}>
        <BackgroundContainer>
          <BoothBackground leftColor={leftColor || rightColor || defaultColor} rightColor={rightColor || leftColor || defaultColor} />
        </BackgroundContainer>
        <TopBannerContainer onClick={() => onBannerClick('Banner 2_Click')}>
          <TopBannerWrapper>
            <GenericCMSBoothBanner
              onBannerClick={handleClick as any}
              autoPlayVideo={!isPreview}
              bannerData={topBanner}
            />
          </TopBannerWrapper>
        </TopBannerContainer>
        <ShowDeskContainer enableClick={!!booth.website} onClick={() => onBannerClick('Banner 3_Click')}>
          <CenterBannerContainer>
            <LogoDesk
              deskColor="#484848"
              logo={centerBanner}
              showShadow={false}
            />
          </CenterBannerContainer>
        </ShowDeskContainer>
        <LeftBannerBgContainer bg={leftBannerSvg}>
          <LeftBannerContainer onClick={() => onBannerClick('Banner 1_Click')}>
            <GenericCMSBoothBanner
              bannerData={leftBanner}
            />
          </LeftBannerContainer>
        </LeftBannerBgContainer>
        <DisplayBoardBgContainer bg={rightBannerSvg}>
          <DisplayBoardContainer onClick={() => onBannerClick('Banner 4_Click')}>
            <GenericCMSBoothBanner
              bannerData={displayBoard}
            />
          </DisplayBoardContainer>
        </DisplayBoardBgContainer>
        <BrochureContainer enableClick={booth.hasAttachment} onClick={() => handleClick('attachment')}>
          <OrangeShelf hasAttachment={booth.hasAttachment} />
        </BrochureContainer>
        {
          showRightRepresentative && (
            <SaleContainer id="reps">
              <BoothSaleContainer booth={booth} sale="male-right" />
            </SaleContainer>
          )
        }
      </BoothContainer>
    </RootContainer>
  );
};

export default Banner;
