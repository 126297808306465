import styled from 'styled-components';
import React from 'react';
import HtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { WebinarSpeakerPropType } from 'models/types/webinars';
import Routes from 'Routes';
import ShowMore from 'components/utils/ShowMore';

const WebinarSpeakerContainer = styled.div`
  padding: 24px 0 16px;
`;

const WebinarSpeakerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 26px;
`;

const WebinarSpeakerAvatar = styled(Avatar)`
  width: 64px !important;
  height: 64px !important;
  display: flex;

  & img {
    vertical-align: middle;
  }
`;

const WebinarSpeakerInfo = styled.div`
  margin-left: 24px;
`;

const WebinarSpeakerInfoRow = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #A2AAB3;
  margin-top: 8px;
`;

const WebinarSpeakerName = styled(Link)`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #484848;
  text-decoration: none;
`;

const WebinarSpeakerDetails = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #484848;
`;

const locale = 'en';

const WebinarSpeakerItem = ({ webinarId, speaker }) => {
  const {
    name, company, title, bio,
  } = speaker.translations[locale];

  return (
    <WebinarSpeakerContainer>
      <WebinarSpeakerHeader>
        <Link to={Routes.webinarSpeaker(webinarId, speaker.id)}>
          <WebinarSpeakerAvatar
            // eslint-disable-next-line camelcase
            src={speaker.profile_picture_file?.data?.full_url ?? speaker.profile_picture_url}
          />
        </Link>
        <WebinarSpeakerInfo>
          {name && <WebinarSpeakerName to={Routes.webinarSpeaker(webinarId, speaker.id)}>{name}</WebinarSpeakerName>}
          {company && <WebinarSpeakerInfoRow>{company}</WebinarSpeakerInfoRow>}
          {title && <WebinarSpeakerInfoRow>{title}</WebinarSpeakerInfoRow>}
        </WebinarSpeakerInfo>
      </WebinarSpeakerHeader>
      <WebinarSpeakerDetails>
        <ShowMore maxHeight={152}>
          {HtmlParser(bio)}
        </ShowMore>
      </WebinarSpeakerDetails>
    </WebinarSpeakerContainer>
  );
};

WebinarSpeakerItem.propTypes = {
  webinarId: PropTypes.number.isRequired,
  speaker: WebinarSpeakerPropType.isRequired,
};

export default WebinarSpeakerItem;
