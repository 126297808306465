import React from 'react';
import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <path
    d="M9.73633 0.8125C4.9043 0.8125 0.986328 4.73047 0.986328 9.5625C0.986328 14.3945 4.9043 18.3125 9.73633 18.3125C14.5684 18.3125 18.4863 14.3945 18.4863 9.5625C18.4863 4.73047 14.5684 0.8125 9.73633 0.8125ZM9.73633 16.8281C5.72461 16.8281 2.4707 13.5742 2.4707 9.5625C2.4707 5.55078 5.72461 2.29688 9.73633 2.29688C13.748 2.29688 17.002 5.55078 17.002 9.5625C17.002 13.5742 13.748 16.8281 9.73633 16.8281Z M13.7871 9.31062L8.00391 5.1114C7.79687 4.96101 7.50977 5.1075 7.50977 5.36336V13.7618C7.50977 14.0177 7.79883 14.1622 8.00391 14.0137L13.7871 9.81453C13.959 9.68953 13.959 9.43562 13.7871 9.31062ZM8.75586 11.9278V7.19734L12.0117 9.56258L8.75586 11.9278Z"
    fill="#484848"
  />,
  'Video',
  { viewBox: '0 0 19 19' },
);
