import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import playButton from 'assets/play-button.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import BannerSmallImg from 'assets/main_lobby/banner-container-small.png';
import BannerLargeImg from 'assets/main_lobby/banner-container-large.png';

const getRootContainerBackground = (size) => {
  switch (size) {
    case 'large':
      return BannerLargeImg;
    case 'small':
    default:
      return BannerSmallImg;
  }
};

const getRootContainerHeight = (size) => {
  switch (size) {
    case 'large':
      return 349;
    case 'small':
    default:
      return 307;
  }
};

const getRootContainerWidth = (size) => {
  switch (size) {
    case 'large':
      return 540;
    case 'small':
    default:
      return 360;
  }
};

const RootContainer = styled.div`
  background: url(${({ size }) => getRootContainerBackground(size)});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ size }) => getRootContainerHeight(size)}px;
  width: ${({ size }) => getRootContainerWidth(size)}px;
`;

const SliderContainer = styled.div`
  background-color: #FFF;
  color: #484848;
  margin-top:
    ${({ size }) => {
    switch (size) {
      case 'large':
        return 59;
      case 'small':
      default:
        return 100;
    }
  }}px;
  height:
    ${({ size }) => {
    switch (size) {
      case 'large':
        return 280;
      case 'small':
      default:
        return 200;
    }
  }}px;
  width:
    ${({ size }) => {
    switch (size) {
      case 'large':
        return 540;
      case 'small':
      default:
        return 360;
    }
  }}px;
  overflow: hidden;

  & video {
    background-color: black;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-prev, .slick-next {
    display: none !important;
    z-index: 1000;

    &::before {
      color: #484848;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-slide {
    height: 100%;
    background-color: #FFF;
  }

  &:hover {
    .slick-prev, .slick-next {
      display: block !important;
    }
  }
`;

const AspectRatioContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom:
    ${({ size }) => {
    switch (size) {
      case 'large':
        return '51.85%';
      case 'small':
      default:
        return '55.56%';
    }
  }};
`;

const AssetContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledAnchor = styled.a`
  flex: 0 0 auto;
  margin: 0;
  text-decoration: none;
  user-select: all;
  pointer-events: all;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
  max-height: 100%;
  margin: 0 8px;
  width: 100%;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  user-select: all;
  pointer-events: all;
`;

const CustomSlide = ({ children, size }) => (
  <AspectRatioContainer size={size}>
    <AssetContainer>
      {children}
    </AssetContainer>
  </AspectRatioContainer>
);

CustomSlide.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
};

const BannerView = ({
  className,
  size,
  assets,
  autoPlay,
}) => {
  const [videoStateMap, setVideoStateMap] = React.useState({});

  return (
    <RootContainer className={className} size={size}>
      <SliderContainer size={size}>
        <StyledSlider
          arrows={assets.length > 1}
          autoplay={autoPlay}
          swipe={false}
          pauseOnHover={false}
          beforeChange={(oldIndex, newIndex) => {
            setVideoStateMap({
              ...videoStateMap,
              [oldIndex]: false,
              [newIndex]: true,
            });
          }}
        >
          {
            assets.map(({
              src,
              type,
              href,
            }, index) => {
              switch (type) {
                case 'embed':
                  return (
                    <CustomSlide key={index} size={size}>
                      <StyledIframe src={src} allow="fullscreen" />
                    </CustomSlide>
                  );
                case 'video':
                  return (
                    <ReactPlayer
                      key={src}
                      url={src}
                      loop
                      controls
                      playIcon={<img src={playButton} alt="Play Button" />}
                      width="100%"
                      playing={videoStateMap[index]}
                      onPlay={() => setVideoStateMap({
                        ...videoStateMap,
                        [index]: true,
                      })}
                      onPause={() => setVideoStateMap({
                        ...videoStateMap,
                        [index]: false,
                      })}
                      height="280px"
                      config={{
                        file: {
                          attributes: {
                            autoPlay: true,
                            disableRemotePlayback: true,
                            disablePictureInPicture: true,
                            controlsList: 'nodownload',
                            muted: true,
                          },
                        },
                      }}
                      playsinline
                      style={{ zIndex: 100, backgroundColor: 'black' }}
                    />
                  );
                case 'image':
                default:
                  return (
                    <CustomSlide key={index} size={size}>
                      {
                        href && href.trim() !== '' ? (
                          <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
                            <StyledImg src={src} />
                          </StyledAnchor>
                        ) : (
                          <StyledImg src={src} />
                        )
                      }
                    </CustomSlide>
                  );
              }
            })
          }
        </StyledSlider>
      </SliderContainer>
    </RootContainer>
  );
};

BannerView.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  assets: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    type: PropTypes.string,
  })),
  autoPlay: PropTypes.bool.isRequired,
};

BannerView.defaultProps = {
  assets: [],
  size: 'small',
  className: null,
};

export default BannerView;
