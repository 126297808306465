import React from 'react';
import styled from 'styled-components';
import { PAGE_NAMES } from 'appenv';
import Webinar618Background from 'assets/main_lobby/webinar-polyu618.png';
import HallPullUpBannerContainer from '../HallPullUpBannerContainer';

const IMG_OFFSET = 6.5;
const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.4);
  line-height: ${275 - IMG_OFFSET * 2}px;
  letter-spacing: -0.408px;
`;

const StyledBackdrop = styled.div`
  height: calc(100% - ${IMG_OFFSET * 2}px);
  width: 100%;
  background: url(${Webinar618Background});
  position: absolute;
  background-position: center;
  background-size: contain;
  top: ${IMG_OFFSET}px;
`;

const Webinar618Template = () => (
  <HallPullUpBannerContainer>
    <StyledBackdrop>
      <StyledTitle>
        {PAGE_NAMES.webinar}
      </StyledTitle>
    </StyledBackdrop>
  </HallPullUpBannerContainer>
);

export default Webinar618Template;
