import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PAGE_NAMES, BRAND_COLOR, APP } from 'appenv';
import { SearchResultsSlice } from 'models/searchResults';
import { UserSessionContext } from 'components/UserSession';
import { SearchFieldContext } from 'components/SearchFieldContext';
import BackToTopButton from 'components/BackToTopButton';
import Footer from 'components/footer/Footer';

import SearchFieldContainer from './SearchFieldContainer';
import SearchResultTabContentContainer from './SearchResultTabContentContainer';

const StyledDialog = withStyles(() => ({
  paper: {
    backgroundColor: '#F0F1F2',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}))(Dialog);

const HeaderContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;
`;

const StyledTabsContainer = withStyles(() => ({
  root: {
    padding: '8px 3vw',
    minHeight: '52px',
  },
  indicator: {
    backgroundColor: BRAND_COLOR || '#0CA58B',
    borderRadius: '100px',
    height: '100%',
    zIndex: 10,
  },
}))(Tabs);

const StyledTab = withStyles(() => ({
  root: {
    color: '#999',
    fontSize: '13px',
    fontWeight: 'bold',
    textTransform: 'none',
    flex: '1 0 auto',
    zIndex: 20,
    minHeight: 0,
    width: 'auto',
    letterSpacing: '0.09375px',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
  },
  selected: {
    color: '#FFF',
  },
}))(Tab);

const BodyContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const SearchModal = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { location: { state } } = history;
  const [searchInputText, setSearchInputText] = useState((state && state.searchInputText) || '');
  const [activeTab, setActiveTab] = useState(() => {
    if (APP === 'ms624') return 1;
    return (state && state.activeTab) || 0;
  });

  const { canSee } = useContext(UserSessionContext);
  const { fetchSearchResultsWithDeboune } = useContext(SearchFieldContext);

  const showResult = searchInputText.length >= 2;
  const showTabs = APP !== 'ms624';

  const fetchMore = useCallback(() => {
    fetchSearchResultsWithDeboune({
      newSearchText: showResult ? searchInputText : '',
    });
  }, [fetchSearchResultsWithDeboune, searchInputText, showResult]);

  const isDataReady = useSelector((s) => (
    !((s.cms.booths.fetching && s.exhibitors.fetching) || s.webinars.isLoading)
  ));

  const onSeacrhInputChange = (event) => {
    history.replace({
      state: {
        ...state,
        searchInputText: event.target.value,
      },
    });
  };

  const onTabClick = (_, selectedTab) => {
    history.replace({
      state: {
        ...state,
        activeTab: selectedTab,
      },
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const unlisten = history.listen(({ state: newState }) => {
      const newSearchInputText = (newState && newState.searchInputText) || '';
      setSearchInputText(newSearchInputText);
      if (APP === 'ms624') {
        setActiveTab(1);
      } else {
        setActiveTab((newState && newState.activeTab) || 0);
      }
    });
    return unlisten;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    if (isDataReady) {
      batch(() => {
        dispatch(SearchResultsSlice.actions.resetSearchResults());
        if (searchInputText.length >= 2) {
          fetchMore();
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputText, isDataReady]);

  const [scrollableTarget, setScrollableTarget] = useState();
  const scrollParentRef = useRef();

  const onDialogEntered = () => {
    setScrollableTarget(scrollParentRef.current);
  };

  const viewableTabs = [
    {
      label: t('all', 'All'),
      condition: true,
    },
    {
      label: PAGE_NAMES.exhibitors || t('page.exhibitors', 'Exhibitors'),
      condition: canSee('hall'),
    },
    {
      label: PAGE_NAMES.webinar || t('page.webinar', 'Webinars'),
      condition: canSee('webinar'),
    },
    {
      label: PAGE_NAMES.participant || t('page.participants', 'Participants'),
      condition: canSee('participant'),
    },
  ]
    .filter((it) => it.condition)
    .map(({ label }) => <StyledTab key={label} label={label} />);

  return (
    <StyledDialog
      fullScreen
      open={open}
      onClose={onClose}
      onEntered={onDialogEntered}
      aria-labelledby="search-modal"
    >
      <HeaderContainer>
        <SearchFieldContainer
          searchText={searchInputText}
          onChange={onSeacrhInputChange}
          onCancel={onClose}
        />
        {showTabs && viewableTabs.length > 2 && (
          <StyledTabsContainer
            value={activeTab}
            onChange={onTabClick}
            variant="scrollable"
            scrollButtons="auto"
          >
            {viewableTabs}
          </StyledTabsContainer>
        )}
      </HeaderContainer>
      <BodyContainer ref={scrollParentRef}>
        <SearchResultTabContentContainer
          activeTab={activeTab}
          showResult={showResult}
          fetchMore={fetchMore}
          scrollableTarget={scrollableTarget}
        />
        <Footer hashState="/main-lobby" />
      </BodyContainer>
      <BackToTopButton scrollableTarget={scrollableTarget} />
    </StyledDialog>
  );
};

SearchModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

SearchModal.defaultProps = {
  open: false,
  onClose: () => {},
};

export default SearchModal;
