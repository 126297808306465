import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  ORGANISER_NAME,
  ORGANISER_DOMAIN,
} from 'appenv';
import ReedBranding from 'components/footer/ReedBranding';
import EventXtraBranding from 'components/footer/EventXtraBranding';
import { envSwitch } from 'utils/envUtils';
import PicoXBranding from 'components/footer/PicoXBranding';
import Apps, { reedApps } from 'apps';
import { selectLocale } from 'models/localization';

const EventXtraBrandingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 40px;
`;

const encodeQueryData = (data) => {
  if (typeof data !== 'object') {
    throw new Error('Unable to encode query data, parameter is not an object.');
  }
  return Object.keys(data).map((key) => [key, data[key]].map(encodeURIComponent).join('=')).join('&');
};

const mapLocale = (locale) => {
  switch (locale) {
    case 'zh-Hant': return 'zh-TW';
    case 'zh-Hans': return 'zh-CN';
    default: return locale;
  }
};

const Footer = ({ hashState, className }) => {
  const locale = useSelector(selectLocale);

  const queryData = {
    organiser: ORGANISER_NAME,
    domain: ORGANISER_DOMAIN,
    utm_source: 'eventxtra',
    utm_medium: 'internal_referral',
    utm_campaign: 'virtual_page_banner',
  };

  const brandingUrl = envSwitch([
    [Apps.PicoX707, null],
  ], `https://eventxtra.com/${mapLocale(locale)}/virtual-exhibition-v/?${encodeQueryData(queryData)}#${hashState || ''}`);

  const Element = envSwitch([
    [Apps.PicoX707, PicoXBranding],
    [reedApps, ReedBranding],
  ], EventXtraBranding);

  return (
    <EventXtraBrandingContainer className={className} onClick={() => window.trackingEvent('Event Navigation', 'Footer_Click', '')}>
      <Element url={brandingUrl} />
    </EventXtraBrandingContainer>
  );
};

Footer.propTypes = {
  hashState: PropTypes.string,
  className: PropTypes.string,
};

Footer.defaultProps = {
  hashState: '',
  className: '',
};

export default Footer;
