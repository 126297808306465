import styled from 'styled-components';

import HallPullUpBannerImg from '../../assets/main_lobby/hall-pull-up-banner.png';

export default styled.div`
  background: url(${HallPullUpBannerImg});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #FFF;
  width: 640px;
  height: 275px;
  margin-left: 25px;
  margin-right: 25px;
  position: relative;
`;
