import React, {
  createContext,
  useState,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import useURLSearchParams from 'hooks/useURLSearchParams';
import { fetchSearchResults } from 'models/searchResults';

const SearchFieldContext = createContext({
  searchKeyword: '',
  setSearchKeyword: () => {},
  fetchSearchResults: () => {},
  fetchSearchResultsWithDeboune: () => {},
});

const SearchFieldStateProvider = ({ children }) => {
  const query = useURLSearchParams();
  const dispatch = useDispatch();

  const [searchKeyword, setSearchKeyword] = useState(query.get('searchText') || '');

  const promiseRef = useRef();
  const fetchSearchResultFromRemote = ({ newSearchText, limit }) => {
    setSearchKeyword(newSearchText);
    if (promiseRef.current) {
      promiseRef.current.abort();
    }
    promiseRef.current = dispatch(fetchSearchResults({ keyword: newSearchText, limit }));
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSearchResultsWithDeboune = useCallback(debounce(fetchSearchResultFromRemote, 2000), []);

  return (
    <SearchFieldContext.Provider
      value={{
        searchKeyword,
        setSearchKeyword,
        fetchSearchResults: fetchSearchResultFromRemote,
        fetchSearchResultsWithDeboune,
      }}
    >
      {children}
    </SearchFieldContext.Provider>
  );
};

SearchFieldStateProvider.propTypes = {
  children: PropTypes.node,
};

SearchFieldStateProvider.defaultProps = {
  children: null,
};

export {
  SearchFieldContext,
  SearchFieldStateProvider,
};
