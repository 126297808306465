import React from 'react';
import styled from 'styled-components';
import { Stream } from '@cloudflare/stream-react';
import ReactPlayer from 'react-player';
import PlayButton from 'assets/play-button.png';

import BannerDescriptionImage from './BannerDescriptionImage';
import BannerPlayButtonImage from './BannerPlayButtonImage';

const BannerPreviewImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const BannerVideoPreview = React.forwardRef(({
  isPreview,
  cfStreamId,
  bannerInlineVideoUrl,
  bannerInlineVideoPreviewThumbnail,
}: {
  isPreview: boolean;
  cfStreamId?: string;
  bannerInlineVideoUrl: string;
  bannerInlineVideoPreviewThumbnail: string;
}, ref: React.RefObject<ReactPlayer | HTMLVideoElement>) => {
  if (isPreview) {
    if (!bannerInlineVideoPreviewThumbnail) return null;
    return (
      <BannerPreviewImage
        src={bannerInlineVideoPreviewThumbnail}
        alt="Poster"
        data-object-fit="contain"
        data-object-position="center"
      />
    );
  }

  if (cfStreamId) {
    return (
      <Stream
        autoplay={!isPreview}
        controls
        loop
        poster={bannerInlineVideoPreviewThumbnail}
        src={cfStreamId}
        streamRef={ref ? ref as React.RefObject<HTMLVideoElement> : undefined}
        width="100%"
        height="100%"
      />
    );
  }

  return (
    <ReactPlayer
      url={bannerInlineVideoUrl}
      controls
      ref={ref ? ref as React.RefObject<ReactPlayer> : undefined}
      playIcon={<img src={PlayButton} alt="Play Button" />}
      width="100%"
      height="100%"
      config={{
        file: {
          attributes: {
            autoPlay: !isPreview,
            playsInline: true,
            loop: true,
            disableRemotePlayback: true,
            disablePictureInPicture: true,
            controlsList: 'nodownload',
          },
        },
      }}
      style={{
        zIndex: 100,
        backgroundColor: 'black',
        padding: '0 !important',
      }}
    />
  );
});

const BannerContentElement = React.forwardRef(({
  bannerData,
  name = '',
  objectFit = 'contain',
  isPreview,
}: {
  bannerData: Record<any, any>;
  name: string;
  objectFit: 'contain' | 'cover';
  isPreview: boolean;
}, ref: React.RefObject<ReactPlayer | HTMLVideoElement>) => {
  const { preview, file } = bannerData;
  // eslint-disable-next-line camelcase
  const previewLight = bannerData?.video_preview ?? bannerData?.video_thumbnail;
  const isVideoPreview = !!preview?.type?.startsWith('video');
  const isVideoFile = !!file?.type?.startsWith('video');

  const directusThumbKey = isPreview ? 'thumbnail' : 'directus-large-contain';

  // eslint-disable-next-line camelcase
  const bannerImageUrl = preview?.data?.thumbnails?.find((thumb) => thumb?.url?.endsWith(directusThumbKey))?.url
  // eslint-disable-next-line camelcase
    ?? file?.data?.thumbnails?.find((thumb) => thumb?.url?.endsWith(directusThumbKey))?.url
    // eslint-disable-next-line camelcase
    ?? file?.data?.full_url;
  // eslint-disable-next-line camelcase
  const bannerInlineVideoUrl = preview?.data?.full_url;
  const bannerInlineVideoPreviewThumbnail = previewLight?.data?.thumbnails?.find((thumb) => thumb?.url?.endsWith(directusThumbKey))?.url;
  return (
    <>
      {isVideoPreview ? (
        <BannerVideoPreview
          isPreview={isPreview}
          ref={ref}
          cfStreamId={bannerData.cloudflare_stream_id}
          bannerInlineVideoUrl={bannerInlineVideoUrl}
          bannerInlineVideoPreviewThumbnail={bannerInlineVideoPreviewThumbnail}
        />
      ) : (
        <>
          {bannerImageUrl && (
            <BannerDescriptionImage
              src={bannerImageUrl}
              alt={`${name} Description`}
              className="media"
              objectFit={objectFit}
              data-object-fit={objectFit}
              data-object-position="center"
            />
          )}
          {isVideoFile && (<BannerPlayButtonImage src={PlayButton} />)}
        </>
      )}
    </>
  );
});

export default BannerContentElement;
