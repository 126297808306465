/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';

const ChatContext = React.createContext({
  targetId: '',
  type: '',
  showChatWindow: (id, chatType, title) => {},
  closeChatWindow: () => {},
  disconnect: () => {},
  chatroomAvailable: false,
  rtcAlert: '',
  setRtcAlert: () => {},
  createVideoCall: () => {},
  createGroupVideoCall: (name, id) => {},
  sendMessage: (groupId, conversationType, messageValue, messageType) => {},
  status: '',
});

export default ChatContext;
