import moment from 'moment';
import remoteTimeNow from 'libs/remote-time-now';
import Apps from 'apps';
import { APP } from 'appenv';

// Datetime of Hiding chatroom entries

// scmp711
const CHATROOM_SCMP711_DISABLED_TIME = '2020-08-02T00:00:00+08:00';

// mig828
const CHATROOM_MIG828_DISABLED_TIME_1 = '2020-08-28T13:30:00+08:00';
const CHATROOM_MIG828_REENABLED_TIME = '2020-09-11T00:00:00+08:00';
const CHATROOM_MIG828_DISABLED_TIME_2 = '2020-09-11T13:30:00+08:00';

const isChatroomDisabled = () => {
  const now = remoteTimeNow();
  switch (APP) {
    case Apps.SCMP711: {
      const chatroom711DisableTime = moment(CHATROOM_SCMP711_DISABLED_TIME);
      return now.isAfter(chatroom711DisableTime);
    }
    case Apps.Mig0828: {
      const chatroom828DisableTime1 = moment(CHATROOM_MIG828_DISABLED_TIME_1);
      const chatroom828ReenableTime = moment(CHATROOM_MIG828_REENABLED_TIME);
      const chatroom828DisableTime2 = moment(CHATROOM_MIG828_DISABLED_TIME_2);
      return now.isBetween(chatroom828DisableTime1, chatroom828ReenableTime) || now.isAfter(chatroom828DisableTime2);
    }
    default:
      return false;
  }
};

export default isChatroomDisabled;
