import request, { gql } from 'graphql-request';
import {
  CommonLocalizableString, CommonLocalizableImage, CommonBoothTier,
} from 'network/common/cmsFragment';
import { apiUrl } from 'network/common/cmsConfig';
import { fetchAllPage } from 'network/common/cmsUtils';

interface FetchMetaBoothByIdProps {
  boothId: string;
  locales: string[];
}

const fetchBoothBasicInfoByIdQuery = gql`
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonBoothTier}

  query fetchBoothBasicInfoByIdQuery(
    $boothId: ID!
    $locales: [Locale!]!
  ) {
    node(id: $boothId) {
      ... on Booth {
        id
        appliedDesignCodename
        appointmentBookingUrl
        meetupEnabled
        website
        boothNumber
        description {
          ...CommonLocalizableString
        }
        name {
          ...CommonLocalizableString
        }
        logo {
          ...CommonLocalizableImage
        }
        tier {
          ...CommonBoothTier
        }
      }
    }
  }
`;

const fetchBoothCategoriesByIdQuery = gql`
  ${CommonLocalizableString}

  query fetchBoothCategoriesByIdQuery(
    $boothId: ID!
    $locales: [Locale!]!
    $cursor: String
    $count: Int
  ) {
    node(id: $boothId) {
      ... on Booth {
        id
        categories(after: $cursor, first: $count) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            name {
              ...CommonLocalizableString
            }
          }
        }
      }
    }
  }
`;

const fetchBoothMetaById = async (config: FetchMetaBoothByIdProps) => {
  const basicInfo = await request(apiUrl, fetchBoothBasicInfoByIdQuery, config);
  const categories = (await fetchAllPage((data: any) => data.node.categories.pageInfo, fetchBoothCategoriesByIdQuery, config))
    .reduce((sum, data) => [...sum, ...(data.node.categories.nodes || [])], []);

  const result = {
    ...basicInfo.node,
    categories: { nodes: categories },
  };

  return result;
};

export default fetchBoothMetaById;
