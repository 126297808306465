import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import fetchAllParticipants from 'network/fetchAllParticipants';

export const participantEntity = new schema.Entity('participants', {}, {
  processStrategy: (entity) => {
    const { customAttendeeFields: customFields = {} } = entity;
    const {
      vexpo_chat_tag: userTag,
      vexpo_chat_user_description: userDescription,
    } = customFields;
    entity.userTag = userTag;
    entity.userDescription = userDescription;
    return entity;
  },
});

export const fetchParticipantList = createAsyncThunk(
  'participants/fetchAll',
  async (_, { getState }) => {
    const { lastCursor } = getState().participants;
    try {
      const {
        pageInfo: {
          endCursor,
          hasNextPage,
        },
        edges,
      } = await fetchAllParticipants(lastCursor);
      const participants = edges.map(({ cursor, node }) => {
        const id = node.uuid;
        return {
          ...node,
          id,
          cursor,
        };
      });
      const normalized = normalize(participants, [participantEntity]);
      return {
        entities: normalized.entities,
        lastCursor: endCursor,
        hasNextPage,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      entities: {},
    };
  },
  {
    condition: (_, { getState }) => {
      const { loading } = getState().participants;
      return !loading;
    },
  },
);

const participantsAdapter = createEntityAdapter();

export const {
  selectById: selectParticipantsById,
  selectIds: selectParticipantIds,
  selectEntities: selectParticipantEntities,
  selectAll: selectAllParticipants,
  selectTotal: selectTotalParticipants,
} = participantsAdapter.getSelectors((state) => state.participants);

const initialState = {
  ...participantsAdapter.getInitialState(),
  lastCursor: undefined,
  initialLoading: true,
  hasNextPage: true,
};

export const ParticipantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {
    resetParticipants() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchParticipantList.pending]: (state) => {
      state.loading = true;
    },
    [fetchParticipantList.fulfilled]: (state, action) => {
      const results = action.payload.entities.participants || {};
      participantsAdapter.upsertMany(state, results);
      state.lastCursor = action.payload.lastCursor;
      state.hasNextPage = action.payload.hasNextPage;
      state.initialLoading = false;
      state.loading = false;
    },
  },
});

export default ParticipantsSlice.reducer;
