import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  EVENT_START_TIME,
  EVENT_END_TIME,
  EVENT_REGISTER_START_TIME,
  EVENT_REGISTER_END_TIME,
  WEBINAR_START_TIME,
  ON_DEMAND_WEBINAR_START_TIME,
  EVENT_STAGE_PROGRAMME_REGISTER_END_TIME,
} from 'appenv';
import remoteTimeNow, { remoteTimeInit, addRemoteTimeReadyListener } from 'libs/remote-time-now';

const EventDatetimeContext = React.createContext({
  startDatetime: undefined,
  endDatetime: undefined,
  registerStartDatetime: undefined,
  registerEndDatetime: undefined,
  stageProgrammeRegisterEndDatetime: undefined,
  hasEventStarted: undefined,
  hasEventEnded: undefined,
  hasRegisterStarted: undefined,
  hasRegisterEnded: undefined,
  hasWebinarGoneLive: undefined,
  isOnDemandWebinarReady: undefined,
  hasStageProgrammeRegisterEnded: undefined,
});

const EventDatetimeContextStateProvider = ({ children }) => {
  const [startDatetime] = useState(moment(EVENT_START_TIME));
  const [endDatetime] = useState(moment(EVENT_END_TIME));
  const [registerStartDatetime] = useState(moment(EVENT_REGISTER_START_TIME));
  const [registerEndDatetime] = useState(moment(EVENT_REGISTER_END_TIME));
  const [webinarStartTime] = useState(moment(WEBINAR_START_TIME));
  const [onDemandWebinarStartTime] = useState(moment(ON_DEMAND_WEBINAR_START_TIME));
  const [stageProgrammeRegisterEndDatetime] = useState(EVENT_STAGE_PROGRAMME_REGISTER_END_TIME);
  const [hasEventStarted, setHasEventStarted] = useState(false);
  const [hasEventEnded, setHasEventEnded] = useState(false);
  const [hasRegisterStarted, setHasRegisterStarted] = useState(false);
  const [hasRegisterEnded, setHasRegisterEnded] = useState(false);
  const [hasWebinarGoneLive, setHasWebinarGoneLive] = useState(false);
  const [isOnDemandWebinarReady, setIsOnDemandWebinarReady] = useState(false);
  const [hasStageProgrammeRegisterEnded, setHasStageProgrammeRegisterEnded] = useState(false);

  const updateStates = useCallback(() => {
    const now = remoteTimeNow();
    setHasEventStarted(now.isAfter(startDatetime));
    setHasEventEnded(now.isSameOrAfter(endDatetime));
    setHasRegisterStarted(now.isAfter(registerStartDatetime));
    setHasRegisterEnded(now.isSameOrAfter(registerEndDatetime));
    setHasWebinarGoneLive(now.isSameOrAfter(webinarStartTime));
    setIsOnDemandWebinarReady(now.isSameOrAfter(onDemandWebinarStartTime));
    setHasStageProgrammeRegisterEnded(now.isAfter(stageProgrammeRegisterEndDatetime));
  }, [
    startDatetime,
    endDatetime,
    registerStartDatetime,
    registerEndDatetime,
    webinarStartTime,
    stageProgrammeRegisterEndDatetime,
    onDemandWebinarStartTime,
  ]);

  useEffect(() => {
    remoteTimeInit();
    updateStates();
    addRemoteTimeReadyListener(() => {
      updateStates();
    });
  }, [updateStates]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateStates();
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [registerStartDatetime, updateStates]);

  return (
    <EventDatetimeContext.Provider
      value={{
        startDatetime,
        endDatetime,
        registerStartDatetime,
        registerEndDatetime,
        webinarStartTime,
        stageProgrammeRegisterEndDatetime,
        hasEventStarted,
        hasEventEnded,
        hasRegisterStarted,
        hasRegisterEnded,
        hasWebinarGoneLive,
        hasStageProgrammeRegisterEnded,
        isOnDemandWebinarReady,
      }}
    >
      {children}
    </EventDatetimeContext.Provider>
  );
};

EventDatetimeContextStateProvider.propTypes = {
  children: PropTypes.node,
};

EventDatetimeContextStateProvider.defaultProps = {
  children: null,
};

export {
  EventDatetimeContext,
  EventDatetimeContextStateProvider,
};
