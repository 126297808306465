// eslint-disable-next-line import/prefer-default-export
export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/d593947f-3d05-4184-bdf6-0d243cfdc920.png',
  ],
  url: {
    exhibition_website: 'https://www.office-expo.jp/ja-jp.html',
  },
  title: 'オンライン来場・商談サービス｜第15回 【東京】総務・人事・経理Week',
};
