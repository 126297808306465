import React, { useContext } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { UserSessionContext } from 'components/UserSession';

const RootContainer = styled.div`
  flex: 0 0 auto;
`;

const HelpButton = () => {
  const { userSession } = useContext(UserSessionContext);

  const onHelpBtnClick = () => {
    // if there have new enter point please let allen know.
    window.trackingEvent('Event Navigation', 'MainMenu_Click', 'Help');
    const { $zopim } = window;
    if (!$zopim) return;
    $zopim(() => {
      $zopim.livechat.setName(userSession?.fullName || '');
      $zopim.livechat.window.show();
    });
  };

  return (
    <RootContainer>
      <IconButton
        color="inherit"
        aria-label="help"
        onClick={onHelpBtnClick}
      >
        <HelpOutlineIcon />
      </IconButton>
    </RootContainer>
  );
};

export default HelpButton;
