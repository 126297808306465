export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/14c3a42b-e0af-48ce-a31f-e23fa93e288b.png',
  ],
  url: {
    exhibition_website: 'https://www.content-tokyo.jp/en-gb.html',
    next_show: '/booth/4gQ1T7ZXjC2WWMi6YAcKGbRh4u54ZxVNjWMk7HbtB7KH',
  },
  title: 'CONTENT TOKYO 2020 -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/286aa8d8-b969-4f04-916f-792da96cb140.png',
  ],
  url: {
    exhibition_website: 'https://www.content-tokyo.jp/',
    next_show: '/booth/4gQ1T7ZXjC2WWMi6YAcKGbRh4u54ZxVNjWMk7HbtB7KH',
  },
  title: 'オンライン来場・商談サービス｜第10回 コンテンツ東京',
};
