import { gql } from 'graphql-request';
import {
  CommonLocalizableString,
} from 'network/common/cmsFragment';
import { fetchAllPage } from 'network/common/cmsUtils';

interface FetchBoothCategoriesByIdProps {
  boothId: string;
  locales: string[];
  cursor?: string;
  count?: number;
}

const fetchBoothCategoriesByIdQuery = gql`
  ${CommonLocalizableString}

  query fetchBoothCategoriesByIdQuery(
    $boothId: ID!
    $locales: [Locale!]!
    $cursor: String
    $count: Int
  ) {
    node(id: $boothId) {
      ... on Booth {
        id
        categories(after: $cursor, first: $count) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            id
            name {
              ...CommonLocalizableString
            }
          }
        }
      }
    }
  }
`;

const fetchBoothCategoriesById = async (config: FetchBoothCategoriesByIdProps) => {
  const pages = await fetchAllPage((data: any) => data.node.categories.pageInfo, fetchBoothCategoriesByIdQuery, config);
  const categories = pages.reduce((sum, data) => [...sum, ...(data.node.categories.nodes || [])], []);

  return {
    id: pages[0].node.id,
    categories: { nodes: categories },
  };
};

export default fetchBoothCategoriesById;
