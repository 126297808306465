import React, { useMemo } from 'react';
import { APP, BRAND_COLOR } from 'appenv';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const DescriptionContainer = styled.div`
  position: relative;
  line-height: 24px;
  overflow: hidden;
  height: 72px;
  flex-grow: 1;
  margin-block-start: ${({ needTopOffset }) => needTopOffset && '1em'};
  margin-block-end: 1em;
  text-align: justify;

  & p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image:
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 1)
    );
`;

const StyledH2 = styled.h2`
  margin-top: 12px;
  margin-bottom: 6px;
`;

const BoothNumContainer = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #484848;
`;

const ProfileBtn = styled.button`
  background: ${BRAND_COLOR || '#5C8DF3'};
  border-radius: 4px;
  font-family: Lato, sans-serif;
  border: 0;
  color: #FFF;
  padding: 8px 16px;
  align-self: flex-start;
  text-decoration: unset;
  cursor: pointer;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  padding: 8px 16px;
  display: flex;
  height: 205px;
  width: 100%;
  flex-direction: column;
`;

const BoothDescription = ({
  name,
  boothNumber,
  description,
  handleClick,
}) => {
  const displayBooth = useMemo(() => (APP !== 'polyu618'), []);
  const needTopOffset = useMemo(() => (APP !== 'polyu618'), []);

  return (
    <Container>
      <StyledH2>{name}</StyledH2>
      {displayBooth && (
        <BoothNumContainer>
          {`Booth: ${boothNumber}`}
        </BoothNumContainer>
      )}
      <DescriptionContainer needTopOffset={needTopOffset}>
        {ReactHtmlParser(description)}
        <GradientOverlay />
      </DescriptionContainer>
      <ProfileBtn
        onClick={() => handleClick('desk')}
      >
        View More
      </ProfileBtn>
    </Container>
  );
};

BoothDescription.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  boothNumber: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BoothDescription;
