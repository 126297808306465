import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const removeContactMutation = `
  mutation removeContact($userRongcloudId: String!, $contactRongcloudId: String!) {
    removeContact(input: {
      userUuid: $userRongcloudId,
      contactUuid: $contactRongcloudId
    }) {
      success
    }
  }
`;

function removeContact(userRongcloudId, contactRongcloudId) {
  return request(API_URL, removeContactMutation, {
    userRongcloudId,
    contactRongcloudId,
  }).then(({ removeContact: { success } }) => success);
}

export default removeContact;
