import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoDataIndicator from 'components/indicators/NoDataIndicator';

const RootContainer = styled.div`
  width: auto;
  max-width: 100%;
  margin: 20px;
  letter-spacing: 0.3px;
`;

const ItemContainer = styled.div`
  padding-bottom: 12px;
`;

const StyledLink = styled.a`
  white-space: break-spaces;
  text-align: justify;
  font-size: 17px;
  line-height: 24px;
  color: #5C8DF3;
  word-break: break-all;
`;

const BoothSurvey = ({ survey }) => (
  <RootContainer>
    {
      survey
        ? (
          <ItemContainer>
            <StyledLink href={survey} target="_blank" rel="noopener noreferrer">
              {survey}
            </StyledLink>
          </ItemContainer>
        )
        : <NoDataIndicator />
    }
  </RootContainer>
);

BoothSurvey.propTypes = {
  survey: PropTypes.string.isRequired,
};

export default BoothSurvey;
