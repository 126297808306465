import styled from 'styled-components';

const SliderBullet = styled.div<{ active: boolean }>`
  width: 8px;
  height: 2px;
  background: #484848;
  opacity: ${({ active }) => (active ? '1' : '0.2')};
  border-radius: 4px;
`;

export default SliderBullet;
