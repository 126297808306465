import React, { useMemo } from 'react';
import { APP } from 'appenv';
import styled from 'styled-components';
import Apps from 'apps';

const StyledH2 = styled.h2`
  margin-top: 12px;
  margin-bottom: 6px;
`;

const BoothNumberContainer = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0.5em;
  color: #484848;
`;

const hideBoothNumberList = ['polyu618', 'scmp711', 'sph706', Apps.PicoX707, Apps.Lighthouse710];

const BoothNameContainer = ({
  name, boothNumber,
}: {
  name: string;
  boothNumber: number | string;
}) => {
  const displayBoothNumber = useMemo(() => !hideBoothNumberList.includes(APP), []);
  return (
    <>
      <StyledH2>{name}</StyledH2>
      {displayBoothNumber && (
        <BoothNumberContainer>
          {`Booth: ${boothNumber}`}
        </BoothNumberContainer>
      )}
    </>
  );
};

export default BoothNameContainer;
