import React from 'react';
import { BRAND_COLOR, ENABLED_FEATURES } from 'appenv';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FilterOptionContainer from './FilterOptionContainer';

const CustomCheckbox = withStyles({
  root: {
    width: '42px',
    padding: '0 9px',
    verticalAlign: 'top',
    marginRight: '7px',
    color: '#A2AAB3',
    '&$checked': {
      color: BRAND_COLOR || '#0CA58B',
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Checkbox color="default" {...props} />);

const StyledFilterOptionContainer = styled(FilterOptionContainer)`
  display: block;
`;

const StyledLabel = styled.label`
  display: inline-block;
  width: calc(100% - 50px);
  font-size: 14px;
  line-height: 20px;
  color: #484848;
  cursor: pointer;
`;

const FilterCheckboxOption = ({
  label,
  index,
  category,
  onClick,
  selectedOption,
  sumOfCategory,
  topCategory,
}) => {
  const displayLabel = ENABLED_FEATURES.xtraCMS
    ? label
    : `${label} (${sumOfCategory})`;

  return (
    <StyledFilterOptionContainer>
      <CustomCheckbox
        onClick={() => onClick(category, index, topCategory)}
        checked={selectedOption.includes(index)}
        id={category + index}
      />
      <StyledLabel htmlFor={category + index}>{displayLabel}</StyledLabel>
    </StyledFilterOptionContainer>
  );
};

FilterCheckboxOption.propTypes = {
  selectedOption: PropTypes.arrayOf(PropTypes.number),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
  sumOfCategory: PropTypes.number.isRequired,
  topCategory: PropTypes.string,
};

FilterCheckboxOption.defaultProps = {
  selectedOption: [],
  topCategory: '',
};

export default FilterCheckboxOption;
