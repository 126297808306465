import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{ site: string }>`
  width: 162px;
  position: absolute;
  transform: ${({ site }: { site: string}) => (site === 'right' ? 'rotate(180deg) scaleX(1.5)' : 'scaleX(1.5)')};
  left: ${({ site }: { site: string}) => (site === 'right' ? 'unset' : '72px')};
  bottom: -115px;
  right: ${({ site }: { site: string}) => (site === 'right' ? '72px' : 'unset')};
`;

interface BoothColorProps {
  color: string;
  site: string;
}

const BoothColor: React.FC<BoothColorProps> = ({ color, site }: BoothColorProps) => (
  <Container site={site}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.96 250">
      <g data-name="Layer 2">
        <path
          fill={color}
          d="M56.55 244.43L96.82 235.44 96.96 157.92 96.96 19.99 25.29 0 0 0 0 250 56.55 244.43z"
          className="cls-1"
          data-name="1"
        />
      </g>
    </svg>
  </Container>
);

export default BoothColor;
