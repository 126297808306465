import React, { useContext } from 'react';
import { BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';

import BoothProfile from '../../components/BoothProfile';
import { BoothProfileTabsContext } from '../../components/BoothProfileTabsContext';
import BoothPlainText from '../../components/BoothPlainText';

const Container = styled.div`
  width: ${({ isDesktop }) => (isDesktop ? '70%' : '100%')};
  height: auto;
  margin: 0 auto;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: #FFF;
  letter-spacing: 0.3px;

  .MuiTab-wrapper {
    font-size: ${({ isDesktop }) => (isDesktop ? '24px' : '18px')};
  }

  button.MuiButtonBase-root.MuiTab-root {
    margin-right: ${({ isDesktop }) => (isDesktop ? '40px' : '20px')};
  }
`;

const StyledTabs = withStyles({
  root: {
    margin: '24px 24px 40px 24px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > div': {
      maxWidth: 139,
      width: '100%',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#A2AAB3',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Lato',
    padding: '0',
    minWidth: 'unset',
    '&:focus': {
      opacity: 1,
    },
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      children
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const BoothProfileTabsContainer = ({
  booth: {
    id,
    name,
    boothNumber,
    description,
    website,
    emailContacts,
    extraFields,
    coreTechnology,
    hashtags,
  },
  isDesktop,
}) => {
  const { activeTabKey, setActiveTabKey } = useContext(BoothProfileTabsContext);
  const onActiveTabKeyChange = (event, tabKey) => {
    window.trackingEvent('Booth', `Booth_Profile_Tab_${tabKey + 1}_Click`, `${id} - ${name}`);
    setActiveTabKey(tabKey);
  };

  const oneLiner = extraFields['1-liner'] || '';

  return (
    <Container isDesktop={isDesktop}>
      <StyledTabs scrollButtons="off" variant="scrollable" value={activeTabKey} onChange={onActiveTabKeyChange} aria-label="styled tabs example">
        <StyledTab label="Our solution" />
        <StyledTab label="Who we are" />
        <StyledTab label="Learn more" />
      </StyledTabs>
      <SwipeableViews
        axis="x"
        index={activeTabKey}
        disabled
        ignoreNativeScroll
        resistance
      >
        <BoothPlainText text={extraFields['Our Solutions']} />
        <TabPanel value={activeTabKey} index={1}>
          <>
            <BoothProfile
              name={name}
              boothNumber={boothNumber}
              description={description}
              website={website}
              coreTechnology={coreTechnology}
              hashtags={hashtags}
              contacts={emailContacts}
              oneLiner={oneLiner}
            />
          </>
        </TabPanel>
        <TabPanel value={activeTabKey} index={2}>
          <BoothPlainText text="To find out more about the solution, please send us an email at <a href='mailto:hkpart@microsoft.com'>hkpart@microsoft.com</a>" />
        </TabPanel>
      </SwipeableViews>
    </Container>
  );
};

BoothProfileTabsContainer.propTypes = {
  booth: PropTypes.shape({
    id: PropTypes.number.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    emailContacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    name: PropTypes.string.isRequired,
    boothNumber: PropTypes.string,
    description: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    extraFields: PropTypes.shape().isRequired,
    industryVerticals: PropTypes.shape().isRequired,
    fundingStage: PropTypes.string.isRequired,
    coreTechnology: PropTypes.string,
    hashtags: PropTypes.string,
    oneLiner: PropTypes.string,
  }).isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default BoothProfileTabsContainer;
