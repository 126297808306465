import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { CMSEvent } from './types';

export const eventsAdapter = createEntityAdapter<CMSEvent>();

export const {
  selectIds: selectEventIds,
  selectEntities: selectEventEntities,
  selectAll: selectAllEvents,
  selectTotal: selectTotalEvent,
  selectById: selectEventById,
} = eventsAdapter.getSelectors((state: any) => state.cms.events);

export const selectXtraEvent = createSelector(
  selectAllEvents,
  (events) => events[0],
);

export const selectBoothTaxonomies = createSelector(
  selectXtraEvent,
  (event) => event?.boothTaxonomies || [],
);
