import React from 'react';
import { BoothBanner } from 'models/exhibitors';
import ReactPlayer from 'react-player';
import BannerTextElement from './BannerTextElement';
import BannerContentElement from './BannerContentElement';

const BannerElement = React.forwardRef(({
  description,
  isPreview,
  objectFit,
  bannerData,
  handleClick,
  boothNumber,
  name,
  isDescriptionBanner,
  isVideo,
}: {
  description: string;
  isPreview: boolean;
  objectFit: 'contain' | 'cover';
  bannerData: BoothBanner;
  handleClick: (type: string) => void;
  boothNumber: number | string;
  name: string;
  isDescriptionBanner: boolean;
  isVideo: boolean;
}, ref: React.RefObject<ReactPlayer | HTMLVideoElement>) => (
  description ? (
    <BannerTextElement
      isDescriptionBanner={isDescriptionBanner}
      name={name}
      boothNumber={boothNumber}
      handleClick={handleClick}
      description={description}
      isVideo={isVideo}
    />
  ) : (
    <BannerContentElement
      name={name}
      bannerData={bannerData}
      objectFit={objectFit}
      isPreview={isPreview}
      ref={ref}
    />
  )
));

export default BannerElement;
