import { request } from 'graphql-request';
import { API_URL, EVENT_TOKEN } from 'appenv';

const fetchAttendeeInformation = `
  query fetchAttendeeInformation($eventToken: String!, $ids: [String!]!) {
    fetchAttendeeInformation(
      eventToken: $eventToken,
      ids: $ids
    ) {
      id: uuid
      name
      customAttendeeFields
    }
  }
`;

function attendeesByIds(ids) {
  return request(API_URL, fetchAttendeeInformation, {
    eventToken: EVENT_TOKEN,
    ids,
  }).then((result) => {
    const attendees = result.fetchAttendeeInformation || [];
    return attendees;
  });
}

export default attendeesByIds;
