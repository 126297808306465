import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import EmptyStateImg from 'assets/search-empty-state.svg';

const EmptyStateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EmptyStateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #484848;
  padding: 24px;
`;

const EmptyStatePlaceholder = ({
  className = '',
  i18nKey = 'search.no_result_found',
  defaultText = 'Sorry, no results found :(',
}: { className?: string; i18nKey?: string; defaultText?: string }) => (
  <EmptyStateContainer className={className}>
    <img src={EmptyStateImg} alt={defaultText} />
    <EmptyStateText>
      <Trans
        defaults={defaultText}
        i18nKey={i18nKey}
      />
    </EmptyStateText>
  </EmptyStateContainer>
);

export default EmptyStatePlaceholder;
