import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LineImg = styled.div`
  position: absolute;
  left: ${({ site }) => (site === 'right' ? '' : '85px')};
  top: 130px;
  height: 420px;
  width: 220px;
  transform:
    ${({ site }) => {
    if (site === 'left') {
      return 'scale(1.3) rotate(180deg)';
    }
    return 'scale(1.3)';
  }};
  right: ${({ site }) => (site === 'right' ? '90px' : '')};
`;

const BoothColor = ({ site, boothColor }) => (
  <LineImg site={site}>
    <svg width="220" height="420" viewBox="0 0 246 613" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M245.817 612.932L0.770996 548.446V61.4076L245.817 0.655029V612.932Z" fill={boothColor} />
    </svg>
  </LineImg>
);

BoothColor.propTypes = {
  site: PropTypes.string.isRequired,
  boothColor: PropTypes.string,
};

BoothColor.defaultProps = {
  boothColor: undefined,
};

export default BoothColor;
