import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin-left: ${({ isSelfSent }) => (isSelfSent ? '12px' : 0)};
  margin-right: ${({ isSelfSent }) => (isSelfSent ? 0 : '12px')};
`;

const AudioCallIcon = ({ color, isSelfSent }) => (
  <Container isSelfSent={isSelfSent}>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.02363 3.15098L2.89571 1.28066C3.12422 1.05215 3.43008 0.923828 3.75352 0.923828C4.07695 0.923828 4.38281 1.05039 4.61133 1.28066L6.62754 3.29336C6.85606 3.52188 6.98438 3.82949 6.98438 4.15293C6.98438 4.47813 6.85781 4.78223 6.62754 5.0125L5.05254 6.58926C5.41465 7.42949 5.92441 8.18184 6.57129 8.82695C7.21641 9.47559 7.97051 9.98711 8.80723 10.3492L10.3822 8.77246C10.6107 8.54395 10.9166 8.41563 11.24 8.41563C11.5652 8.41563 11.8693 8.54219 12.0979 8.77246L14.1158 10.7852C14.3443 11.0137 14.4727 11.3213 14.4727 11.6447C14.4727 11.9699 14.3461 12.274 14.1158 12.5043L12.2455 14.3746C11.8553 14.7648 11.3174 14.9881 10.7654 14.9881C10.6512 14.9881 10.5404 14.9793 10.4279 14.96C8.10059 14.5768 5.79082 13.3375 3.92578 11.4742C2.0625 9.60742 0.825001 7.29766 0.438282 4.96855C0.32754 4.30762 0.549026 3.62734 1.02363 3.15098ZM1.68457 4.75938C2.02734 6.83184 3.1418 8.89902 4.82051 10.5777C6.49922 12.2564 8.56465 13.3709 10.6371 13.7137C10.8973 13.7576 11.1645 13.6697 11.3543 13.4816L13.1912 11.6447L11.2436 9.69531L9.1377 11.8047L9.12188 11.8205L8.74219 11.6799C6.40957 10.8203 4.57793 8.98867 3.72012 6.65605L3.57949 6.27637L5.70293 4.15469L3.75527 2.20527L1.91836 4.04219C1.72852 4.23203 1.64063 4.49922 1.68457 4.75938Z" fill={color} />
    </svg>
  </Container>
);

AudioCallIcon.propTypes = {
  color: PropTypes.string.isRequired,
  isSelfSent: PropTypes.bool.isRequired,
};

export default AudioCallIcon;
