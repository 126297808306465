import React from 'react';
import styled from 'styled-components';
import Webinar623Background from 'assets/main_lobby/webinar-paloalto623.png';
import HallPullUpBannerContainer from '../HallPullUpBannerContainer';

const IMG_OFFSET = 6.5;

const StyledBackdrop = styled.div`
  height: calc(100% - ${IMG_OFFSET * 2}px);
  width: 100%;
  background: url(${Webinar623Background});
  position: absolute;
  background-position: center;
  background-size: contain;
  top: ${IMG_OFFSET}px;
`;

const Webinar623Template = () => (
  <HallPullUpBannerContainer>
    <StyledBackdrop />
  </HallPullUpBannerContainer>
);

export default Webinar623Template;
