import { request } from 'graphql-request';
import { API_URL, EVENT_TOKEN, RONGCLOUD_CHATROOM_PREFIX } from 'appenv';

const pushChatRoomNotificationMutation = `
  mutation pushChatRoomNotification($uniqueToken: String!, $eventToken: String!, $message: String!, $sender: String!) {
    pushNotification(input: {
      uniqueToken: $uniqueToken,
      eventToken: $eventToken,
      message: $message,
      sender: $sender
    }) {
      success
    }
  }
`;

function pushChatRoomNotification(attendeeUniqueToken, message) {
  return request(API_URL, pushChatRoomNotificationMutation, {
    eventToken: EVENT_TOKEN,
    uniqueToken: attendeeUniqueToken,
    sender: `${RONGCLOUD_CHATROOM_PREFIX}_announcement`,
    message,
  }).then((response) => {
    const { pushNotification: { success } } = response;
    return success;
  }).catch((error) => {
    console.log(error);
  });
}

export default pushChatRoomNotification;
