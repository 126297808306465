import React from 'react';
import { APP } from 'appenv';
import Webinar608Template from './templates/Webinar608Template';
import Webinar618Template from './templates/Webinar618Template';
import Webinar623Template from './templates/Webinar623Template';
import Webinar711Template from './templates/Webinar711Template';

const WebinarTemplateSwitch = (props) => {
  switch (APP) {
    case 'polyu618':
      return (
        <Webinar618Template {...props} />
      );
    case 'paloalto623':
      return (
        <Webinar623Template {...props} />
      );
    case 'ms624':
      return null;
    case 'scmp711':
      return (
        <Webinar711Template {...props} />
      );
    case 'lighthouse710':
      return null;
    default:
      return (
        <Webinar608Template {...props} />
      );
  }
};

export default WebinarTemplateSwitch;
