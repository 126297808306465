import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  width: 1150px;
  bottom: -190px;
  left: calc((100% - 1150px) / 2);
`;

const BoothFloor: React.FC<{ boothColor: string }> = (
  { boothColor }: { boothColor: string },
) => (
  <Container>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 689.89 66.32"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="2301.79"
          x2="2474.57"
          y1="44.81"
          y2="44.81"
          gradientTransform="matrix(-1 0 0 1 2991.68 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" />
          <stop offset="0.11" stopColor="#757575" />
          <stop offset="0.36" stopColor="#616161" />
          <stop offset="0.62" stopColor="#555" />
          <stop offset="0.88" stopColor="#515151" />
          <stop offset="1" stopColor="#494949" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="2450.93"
          x2="2446.27"
          y1="32.17"
          y2="41.72"
          gradientTransform="matrix(-1 0 0 1 2991.68 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" />
          <stop offset="0.01" stopColor="#080808" />
          <stop offset="0.09" stopColor="#3b3b3b" />
          <stop offset="0.17" stopColor="#6a6a6a" />
          <stop offset="0.26" stopColor="#929292" />
          <stop offset="0.35" stopColor="#b4b4b4" />
          <stop offset="0.44" stopColor="#cfcfcf" />
          <stop offset="0.55" stopColor="#e4e4e4" />
          <stop offset="0.66" stopColor="#f3f3f3" />
          <stop offset="0.8" stopColor="#fcfcfc" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="2424.87"
          x2="2474.57"
          y1="33.44"
          y2="33.44"
          gradientTransform="matrix(-1 0 0 1 2991.68 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#8e8e8e" />
          <stop offset="1" stopColor="#c5c5c5" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="3162.91"
          x2="3335.69"
          y1="44.73"
          y2="44.73"
          gradientTransform="translate(-3162.91)"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="3312.06"
          x2="3307.4"
          y1="32.29"
          y2="41.84"
          gradientTransform="translate(-3162.91)"
          xlinkHref="#linear-gradient-2"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="3285.99"
          x2="3335.69"
          y1="33.56"
          y2="33.56"
          gradientTransform="translate(-3162.91)"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="2561.8"
          x2="2561.8"
          y1="51.42"
          y2="48.82"
          gradientTransform="matrix(-1 0 0 1 2991.68 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#4e4e4e" />
          <stop offset="1" stopColor="#454545" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-8"
          x1="260.27"
          x2="260.27"
          y1="51.42"
          y2="48.82"
          xlinkHref="#linear-gradient-7"
        />
        <linearGradient
          id="linear-gradient-9"
          x1="123.08"
          x2="172.78"
          y1="33.26"
          y2="33.26"
          xlinkHref="#linear-gradient-3"
        />
        <linearGradient
          id="linear-gradient-10"
          x1="2424.88"
          x2="2474.59"
          y1="33.26"
          y2="33.26"
          xlinkHref="#linear-gradient-3"
        />
      </defs>
      <g className="cls-1">
        <g data-name="Layer 2">
          <g data-name="1">
            <g>
              <path
                fill="url(#linear-gradient)"
                d="M689.12 38.08l.77 3.09-14.08 18a18.54 18.54 0 01-8.73 4.6A106.78 106.78 0 01648.77 66c-6.57 0-14.47.3-18.67 0s-24.29-2.73-24.29-2.73l-88.7-11.85v-3.73l.62.9c31-21.52 45.52-26 52.81-25L580.69 25c7.73 1 13.69 1.8 17.91 2.34 21.74 2.75 32.6 4.13 47.69 5.92 1 1.43 26.84 3.09 42.83 4.82z"
                className="cls-2"
              />
              <path
                fill="url(#linear-gradient-2)"
                d="M518.5 48.68L558.41 23.56 567.34 24.56 528.48 49.98 518.5 48.68z"
                className="cls-3"
                opacity="0.18"
              />
              <path
                fill="url(#linear-gradient-3)"
                d="M566.81 21.65L563.9 21.59 517.11 43.87 517.11 45.29 566.81 21.65z"
                className="cls-4"
              />
              <path
                fill="#626262"
                d="M518.65 45.72L517.11 45.72 517.23 45.21 566.81 21.65 569.05 21.65 518.65 45.72z"
                className="cls-5"
              />
              <path
                fill="#545454"
                d="M518.65 45.72L518.65 49 518.91 48.8 570.54 23.56 570.54 20.94 518.65 45.72z"
                className="cls-6"
              />
              <path
                fill="#575757"
                d="M172.78 51.42l-53.43-28c22.77-2.47 47-5.93 72.54-10.7C211.71 9 230.51 4.81 248.25.37 291 .93 323.5.66 345.89.28c9.5-.16 29.24-.54 56 0 21.83.44 32.75.67 42.4 1.6 32.12 3.11 41.59 9.18 77.2 14.8a436.43 436.43 0 0046.63 4.79l2.41 1.91-53.44 28z"
                className="cls-7"
              />
              <path
                fill="url(#linear-gradient-4)"
                d="M.77 38.2L0 41.29l14.08 18.05a18.44 18.44 0 008.73 4.6 106.78 106.78 0 0018.31 2.23c6.57 0 14.47.3 18.67 0s24.29-2.73 24.29-2.73l88.7-11.85v-3.78l-.62.9c-24.8-23-41.73-26.63-52.81-25.33-2.94.34-6 1.1-17.24 2.7-12.8 1.84-18.74 2.29-31.29 3.74-17.39 2-17.76 2.52-35.73 4.73-7.98.98-19.77 2.33-34.32 3.65z"
                className="cls-8"
              />
              <path
                fill="url(#linear-gradient-5)"
                d="M171.39 48.8L131.48 23.68 122.56 24.68 161.41 50.1 171.39 48.8z"
                className="cls-9"
                opacity="0.18"
              />
              <path
                fill="url(#linear-gradient-6)"
                d="M123.08 21.77L125.99 21.71 172.78 43.99 172.78 45.41 123.08 21.77z"
                className="cls-10"
              />
              <path
                fill="#626262"
                d="M171.24 45.84L172.78 45.84 172.66 45.33 123.08 21.77 120.84 21.77 171.24 45.84z"
                className="cls-5"
              />
              <path
                fill="#545454"
                d="M171.24 45.84L171.24 49.12 170.98 48.92 119.35 23.68 119.35 21.06 171.24 45.84z"
                className="cls-6"
              />
              <path
                fill="url(#linear-gradient-7)"
                d="M342.67 48.82H517.09V51.43H342.67z"
                className="cls-11"
              />
              <path
                fill="#d7d7d7"
                d="M517.09 43.69v1.59l-171.83-.18v-1.28z"
                className="cls-12"
              />
              <path
                fill="#575757"
                d="M343.51 45.54H518.64V48.82H343.51z"
                className="cls-7"
              />
              <path
                fill="url(#linear-gradient-8)"
                d="M172.78 48.82H347.76V51.43H172.78z"
                className="cls-13"
              />
              <path
                fill="#d7d7d7"
                d="M172.78 43.69v1.52l172.48-.11v-1.28z"
                className="cls-12"
              />
              <path
                fill="url(#linear-gradient-9)"
                d="M123.08 21.47L125.99 21.41 172.78 43.69 172.78 45.11 123.08 21.47z"
                className="cls-15"
              />
              <path
                fill="#626262"
                d="M171.24 45.54L172.78 45.54 172.66 45.03 123.08 21.47 120.84 21.47 171.24 45.54z"
                className="cls-5"
              />
              <path
                fill="#545454"
                d="M171.24 45.54L171.24 48.82 170.98 48.62 119.35 23.38 119.35 20.76 171.24 45.54z"
                className="cls-6"
              />
              <path
                fill="url(#linear-gradient-10)"
                d="M566.8 21.47L563.88 21.41 517.1 43.69 517.1 45.11 566.8 21.47z"
                className="cls-16"
              />
              <path
                fill="#626262"
                d="M518.64 45.54L517.1 45.54 517.21 45.03 566.8 21.47 569.03 21.47 518.64 45.54z"
                className="cls-5"
              />
              <path
                fill="#545454"
                d="M518.64 45.54L518.64 48.82 518.89 48.62 570.52 23.38 570.52 20.76 518.64 45.54z"
                className="cls-6"
              />
              <g>
                <path
                  fill={boothColor}
                  d="M44.78 33.48L0 38.2S13.46 55.41 15.92 58s14 4.32 25.26 5.13 27.51-.81 27.51-.81l102.7-13.48-52-25.14zM645.11 33.36l44.78 4.72S676.44 55.29 674 57.85s-14 4.32-25.26 5.13-27.51-.81-27.51-.81L518.5 48.69l52-25.13z"
                  className="cls-17"
                />
                <path
                  fill={boothColor}
                  d="M248.25 0.37L193.05 11.97 171.49 16.8 125.99 21.43 172.78 43.71 345.26 43.84 345.26 0.37 248.25 0.37z"
                  className="cls-17"
                />
                <path
                  fill={boothColor}
                  d="M518.38 16.8L496.82 11.97 441.63 0.37 345.26 0.37 345.26 43.84 517.1 43.71 563.88 21.43 518.38 16.8z"
                  className="cls-17"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Container>
);

export default BoothFloor;
