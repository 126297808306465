import React from 'react';

const isMSIE = navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1;

const IEFallbackWrapper = ({ fallback, children, forceMSIE = false }: { fallback: React.ReactElement; children: React.ReactElement; forceMSIE?: boolean }) => {
  if (forceMSIE || isMSIE) {
    return fallback;
  }

  return children;
};

export default IEFallbackWrapper;
