import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { BRAND_COLOR } from 'appenv';

const DescriptionContainer = styled.div`
  position: relative;
  line-height: 24px;
  overflow: hidden;
  height: 100px;
  margin-block-end: 1em;
  text-align: justify;

  & * {
    margin: 0;
  }
`;

const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image:
    linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 1)
    );
`;

const StyledH2 = styled.h2`
  margin-top: 12px;
  margin-bottom: 6px;
`;

const ProfileBtn = styled.button`
  background: ${BRAND_COLOR || '#0CA58B'};
  border-radius: 4px;
  color: #FFF;
  padding: 4px 8px;
  text-decoration: unset;
  cursor: pointer;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  padding: 8px 16px;
`;

const BoothDescription = ({
  name,
  description,
  handleClick,
}) => (
  <Container>
    <StyledH2>{name}</StyledH2>
    <DescriptionContainer>
      {ReactHtmlParser(description)}
      <GradientOverlay />
    </DescriptionContainer>
    <ProfileBtn
      onClick={() => handleClick('desk')}
    >
      View More
    </ProfileBtn>
  </Container>
);

BoothDescription.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
};

export default BoothDescription;
