import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoDataIcon from 'assets/pitchNoData.svg';

const RootContainer = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const Text = styled.div`
  line-height: 24px;
  color: #484848;
  font-weight: bold;
  margin-top: 20px;
`;

const NoDataIndicator = ({ icon, message }) => (
  <RootContainer>
    <img src={icon} alt={message} />
    <Text>{message}</Text>
  </RootContainer>
);

NoDataIndicator.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
};

NoDataIndicator.defaultProps = {
  icon: NoDataIcon,
  message: 'Coming Soon!',
};

export default NoDataIndicator;
