import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const DescriptionContainer = styled.div`
  position: relative;
  line-height: 24px;
  overflow: auto;
  max-height: 100%;
  max-width: 100%;
  flex-grow: 1;
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: justify;
  text-overflow: ellipsis;
  word-break: break-word;
  padding: 10px;

  & p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const BannerRichText = ({
  htmlContent,
}: {
  htmlContent: string;
}) => (
  <DescriptionContainer>
    {ReactHtmlParser(htmlContent)}
  </DescriptionContainer>
);

export default BannerRichText;
