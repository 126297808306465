import React, { useState } from 'react';
import PropTypes from 'prop-types';

const BoothProfileTabsContext = React.createContext({
  activeTabKey: undefined,
  setActiveTabKey: undefined,
  tabsIndexMap: undefined,
  setTabsIndexMap: undefined,
});

const BoothProfileTabsContextStateProvider = ({ children }) => {
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [tabsIndexMap, setTabsIndexMap] = useState({});

  return (
    <BoothProfileTabsContext.Provider
      value={{
        activeTabKey,
        setActiveTabKey,
        tabsIndexMap,
        setTabsIndexMap,
      }}
    >
      {children}
    </BoothProfileTabsContext.Provider>
  );
};

BoothProfileTabsContextStateProvider.propTypes = {
  children: PropTypes.node,
};

BoothProfileTabsContextStateProvider.defaultProps = {
  children: null,
};

export {
  BoothProfileTabsContext,
  BoothProfileTabsContextStateProvider,
};
