import React, { useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAllProductsByLocale, fetchProductsNextPage } from 'models/products';
import { LocalizedProduct } from 'models/types/products';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import styled from 'styled-components';
import EmptyStateImg from 'assets/search-empty-state.svg';
import Footer from 'components/footer/Footer';
import ProductFilter from 'components/filter/ProductFilter';
import ProductItem from 'components/product/ProductItem';
import { useTranslation } from 'react-i18next';
import isChatroomDisabledByReed from 'components/chat/utils/isChatroomDisabledByReed';

const RootContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  overflow: auto;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  padding-top: 40px;
`;

const ProductListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px 16px;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: auto;
`;

const FilterContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const EmptyStateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const EmptyStateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #484848;
  padding: 24px;
`;

const ProductListWrapper = ({ productList }: {
  productList: LocalizedProduct[];
}) => {
  const hideChatLink = useMemo(() => isChatroomDisabledByReed(), []);

  return (
    <>
      {productList.sort((a, b) => (a.sort - b.sort)).map((product) => (
        <ProductItem product={product} key={product.id} hideChatLink={hideChatLink} />
      ))}
    </>
  );
};

const ProductPageLoadingContainer = styled.div`
  flex-basis: 100%;
`;

const ProductPageLoadingIndicator = () => (
  <ProductPageLoadingContainer>
    <CircularLoadingIndicator />
  </ProductPageLoadingContainer>
);

const ProductList = () => {
  const dispatch = useDispatch();
  const productList: LocalizedProduct[] = useSelector(selectAllProductsByLocale);
  const fetching = useSelector((state: any) => state.products.fetching);
  const hasNextPage = useSelector((state: any) => state.products.pageInfo.hasNextPage);
  const { t } = useTranslation();
  const containerRef: React.MutableRefObject<HTMLDivElement> = React.useRef();

  const fetchNextPage = useCallback(() => {
    const { scrollHeight, clientHeight, scrollTop } = containerRef.current;
    if (scrollHeight - clientHeight - scrollTop < 500 && !fetching && hasNextPage) {
      dispatch(fetchProductsNextPage());
    }
  }, [fetching, dispatch, hasNextPage]);

  return (
    <RootContainer>
      <FilterContainer>
        <ProductFilter />
      </FilterContainer>
      <ProductListContainer ref={containerRef} onScroll={fetchNextPage}>
        {!!productList.length && <ProductListWrapper productList={productList} />}
        {(!productList.length && !fetching) && (
          <EmptyStateContainer>
            <img src={EmptyStateImg} alt="Coming Soon" />
            <EmptyStateText>
              {t('product.empty', 'No Products')}
            </EmptyStateText>
          </EmptyStateContainer>
        )}
        {fetching && (
          <ProductPageLoadingIndicator />
        )}
        <FooterWrapper>
          <Footer hashState="/products" />
        </FooterWrapper>
      </ProductListContainer>
    </RootContainer>
  );
};

export default ProductList;
