import styled from 'styled-components';

const BoothContainer = styled.div<{ needOffset: boolean }>`
  position: relative;
  width: 900px;
  height: 600px;
  margin: 0 auto;
  margin-bottom: ${({ needOffset }) => (needOffset ? 110 : 0)}px;
  user-select: none;
`;

export default BoothContainer;
