import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const RootContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledVideo = styled.video`
  width: 70vw;
  max-height: 80vh;
  user-select: all;
  pointer-events: all;
  background-color: black;
`;

const CloseBtn = styled.div`
  position: fixed;
  right: 32px;
  top: 14px;
  cursor: pointer;
  user-select: none;
  color: #FFF;

  & svg {
    width: 30px;
    height: auto;
  }
`;

const VideoLightbox = ({ src, onClose }) => {
  const containerRef = useRef();
  const onContainerClick = (e) => e.target === containerRef.current && onClose(e);
  return (
    <RootContainer ref={containerRef} onClick={onContainerClick}>
      <StyledVideo src={src} controls />
      <CloseBtn onClick={onClose}>
        <CloseIcon />
      </CloseBtn>
    </RootContainer>
  );
};

VideoLightbox.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default VideoLightbox;
