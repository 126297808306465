import styled from 'styled-components';
import { styled as muiStyled } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import PropTypes from 'prop-types';
import { BRAND_COLOR } from 'appenv';

const LoadingIndicatorContainer = styled.div`
  margin: 16px;
  text-align: center;
`;

const StyledCircularProgress = muiStyled(CircularProgress)({
  color: BRAND_COLOR || '#1976D2',
});

const CircularLoadingIndicator = ({ className, size }) => (
  <LoadingIndicatorContainer className={className}>
    <StyledCircularProgress size={size} />
  </LoadingIndicatorContainer>
);

CircularLoadingIndicator.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

CircularLoadingIndicator.defaultProps = {
  className: '',
  size: undefined,
};

export const CircularLoadingHider = ({ condition, children }) => (
  condition ? (children) : <CircularLoadingIndicator />
);

CircularLoadingHider.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.bool.isRequired,
};

export default CircularLoadingIndicator;
