import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';

import maleRight from 'assets/booths/sale-male-right.png';

const BoothContainer = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  margin: 0 auto;
  margin-bottom: ${({ needOffset }) => (needOffset ? '70px' : 'inherit')};
  display: flex;
  justify-content: center;
`;

const SaleImg = styled.img`
  height: 480px;
  position: absolute;
  bottom: 0;
  cursor: ${({ shouldDisableSalesRepClick }) => (!shouldDisableSalesRepClick ? 'pointer' : 'initial')};
`;

const SaleInfoContainer = styled.div`
  position: absolute;
  left: -70px;
  top: -75px;
  z-index: 55;
  width: 320px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  text-align: center;
`;

const TitleContainer = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 130px;
  z-index: 100;
  display: ${({ showRepsImg }) => (showRepsImg ? 'block' : 'none')};
`;

const BubbleArrow = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  top: 41px;
  left: 140px;
  background: #FFFFFF;
  border-radius: 2px;
  box-shadow:
    3px 3px 3px -2px rgba(0, 0, 0, 0.2),
    3px 3px 4px 0 rgba(0, 0, 0, 0.14),
    1px 1px 8px -5px rgba(0, 0, 0, 0.12);
  transform: rotate(45deg);
`;

const BoothTemplateContainer = ({ boothComponent, showRepsImg }) => {
  const [showRepsInfo, setShowRepsInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowRepsInfo(true);
    }, 500);
  }, []);

  return (
    <BoothContainer needOffset={showRepsImg}>
      { boothComponent }
      <SaleContainer id="reps" showRepsImg={showRepsImg}>
        <SaleImg src={maleRight} />
        <Grow
          in={showRepsInfo}
          timeout={800}
          style={{ transformOrigin: 'bottom' }}
        >
          <SaleInfoContainer>
            <Paper elevation={3}>
              <Container>
                <TitleContainer>
                  Click the TV to watch the demo video
                </TitleContainer>
              </Container>
            </Paper>
            <BubbleArrow />
          </SaleInfoContainer>
        </Grow>
      </SaleContainer>
    </BoothContainer>
  );
};

BoothTemplateContainer.propTypes = {
  boothComponent: PropTypes.node.isRequired,
  showRepsImg: PropTypes.bool,
};

BoothTemplateContainer.defaultProps = {
  showRepsImg: false,
};

export default BoothTemplateContainer;
