import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import WarningIcon from 'components/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import { API_URL } from 'appenv';
import { request } from 'graphql-request';

const RootContainer = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  z-index: 2000;

  @media only screen and (min-width: 960px) {
    justify-content: unset;
  }
`;

const ModalContainer = styled.div`
  background: #FFF;
  border-radius: 8px 8px 0 0;
  width: 100%;
  margin-top: unset;
  overflow: auto;

  & > * {
    margin: 20px;
  }

  @media only screen and (min-width: 960px) {
    width: min(845px, 58.7vw);
    margin-top: min(80px, 8vh);
    border-radius: 8px;
  }
`;

const StyledWarningIcon = styled(WarningIcon)`
  width: 35px;
  height: 30px;
  margin-right: 20px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  color: #A2AAB3;
  cursor: pointer;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleText = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: #E2654D;
  display: flex;
  align-items: center;
  font-weight: bold;
`;

const MessageContainer = styled.div`
  & > * {
    margin-bottom: 24px;
  }
`;

const MessageText = styled.div`
  color: #484848;
  font-size: 16px;
  line-height: 22px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ContactUsText = styled.span`
  color: #5C8DF3;
  cursor: pointer;
`;

const API_DOMAIN = new URL(API_URL).origin;

const NetworkCheckModal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    request(API_URL).catch((err) => {
      const statusCode = `${err?.response?.status}`;
      if (statusCode.startsWith('2') || statusCode.startsWith('5')) return;
      setShowModal(true);
    });
  }, []);

  const onCloseIconClick = () => setShowModal(false);

  const onCsLinkClick = () => {
    const { $zopim } = window;
    $zopim(() => {
      $zopim.livechat.window.show();
    });
  };

  if (!showModal) return null;

  return (
    <RootContainer>
      <ModalContainer>
        <TitleContainer>
          <TitleText>
            <StyledWarningIcon />
            Oops. Access denied.
          </TitleText>
          <StyledCloseIcon onClick={onCloseIconClick} />
        </TitleContainer>
        <MessageContainer>
          <MessageText>
            Please check if this website is currently blocked by your company network.
            If yes, please contact your IT team to unblock this website -
            <BoldText>{` URL: ${API_DOMAIN} `}</BoldText>
            and reload the page.
          </MessageText>
          <MessageText>
            If you still cannot access, please check your network connection and reload.
            Or
            <ContactUsText onClick={onCsLinkClick}>{' contact us '}</ContactUsText>
            if need further support.
          </MessageText>
        </MessageContainer>
      </ModalContainer>
    </RootContainer>
  );
};

export default NetworkCheckModal;
