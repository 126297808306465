const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
    next_show: 'Space Booking for Next Show',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/ccac6c77-5b72-4876-8d3b-b52e78f843bd.jpg',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/d9ce86a3-9e9e-49f6-8a41-ffc751d97df1.jpg',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/d37b0573-bfaf-4d83-bc6f-e7da51a80970.jpg',
        'https://www.automotiveworld-nagoya.jp/en-gb.html',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
  ...en.banners.right,
];

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
    next_show: '次年度 出展に興味のある方',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/f7b87232-10dd-4373-91a3-e0e202cecce0.jpg',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c599d5ab-e926-450b-a202-e443787b6c3b.jpg',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/56adf646-15f9-4284-9676-92503fae06e0.jpg',
        'https://www.automotiveworld-nagoya.jp/',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];
