import React from 'react';
import { APP, ENABLED_FEATURES } from 'appenv';
import MobileProfile from './MobileProfile';
import CMSMobileProfile from './templates/BoothCMSTemplate/MobileProfile';

export interface MobileProfileSwitchProps {
  booth: any;
}

const MobileProfileSwitch: React.FC<MobileProfileSwitchProps> = (props) => {
  switch (APP) {
    default:
      return ENABLED_FEATURES.xtraCMS
        ? <CMSMobileProfile {...props} />
        : <MobileProfile {...props} />;
  }
};

export default MobileProfileSwitch;
