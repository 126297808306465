import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import XtraAppBar from './appbar/XtraAppBar';

const RootContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  position: relative;
  flex: 1 1;
  overflow-y: auto;
`;

const customAppBarRoutes = ['/landing'];
const ContentContainer = ({ children }) => {
  const location = useLocation();

  const hideUser = ['/preview/booth/'].some((it) => location.pathname.startsWith(it));

  return (
    <RootContainer>
      {customAppBarRoutes.indexOf(location.pathname) === -1 && <XtraAppBar hideUser={hideUser} />}
      <MainContent>
        {children}
      </MainContent>
    </RootContainer>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentContainer;
