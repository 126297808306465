export const en = {
  message: {
    titles: ['Welcome to', 'Online Meeting Service'],
    contents: ["You Can View Exhibitors's Product Information and", 'Arrange Onsite/Online Meeting Appointments with Exhibitors.'],
  },
  button: {
    onlineMeetingService: 'What is "Online Meeting Service"?',
    login: 'LOGIN',
  },
  link: {
    exhibition: 'Go to Exhibition Website >>',
  },
  text: {
    registrations: ['Registrations ', '(Choose the show of your interest)'],
  },
};

export const ja = {
  message: {
    titles: ['オンライン商談サービスへようこそ！'],
    contents: ['アポイント予約、製品情報・動画の閲覧ができます'],
  },
  button: {
    onlineMeetingService: 'オンライン商談サービス とは？',
    login: 'ログイン',
  },
  link: {
    exhibition: '展示会ホームページは、こちら',
  },
  text: {
    registrations: ['利用登録はこちら ', '(ご希望の展示会をお選びください)'],
  },
};
