import { schema } from 'normalizr';
import { LocalizableString } from '../common/types';
import { mapLocalizable } from '../common/utils';

export const eventEntity = new schema.Entity('events', {}, {
  processStrategy(entity) {
    return mapLocalizable(entity);
  },
});

export interface BoothTaxonomies {
  id: string;
  name: LocalizableString;
  subcategories?: BoothTaxonomies[];
}

export interface CMSEvent {
  id: string;
  locales: string[];
  defaultLocale: string;
  boothTaxonomies: BoothTaxonomies[];
}
