import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';

import playButtonImg from 'assets/play-button.png';

const useStyles = makeStyles(() => ({
  close: {
    color: '#000',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const VideoContainer = styled.div`
  width: 100%;
  height: 80%;
  background-color:#FFF;
  border: 2px solid #000;
`;

const CloseButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
`;

const VideoModal = ({ isOpen, onClose, src }) => {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <VideoContainer>
          <CloseButtonContainer>
            <IconButton
              className={classes.close}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </CloseButtonContainer>
          <ReactPlayer
            url={src}
            controls
            playIcon={<img src={playButtonImg} alt="Play Button" />}
            width="100%"
            height="80%"
            config={{
              file: {
                attributes: {
                  autoPlay: true,
                },
              },
            }}
            playsinline
            style={{ zIndex: 100, backgroundColor: 'black' }}
          />
        </VideoContainer>
      </Fade>
    </Modal>
  );
};

VideoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  src: PropTypes.string.isRequired,
};

export default VideoModal;
