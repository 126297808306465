export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/f048171d-a646-4df3-9fac-c9f7ea546074.png',
  ],
  url: {
    exhibition_website: 'https://www.ai-expo-at.jp/en-gb.html',
    next_show: '/booth/3CEWGpduQmSx3WLMuYBhY3hTH8NQ15XadRPw4mx9G9SQ',
  },
  title: '[AI][BLOCKCHAIN][QUANTUM COMPUTING] EXPO TOKYO -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/09c494d0-f14c-48c9-8022-312d849e467c.png',
  ],
  url: {
    exhibition_website: 'https://www.ai-expo-at.jp/gate/',
    next_show: '/booth/3CEWGpduQmSx3WLMuYBhY3hTH8NQ15XadRPw4mx9G9SQ',
  },
  title: 'オンライン来場・商談サービス｜【AI】【ブロックチェーン】【量子コンピューティング】EXPO',
};
