import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  width: 755px;
  bottom: -118px;
  left: calc((100% - 755px) / 2);
`;

const BoothPanel: React.FC = () => (
  <Container>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 453.62 253.5"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="4179.17"
          x2="4202.97"
          y1="1.31"
          y2="1.31"
          gradientTransform="matrix(1 0 0 -1 -3752.06 2.61)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#fff" />
          <stop offset="0.09" stopColor="#f8f8f8" />
          <stop offset="0.22" stopColor="#e3e3e3" />
          <stop offset="0.38" stopColor="#c1c1c1" />
          <stop offset="0.56" stopColor="#929292" />
          <stop offset="0.75" stopColor="#555" />
          <stop offset="0.97" stopColor="#0d0d0d" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="4177.14"
          x2="4177.14"
          y1="170.57"
          y2="121.66"
          gradientTransform="matrix(1 0 0 -1 -3724.2 292.23)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" />
          <stop offset="0.03" stopColor="#0d0d0d" />
          <stop offset="0.25" stopColor="#555" />
          <stop offset="0.44" stopColor="#929292" />
          <stop offset="0.62" stopColor="#c1c1c1" />
          <stop offset="0.78" stopColor="#e3e3e3" />
          <stop offset="0.91" stopColor="#f8f8f8" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="2.71"
          x2="26.51"
          y1="1.31"
          y2="1.31"
          xlinkHref="#linear-gradient"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="1.36"
          x2="1.36"
          y1="172.44"
          y2="121.66"
          xlinkHref="#linear-gradient-2"
        />
      </defs>
      <g className="cls-1">
        <g data-name="Layer 2">
          <g data-name="1">
            <g>
              <path
                fill="#696969"
                d="M427.11 0L450.91 0 453.62 0 453.62 4.33 453.62 252.61 450.91 252.61 450.91 4.33 427.11 4.33 427.11 0z"
                className="cls-2"
              />
              <path
                fill="url(#linear-gradient)"
                d="M427.11 0H450.91V2.61H427.11z"
                className="cls-3"
                opacity="0.18"
                transform="rotate(180 439.005 1.305)"
              />
              <path
                fill="url(#linear-gradient-2)"
                d="M452.26 121.66H453.62V170.57H452.26z"
                className="cls-4"
                opacity="0.18"
                transform="rotate(180 452.94 146.115)"
              />
              <path
                fill="#cfd2d7"
                d="M353.59 49l97.32-18.81V31l-97.3 18.8c-.01-.27-.02-.56-.02-.8z"
                className="cls-5"
              />
              <path
                fill="#606774"
                d="M353.61 48.08l97.3-18.85v.92L353.59 49c0-.33.01-.63.02-.92z"
                className="cls-6"
              />
              <path
                fill="#cfd2d7"
                d="M99.16 48.81L3 30.22v.86l96.16 18.59z"
                className="cls-5"
              />
              <path
                fill="#606774"
                d="M99.16 47.93L2.93 29.3v.92l96.23 18.59z"
                className="cls-6"
              />
              <path
                fill="#cfd2d7"
                d="M353.71 215.18l97.2 6.93v.89l-97.2-6.92z"
                className="cls-5"
              />
              <path
                fill="#606774"
                d="M353.71 214.31l97.2 6.88v.92l-97.2-6.93z"
                className="cls-6"
              />
              <path
                fill="#cfd2d7"
                d="M99.16 215.23L3 222.1v.9l96.21-6.85z"
                className="cls-5"
              />
              <path
                fill="#606774"
                d="M99.16 214.35L3 221.16v.94l96.21-6.87z"
                className="cls-6"
              />
              <path
                fill="#696969"
                d="M26.51 0L2.71 0 0 0 0 4.34 0 253.5 2.71 253.5 2.71 4.34 26.51 4.34 26.51 0z"
                className="cls-2"
              />
              <path
                fill="url(#linear-gradient-3)"
                d="M2.71 0H26.51V2.61H2.71z"
                className="cls-7"
                opacity="0.18"
              />
              <path
                fill="url(#linear-gradient-4)"
                d="M0 121.66H2.71V172.44H0z"
                className="cls-8"
                opacity="0.18"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </Container>
);

export default BoothPanel;
