import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { selectLocale } from 'models/localization';
import { ENABLED_FEATURES } from 'appenv';
import { UserSessionContext } from 'components/UserSession';
import findAvailableLocale from 'models/helpers/findAvailableLocale';

const useBoothEnterTracking = (booth: any) => {
  const locale = useSelector(selectLocale);
  const boothId = booth?.id;
  const boothName = ENABLED_FEATURES.xtraCMS ? findAvailableLocale(booth?.name || {}, 'en') : booth?.name;
  const { userSession } = useContext(UserSessionContext);

  useEffect(() => {
    if (!boothId) return;
    window.trackingEvent('Booth', 'Enter', `${boothId} - ${boothName}`);
    window.trackingEvent('Booth', `Visit_by_${userSession.email}`, `${boothId} - ${boothName}`);
  }, [boothId, boothName]);
};

export default useBoothEnterTracking;
