import PropTypes from 'prop-types';
import { WebinarType } from '../webinars';

export const WebinarDayPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
});

export const WebinarSpeakerPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  profile_picture_url: PropTypes.string,
  translations: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string,
    title: PropTypes.string,
    bio: PropTypes.string,
  })).isRequired,
});


export const WebinarPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  webinar_day: WebinarDayPropType.isRequired,
  start_time: PropTypes.string.isRequired,
  startAt: PropTypes.shape().isRequired,
  end_time: PropTypes.string.isRequired,
  endAt: PropTypes.shape().isRequired,
  timezone: PropTypes.string.isRequired,
  kind: PropTypes.oneOf([WebinarType.Live, WebinarType.OnDemand]).isRequired,
  speakers: PropTypes.arrayOf(WebinarSpeakerPropType).isRequired,
  translations: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    url: PropTypes.string.isRequired,
  })).isRequired,
});
