export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/4b11dad9-bf89-4b63-8283-b5acb8cd2f39.png',
  ],
  url: {
    exhibition_website: 'https://www.ioft.jp/en-gb.html',
    next_show: '/booth/a3ZxqAomrS4HqicWemau566o6FJ5hg8Pgvy2CbHWNMhJ',
  },
  title: 'IOFT 2020 -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/67826a21-7794-4199-9c26-7dab4f6989f6.png',
  ],
  url: {
    exhibition_website: 'https://www.ioft.jp/',
    next_show: '/booth/a3ZxqAomrS4HqicWemau566o6FJ5hg8Pgvy2CbHWNMhJ',
  },
  title: 'オンライン来場・商談サービス｜第33回 国際メガネ展（IOFT）',
};
