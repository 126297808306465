import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';

import DrawerView from './DrawerView';

const MenuButtonContainer = styled.div`
  flex: 1 0 auto;
`;

const DrawerContainer = () => {
  const history = useHistory();
  const { state } = history.location;
  const [openDrawer, setOpenDrawer] = useState(Boolean(state && state.openDrawer));

  const onMenuBtnClick = () => {
    history.push({
      ...history.location,
      state: {
        ...state,
        openDrawer: true,
      },
    });
  };

  const onDrawerClose = () => {
    history.goBack();
  };

  useEffect(() => {
    const unlisten = history.listen(({ state: newState }) => {
      setOpenDrawer(Boolean(newState && newState.openDrawer));
    });
    return unlisten;
  }, [history]);

  return (
    <MenuButtonContainer>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={onMenuBtnClick}
      >
        <MenuIcon />
      </IconButton>
      <DrawerView open={openDrawer} onClose={onDrawerClose} />
    </MenuButtonContainer>
  );
};

export default DrawerContainer;
