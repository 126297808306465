import React, { Suspense } from 'react';
import { envSwitch } from 'utils/envUtils';

const HallTemplateElement = React.lazy(() => envSwitch([
  ['polyu618', () => import('./templates/Hall618Template')],
  ['paloalto623', () => import('./templates/Hall623Template')],
  ['ms624', async () => ({ default: () => null })],
  ['scmp711', () => import('./templates/Hall711Template')],
  ['lighthouse710', async () => ({ default: () => null })],
], () => import('./templates/Hall608Template'))());

const HallTemplateSwitch = (props) => (
  (HallTemplateElement && (
  <Suspense fallback={null}>
    <HallTemplateElement {...props} />
  </Suspense>
  ))
);

export default HallTemplateSwitch;
