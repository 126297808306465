import React, { useMemo } from 'react';
import {
  PAGE_NAMES,
  BRAND_COLOR,
} from 'appenv';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Routes from 'Routes';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import { BoothTaxonomies } from 'models/cms/events';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FilterCategoryContainer from './FilterCategoryContainer';

const HallFilterContainer = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100%;
  overflow: auto;
  padding: 24px;
  color: #282B40;
  background: rgba(255, 255, 255, 0.8);

  @media only screen and (max-width: 959px) {
    display: none;
  }
`;

const FilterTitleContainer = styled.div`
  width: 100%;
`;

const FilterText = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 12px 0;
  display: inline-block;
  text-transform: uppercase;
`;

const ClearAllText = styled.span`
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  cursor: pointer;
  transition: all 0.1s;
  display: block;
  padding: 12px 0;
  float: right;

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const FilterWrapper = styled.div`
  display: block;
`;

const StyledXtraBreadcrumbs = styled(XtraBreadcrumbs)`
  padding: 0;
`;

export interface GenericCMSBoothFilterProps {
  filterOptions: BoothTaxonomies[];
  selectedFilter: string[];
  setSelectedFilter: (updater: string[] | ((filter: string[]) => string[])) => null;
  resetSelectedFilter: () => null;
}

const GenericCMSBoothFilter: React.FC<GenericCMSBoothFilterProps> = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  resetSelectedFilter,
}: GenericCMSBoothFilterProps) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery('(min-width: 960px)', { defaultMatches: true });

  const totalSelectedText = useMemo(
    () => (
      selectedFilter.length
        ? ` (${selectedFilter.length})`
        : ''
    ),
    [selectedFilter.length],
  );

  if (!isDesktop) return null;

  return (
    <HallFilterContainer>
      <StyledXtraBreadcrumbs paths={[{ name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby }, { name: PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall') }]} />
      <FilterTitleContainer>
        <FilterText>
          {`${t('filter.title', 'Filter')}${totalSelectedText}`}
        </FilterText>
        <ClearAllText onClick={resetSelectedFilter}>
          {t('filter.clear_all', 'Clear all')}
        </ClearAllText>
      </FilterTitleContainer>
      <FilterWrapper>
        {filterOptions.map((filter) => (
          <FilterCategoryContainer
            key={filter.id}
            filter={filter}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
          />
        ))}
      </FilterWrapper>
    </HallFilterContainer>
  );
};

export default GenericCMSBoothFilter;
