import React from 'react';
import ReactPlayer from 'react-player';
import PlayButton from 'assets/play-button.png';

const SliderVideo = ({
  url,
  renderKey,
  thumbnail,
  onPlayVideo,
  onPauseVideo,
}: {
  url: string;
  renderKey: string;
  thumbnail?: string;
  onPauseVideo: () => void;
  onPlayVideo: () => void;
}) => (
  <ReactPlayer
    light={thumbnail || false}
    $renderKey={renderKey}
    url={url}
    controls
    playIcon={<img src={PlayButton} alt="Play Video" />}
    width="100%"
    onPlay={onPlayVideo}
    onBuffer={onPlayVideo}
    onStart={onPlayVideo}
    onReady={onPlayVideo}
    onPause={onPauseVideo}
    onClick={onPlayVideo}
    height="100%"
    config={{
      file: {
        attributes: {
          autoPlay: true,
          muted: true,
          onLoadStart: onPlayVideo,
          disablePictureInPicture: true,
          controlsList: 'nodownload noremoteplayback',
        },
      },
    }}
    playsinline
    style={{ zIndex: 100, backgroundColor: 'black' }}
  />
);

export default SliderVideo;
