import React, { useMemo } from 'react';
import {
  PAGE_NAMES, EVENT_NAME, APP,
} from 'appenv';
import { reedApps } from 'apps';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ProductList from './components/ProductList';

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-color: #F0F2F5;
  position: relative;
`;

const RootContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  overflow: auto;
`;

const ProductPage = () => {
  const { t } = useTranslation();
  const pageTitle = PAGE_NAMES.products || t('page.products', 'Products');
  const eventName = useMemo(() => {
    if (reedApps.includes(APP as any)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | ${eventName}`}</title>
        <meta name="description" content={`${pageTitle} page of ${eventName}.`} />
      </Helmet>
      <PageContainer>
        <RootContainer>
          <ProductList />
        </RootContainer>
      </PageContainer>
    </>
  );
};

export default ProductPage;
