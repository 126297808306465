import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import NoDataIndicator from 'components/indicators/NoDataIndicator';
import OnDemandWebinarItem from './OnDemandWebinarItem';

const StyledWebinarItem = styled(OnDemandWebinarItem)``;

const RootContainer = styled.div`
  flex: 1 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;

  @media (min-width: 960px) {
    padding: 24px 75px;
  }

  @media (max-width: 960px) {
    padding: 16px 10px;
  }

  ${StyledWebinarItem}:not(last-child) {
    margin-bottom: 16px;
  }
`;

const OnDemandWebinarList = ({ webinars }) => (
  <RootContainer>
    {!webinars.length && <NoDataIndicator />}
    {
      webinars.map((webinar) => (
        <StyledWebinarItem key={webinar.id} webinar={webinar} />
      ))
    }
  </RootContainer>
);

OnDemandWebinarList.propTypes = {
  webinars: PropTypes.arrayOf(PropTypes.shape()),
};

OnDemandWebinarList.defaultProps = {
  webinars: [],
};

export default OnDemandWebinarList;
