import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from 'react-router-dom';

import { SearchFieldStateProvider } from 'components/SearchFieldContext';
import SearchModal from './SearchModal';

const RootContainer = styled.div`
  flex: 0 0 auto;
`;

const SearchButtonContainer = () => {
  const history = useHistory();
  const { state } = history.location;
  const [openModal, setOpenModal] = useState(Boolean(state && state.openModal));

  const onSearchBtnClick = () => {
    // if there have new enter point please let allen know.
    window.trackingEvent('Event Navigation', 'MainMenu_Click', 'Search');
    history.push({
      state: { openModal: true },
    });
  };

  const onModalClose = () => {
    history.goBack();
  };

  useEffect(() => {
    const unlisten = history.listen(({ state: newState }) => {
      setOpenModal(Boolean(newState && newState.openModal));
    });
    return unlisten;
  }, [history]);

  return (
    <RootContainer>
      <IconButton
        edge="end"
        color="inherit"
        aria-label="search"
        onClick={onSearchBtnClick}
      >
        <SearchIcon />
      </IconButton>
      <SearchFieldStateProvider>
        <SearchModal open={openModal} onClose={onModalClose} />
      </SearchFieldStateProvider>
    </RootContainer>
  );
};

export default SearchButtonContainer;
