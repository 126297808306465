import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const fetchRongcloudGroupQuery = `
  query fetchRongcloudGroup(
    $rongcloudId: String!,
    $afterCursor: String!,
    $keyword: String,
  ) {
    rongcloudGroup(rongcloudId: $rongcloudId) {
      rongcloudUsers(
        keyword: $keyword,
        first: 20,
        after: $afterCursor
      ) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            name
            customAttendeeFields
            uuid
          }
        }
      }
    }
  }
`;

function fetchRongcloudGroup(
  rongcloudId,
  afterCursor = '',
  keyword = '',
) {
  return request(API_URL, fetchRongcloudGroupQuery, {
    rongcloudId,
    afterCursor,
    keyword,
  }).then((result) => {
    const { rongcloudGroup } = result;
    return rongcloudGroup;
  });
}

export default fetchRongcloudGroup;
