/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Booth618TemplateA from './Booth618TemplateA';
import Booth618TemplateB from './Booth618TemplateB';

const Booth618Template = ({ booth, handleClick, showRepsImg }) => {
  switch (booth.design) {
    case '001':
      return <Booth618TemplateA booth={booth} handleClick={handleClick} showRepsImg={showRepsImg} />;
    default:
      return <Booth618TemplateB booth={booth} handleClick={handleClick} showRepsImg={showRepsImg} />;
  }
};

Booth618Template.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth618Template.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth618Template;
