import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const fetchAllContactsQuery = `
  query fetchAllContacts(
    $rongcloudId: String!,
    $keyword: String,
    $afterCursor: String,
    $limit: Int,
  ) {
    contactsByUuid(
      uuid: $rongcloudId
      keyword: $keyword
      first: $limit
      after: $afterCursor
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          createdAt
          contact {
            uuid
            name
            customAttendeeFields
            email
            role
          }
        }
      }
    }
  }
`;

function fetchAllContacts(
  rongcloudId,
  keyword = '',
  afterCursor = '',
  limit = 20,
) {
  return request(API_URL, fetchAllContactsQuery, {
    rongcloudId,
    keyword,
    afterCursor,
    limit,
  }).then((result) => {
    const { contactsByUuid } = result;
    return contactsByUuid;
  });
}

export default fetchAllContacts;
