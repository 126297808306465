import {
  createSlice,
} from '@reduxjs/toolkit';

export const RtcCallingInfoSlice = createSlice({
  name: 'rtcCallingInfo',
  initialState: {},
  reducers: {
    setCallInfo(state, action) {
      return action.payload;
    },
  },
});

export const {
  setCallInfo,
} = RtcCallingInfoSlice.actions;

export default RtcCallingInfoSlice.reducer;
