import React from 'react';
import { BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FilterOptionContainer from './FilterOptionContainer';

const CustomCheckbox = styled(Checkbox)`
  width: 42px;
  padding: 0 9px;
  vertical-align: top;
  margin-right: 7px;
  color: #A2AAB3;

  &:checked {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const StyledLabel = styled.label`
  display: inline-block;
  width: calc(100% - 50px);
  font-size: 14px;
  line-height: 20px;
  color: #484848;
  cursor: pointer;
`;

const FilterCheckboxOption = ({
  label,
  index,
  category,
  onClick,
  checked = false,
}: {
  label: string;
  index: number;
  category: string;
  checked?: boolean;
  onClick: (categoryId: string, checked: boolean) => void;
}) => {
  const onCheckboxClick = (event: React.MouseEvent) => {
    // eslint-disable-next-line
    // @ts-ignore
    onClick(category, event.target.checked);
  };

  return (
    <FilterOptionContainer selected={checked}>
      <CustomCheckbox color="default" id={category + index} onClick={onCheckboxClick} checked={checked} />
      <StyledLabel htmlFor={category + index}>{`${label}`}</StyledLabel>
    </FilterOptionContainer>
  );
};

export default FilterCheckboxOption;
