import Switch from '@material-ui/core/Switch';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment-timezone';
import { localizationSlice, selectLocalizationState } from 'models/localization';


const RootContainer = styled.div``;

const TimeZoneSwitch = ({ className }: any) => {
  const { useLocalTime, eventTimezone } = useSelector(createSelector(selectLocalizationState,
    ((state) => ({ useLocalTime: state.useLocalTime, eventTimezone: state.eventTimezone }))));

  const sameTimeZone = moment.tz(moment.tz.guess()).utcOffset() === moment.tz(eventTimezone).utcOffset();

  const dispatch = useDispatch();
  const onToggle = (e) => {
    dispatch(localizationSlice.actions.setUseLocalTime(e.target.checked));
  };
  return (
    !sameTimeZone && (
      <RootContainer className={className}>
        <FormControlLabel
          control={<Switch checked={useLocalTime} onChange={onToggle} />}
          label="Use local time"
        />
      </RootContainer>
    )
  );
};

TimeZoneSwitch.defaultProps = {
  className: '',
};

export default TimeZoneSwitch;
