import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import playButton from 'assets/play-button.png';

const BoothVideoContainer = styled.div`
  width: 100%;
  min-height: 100px;
  margin: 12px 0;
`;

const BoothVideo = ({ src, thumbnail }: {
  src: string;
  thumbnail: string;
}) => (
  <BoothVideoContainer>
    <ReactPlayer
      light={thumbnail || 'light'}
      url={src}
      controls
      playIcon={<img src={playButton} alt="Play Button" />}
      width="100%"
      height="100%"
      config={{
        file: {
          attributes: {
            autoPlay: true,
          },
        },
      }}
      playsinline
      style={{ zIndex: 100, backgroundColor: 'black' }}
    />
  </BoothVideoContainer>
);

export default BoothVideo;
