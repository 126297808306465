import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import { selectGroupMembersByGroupId } from 'models/chatTargetGroupMembers';
import GroupMemberListItem from './GroupMemberListItem';
import { UserSessionContext } from '../UserSession';

const Container = styled.div`
  z-index: 9999;
  background-color: #FFF;
  height: ${({ isMobile }) => (isMobile ? 'calc(100% - 44px)' : 'calc(100% - 48px - 44px)')};
  border-top: ${({ isMobile }) => (isMobile ? '1px solid #DBDEE2' : 'none')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '40%')};
  position: absolute;
  border-left: ${({ isMobile }) => (isMobile ? 'none' : '1px solid #DBDEE2')};
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleText = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 16px;
  color: #484848;
  font-weight: normal;
  font-size: 12px;
  border-top: 1px solid #DBDEE2;
  border-bottom: 1px solid #DBDEE2;
`;

const ListContainer = styled.div`
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
`;

const GroupMemberList = ({ joiningChat, handleAvatarOnclick, isMobile }) => {
  const { targetId } = useSelector((state) => state.chatTarget);
  const groupMembers = useSelector(selectGroupMembersByGroupId(targetId));
  const { userSession } = useContext(UserSessionContext);

  return (
    <Container isMobile={isMobile}>
      {
        !isMobile && (
          <TitleText>Group Members</TitleText>
        )
      }
      <ListContainer>
        {
          joiningChat && <CircularLoadingIndicator />
        }
        {
          !joiningChat && groupMembers && groupMembers.map((member) => {
            const {
              name,
              uuid,
              customAttendeeFields: {
                vexpo_chat_tag: userTag,
                vexpo_chat_user_description: userDescription,
              },
            } = member;
            const isSelf = userSession.rongcloudUserId === uuid;

            return (
              <GroupMemberListItem
                key={uuid}
                name={name}
                userTag={userTag}
                userDescription={userDescription}
                handleAvatarOnclick={() => handleAvatarOnclick('PublicChatroom_GroupMembersAvatar_Click', isSelf, uuid, name)}
              />
            );
          })
        }
      </ListContainer>
    </Container>
  );
};

export default GroupMemberList;

GroupMemberList.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  handleAvatarOnclick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
