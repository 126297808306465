import React from 'react';
import { BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { BoothTaxonomies } from 'models/cms/events';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';

const CustomCheckbox = withStyles({
  root: {
    width: '42px',
    padding: '0 9px',
    verticalAlign: 'top',
    marginRight: '7px',
    color: '#A2AAB3',
    '&$checked': {
      color: BRAND_COLOR || '#0CA58B',
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props: any) => <Checkbox color="default" {...props} />);

const StyledFilterOptionContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 0;
  transition: all 0.1s;
  display: block;
  color: ${({ selected }) => (selected && (BRAND_COLOR || '#0CA58B'))};

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const StyledLabel = styled.label`
  display: inline-block;
  width: calc(100% - 50px);
  font-size: 14px;
  line-height: 20px;
  color: #484848;
  cursor: pointer;
`;

export interface FilterCheckboxOptionProps {
  filter: Omit<BoothTaxonomies, 'subcategories'>;
  selectedFilter: string[];
  setSelectedFilter: (updater: string[] | ((filter: string[]) => string[])) => null;
}

const FilterCheckboxOption: React.FC<FilterCheckboxOptionProps> = ({
  filter,
  selectedFilter,
  setSelectedFilter,
}: FilterCheckboxOptionProps) => {
  const locale = useSelector(selectLocale);
  const isSelected = !!selectedFilter.find((it) => it === filter.id);
  const displayLabel = filter.name[locale] || Object.values(filter.name).filter((it) => it)[0];

  const onCustomCheckboxClick = () => {
    setSelectedFilter((prev) => {
      const index = prev.findIndex((it) => it === filter.id);
      if (index === -1) return [...Array.from(new Set([...prev, filter.id]))];
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  return (
    <StyledFilterOptionContainer>
      <CustomCheckbox
        id={filter.id}
        onClick={onCustomCheckboxClick}
        checked={isSelected}
      />
      <StyledLabel htmlFor={filter.id}>{displayLabel}</StyledLabel>
    </StyledFilterOptionContainer>
  );
};

export default FilterCheckboxOption;
