import i18n from 'i18next';
import { reedApps } from 'apps';
import { initReactI18next } from 'react-i18next';
import { envSwitch } from 'utils/envUtils';
import { resources } from './resources';

const defaultLang = envSwitch([
  [reedApps, 'ja'],
], 'en');

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLang,
    fallbackLng: {
      'zh-Hant': ['zh-Hans', 'zh', 'en', 'ja'],
      'zh-Hans': ['zh-Hant', 'zh', 'en', 'ja'],
      default: [defaultLang],
    },
    interpolation: {
      escapeValue: false,
      prefix: '%{',
      suffix: '}',
    },
  });

export default i18n;
