import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ReactPlayer from 'react-player';
import BusinessPopover from 'pages/booths/components/BusinessPopover';
import { LightboxContext } from 'components/booth/CustomLightbox/LightboxContext';
import BrochureShelf from './BrochureShelf';
import BoothContainer from '../../../components/BoothContainer';
import UpgradedScreenBackground from './UpgradedScreenBackground';
import GenericBoothBanner from '../../../components/GenericBoothBanner';

const Booth711Container = styled(BoothContainer)<{ isPreview: boolean }>`
  width: 1150px;
  left: ${({ isPreview }) => (isPreview ? '-106px' : 'auto')};
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: contain;
  position: absolute;
`;

const VerticalBannerContainer = styled.div`
  position: absolute;
  width: 152px;
  height: 334px;
`;

const LeftBannerContainer = styled(VerticalBannerContainer)`
  top: 141px;
  left: 103px;
`;

const RightBannerContainer = styled(VerticalBannerContainer)`
  top: 144px;
  right: 102px;
`;

const BottomBannerContainer = styled.div`
  position: absolute;
  width: 395px;
  top: 383px;
  left: 377px;
  height: 104px;
`;

const TopBannerContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 399px;
  width: 351px;
  height: 97px;
`;

const CenterTVContainer = styled.div`
  position: absolute;
  left: 432px;
  top: 164px;
  width: 287px;
  background-color: rgb(126, 142, 150);
  height: 143px;
  padding: 2px 20px;
`;

const TVScreenColor = styled.div`
  width: 100%;
  height: 100%;
  background-color: black;
`;

const BrochureContainer = styled.div<{ enableClick: boolean }>`
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'auto')};
  position: absolute;
  bottom: 0;
  right: 20px;
`;

const BusinessPopoverContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 340px;
`;

const Booth711Template = ({
  booth,
  handleClick,
  showRepsImg,
}: {
  booth: any;
  handleClick: (type: string) => void;
  showRepsImg: boolean;
}) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const pullUpFile = booth.verticalBannerFile;
  const { url, cfStreamId } = useContext(LightboxContext);
  const centerTVRef: React.RefObject<ReactPlayer | HTMLVideoElement> = useRef();

  const setCenterVideoPlaying = useCallback((playState: boolean) => {
    const videoElement: any = (centerTVRef?.current instanceof ReactPlayer) ? centerTVRef?.current?.getInternalPlayer() : centerTVRef?.current;
    if (videoElement && videoElement.play && videoElement.pause) {
      if (playState) {
        videoElement?.play();
      } else {
        videoElement?.pause();
      }
    }
  }, [centerTVRef]);

  const onShowBannerPopup = useCallback((type) => {
    if (type === 'video') {
      setCenterVideoPlaying(false);
    }
  }, [setCenterVideoPlaying]);
  const onHideBannerPopup = useCallback((type) => {
    if (type === 'video') {
      setCenterVideoPlaying(true);
    }
  }, [setCenterVideoPlaying]);

  useEffect(() => {
    if (url || cfStreamId) {
      onShowBannerPopup('video');
    } else {
      onHideBannerPopup('video');
    }
  }, [
    url,
    cfStreamId,
    onShowBannerPopup,
    onHideBannerPopup,
  ]);

  return (
    <Booth711Container isPreview={!showRepsImg} needOffset={showRepsImg}>
      <BackgroundContainer>
        <UpgradedScreenBackground boothColor={booth.boothColor || '#FCB415'} />
      </BackgroundContainer>
      <LeftBannerContainer>
        <GenericBoothBanner
          name={booth.name}
          from="Banner 1"
          boothNumber={booth.id}
          handleClick={handleClick}
          bannerData={booth?.banners?.left[0]}
          onShowPopup={onShowBannerPopup}
          onHidePopup={onHideBannerPopup}
        />
      </LeftBannerContainer>
      <RightBannerContainer>
        <GenericBoothBanner
          name={booth.name}
          from="Banner 6"
          boothNumber={booth.id}
          handleClick={handleClick}
          bannerData={booth?.banners?.right[0]}
          onShowPopup={onShowBannerPopup}
          onHidePopup={onHideBannerPopup}
        />
      </RightBannerContainer>
      <TopBannerContainer>
        <GenericBoothBanner
          name={booth.name}
          from="Banner 2"
          boothNumber={booth.id}
          handleClick={handleClick}
          bannerData={booth?.banners?.top[0]}
          objectFit="contain"
          onShowPopup={onShowBannerPopup}
          onHidePopup={onHideBannerPopup}
        />
      </TopBannerContainer>
      <CenterTVContainer>
        <TVScreenColor>
          <GenericBoothBanner
            name={booth.name}
            from="Banner 3"
            boothNumber={booth.id}
            handleClick={handleClick}
            bannerData={booth?.banners?.center[0]}
            isPreview={!showRepsImg}
            ref={centerTVRef}
          />
        </TVScreenColor>
      </CenterTVContainer>
      <BottomBannerContainer>
        <GenericBoothBanner
          name={booth.name}
          from="Banner 5"
          boothNumber={booth.id}
          handleClick={handleClick}
          bannerData={booth?.banners?.bottom[0]}
          onShowPopup={onShowBannerPopup}
          onHidePopup={onHideBannerPopup}
        />
      </BottomBannerContainer>
      <BrochureContainer enableClick={!!booth.attachments.length} onClick={() => handleClick('attachment')}>
        <BrochureShelf
          hasAttachment={!!booth.attachments.length}
        />
      </BrochureContainer>
      { showRepsImg && (
        <BusinessPopoverContainer>
          <BusinessPopover
            boothId={booth.id}
            boothName={booth.name}
            emailContacts={booth.emailContacts}
            meetupUrl={booth.meetupUrl}
          />
        </BusinessPopoverContainer>
      ) }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={pullUpFile}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
    </Booth711Container>
  );
};

export default Booth711Template;
