/* eslint-disable no-underscore-dangle */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import { normalize } from 'normalizr';

import remoteTimeNow from 'libs/remote-time-now';
import { selectAvailableLocales } from 'models/localization';
import fetchBoothMetaById from 'network/fetchBoothMetaById';
import fetchBoothDesignElementsById from 'network/fetchBoothDesignElements';
import fetchBoothRootContentElementById from 'network/fetchBoothRootContentElementById';
import fetchMetaBoothsRequest from 'network/fetchMetaBooths';
import fetchBoothCategoriesByIdRequest from 'network/fetchBoothCategoriesById';
import {
  BoothFetchedLevel,
  isFetchingLevelHigherThanExpected,
  Booth,
  BoothFetchingState,
  isFetchingLevelLowerThanExpected,
  boothEntity,
} from './types';
import {
  selectBoothById,
  boothsAdapter,
  selectBoothsState,
  selectBoothPageInfo,
} from './selectors';
import { fetchCachedCMSData } from '../common/thunks';

interface FetchBoothByIdProps {
  boothId: string;
  expectedLevel?: BoothFetchedLevel;
}

export const fetchBoothById = createAsyncThunk(
  'booths/fetchBoothById',
  async ({
    boothId,
    expectedLevel = BoothFetchedLevel.Details,
  }: FetchBoothByIdProps, { getState }) => {
    const state = getState();
    const locales = selectAvailableLocales(state);
    const fetchedLevel = selectBoothById(state, boothId)?.$fetchedLevel;

    const rules: [BoothFetchedLevel, any][] = [
      [BoothFetchedLevel.Preview, fetchBoothDesignElementsById],
      [BoothFetchedLevel.Details, fetchBoothRootContentElementById],
    ];

    if (!fetchedLevel || expectedLevel === BoothFetchedLevel.Meta) {
      rules.unshift([BoothFetchedLevel.Meta, fetchBoothMetaById]);
    }

    const tasks = rules
      .filter(([level]) => isFetchingLevelHigherThanExpected(level, fetchedLevel) && !isFetchingLevelHigherThanExpected(level, expectedLevel))
      .map(async ([, action]) => action({ boothId, locales }));

    const result = (await Promise.all(tasks))
      .reduce((sum, next) => merge(sum, next), {
        $fetchedLevel: isFetchingLevelLowerThanExpected(fetchedLevel, expectedLevel) ? expectedLevel : fetchedLevel,
        $lastReceivedAt: remoteTimeNow().toISOString(),
      });

    const { entities } = normalize<Booth>([result], [boothEntity]);
    return entities.booths || {};
  },
  {
    condition({ boothId, expectedLevel }, { getState }) {
      const state = getState();
      const entity = selectBoothsState(state);
      const fetchingState = entity.boothFetchingState[boothId];
      const booth = selectBoothById(state, boothId);
      switch (fetchingState) {
        case BoothFetchingState.Rejected: return false;
        case BoothFetchingState.Pending: return false;
        case BoothFetchingState.Fulfilled: return isFetchingLevelLowerThanExpected(booth?.$fetchedLevel, expectedLevel);
        default: return true;
      }
    },
  },
);

export const fetchBoothCategoriesById = createAsyncThunk(
  'booths/fetchBoothCategoriesById',
  async ({ boothId }: any, { getState }) => {
    const state = getState();
    const locales = selectAvailableLocales(state);
    const response = await fetchBoothCategoriesByIdRequest({ locales, boothId });
    const { entities } = normalize<Booth>([response], [boothEntity]);
    return { booths: entities.booths };
  },
);

export const fetchMetaBooths = createAsyncThunk(
  'booths/fetchMetaBooths',
  async ({ boothCount, boothsCursor, continueFetching = false }: any = {}, { getState, dispatch }) => {
    const state = getState();
    const locales = selectAvailableLocales(state);
    const response = await fetchMetaBoothsRequest({ locales, cursor: boothsCursor, count: boothCount });

    const { pageInfo } = response.booths;
    delete response.booths.pageInfo;

    const now = remoteTimeNow().toISOString();
    response.booths.nodes = response.booths.nodes
      .filter((node) => isFetchingLevelLowerThanExpected(selectBoothById(getState(), node.id)?.$fetchedLevel, BoothFetchedLevel.Meta))
      .map((node) => ({
        ...node,
        $fetchedLevel: BoothFetchedLevel.Meta,
        $lastReceivedAt: now,
      }));

    response.booths.nodes.forEach((it: any) => dispatch(fetchBoothCategoriesById({ boothId: it.id })));

    const { entities } = normalize<Booth>(response.booths.nodes, [boothEntity]);

    return {
      booths: entities.booths || {},
      pageInfo: {
        ...pageInfo,
        fetching: continueFetching && pageInfo.hasNextPage,
      },
    };
  },
);

export const fetchMoreMetaBooths = createAsyncThunk(
  'booths/fetchMoreMetaBooths',
  async (_, { getState, dispatch }) => {
    const state = getState();
    const pageInfo = selectBoothPageInfo(state);
    return dispatch(fetchMetaBooths({
      boothsCursor: pageInfo.endCursor,
    }));
  },
  {
    condition(_, { getState }) {
      const state = getState();
      const pageInfo = selectBoothPageInfo(state);
      return pageInfo.hasNextPage && !pageInfo.fetching;
    },
  },
);

export const boothsSlice = createSlice({
  name: 'booths',
  initialState: boothsAdapter.getInitialState({
    fetching: true,
    pageInfo: {
      hasNextPage: true,
      endCursor: null,
      fetching: false,
    },
    boothFetchingState: {},
  }),
  reducers: {
    setPageInfoFetching(state, action: PayloadAction<boolean>) {
      state.pageInfo.fetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCachedCMSData.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload.booths);
      state.ids = action.payload.boothIds;
      state.fetching = false;
      state.pageInfo = {
        hasNextPage: false,
        endCursor: null,
        fetching: false,
      };
      state.boothFetchingState = action.payload.boothFetchingState;
    });

    builder.addCase(fetchBoothById.pending, (state, action) => {
      const { boothId } = action.meta.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Pending;
    });
    builder.addCase(fetchBoothById.rejected, (state, action) => {
      const { boothId } = action.meta.arg;
      state.boothFetchingState[boothId] = BoothFetchingState.Rejected;
    });
    builder.addCase(fetchBoothById.fulfilled, (state, action) => {
      const { boothId } = action.meta.arg;
      if (!action.payload[boothId]) {
        state.boothFetchingState[boothId] = BoothFetchingState.Rejected;
        return;
      }
      state.boothFetchingState[boothId] = BoothFetchingState.Fulfilled;
      boothsAdapter.upsertMany(state, action.payload);
    });

    builder.addCase(fetchMetaBooths.pending, (state) => {
      state.pageInfo.fetching = true;
    });
    builder.addCase(fetchMetaBooths.rejected, (state) => {
      if (state.fetching) state.fetching = false;
      state.pageInfo.fetching = false;
    });
    builder.addCase(fetchMetaBooths.fulfilled, (state, action) => {
      if (state.fetching) state.fetching = false;
      if (Object.keys(action.payload).length === 0) {
        state.fetching = false;
        state.pageInfo.hasNextPage = false;
        state.pageInfo.fetching = false;
        return;
      }
      state.fetching = false;
      state.pageInfo = action.payload.pageInfo;
      boothsAdapter.upsertMany(state, action.payload.booths || {});
    });
    builder.addCase(fetchBoothCategoriesById.fulfilled, (state, action) => {
      boothsAdapter.upsertMany(state, action.payload.booths || {});
    });
  },
});

export default boothsSlice.reducer;
