import {
  createSlice,
} from '@reduxjs/toolkit';

export const loginTime = createSlice({
  name: 'loginTime',
  initialState: Date.now(),
  reducers: {
    setLoginTime(state, action) {
      return action.payload;
    },
  },
});

export const {
  setLoginTime,
} = loginTime.actions;

export default loginTime.reducer;
