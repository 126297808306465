import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

import groupBy from 'lodash/groupBy';

import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import NoDataIndicator from 'components/indicators/NoDataIndicator';
import LiveWebinarList from 'pages/webinar/live/LiveWebinarList';
import DaySelector from 'pages/webinar/DaySelector';
import { Webinar } from 'models/webinars';
import { BREAKPOINT } from 'pages/webinar/constants';
import { LiveWebinarListOptions } from 'pages/webinar/live/LiveWebinarListOptions';
import moment from 'moment';

const WebinarListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  flex: 1 1 auto;
`;

const LoadingIndicator = styled(CircularLoadingIndicator)`
  width: 100%;
`;

const NoDataIndicatorContainer = styled.div`
  margin-top: 24px;
`;

const LiveWebinarDaysList = styled.div<any>`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: hidden;

  @media (max-width: ${BREAKPOINT}px) {
    margin-left: 0;
    width: 100%;
  }
`;

interface LiveListContainerProps {
  webinars: Webinar[];
  isLoading: boolean;
  options: LiveWebinarListOptions;
}

const LiveListContainer = ({ webinars = [], isLoading, options }: LiveListContainerProps) => {
  const [activeDay, setActiveDay] = useState(0);
  const webinarsByDays = useMemo(() => (
    groupBy(
      webinars.sort((a, b) => moment(a.startAt).unix() - moment(b.startAt).unix()),
      ({ startAt }) => moment(startAt).format('ddd, MMM DD'),
    )
  ), [webinars]);

  const onDaySelected = (selectedIndex) => {
    setActiveDay(selectedIndex);
  };

  const enableDaySelector = options.daySelector.enable;

  return (
    <WebinarListContainer>
      {
        isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {!Object.keys(webinarsByDays).length && (
              <NoDataIndicatorContainer>
                <NoDataIndicator />
              </NoDataIndicatorContainer>
            )}
            {!!Object.keys(webinarsByDays).length && (
              <LiveWebinarDaysList>
                {enableDaySelector && (
                  <DaySelector
                    days={Object.keys(webinarsByDays)}
                    activeIndex={activeDay}
                    onDaySelected={onDaySelected}
                  />
                )}
                <LiveWebinarList webinars={Object.values(webinarsByDays)[activeDay]} options={options} />
              </LiveWebinarDaysList>
            )}
          </>
        )
      }
    </WebinarListContainer>
  );
};

export default LiveListContainer;
