import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const BoothRichtextContainer = styled.div`
  padding: 12px 24px;
`;

const BoothRichtext = ({ content }: {
  content: string;
}) => (
  <BoothRichtextContainer>
    {ReactHtmlParser(content)}
  </BoothRichtextContainer>
);

export default BoothRichtext;
