import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TrashIcon from 'assets/trashIcon.svg';
import { UserSessionContext } from 'components/UserSession';
import {
  ButtonIcon, ButtonLabel, RoundedButton,
} from '../input/RoundedButton';
import { ENABLED_FEATURES, BRAND_COLOR } from '../../appenv';
import BookmarkedIcon from '../icons/Bookmarked';
import BookmarkIcon from '../icons/Bookmark';
import { LightboxContext } from './CustomLightbox/LightboxContext';

const ItemContainer = styled.div`
  margin: 12px 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0;
  }
`;

const LabelContainer = styled.a`
  font-size: 16px;
  line-height: 16px;
  display: block;
  word-break: break-word;
  color: #5C8DF3;

  @media (min-width: 960px) {
    color: #000;
    text-transform: none;
    text-decoration: none;
  }
`;

const StyledButtonLabel = styled(ButtonLabel)`
  display: none;

  @media (min-width: 960px) {
    display: inline-block;
  }
`;

const AttachmentViewButton = styled(RoundedButton)`
  background: ${BRAND_COLOR || '#8B383A'};
  width: 96px;
  height: 34px;

  @media (max-width: 959px) {
    display: none;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  & > * {
    margin-left: 8px;
  }
`;

const AttachmentRow = ({
  attachment, children, href, trackingBooth, cfStreamId,
}) => {
  const lightboxContext = useContext(LightboxContext);
  const { userSession } = useContext(UserSessionContext);
  const onTitleClick = useCallback(() => {
    if (cfStreamId) {
      lightboxContext.setCfStreamId(cfStreamId);
    } else {
      window.open(href);
    }
    window.trackingEvent('Booth', 'Total_Files_View', trackingBooth);
    window.trackingEvent('Booth', `Per_File_View_${attachment.file.data.full_url}_View_${userSession.email}`, trackingBooth);
  }, [cfStreamId, trackingBooth, attachment.file.data.full_url, lightboxContext, href, userSession]);

  if (!attachment.file) return null;

  return (
    <ItemContainer>
      <LabelContainer target="_blank" rel="noopener noreferrer" onClick={onTitleClick}>
        {/* eslint-disable-next-line camelcase */}
        {attachment.file?.filename_download}
      </LabelContainer>
      <ActionsContainer>
        {children}
      </ActionsContainer>
    </ItemContainer>
  );
};

AttachmentRow.propTypes = {
  attachment: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  trackingBooth: PropTypes.string.isRequired,
  cfStreamId: PropTypes.string.isRequired,
};

const BoothPitchAttachmentBookmarkButton = styled(RoundedButton)`
  width: 42px;
  background: #FFF;
  color: #484848;
  border: none;

  @media (min-width: 960px) {
    width: 136px;
    border: 2px solid #DBDEE2;
  }
`;

const AttachmentBookmarkIcon = ({ isBookmarked }) => (
  <ButtonIcon>
    {isBookmarked ? <BookmarkedIcon /> : <BookmarkIcon />}
  </ButtonIcon>
);

AttachmentBookmarkIcon.propTypes = {
  isBookmarked: PropTypes.bool.isRequired,
};

export const BoothPitchAttachmentRow = ({
  attachment, isBookmarked, onBookmarkToggle, trackingBooth,
}) => {
  const { userSession } = useContext(UserSessionContext);

  const onBookmarkedClick = useCallback(() => {
    onBookmarkToggle();
    window.trackingEvent('Booth', 'Total_Files_Bookmark', trackingBooth);
    window.trackingEvent('Booth', `Per_File_Bookmark_${attachment.file.data.full_url}`, trackingBooth);
  }, [attachment.file.data.full_url, onBookmarkToggle, trackingBooth]);

  const onTitleClick = useCallback(() => {
    window.open(attachment.file.data.full_url);
    window.trackingEvent('Booth', 'Total_Files_View', trackingBooth);
    window.trackingEvent('Booth', `Per_File_View_${attachment.file.data.full_url}_View_${userSession.email}`, trackingBooth);
  }, [attachment.file.data.full_url, trackingBooth, userSession]);

  return (
    <AttachmentRow attachment={attachment} trackingBooth={trackingBooth} href={attachment.file.data.full_url}>
      {
        ENABLED_FEATURES.bookmarks && (
          <BoothPitchAttachmentBookmarkButton onClick={onBookmarkedClick}>
            <AttachmentBookmarkIcon isBookmarked={isBookmarked} />
            <StyledButtonLabel>
              {isBookmarked ? 'Bookmarked' : 'Bookmark'}
            </StyledButtonLabel>
          </BoothPitchAttachmentBookmarkButton>
        )
      }
      <AttachmentViewButton onClick={onTitleClick}>View</AttachmentViewButton>
    </AttachmentRow>
  );
};

BoothPitchAttachmentRow.propTypes = {
  onBookmarkToggle: PropTypes.func.isRequired,
  attachment: PropTypes.shape().isRequired,
  isBookmarked: PropTypes.bool.isRequired,
  trackingBooth: PropTypes.string.isRequired,
};

export const BoothPitchWebinarRow = ({
  webinar,
  trackingBooth,
}) => {
  const lightboxContext = useContext(LightboxContext);
  const { userSession } = useContext(UserSessionContext);
  // eslint-disable-next-line camelcase
  const { cloudflare_stream_id = '', url } = (webinar.translations || [])[0] ?? {};
  const onTitleClick = useCallback(() => {
    // eslint-disable-next-line camelcase
    if (cloudflare_stream_id) {
      lightboxContext.setCfStreamId(cloudflare_stream_id);
    } else {
      window.open(url);
    }
    window.trackingEvent('Booth', 'Total_Files_View', trackingBooth);
    window.trackingEvent('Booth', `Per_File_View_${url}_View_${userSession.email}`, trackingBooth);
  // eslint-disable-next-line camelcase
  }, [cloudflare_stream_id, trackingBooth, url, lightboxContext, userSession]);
  return (
    <AttachmentRow
      attachment={{
        file: {
          filename_download: webinar.webinar_session_title,
          data: {
            full_url: url,
          },
        },
      }}
      // eslint-disable-next-line camelcase
      cfStreamId={cloudflare_stream_id}
      trackingBooth={trackingBooth}
      href={url}
    >
      <AttachmentViewButton onClick={onTitleClick}>View</AttachmentViewButton>
    </AttachmentRow>
  );
};

BoothPitchWebinarRow.propTypes = {
  webinar: PropTypes.shape().isRequired,
  trackingBooth: PropTypes.string.isRequired,
};

const RemoveButton = styled(RoundedButton)`
  background: #FFF;
  border: none;
  color: #000;
  border-radius: 8px;
  width: 42px;
  margin: -8px 0;
  visibility: ${({ removable }) => (removable ? 'auto' : 'hidden')};

  @media (min-width: 960px) {
    border: 2px solid #DBDEE2;
    border-radius: 2px;
    height: 34px;
    width: 96px;
    margin: initial;
  }
`;

const RemoveText = styled.span`
  @media (max-width: 959px) {
    display: none;
  }
`;

const StyledRemoveIcon = styled.img`
  padding: 0 10px;
  display: block;
  fill: #484848;

  & path {
    fill: #484848;
  }

  @media (min-width: 960px) {
    display: none;
  }
`;

export const BookmarksAttachmentRow = ({ attachment, onRemove }) => {
  const openAttachment = useCallback(() => window.open(attachment.file.data.full_url), [attachment.file.data.full_url]);
  return (
    <AttachmentRow attachment={attachment} href={attachment.file.data.full_url}>
      <RemoveButton onClick={onRemove} removable={attachment.removable}>
        <RemoveText>Remove</RemoveText>
        <StyledRemoveIcon src={TrashIcon} />
      </RemoveButton>
      <AttachmentViewButton onClick={openAttachment}>View</AttachmentViewButton>
    </AttachmentRow>
  );
};

BookmarksAttachmentRow.propTypes = {
  attachment: PropTypes.shape().isRequired,
  onRemove: PropTypes.func.isRequired,
};
