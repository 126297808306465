import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';

import { BRAND_COLOR } from 'appenv';
import BoothProfile from './BoothProfile';
import BoothSurvey from './BoothSurvey';
import BoothLink from './BoothLink';
import BoothPitch from '../../components/BoothPitch';
import { BoothProfileTabsContext } from '../../components/BoothProfileTabsContext';

const Container = styled.div`
  width: ${({ isDesktop }) => (isDesktop ? '70%' : '100%')};
  height: fit-content;
  margin: 0 auto;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: #FFF;
  letter-spacing: 0.3px;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  .MuiTab-wrapper {
    font-size: ${({ isDesktop }) => (isDesktop ? '24px' : '18px')};
  }

  button.MuiButtonBase-root.MuiTab-root {
    margin-right: ${({ isDesktop }) => (isDesktop ? '40px' : '20px')};
  }
`;

const StyledSwipeableViews = styled(SwipeableViews)`
  overflow: hidden;
`;

const StyledTabs = withStyles({
  root: {
    margin: '24px 24px 24px 24px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > div': {
      maxWidth: 139,
      width: '100%',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
// eslint-disable-next-line react/jsx-props-no-spreading
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#A2AAB3',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Lato',
    padding: '0',
    minWidth: 'unset',
    '&:focus': {
      opacity: 1,
    },
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

const TabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      children
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

const BoothProfileTabsContainer = ({
  booth: {
    id,
    attachments,
    name,
    description,
    website,
    contacts,
    survey,
    links,
  },
  isDesktop,
}) => {
  const { activeTabKey, setActiveTabKey } = useContext(BoothProfileTabsContext);
  const onActiveTabKeyChange = (event, tabKey) => {
    window.trackingEvent('Booth', `Booth_Profile_Tab_${tabKey + 1}_Click`, `${id} - ${name}`);
    setActiveTabKey(tabKey);
  };

  const tabs = [
    {
      title: 'Files',
      Element: () => <BoothPitch attachments={attachments} />,
      condition: () => true,
    },
    {
      title: 'Survey',
      Element: () => <BoothSurvey survey={survey} />,
      condition: () => !!survey?.trim(),
    },
    {
      title: 'Link',
      Element: () => <BoothLink links={links} />,
      condition: () => links?.length > 0,
    },
  ].filter((it) => it.condition());

  return (
    <Container isDesktop={isDesktop}>
      <StyledTabs scrollButtons="off" variant="scrollable" value={activeTabKey} onChange={onActiveTabKeyChange} aria-label="styled tabs example">
        <StyledTab label="Company" />
        {tabs.map((it) => <StyledTab key={it.title} label={it.title} />)}
      </StyledTabs>
      <StyledSwipeableViews
        axis="x"
        index={activeTabKey}
        disabled
        ignoreNativeScroll
        resistance
        style={{ overflow: 'hidden' }}
      >
        <BoothProfile
          name={name}
          description={description}
          website={website}
          contacts={contacts}
        />
        {tabs.map(({ title, Element }, index) => (
          <TabPanel key={title} value={activeTabKey} index={index + 1}>
            <Element />
          </TabPanel>
        ))}
      </StyledSwipeableViews>
    </Container>
  );
};

BoothProfileTabsContainer.propTypes = {
  booth: PropTypes.shape({
    id: PropTypes.number.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    contacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    socialMedias: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    emailContacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    phoneContacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    name: PropTypes.string.isRequired,
    boothNumber: PropTypes.string,
    description: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    extraFields: PropTypes.shape().isRequired,
    industryVerticals: PropTypes.shape().isRequired,
    fundingStage: PropTypes.string.isRequired,
    coreTechnology: PropTypes.string,
    hashtags: PropTypes.string,
    oneLiner: PropTypes.string,
    survey: PropTypes.string,
    links: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default BoothProfileTabsContainer;
