import React from 'react';
import styled from 'styled-components';
import FileArrow from 'components/icons/FileArrow';
import shelf711Img from 'assets/booths/711-upgraded/shelf-711.png';

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledArrowContainer = styled.div`
  position: absolute;
  left: 32px;
  top: 44px;

  & svg {
    width: 40px;
    height: 40px;
  }

  & path {
    color: white;
    fill: white;
  }
`;

const BrochureShelf = ({ hasAttachment }: { hasAttachment: boolean }) => (
  <>
    <StyledImg src={shelf711Img} />
    <StyledArrowContainer>
      {hasAttachment && <FileArrow />}
    </StyledArrowContainer>
  </>
);

export default BrochureShelf;
