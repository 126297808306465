import {
  createSlice,
  createEntityAdapter,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import { normalize, schema } from 'normalizr';
import { VIDEO_CALL_URL } from 'appenv';
import quitVideoCallRoom from 'network/quitVideoCallRoom';
import acceptVideoCallInvitation from 'network/acceptVideoCallInvitation';
import i18n from 'i18n';
import { addConversation } from './conversations';
import { setCallInfo } from './rtcCallingInfo';
import { setCallStatus } from './rtcCallingStatus';

const messageEntity = new schema.Entity('messages');

const messageAdapter = createEntityAdapter({
  sortComparer: (a, b) => (a.sentTime > b.sentTime ? 1 : -1),
});

export const receiveMessage = createAsyncThunk(
  'messages/received',
  async (message, { dispatch }) => {
    try {
      const { targetId, conversationType } = message;
      dispatch(addConversation({
        id: targetId,
        type: conversationType === 1 ? 'private' : 'group',
        message,
      }));
      return message;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const receiveCallInvitation = createAsyncThunk(
  'messages/receiveCallInvitation',
  async (
    {
      message,
      rongcloudUserId,
    },
    { dispatch },
  ) => {
    const {
      content: { content, user = {} },
      conversationType, senderUserId, targetId,
    } = message;
    if (rongcloudUserId !== senderUserId) {
      dispatch(setCallStatus('being_called'));
      dispatch(setCallInfo({
        name: user.name,
        roomToken: content,
        caller: senderUserId,
        chatId: targetId,
        conversationType,
      }));
    }
  },
);

export const receiveCallJoinApprove = createAsyncThunk(
  'messages/receiveCallJoinApprove',
  async (
    {
      message,
      rongcloudUserId,
      setRtcAlert,
    },
    { dispatch, getState },
  ) => {
    const {
      content: { content }, senderUserId,
    } = message;
    const { roomToken, roomAdmin } = getState().rtcCallingInfo;
    const { locale } = getState().localization;
    if (roomToken === content && roomAdmin === senderUserId) {
      const { success, errors } = await acceptVideoCallInvitation(rongcloudUserId, roomToken);
      if (success) {
        dispatch(setCallStatus('caller_connecting'));
        const videoCallUrl = `${VIDEO_CALL_URL}?roomToken=${roomToken}&participantId=${rongcloudUserId}&locale=${locale}`;
        window.open(videoCallUrl, '_blank');
      } else {
        setRtcAlert(i18n.t(`call.${errors[0].type}`));
        dispatch(setCallStatus('available'));
        dispatch(setCallInfo({}));
      }
    }
  },
);

export const receiveCallAccept = createAsyncThunk(
  'messages/receiveCallAccept',
  async (
    {
      message,
      rongcloudUserId,
      setRtcAlert,
    },
    { dispatch, getState },
  ) => {
    const { content: { content }, senderUserId } = message;
    const { roomToken } = getState().rtcCallingInfo;
    const { locale } = getState().localization;
    if (getState().rtcCallingStatus === 'waiting_for_response' && content === roomToken) {
      const { success, errors } = await acceptVideoCallInvitation(rongcloudUserId, roomToken);
      if (success) {
        dispatch(setCallStatus('caller_connecting'));
        const videoCallUrl = `${VIDEO_CALL_URL}?roomToken=${roomToken}&participantId=${rongcloudUserId}&locale=${locale}`;
        window.open(videoCallUrl, '_blank');
      } else {
        setRtcAlert(i18n.t(`call.${errors[0].type}`));
        dispatch(setCallStatus('available'));
        dispatch(setCallInfo({}));
      }
    } else if (senderUserId === rongcloudUserId) {
      dispatch(setCallStatus('available'));
      dispatch(setCallInfo({}));
    }
  },
);

export const receiveCallHangup = createAsyncThunk(
  'messages/receiveCallHangup',
  async (
    {
      message,
      rongcloudUserId,
      sendMessage,
    },
    { dispatch, getState },
  ) => {
    const { content: { content }, senderUserId } = message;
    const {
      calleeIds, roomToken, chatId, conversationType, roomAdmin,
    } = getState().rtcCallingInfo;
    if (roomToken !== content) return;
    if (calleeIds) {
      if (calleeIds.length > 0 && calleeIds.includes(senderUserId)) {
        const waitingIds = calleeIds.filter((id) => id !== senderUserId);
        if (waitingIds.length > 0) {
          dispatch(setCallInfo({
            ...getState().rtcCallingInfo,
            calleeIds: waitingIds,
          }));
        } else {
          dispatch(setCallStatus('available'));
          dispatch(setCallInfo({}));
          sendMessage(chatId, conversationType, i18n.t('call.declined'), 'VideoCallMessage');
          quitVideoCallRoom(rongcloudUserId, roomToken);
        }
      }
    } else if (getState().rtcCallingStatus === 'being_called' || (getState().rtcCallingStatus === 'waiting_for_response' && senderUserId === roomAdmin)) {
      dispatch(setCallStatus('available'));
      dispatch(setCallInfo({}));
      quitVideoCallRoom(rongcloudUserId, roomToken);
    } else if (!['caller_connecting', 'callee_connecting'].includes(getState().rtcCallingStatus)) {
      dispatch(setCallStatus('available'));
      dispatch(setCallInfo({}));
    }
  },
);

export const receiveCallSummaryMessage = createAsyncThunk(
  'messages/receiveCallSummaryMessage',
  async (
    {
      message,
      rongcloudUserId,
    },
    { dispatch, getState },
  ) => {
    if (!isEmpty(getState().rtcCallingInfo) && (message.senderUserId === getState().rtcCallingInfo.chatId || message.senderUserId === rongcloudUserId)) {
      dispatch(setCallStatus('available'));
      dispatch(setCallInfo({}));
    }
    dispatch(receiveMessage(message));
  },
);

export const {
  selectById: selectMessageById,
  selectIds: selectMessageIds,
  selectEntities: selectMessageEntities,
  selectAll: selectAllMessages,
  selectTotal: selectTotalMessages,
} = messageAdapter.getSelectors((state) => state.messages);

export const selectMessagesByTargetId = (targetId) => (
  createSelector(
    selectAllMessages,
    (messages) => messages.filter((message) => (
      targetId && (
        message.targetId === targetId
      )
    )),
  )
);

export const MessagesSlice = createSlice({
  name: 'messages',
  initialState: messageAdapter.getInitialState(),
  reducers: {
    addMessage(state, action) {
      const message = action.payload;
      const normalizedData = normalize(message, messageEntity);
      messageAdapter.upsertMany(state, normalizedData.entities.messages);
    },
    resetMessages(state) {
      messageAdapter.removeAll(state);
    },
    setMessages(state, action) {
      const normalizedData = normalize(action.payload, [messageEntity]);
      messageAdapter.upsertMany(state, normalizedData.entities.messages);
    },
    removeMessage(state, action) {
      messageAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: {
    [receiveMessage.fulfilled]: (state, action) => {
      const message = action.payload;
      const normalizedData = normalize(message, messageEntity);
      messageAdapter.upsertMany(state, normalizedData.entities.messages);
    },
  },
});

export const {
  addMessage, resetMessages, setMessages, removeMessage,
} = MessagesSlice.actions;

export default MessagesSlice.reducer;
