/* eslint-disable camelcase */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { FETCH_EVENT_URL } from 'appenv';

export interface XtraEvent {
  id: number;
  name: string;
  timezone: string;
  translations: { [local: string]: string };
  languages: { [local: string]: string };
  start_at: string;
  end_at: string;
}

export const fetchXtraEvent = createAsyncThunk(
  'event/fetch',
  async () => {
    try {
      const response = await axios.get(FETCH_EVENT_URL);
      return response.data.data;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const eventAdapter = createEntityAdapter<XtraEvent>();

const {
  selectAll: selectAllXtraEvent,
} = eventAdapter.getSelectors((state: any) => state.event);

export const selectXtraEvent = () => createSelector(selectAllXtraEvent, (events) => events[0]);

export const xtraEventSlice = createSlice({
  name: 'xtra-event',
  initialState: eventAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchXtraEvent.fulfilled, (state, action) => {
      eventAdapter.upsertMany(state, action.payload);
    });
  },
});

export default xtraEventSlice.reducer;
