const WEBINAR_LIST_WIDTH = 1000;
const TIMELINE_INDICATOR_WIDTH = 125.5;

const BREAKPOINT = WEBINAR_LIST_WIDTH + TIMELINE_INDICATOR_WIDTH * 2;

export {
  WEBINAR_LIST_WIDTH,
  TIMELINE_INDICATOR_WIDTH,
  BREAKPOINT,
};
