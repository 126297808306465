import styled from 'styled-components';

const FooterWrapper = styled.div`
  align-items: flex-end;
  justify-content: center;
  border-radius: 0 !important;
  display: flex;
  position: relative;
  top: ${({ offsetTop }: { offsetTop: number }) => offsetTop}px;
  flex-basis: 100%;
  flex-wrap: wrap;
`;

export default FooterWrapper;
