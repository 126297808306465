import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tanShelfImg from 'assets/booths/tan-shelf.png';

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const BannerImg = styled.img`
  width: 100%;
  max-height: 100%;
  user-select: none;
`;

const BannerImgContainer = styled.div`
  position: absolute;
  width: 55px;
  height: 78px;
  left: 14px;
  top: 3px;
  display: flex;
  align-items: center;
`;

const TanShelf = ({ pullUpBanner }) => (
  <>
    <StyledImg src={tanShelfImg} />
    {pullUpBanner && (
      <BannerImgContainer>
        <BannerImg src={pullUpBanner} />
      </BannerImgContainer>
    )}
  </>
);

TanShelf.propTypes = {
  pullUpBanner: PropTypes.string,
};

TanShelf.defaultProps = {
  pullUpBanner: '',
};

export default TanShelf;
