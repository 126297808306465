const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
    next_show: 'Space Booking for Next Show',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/331e030d-0edf-4e04-8bdc-d7b8dbbb61e0.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/4417d8ad-4da9-416d-b34d-546a5677a54e.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/dc582087-f1c9-43eb-95d7-76876c9677a6.png',
        'https://www.ijt-aki.jp/en-gb.html',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
  ...en.banners.right,
];

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
    next_show: '次年度 出展に興味のある方',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/7a7ed918-e4ca-4f41-a2ac-4c7dd1375663.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/1ce0c738-50f6-4d55-9627-a5af974cacbb.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/74af5665-f032-471d-a034-615e590beb11.png',
        'https://www.ijt-aki.jp/ja-jp.html',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];

export const zhHans = {
  entry: {
    to_exhibition_hall: '展览会场',
    how_to_use_oms: '<b>在线观展・商谈指南</b>',
    next_show: '下届展会参展咨询',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/d3eece63-e977-4083-8778-f4c750562097.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/cb67e6ef-6c2c-48ee-af0b-acdba65d22fe.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/adc27480-3e39-46be-b2c0-d4b9d2741b4c.png',
        'https://www.ijt-aki.jp/zh-cn.html',
      ),
    ],
    mobile: [],
  },
};

zhHans.banners.mobile = [
  ...zhHans.banners.middle,
  ...zhHans.banners.left,
  ...zhHans.banners.right,
];

export const zhHant = {
  entry: {
    to_exhibition_hall: '展覽會場',
    how_to_use_oms: '<b>線上觀展/商談服務</b><b>使用方式</b>',
    next_show: '預定下屆參展攤位',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/634d1556-6bb6-4573-b4d6-abd1df4a1b64.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/fffa6d26-c796-4eb3-9f93-0c1c29011a47.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/e49ed255-d628-4b5c-88eb-4044133a548a.png',
        'https://www.ijt-aki.jp/zh-hk.html',
      ),
    ],
    mobile: [],
  },
};

zhHant.banners.mobile = [
  ...zhHant.banners.middle,
  ...zhHant.banners.left,
  ...zhHant.banners.right,
];
