import React, { useState } from 'react';

export const ProductDetailTabsContext: React.Context<{
  activeTabKey: number;
  setActiveTabKey: React.Dispatch<React.SetStateAction<number>>;
}> = React.createContext({
  activeTabKey: undefined,
  setActiveTabKey: undefined,
});

export const ProductDetailTabsContextStateProvider = ({ children }: {
  children: React.ReactElement;
}) => {
  const [activeTabKey, setActiveTabKey] = useState(0);

  return (
    <ProductDetailTabsContext.Provider
      value={{
        activeTabKey,
        setActiveTabKey,
      }}
    >
      {children}
    </ProductDetailTabsContext.Provider>
  );
};

export default {
  ProductDetailTabsContext,
  ProductDetailTabsContextStateProvider,
};
