import React, { Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';

const ImageLightbox = import('./ImageLightbox');
const VideoLightbox = import('./VideoLightbox');
const IframeLightbox = import('./IframeLightbox');
const PopupLightbox = import('./PopUpLightbox');

const CustomLightbox = ({
  src,
  onClose,
  type,
  open,
  cfStreamId,
}) => {
  const SelectedLightbox = useMemo(() => React.lazy(() => {
    switch (type) {
      case 'image': return ImageLightbox;
      case 'video': return VideoLightbox;
      case 'pdf': return IframeLightbox;
      case 'link':
      default: return PopupLightbox;
    }
  }), [type]);

  if (!open || !src) return null;

  return (
    <Suspense fallback={null}>
      <SelectedLightbox src={src} cfStreamId={cfStreamId} onClose={onClose} open={open} />
    </Suspense>
  );
};

CustomLightbox.propTypes = {
  src: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string,
  open: PropTypes.bool.isRequired,
  cfStreamId: PropTypes.string,
};

CustomLightbox.defaultProps = {
  src: '',
  type: 'link',
  cfStreamId: null,
};

export default CustomLightbox;
