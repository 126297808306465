/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import fetchCachedCMSDataRequest from 'network/fetchCachedCMSData';
import { eventToken } from 'network/common/cmsConfig';
import remoteTimeNow from 'libs/remote-time-now';
import { CMSEvent, eventEntity } from '../events/types';
import {
  Booth,
  boothEntity,
  BoothFetchingState,
  BoothFetchedLevel,
} from '../booths/types';

export const fetchCachedCMSData = createAsyncThunk(
  'cms/fetchCachedCMSData',
  async () => {
    const { data: { eventByToken: data } } = await fetchCachedCMSDataRequest();

    const { halls } = data;
    delete data.halls;

    if (!data.id) {
      data.id = eventToken;
    }

    const lastReceivedAt = remoteTimeNow().add(7, 'days').toISOString();
    const boothsNodes = halls.nodes
      .flatMap((it: any) => it.booths.nodes)
      .map((it: any) => ({ ...it, $fetchedLevel: BoothFetchedLevel.Details, $lastReceivedAt: lastReceivedAt }));
    const boothIds = boothsNodes.map((it: any) => it.id);
    const boothFetchingState = boothIds
      .reduce((sum: any, it: any) => ({
        ...sum,
        [it]: BoothFetchingState.Fulfilled,
      }), {});

    const { events } = normalize<CMSEvent>(data, eventEntity).entities;
    const { booths } = normalize<Booth>(boothsNodes, [boothEntity]).entities;

    return {
      events,
      booths,
      boothIds,
      boothFetchingState,
    };
  },
);
