import { apiUrl, eventToken } from 'network/common/cmsConfig';
import request, { gql } from 'graphql-request';
import { CommonLocalizableString } from 'network/common/cmsFragment';

const fetchBoothTaxonomiesQuery = gql`
  ${CommonLocalizableString}

  query fetchBoothTaxonomiesQuery(
    $eventToken: String!
    $locales: [Locale!]!
    $boothTaxonomiesCount: Int
    $boothTaxonomiesCursor: String
  ) {
    data: eventByToken(eventToken: $eventToken) {
      id
      boothTaxonomies(
        first: $boothTaxonomiesCount
        after: $boothTaxonomiesCursor
      ) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          ... on BoothSupercategory {
            id
            name {
              ...CommonLocalizableString
            }
            subcategories {
              nodes {
                id
                name {
                  ...CommonLocalizableString
                }
              }
            }
          }
          ... on BoothCategory {
            id
            name {
              ...CommonLocalizableString
            }
          }
        }
      }
    }
  }
`;

export interface FetchBoothTaxonomiesQueryProps {
  locales?: string[];
  boothTaxonomiesCursor?: string;
  boothTaxonomiesCount?: number;
}

const fetchBoothTaxonomies = ({
  locales = ['en'],
  boothTaxonomiesCount = 20,
  boothTaxonomiesCursor,
}: FetchBoothTaxonomiesQueryProps = {}) => request(
  apiUrl,
  fetchBoothTaxonomiesQuery,
  {
    eventToken,
    locales,
    boothTaxonomiesCount,
    boothTaxonomiesCursor,
  },
).then((response) => response.data);

export default fetchBoothTaxonomies;
