import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import whiteShelfImg from 'assets/booths/white-shelf.png';
import arrowImg from 'assets/booths/arrow.svg';

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledArrowImg = styled.img`
  position: absolute;
  left: 60px;
  top: 16px;
`;

const WhiteShelf = ({ hasAttachment }) => (
  <>
    <StyledImg src={whiteShelfImg} />
    {hasAttachment && <StyledArrowImg src={arrowImg} alt="Attachments" />}
  </>
);

WhiteShelf.propTypes = {
  hasAttachment: PropTypes.bool.isRequired,
};

export default WhiteShelf;
