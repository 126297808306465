export interface StreamableContent {
  url?: string;
  id?: string;
  type: 'cfStream' | 'direct';
}

const convertStreamableLink = (link: string): StreamableContent => {
  if (link?.startsWith('http')) {
    return {
      url: link,
      type: 'direct',
    };
  }
  return {
    id: link,
    type: 'cfStream',
  };
};

export default convertStreamableLink;
