import React, { useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import {
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { APP, EVENT_NAME, PAGE_NAMES } from 'appenv';
import { selectAllWebinars, selectWebinarById, WebinarType } from 'models/webinars';
import FollowingWebinarItem from './FollowingWebinarItem';
import WebinarMainContentContainer from './WebinarMainContentContainer';
import { CircularLoadingHider } from '../../components/indicators/CircularLoadingIndicator';
import PageNoFound from '../PageNoFound';

const locale = 'en';

const ContentContainer = styled.div`
  display: flex;
  padding: 40px 80px;

  @media (max-width: 1200px) {
    padding: 40px 40px;
  }

  @media (max-width: 960px) {
    padding: 0;
  }
`;


const FollowingWebinarContainer = styled.div`
  margin-left: 40px;
  width: 360px;
  border: 1px solid #DBDEE2;
  height: 420px;
  overflow-y: auto;

  @media (max-width: 960px) {
    display: none;
  }
`;


const WebinarDetailContainer = () => {
  const { webinarId } = useParams();

  const webinars = useSelector(selectAllWebinars);
  const webinar = useSelector((s) => selectWebinarById(s, webinarId));
  const followingWebinars = useMemo(() => webinars.sort((prevWebinar, nextWebinar) => {
    if (prevWebinar.kind !== nextWebinar.kind) {
      return prevWebinar.kind === WebinarType.OnDemand || nextWebinar.kind === WebinarType.OnDemand
        ? -1
        : 1;
    }
    const prevMoment = moment(prevWebinar.startAt);
    const nextMoment = moment(nextWebinar.startAt);
    if (!prevMoment.isSame(nextMoment)) {
      return prevMoment.isBefore(nextMoment) ? -1 : 1;
    }
    return 0; // TODO: Replace with webinar track type comparator
  }), [webinars]);

  const webinarTitle = webinar?.translations?.[locale]?.title;
  useEffect(() => {
    if (webinar) {
      window.trackingEvent('MainMenu_Webinar', 'Session_DetailPage_Enter', `Webinar-${webinarId}-${webinarTitle}_${webinar.url || ''}`);
    }
  }, [webinar, webinarId, webinarTitle]);

  const hideFollowingWebinars = APP === 'paloalto623';

  return webinars.length > 0 && !webinar ? (<PageNoFound />) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${webinar?.translations[locale]?.title || 'Webinar Not Found'} | ${EVENT_NAME}`}</title>
        <meta name="description" content={`Detail of ${webinar?.translations[locale].title} ${PAGE_NAMES.webinar} in ${EVENT_NAME}.`} />
      </Helmet>
      <CircularLoadingHider condition={!!webinar}>
        <ContentContainer>
          <WebinarMainContentContainer webinar={webinar} />
          {!hideFollowingWebinars && (
            <FollowingWebinarContainer>
              {
                followingWebinars.map((followingWebinar) => (
                  <FollowingWebinarItem
                    key={followingWebinar.id}
                    webinar={followingWebinar}
                    isCurrent={followingWebinar.id === webinar.id}
                  />
                ))
              }
            </FollowingWebinarContainer>
          )}
        </ContentContainer>
      </CircularLoadingHider>
    </>
  );
};

export default WebinarDetailContainer;
