import React, { useMemo } from 'react';
import {
  PAGE_NAMES,
  FILTER_LIST,
  BRAND_COLOR,
  ENABLED_FEATURES,
} from 'appenv';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Routes from 'Routes';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import FilterCategoryContainer from '../FilterCategoryContainer';

const HallFilterContainer = styled.div`
  max-width: 300px;
  height: 100%;
  overflow: auto;
  padding: 24px;
  color: #282B40;
  background: rgba(255, 255, 255, 0.8);

  @media only screen and (max-width: 959px) {
    display: none;
  }
`;

const FilterTitleContainer = styled.div`
  width: 100%;
`;

const FilterText = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 12px 0;
  display: inline-block;
  text-transform: uppercase;
`;

const ClearAllText = styled.span`
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.4px;
  cursor: pointer;
  transition: all 0.1s;
  display: block;
  padding: 12px 0;
  float: right;

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const FilterWrapper = styled.div`
  display: block;
`;

const StyledXtraBreadcrumbs = styled(XtraBreadcrumbs)`
  padding: 0;
`;

const GenericBoothFilter = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  resetSelectedFilter,
  onClick,
  subLevelOnClick,
  customOrder,
  filterCustomLabel,
  allowSingleLevelFilter,
  showEmptyOption,
}) => {
  const { t } = useTranslation();
  const filterOrdering = useMemo(() => {
    if (FILTER_LIST.length > 0) {
      return FILTER_LIST;
    }
    return Object.keys(filterOptions).filter((filterOption) => filterOption !== 'count');
  }, [filterOptions]);

  const totalSelectedText = useMemo(() => {
    if (!ENABLED_FEATURES.xtraCMS) return '';
    const total = Object.values(selectedFilter).flatMap((it) => it).length;
    if (!total) return '';
    return ` (${total})`;
  }, [selectedFilter]);

  return (
    <HallFilterContainer>
      <StyledXtraBreadcrumbs paths={[{ name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby }, { name: PAGE_NAMES.hall || t('page.hall', 'Exhibition Hall') }]} />
      <FilterTitleContainer>
        <FilterText>
          {`${t('filter.title', 'Filter')}${totalSelectedText}`}
        </FilterText>
        <ClearAllText onClick={resetSelectedFilter}>
          {t('filter.clear_all', 'Clear all')}
        </ClearAllText>
      </FilterTitleContainer>
      <FilterWrapper>
        {filterOrdering?.map((filter, index) => (
          <FilterCategoryContainer
            key={index}
            label={filter}
            openOnMount={!index}
            customOrder={customOrder}
            filterCustomLabel={filterCustomLabel}
            selectedFilter={selectedFilter}
            filterOptions={filterOptions}
            filter={filter}
            onClick={onClick}
            subLevelOnClick={subLevelOnClick}
            setSelectedFilter={setSelectedFilter}
            allowSingleLevelFilter={allowSingleLevelFilter}
            showEmptyOption={showEmptyOption}
          />
        ))}
      </FilterWrapper>
    </HallFilterContainer>
  );
};

GenericBoothFilter.propTypes = {
  filterOptions: PropTypes.shape(),
  selectedFilter: PropTypes.shape().isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  resetSelectedFilter: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  subLevelOnClick: PropTypes.func.isRequired,
  customOrder: PropTypes.shape().isRequired,
  filterCustomLabel: PropTypes.shape().isRequired,
  allowSingleLevelFilter: PropTypes.bool,
  showEmptyOption: PropTypes.bool,
};

GenericBoothFilter.defaultProps = {
  filterOptions: {},
  allowSingleLevelFilter: false,
  showEmptyOption: false,
};

export default GenericBoothFilter;
