/* eslint-disable camelcase */
import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import productCategoriesByEventToken from 'network/productCategoriesByEventToken';
import { ProductCategory } from 'models/types/productCategories';

const productsCategoryAdapter = createEntityAdapter<ProductCategory>();

export const fetchProductCategories = createAsyncThunk(
  'productCategories/fetchAll',
  async () => {
    try {
      const productCategories = await productCategoriesByEventToken();
      return productCategories;
    } catch (e) {
      console.error(e);
      return null;
    }
  },
);

export const {
  selectEntities: selectProductCategoryEntities,
  selectAll: selectAllProductCategories,
  selectById: selectProductCategoryById,
} = productsCategoryAdapter.getSelectors((state: any) => state.productCategories.categories);

export const productsCategorySlice = createSlice({
  name: 'productCategories',
  initialState: productsCategoryAdapter.getInitialState({ fetching: true, fetched: false }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductCategories.pending, (state: any) => {
      state.fetching = true;
      state.fetched = true;
    });
    builder.addCase(fetchProductCategories.fulfilled, (state, action) => {
      productsCategoryAdapter.upsertMany(state, action.payload || []);
      state.fetching = false;
    });
    builder.addCase(fetchProductCategories.rejected, (state: any) => {
      state.fetching = false;
    });
  },
});

export default productsCategorySlice.reducer;
