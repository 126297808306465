import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import DesktopSpeakerList from './DesktopSpeakerList';
import MobileSpeakerList from './MobileSpeakerList';

const RootContainer = styled.div`
  width: 100%;
`;

const SpeakerList = ({ webinarId, speakers, backgroundRGBA }) => {
  const isDesktop = useMediaQuery('(min-width: 960px)');

  return (
    <RootContainer>
      {
        isDesktop ? (
          <DesktopSpeakerList webinarId={webinarId} speakers={speakers} />
        ) : (
          <MobileSpeakerList webinarId={webinarId} speakers={speakers} backgroundRGBA={backgroundRGBA} />
        )
      }
    </RootContainer>
  );
};

SpeakerList.propTypes = {
  webinarId: PropTypes.number.isRequired,
  speakers: PropTypes.arrayOf(PropTypes.shape()),
  backgroundRGBA: PropTypes.arrayOf(PropTypes.number),
};

SpeakerList.defaultProps = {
  speakers: [],
  backgroundRGBA: [255, 255, 255, 0.2],
};

export default SpeakerList;
