import React from 'react';
import PropTypes from 'prop-types';
import { APP, ENABLED_FEATURES } from 'appenv';
import BoothProfileTabsContainer from './components/BoothProfileTabsContainer';
import Booth618ProfileTabsContainer from './templates/Booth618Template/BoothProfileTabsContainer';
import Booth623ProfileTabsContainer from './templates/Booth623Template/BoothProfileTabsContainer';
import Booth624ProfileTabsContainer from './templates/Booth624Template/BoothProfileTabsContainer';
import Booth711ProfileTabsContainer from './templates/Booth711Template/BoothProfileTabsContainer';
import Tabs from './templates/BoothCMSTemplate/Tabs';

const BoothProfileTabsContainerTemplateSwitch = ({ booth, isDesktop }) => {
  switch (APP) {
    case 'ms624':
      return <Booth624ProfileTabsContainer booth={booth} isDesktop={isDesktop} />;
    case 'paloalto623':
      return <Booth623ProfileTabsContainer booth={booth} isDesktop={isDesktop} />;
    case 'polyu618':
      return <Booth618ProfileTabsContainer booth={booth} isDesktop={isDesktop} />;
    case 'scmp711':
      return <Booth711ProfileTabsContainer booth={booth} isDesktop={isDesktop} />;
    default: {
      if (ENABLED_FEATURES.xtraCMS) {
        return <Tabs booth={booth} isDesktop={isDesktop} />;
      }
      return <BoothProfileTabsContainer booth={booth} isDesktop={isDesktop} />;
    }
  }
};

BoothProfileTabsContainerTemplateSwitch.propTypes = {
  booth: PropTypes.shape({}).isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

export default BoothProfileTabsContainerTemplateSwitch;
