/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */
import React, { useState, createContext } from 'react';

const LightboxContext: React.Context<{
  url: string;
  cfStreamId: string;
  setCfStreamId: React.Dispatch<React.SetStateAction<string>>;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
}> = createContext({
  url: null,
  cfStreamId: null,
  setCfStreamId: (cfStreamId: string) => {},
  setUrl: (url: string) => {},
});

const LightboxContextStateProvider = ({ children }: {
  children: React.ReactElement;
}) => {
  const [cfStreamId, setCfStreamId] = useState(null);
  const [url, setUrl] = useState(null);

  return (
    <LightboxContext.Provider
      value={{
        url,
        setUrl,
        setCfStreamId,
        cfStreamId,
      }}
    >
      {children}
    </LightboxContext.Provider>
  );
};

export {
  LightboxContext,
  LightboxContextStateProvider,
};
