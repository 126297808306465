import React from 'react';
import Basic711Booth from './Booth711Basic';
import Upgraded711Booth from './Booth711Upgraded';

const Booth711Template = ({ booth, ...props }: {
  booth: Record<any, any>;
  handleClick: (objectName: string) => void;
  showRepsImg: boolean;
}) => {
  switch (booth.type) {
    case 'u':
      return <Upgraded711Booth booth={booth} {...props} />;
    default:
      return <Basic711Booth booth={booth} {...props} />;
  }
};

export default Booth711Template;
