/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { envSwitch } from 'utils/envUtils';
import Apps from 'apps';

const objectFit = envSwitch([
  [Apps.Uzabase826, 'cover'],
], 'contain');

const BannerImageContainer = styled.img<{ actionUrl: string }>`
  width: 100%;
  height: 100%;
  object-fit: ${objectFit};
  cursor: ${({ actionUrl }) => (actionUrl ? 'pointer' : 'default')};
  object-position: center;
`;

const BannerImage = (props): React.ReactElement => {
  console.log('bannerimage');

  const { actionUrl } = props;
  const onBannerClick = useCallback(() => {
    if (actionUrl) {
      window.open(`http://${actionUrl.replace(/\b(https:\/\/|http:\/\/)/, '')}`, '_blank');
    }
  }, [actionUrl]);


  return (
    <BannerImageContainer
      {...props}
      actionUrl={actionUrl}
      onClick={onBannerClick}
      data-object-fit={objectFit}
      data-object-position="center"
    />
  );
};

export default BannerImage;
