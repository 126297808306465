import styled from 'styled-components';

const SliderBulletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
`;

export default SliderBulletContainer;
