import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lightbox from '../Booth623Template/lightbox';
import ScreenBackground from '../../components/background/ScreenBackground';
import TanShelf from './TanShelf';
import BoothPullUpBanner from '../../components/BoothPullUpBanner';
import BoothSaleContainer from '../../components/BoothSaleContainer';
import GenericBoothBanner from '../../components/GenericBoothBanner';

const BoothContainer = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  margin: 0 auto;
  margin-bottom: ${({ needOffset }) => (needOffset ? '70px' : 'inherit')};
  user-select: text;
`;

const LogoContainer = styled.div`
  position: absolute;
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/543/content/logo_container-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 200px;
  z-index: 100;
  left: calc((100% - 400px) / 2);
  cursor: pointer;
`;

const LogoImgContainer = styled.div`
  margin-top: 47.4px;
  height: 149.4px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  flex: 0 0 auto;
  width: 100%;
  max-height: 100%;
`;

const ShowDeskContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/541/content/center_showdesk-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 900;
  position: absolute;
  top: 260px;
  width: 470px;
  height: 400px;
  left: calc((100% - 470px) / 2);
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
`;

const PosterContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/577/content/poster-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 200px;
  position: absolute;
  bottom: -60px;
  z-index: 100;
`;

const BrochureContainer = styled.div`
  position: absolute;
  bottom: -60px;
  z-index: 100;
  right: 120px;
  cursor: pointer;
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
  z-index: 100;
`;

const DescriptionContainer = styled.div`
  height: 206px;
  top: 7px;
  position: relative;
`;

const Booth710Template = ({ booth, handleClick, showRepsImg }) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const lightboxFile = booth.verticalBannerUrl || booth.verticalBannerFile || booth.verticalBannerFullImg || booth.verticalBanner;
  const isVideo = lightboxFile ? ['mp4', 'webm', 'ogg'].some((format) => lightboxFile.endsWith(format)) : false;

  const descriptionBanner = booth?.banner?.descriptionBanner || {};
  const processedDescriptionBanner = useMemo(() => {
    const banner = { ...descriptionBanner };
    if (banner.file || banner.video_preview) banner.description = '';
    return banner;
  }, [descriptionBanner]);

  return (
    <BoothContainer needOffset={showRepsImg}>
      <LogoContainer>
        <LogoImgContainer onClick={() => handleClick('logo')}>
          <LogoImg alt="Logo" src={booth.logo} />
        </LogoImgContainer>
      </LogoContainer>
      <ShowDeskContainer>
        <DescriptionContainer>
          <GenericBoothBanner
            handleClick={handleClick}
            name={booth.name}
            from="Banner 3"
            boothNumber={booth.id}
            bannerData={processedDescriptionBanner}
            isDescriptionBanner
          />
        </DescriptionContainer>
      </ShowDeskContainer>
      <BackgroundContainer>
        <ScreenBackground boothColor={booth.boothColor} />
      </BackgroundContainer>
      <PosterContainer>
        <BoothPullUpBanner isVideo={isVideo} pullUpBanner={booth.verticalBannerFullImg || booth.verticalBanner} setShowBannerModal={setShowBannerModal} />
      </PosterContainer>
      <BrochureContainer onClick={() => handleClick('attachment')}>
        <TanShelf pullUpBanner={booth.verticalBanner} />
      </BrochureContainer>
      {
        showRepsImg && (
          <SaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            onlyImage
            src={lightboxFile}
            onClose={() => setShowBannerModal(false)}
          />
        )
      }
    </BoothContainer>
  );
};

Booth710Template.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth710Template.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth710Template;
