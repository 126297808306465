import { apiUrl, eventToken } from 'network/common/cmsConfig';
import { request, gql } from 'graphql-request';

const fetchXtraEventQuery = gql`
  query fetchXtraEventQuery($eventToken: String!) {
    data: eventByToken(eventToken: $eventToken) {
      id
      locales
      defaultLocale
    }
  }
`;

const fetchXtraEvent = () => request(
  apiUrl,
  fetchXtraEventQuery,
  {
    eventToken,
  },
).then((response) => response.data);

export default fetchXtraEvent;
