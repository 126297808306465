import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import PlayButton from 'assets/play-button.png';

export interface BannerVideoProps {
  videoUrl: string;
  thumbnailUrl: string;
  autoPlay?: boolean;
}

const BannerVideo: React.FC<BannerVideoProps> = ({
  videoUrl,
  thumbnailUrl,
  autoPlay = false,
}: BannerVideoProps) => {
  const [isPlaying, setIsPlaying] = useState(autoPlay);
  return (
    <ReactPlayer
      playing={isPlaying}
      onPlay={() => setIsPlaying(true)}
      onPause={() => setIsPlaying(false)}
      light={autoPlay ? false : thumbnailUrl || 'light'}
      url={videoUrl}
      controls
      playIcon={<img src={PlayButton} alt="Play Button" />}
      width="100%"
      height="100%"
      config={{
        file: {
          attributes: {
            playsInline: true,
            disablePictureInPicture: true,
            controlsList: 'nodownload',
          },
        },
      }}
      playsinline
      style={{
        zIndex: 100,
        backgroundColor: 'black',
        padding: '0 !important',
      }}
    />
  );
};

export default BannerVideo;
