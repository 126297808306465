import styled from 'styled-components';

const GenericBoothBannerContainer = styled.div<{ padding: number }>`
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  padding: ${({ padding }) => (padding || 0)}px;

  & h2, p {
    margin-top: 0;
    margin-bottom: 0;
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

export default GenericBoothBannerContainer;
