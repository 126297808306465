import request from 'graphql-request';
import { apiUrl } from './cmsConfig';

/* eslint-disable import/prefer-default-export */
export const fetchAllPage = async (getPageInfo: any, query: string, config: any): Promise<any[]> => {
  const result = await request(apiUrl, query, config);
  const pageInfo = getPageInfo(result);
  if (pageInfo.hasNextPage) {
    return fetchAllPage(getPageInfo, query, { ...config, cursor: pageInfo.endCursor })
      .catch(() => [])
      .then((pages) => [result, ...pages]);
  }
  return [result];
};
