import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Routes from 'Routes';
import { UserSessionContext } from 'components/UserSession';
import { fetchBoothById, selectBoothById, BoothFetchedLevel } from 'models/cms/booths';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import { BoothProfileTabsContextStateProvider } from 'pages/booths/components/BoothProfileTabsContext';
import { LightboxContextStateProvider } from 'components/booth/CustomLightbox/LightboxContext';
import BoothProfileContainer from 'pages/booths/BoothProfileContainer';
import EmptyState from 'components/EmptyState';
import { EVENT_NAME } from 'appenv';

import Footer from 'components/footer/Footer';

const RootContainer = styled.div<{ bgColor?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: ${({ bgColor }) => (bgColor || '#FFFFFF')};
`;

const BoothContainer = styled.div`
  width: auto;
  display: block;
  overflow: auto;

  .booth-template-wrapper {
    width: 100%;
  }
`;

const FooterWrapper = styled.div`
  padding-top: 20px;
`;

const BoothNotFoundContainer = () => (
  <EmptyState>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`Booth Not Found | ${EVENT_NAME}`}</title>
      <meta name="description" content={`Booth not found in ${EVENT_NAME} event.`} />
    </Helmet>
    Booth not found
  </EmptyState>
);

const selectById: (state: any, id: any) => any = selectBoothById;

const BoothPreviewPage = () => {
  const history = useHistory();
  const { isAuthenticated } = useContext(UserSessionContext);
  const { boothId } = useParams();
  const dispatch = useDispatch();
  const booth = useSelector((state) => selectById(state, boothId));
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    let isEnded = false;
    dispatch(fetchBoothById({ boothId, expectedLevel: BoothFetchedLevel.Details }))
      .then(() => !isEnded && setFetching(false));
    return () => {
      isEnded = true;
    };
  }, [dispatch, boothId]);

  const bgColor = booth?.color;

  if (isAuthenticated) {
    history.replace(Routes.booth(boothId));
  }

  return (
    <RootContainer id="booth-profile-root" bgColor={bgColor}>
      <BoothContainer id="booth-container">
        <BoothProfileTabsContextStateProvider>
          {fetching && <CircularLoadingIndicator />}
          {!fetching && (
            booth
              ? (
                <LightboxContextStateProvider>
                  <BoothProfileContainer
                    booth={booth}
                    isPreview
                  />
                </LightboxContextStateProvider>
              )
              : <BoothNotFoundContainer />
          )}
        </BoothProfileTabsContextStateProvider>
        {!fetching && (
          <FooterWrapper>
            <Footer hashState={`/booth/${boothId}`} />
          </FooterWrapper>
        )}
      </BoothContainer>
    </RootContainer>
  );
};

export default BoothPreviewPage;
