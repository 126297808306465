import { gql } from 'graphql-request';
import {
  CommonLocalizableString,
  CommonLocalizableVideo,
  CommonLocalizableImage,
  CommonLocalizableFile,
  NonQuestionContentElement,
} from 'network/common/cmsFragment';
import { fetchAllPage } from 'network/common/cmsUtils';

interface FetchBoothRootContentElementByIdQueryProps {
  boothId: string;
  locales: string[];
}

const fetchBoothRootContentElementByIdQuery = gql`
  ${CommonLocalizableString}
  ${CommonLocalizableVideo}
  ${CommonLocalizableImage}
  ${CommonLocalizableFile}
  ${NonQuestionContentElement}

  query fetchBoothRootContentElementByIdQuery(
    $boothId: ID!
    $locales: [Locale!]!
    $cursor: String
    $count: Int
  ) {
    node(id: $boothId) {
      ... on Booth {
        id
        rootContentElement {
          tabs(first: $count, after: $cursor) {
            pageInfo {
              hasNextPage
              endCursor
            }
            nodes {
              title {
                ...CommonLocalizableString
              }
              body {
                nodes {
                  __typename
                  id
                  ...NonQuestionContentElement
                  ... on BoothQuestionContentElement {
                    question {
                      ...CommonLocalizableString
                    }
                    answer {
                      ...NonQuestionContentElement
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const fetchBoothRootContentElementById = async (config: FetchBoothRootContentElementByIdQueryProps) => {
  const pages = await fetchAllPage((data: any) => data.node.rootContentElement.tabs.pageInfo, fetchBoothRootContentElementByIdQuery, { ...config, count: 5 });

  const tabs = pages
    .reduce((sum, data) => [...sum, ...(data.node.rootContentElement.tabs.nodes || [])], []);

  const result = {
    id: pages[0].node.id,
    rootContentElement: { tabs: { nodes: tabs } },
  };

  return result;
};

export default fetchBoothRootContentElementById;
