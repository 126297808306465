import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowFowardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Collapse } from 'react-collapse';
import { PAGE_NAMES, BRAND_COLOR } from 'appenv';

import '../index.css';
import FilterOption from '../FilterOption';
import FilterCheckboxOption from '../FilterCheckboxOption';

const MobileHallFilterContainer = styled.div`
  width: 100%;
  background-color: #FFF;
  border-bottom: 0.5px solid #DBDEE2;

  @media only screen and (min-width: 960px) {
    display: none;
  }
`;

const MobileFilterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const MobileFilterOption = styled.div`
  padding: 12px 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
  color: #999;

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }

  @media only screen and (max-width: 370px) {
    font-size: 11px;
  }
`;

const MobileFilterSelectContainer = styled.div`
  width: 100%;
  padding: 16px;
  transition: all 0.3s;
`;

const FilterListContainer = styled.div`
  width: 100%;
  max-height: 350px;
  overflow: auto;
`;

const MobileFilterSelect = styled.div`
  padding: 4px 12px;
  font-size: 15px;
  line-height: 18px;
  color: #282B40;
`;

const ArrowContainer = styled.div`
  transform: ${({ selected }) => (selected ? 'rotate(-90deg) translateX(-2px)' : 'rotate(90deg) translateX(2px)')};
  display: inline-block;
  transition: inherit;
  padding: 4px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 8px;
  display: inline-flex;
  justify-content: space-between;
`;

const ShowButton = withStyles(() => ({
  root: {
    margin: '0 12px',
    padding: '0 12px',
    color: '#FFFFFF',
    backgroundColor: BRAND_COLOR || '#0CA58B',
    '&:hover': {
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
}))(Button);

const BottomButtonContainer = ({ resetSelectedFilter, handleFilterClose, numOfExhibitors }) => (
  <ButtonContainer>
    <Button onClick={resetSelectedFilter}>Clear All</Button>
    <ShowButton onClick={handleFilterClose}>
      {`Show ${PAGE_NAMES.exhibitors}`}
      {` (${numOfExhibitors})`}
    </ShowButton>
  </ButtonContainer>
);

const MobileHallFilter = ({
  filterOptions,
  onClick,
  selectedFilter,
  resetSelectedFilter,
  subLevelOnClick,
  numOfExhibitors,
  customOrder,
}) => {
  const [openedTab, setOpenedTab] = useState(-1);

  const handleFilterClose = () => {
    setOpenedTab(-1);
  };

  return (
    <MobileHallFilterContainer>
      <MobileFilterWrapper>
        <MobileFilterOption onClick={() => setOpenedTab(openedTab === 0 ? -1 : 0)}>
          Industry
          <ArrowContainer selected={openedTab === 0}>
            <ArrowFowardIcon style={{ fontSize: '12px' }} />
          </ArrowContainer>
        </MobileFilterOption>
        <MobileFilterOption onClick={() => setOpenedTab(openedTab === 1 ? -1 : 1)}>
          Core Technology
          <ArrowContainer selected={openedTab === 1}>
            <ArrowFowardIcon style={{ fontSize: '12px' }} />
          </ArrowContainer>
        </MobileFilterOption>
        <MobileFilterOption onClick={() => setOpenedTab(openedTab === 2 ? -1 : 2)}>
          Funding Stage
          <ArrowContainer selected={openedTab === 2}>
            <ArrowFowardIcon style={{ fontSize: '12px' }} />
          </ArrowContainer>
        </MobileFilterOption>
      </MobileFilterWrapper>
      <Collapse isOpened={openedTab === 0}>
        <MobileFilterSelectContainer>
          <FilterListContainer>
            {filterOptions.Industry && customOrder.Industry.map((eachkey, index) => (
              <MobileFilterSelect key={index}>
                <FilterOption
                  key={eachkey}
                  category={eachkey}
                  label={eachkey}
                  options={filterOptions.Industry[eachkey]}
                  onClick={subLevelOnClick}
                  selectedFilter={selectedFilter?.Industry?.[eachkey]}
                  filterOptions={filterOptions}
                  topCategory="Industry"
                />
              </MobileFilterSelect>
            ))}
          </FilterListContainer>
          <BottomButtonContainer
            resetSelectedFilter={resetSelectedFilter}
            handleFilterClose={handleFilterClose}
            numOfExhibitors={numOfExhibitors}
          />
        </MobileFilterSelectContainer>
      </Collapse>
      <Collapse isOpened={openedTab === 1}>
        <MobileFilterSelectContainer>
          <FilterListContainer>
            {filterOptions['Core Technology']?.map((eachTechnology, index) => (
              <MobileFilterSelect key={index}>
                <FilterCheckboxOption
                  index={index}
                  key={eachTechnology}
                  label={eachTechnology}
                  onClick={onClick}
                  selectedOption={selectedFilter['Core Technology']}
                  category="Core Technology"
                  sumOfCategory={filterOptions?.count?.[eachTechnology]}
                />
              </MobileFilterSelect>
            ))}
          </FilterListContainer>
          <BottomButtonContainer
            resetSelectedFilter={resetSelectedFilter}
            handleFilterClose={handleFilterClose}
            numOfExhibitors={numOfExhibitors}
          />
        </MobileFilterSelectContainer>
      </Collapse>
      <Collapse isOpened={openedTab === 2}>
        <MobileFilterSelectContainer>
          <FilterListContainer>
            {filterOptions['Stage of Funding']?.map((eachStage, index) => (
              <MobileFilterSelect key={index}>
                <MobileFilterSelect>
                  <FilterCheckboxOption
                    index={index}
                    key={eachStage}
                    label={eachStage}
                    onClick={onClick}
                    selectedOption={selectedFilter['Stage of Funding']}
                    category="Stage of Funding"
                    sumOfCategory={filterOptions?.count?.[eachStage]}
                  />
                </MobileFilterSelect>
              </MobileFilterSelect>
            ))}
          </FilterListContainer>
          <BottomButtonContainer
            resetSelectedFilter={resetSelectedFilter}
            handleFilterClose={handleFilterClose}
            numOfExhibitors={numOfExhibitors}
          />
        </MobileFilterSelectContainer>
      </Collapse>
    </MobileHallFilterContainer>
  );
};

MobileHallFilter.propTypes = {
  filterOptions: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  selectedFilter: PropTypes.shape().isRequired,
  resetSelectedFilter: PropTypes.func.isRequired,
  subLevelOnClick: PropTypes.func.isRequired,
  numOfExhibitors: PropTypes.number.isRequired,
  customOrder: PropTypes.shape().isRequired,
};

BottomButtonContainer.propTypes = {
  resetSelectedFilter: PropTypes.func.isRequired,
  handleFilterClose: PropTypes.func.isRequired,
  numOfExhibitors: PropTypes.number.isRequired,
};

export default MobileHallFilter;
