import axios from 'axios';
import moment from 'moment';

let remoteTimeRequest = null;
const remoteTimeReadyListeners = [];

async function fetchRemoteTime() {
  const requestStartTime = window.performance.now();
  const response = await axios.get('https://worldtimeapi.org/api/timezone/etc/utc.json');
  const serverDatetime = response.data.datetime;
  const requestEndTime = window.performance.now();
  const medianTime = (requestEndTime + requestStartTime) / 2;
  const newRemoteTime = moment(serverDatetime);
  return { requestTimeMs: medianTime, remoteTime: newRemoteTime };
}

function remoteTimeNow() {
  if (!remoteTimeRequest) return moment();
  const requestedMsAgo = window.performance.now() - remoteTimeRequest.requestTimeMs;
  return moment(remoteTimeRequest.remoteTime).add(requestedMsAgo, 'ms');
}

function remoteTimeInit() {
  (async () => {
    try {
      const newRemoteTimeRequest = await fetchRemoteTime();
      remoteTimeRequest = newRemoteTimeRequest;
    } catch (e) {
      remoteTimeRequest = null;
    }

    const listen = true;
    while (listen) {
      const listenerFn = remoteTimeReadyListeners.shift();
      if (!listenerFn) break;
      listenerFn();
    }
  })();
}

function addRemoteTimeReadyListener(fn) {
  remoteTimeReadyListeners.push(fn);
}

export default remoteTimeNow;
export { remoteTimeInit, addRemoteTimeReadyListener };
