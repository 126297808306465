import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <path
    d="M29.802 24.917l-9.73 1.705-2.88-10.674c-.201-.749-1.212-1.946-2.779-1.526-1.213.325-1.955 1.54-1.641 2.71l4.818 17.984-2.508-2.03a2.302 2.302 0 00-3.39.608 2.104 2.104 0 00.471 2.736l7.027 7.216c.566.463 1.263.359 1.989.164l10.641-2.85c2.101-.563 3.596-2.206 3.478-4.296l-1.312-7.815c-.123-2.21-1.898-3.82-4.187-3.93l.002-.001zM7.306 23.134c-1.217.326-2.456-.354-2.77-1.526l-.709-2.647c-3.97-14.82 18.041-20.717 22.012-5.898l.71 2.647c.313 1.17-.419 2.38-1.633 2.706-1.215.325-2.456-.355-2.77-1.527l-.71-2.646C19.169 5.775 5.962 9.313 8.23 17.782l.71 2.646c.313 1.17-.42 2.381-1.633 2.706z"
  />,
  'File Arrow',
  { viewBox: '0 0 37 47' },
);
