/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fab, { FabProps } from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import throttle from 'lodash/throttle';

import ChatContext from 'components/chat/ChatContext';

const StyledFab = withStyles(() => ({
  root: {
    position: 'fixed',
    right: '24px',
    bottom: ({ bottom }: { bottom: number }) => `${bottom}px`,
    transform: ({ bottom }: { bottom: number }) => `translateY(${60 + bottom}px)`,
    transition: 'transform 400ms',
  },
}))(Fab);

const useActiveStyles = makeStyles(() => ({
  active: {
    transform: 'translateY(0)',
  },
}));

type Props = {
  scrollableTarget?: HTMLElement;
  className?: string;
  size?: 'large' | 'medium' | 'small';
} & FabProps;

const BackToTopButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    scrollableTarget = document.body,
    className = '',
    size = 'medium',
    ...other
  } = props;
  const [showBtn, setShowBtn] = useState(false);
  const activeStyleClasses = useActiveStyles();
  const { chatroomAvailable } = useContext(ChatContext);
  const isDesktop = useMediaQuery('(min-width: 960px)');

  const onClick = () => {
    scrollableTarget.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScrollHandler = useCallback(
    throttle(() => {
      setShowBtn(scrollableTarget.scrollTop !== 0);
    }, 200),
    [scrollableTarget],
  );

  useEffect(() => {
    scrollableTarget.addEventListener('scroll', onScrollHandler);

    return () => {
      scrollableTarget.removeEventListener('scroll', onScrollHandler);
    };
  }, [onScrollHandler, scrollableTarget]);

  return (
    <StyledFab
      {...other}
      className={`${showBtn ? activeStyleClasses.active : ''} ${className}`}
      size={size}
      bottom={chatroomAvailable && isDesktop ? 72 : 24}
      onClick={onClick}
      ref={ref}
    >
      <ArrowUpwardIcon />
    </StyledFab>
  );
});

export default BackToTopButton;
