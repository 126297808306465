import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { EVENT_NAME, PAGE_NAMES } from 'appenv';
import Routes from 'Routes';
import { selectAllWebinars } from 'models/webinars';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #484848;
  overflow: auto;
  text-align: justify;
`;

const DetailContainer = styled.div`
  margin-left: 0;
  margin-right: 0;
  padding: 0 5vw;

  & > * {
    margin-bottom: 20px;
  }

  & a {
    color: #5C8DF3;
    text-decoration: none;
  }

  @media only screen and (min-width: 960px) {
    margin-left: 20vw;
    margin-right: 20vw;
    padding: 0;
  }
`;

const OverflowContainer = styled.div`
  overflow: auto;
`;

const AboutPage = () => {
  const webinars = useSelector(selectAllWebinars);
  const about = webinars.find((it) => it?.translations?.en?.title?.toLowerCase() === 'about');
  const text = about?.translations?.en?.description || '';

  return (
    <RootContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`About | ${EVENT_NAME}`}</title>
        <meta name="description" content={`About page of ${EVENT_NAME}.`} />
      </Helmet>
      <OverflowContainer>
        <XtraBreadcrumbs paths={[{ name: PAGE_NAMES.lobby, to: Routes.lobby }, { name: 'About' }]} />
        <DetailContainer>
          {ReactHtmlParser(text)}
        </DetailContainer>
      </OverflowContainer>
    </RootContainer>
  );
};

export default AboutPage;
