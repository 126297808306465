import React from 'react';
import { useTranslation } from 'react-i18next';
import ActionButton from './ActionButton';

const SessionEndedButton = () => {
  const { t } = useTranslation();
  return (
    <ActionButton disabled>
      {t('webinar.session.ended')}
    </ActionButton>
  );
};

export default SessionEndedButton;
