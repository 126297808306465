import {
  en as enTranslation, ja as jaTranslation, zhHans as zhHansTranslation, zhHant as zhHantTranslation,
} from './translation';
import { en as enLanding, ja as jaLanding } from './landing';
import { en as enCommon, ja as jaCommon } from './common';

export default {
  en: {
    translation: enTranslation,
    landing: enLanding,
    common: enCommon,
  },
  ja: {
    translation: jaTranslation,
    landing: jaLanding,
    common: jaCommon,
  },
  'zh-Hans': {
    translation: zhHansTranslation,
  },
  'zh-Hant': {
    translation: zhHantTranslation,
  },
};
