import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { styled as muiStyled } from '@material-ui/core/styles';
import styled from 'styled-components';
import { APP } from 'appenv';

const StyledDialog = muiStyled(Dialog)({
  '&': {
    top: '64px',
    marginTop: '64px',
  },
  '& *': {
    fontFamily: 'Meiryo, sans-serif',
  },
  '& .MuiDialog-paperWidthSm': {
    maxWidth: '700px',
    '@media (min-width: 960px)': {
      top: '-64px',
    },
  },
  '& .MuiBackdrop-root': {
    top: '64px',
  },
});

const HighlightWord = styled.span`
  color: red;
  text-decoration: underline;
`;

const ReedAlert = () => {
  const storageKey = `${APP}_HALL_ALERT`;
  const [open, setOpen] = useState(() => !localStorage.getItem(storageKey));
  const handleClose = () => {
    setTimeout(() => {
      localStorage.setItem(storageKey, 'true');
      setOpen(false);
    }, 0);
  };

  return (
    <StyledDialog open={open}>
      <DialogTitle>ご来場いただき誠にありがとうございます。</DialogTitle>
      <DialogContent>
        <DialogContentText>
          下記をご一読いただき、【同意して進む】をクリックして、お進みください。
          <ol>
            <li>
              <div>アポイント設定した商談は、出展社ブースの【ビデオ通話】ボタンより商談を開始してください。</div>
            </li>
            <li>
              <div>出展各社内にあるチャットルームは、公開チャットルームとなっております。</div>
              <div>
                詳細の商談は、
                <HighlightWord>個別チャット</HighlightWord>
                で行うようお願いします。
              </div>
            </li>
          </ol>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          同意して進む
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ReedAlert;
