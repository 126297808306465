const youtubeShortLinkPattern = RegExp('^(?:https://)?(?:www.)?youtu.be/(.*)');
const youtubeWatchLinkPattern = RegExp('^(?:https://)?(?:www.)?youtube.com/watch.*v=([a-zA-Z0-9\\-]*)');

const patterns = [youtubeShortLinkPattern, youtubeWatchLinkPattern];

const convertEmbeddedLink = (link) => {
  const youtubeMatchedGroup = patterns.map((pattern) => link.match(pattern))
    .filter((result) => result).find((it) => it);
  if (youtubeMatchedGroup) {
    return `https://www.youtube.com/embed/${youtubeMatchedGroup[1]}`;
  }
  return link;
};

export default convertEmbeddedLink;
