import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chairPole from 'assets/booths/chair-pole.png';
import chairShadow from 'assets/booths/chair-shadow.png';
import ChairCover from 'assets/booths/chair-cover.svg';
import ChairBottomCover from 'assets/booths/chair-bottom-cover.svg';

const ChairUpon = styled.div`
  position: absolute;
  left: calc((100% - 155px) / 2);
  z-index: 5;
`;

const ChairBottom = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 30px;
  left: calc((100% - 64px) / 2);
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
  max-width: 100%;
  max-height: 100%;
`;

const ChairPoleContainer = styled.div`
  position: absolute;
  top: 40px;
  z-index: 4;
  left: calc((100% - 18px) / 2);
`;

const ChairShadowContainer = styled.div`
  position: absolute;
  bottom: 20px;
  z-index: 1;
  left: calc((100% - 200px) / 2);
  width: 200px;
  height: 45px;
`;

const ChairCoverImage = styled.img`
  position: relative;
  top: -54px;
`;

const ChairBottomCoverImage = styled.img`
  opacity: 0.15;
  position: absolute;
  left: 0;
`;

const ChairBottomVector = styled.svg`
  position: relative;
`;

const BoothChair = ({
  color,
}) => (
  <>
    <ChairUpon>
      <svg width="155" height="51" viewBox="0 0 155 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M140.866 20.2913C127.066 15.0913 103.766 11.6913 77.2656 11.6913C50.7656 11.6913 27.4656 15.0913 13.6656 20.2913H0.465576V31.2913C0.465576 42.0913 34.8656 50.8913 77.2656 50.8913C119.666 50.8913 154.066 42.0913 154.066 31.2913V20.2913H140.866Z" fill={color || '#695E8A'} />
        <path d="M77.2656 39.8913C119.681 39.8913 154.066 31.1161 154.066 20.2913C154.066 9.46655 119.681 0.691299 77.2656 0.691299C34.8501 0.691299 0.465576 9.46655 0.465576 20.2913C0.465576 31.1161 34.8501 39.8913 77.2656 39.8913Z" fill={color || '#695E8A'} />
      </svg>
      <ChairCoverImage src={ChairCover} alt="Chair Cover" />
    </ChairUpon>
    <ChairPoleContainer>
      <StyledImg src={chairPole} />
    </ChairPoleContainer>
    <ChairBottom>
      <ChairBottomVector width="65" height="21" viewBox="0 0 65 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M58.5656 8.19128C52.8656 5.99128 43.1656 4.59131 32.2656 4.59131C21.2656 4.59131 11.6656 5.99128 5.96558 8.19128H0.465576V12.7913C0.465576 17.2913 14.6656 20.8913 32.2656 20.8913C49.8656 20.8913 64.0656 17.2913 64.0656 12.7913V8.19128H58.5656Z" fill={color || '#695E8A'} />
        <path d="M32.2656 16.2913C49.8282 16.2913 64.0656 12.6648 64.0656 8.19128C64.0656 3.71778 49.8282 0.0913086 32.2656 0.0913086C14.7029 0.0913086 0.465576 3.71778 0.465576 8.19128C0.465576 12.6648 14.7029 16.2913 32.2656 16.2913Z" fill={color || '#695E8A'} />
      </ChairBottomVector>
      <ChairBottomCoverImage src={ChairBottomCover} alt="Chair Cover" />
    </ChairBottom>
    <ChairShadowContainer>
      <StyledImg src={chairShadow} />
    </ChairShadowContainer>
  </>
);

BoothChair.propTypes = {
  color: PropTypes.string.isRequired,
};

export default BoothChair;
