import React, {
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EmptyStateImg from 'assets/search-empty-state.svg';
import { EventDatetimeContext } from 'components/EventDatetime';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import Footer from 'components/footer/Footer';
import BackToTopButton from 'components/BackToTopButton';
import OnDemandWebinarList from 'pages/webinar/OnDemandWebinarList';

const WebinarListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  overflow: auto;
`;

const LoadingIndicator = withStyles(() => ({
  root: {
    marginTop: '28px',
  },
}))(CircularLoadingIndicator);

const EmptyStateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const EmptyStateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #484848;
  padding: 24px;
`;

const OnDemandListContainer = ({ webinars, isLoading }) => {
  const { isOnDemandWebinarReady } = useContext(EventDatetimeContext);

  const [scrollableTarget, setScrollableTarget] = useState();
  const scrollableTargetRef = useRef();

  useEffect(() => {
    setScrollableTarget(scrollableTargetRef.current);
  }, []);

  if (!isOnDemandWebinarReady || !webinars.length) {
    return (
      <EmptyStateContainer>
        <div>
          <img src={EmptyStateImg} alt="Coming Soon" />
          <EmptyStateText>
            Coming Soon!
          </EmptyStateText>
        </div>
      </EmptyStateContainer>
    );
  }

  return (
    <>
      <WebinarListContainer ref={scrollableTargetRef}>
        {
          isLoading ? (
            <LoadingIndicator />
          ) : (
            <OnDemandWebinarList webinars={webinars} />
          )
        }
        <Footer hashState="/webinar" />
        <BackToTopButton scrollableTarget={scrollableTarget} />
      </WebinarListContainer>
    </>
  );
};

OnDemandListContainer.propTypes = {
  webinars: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool.isRequired,
};

OnDemandListContainer.defaultProps = {
  webinars: [],
};

export default OnDemandListContainer;
