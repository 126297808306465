import React from 'react';
import PropTypes from 'prop-types';
import { APP, ENABLED_FEATURES } from 'appenv';
import Booth001Template from './templates/Booth001Template';
import Booth002Template from './templates/Booth002Template';
import Booth608Template from './templates/Booth608Template';
import Booth618Template from './templates/Booth618Template';
import Booth623Template from './templates/Booth623Template';
import Booth624Template from './templates/Booth624Template';
import Booth711Template from './templates/Booth711Template';
import Booth710Template from './templates/Booth710Template';
import BoothCMSTemplate from './templates/BoothCMSTemplate';

const BoothTemplateSwitch = ({
  booth,
  handleClick,
  showRepsImg,
  showPlayBtn,
  isPreview,
}) => {
  switch (APP) {
    case 'polyu618':
      return (
        <Booth618Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
        />
      );
    case 'paloalto623':
      return (
        <Booth623Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
        />
      );
    case 'ms624':
      return (
        <Booth624Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
        />
      );
    case 'scmp711':
      return (
        <Booth711Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
        />
      );
    case 'hkstp608':
      return (
        <Booth608Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
        />
      );
    case 'lighthouse710':
      return (
        <Booth710Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
        />
      );
    case 'sph706':
      return (
        <Booth002Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
        />
      );
    default: {
      if (ENABLED_FEATURES.xtraCMS) {
        return (
          <BoothCMSTemplate
            booth={booth}
            handleClick={handleClick}
            isPreview={isPreview}
          />
        );
      }

      if (booth.design) {
        switch (booth.design) {
          case '2':
            return (
              <Booth002Template
                booth={booth}
                handleClick={handleClick}
                showRepsImg={showRepsImg}
              />
            );
          default:
            return (
              <Booth001Template
                booth={booth}
                handleClick={handleClick}
                showRepsImg={showRepsImg}
              />
            );
        }
      }
      if (booth.id % 2 === 0) {
        return (
          <Booth002Template
            booth={booth}
            handleClick={handleClick}
            showRepsImg={showRepsImg}
          />
        );
      }
      return (
        <Booth001Template
          booth={booth}
          handleClick={handleClick}
          showRepsImg={showRepsImg}
        />
      );
    }
  }
};

BoothTemplateSwitch.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
  showPlayBtn: PropTypes.bool,
  isPreview: PropTypes.bool,
};

BoothTemplateSwitch.defaultProps = {
  handleClick: () => { },
  showRepsImg: false,
  showPlayBtn: false,
  isPreview: false,
};

export default BoothTemplateSwitch;
