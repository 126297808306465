import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import fetchAllParticipants from 'network/fetchAllParticipants';

import { ENABLED_FEATURES } from 'appenv';
import { participantEntity } from './participants';
import { selectExhibitorsByKeyword as selectExhibitorsByKeywordFromOldCMS } from './exhibitors';
import { selectWebinarsByKeyword } from './webinars';
import { selectBoothsByKeyword as selectExhibitorsByKeywordFromXtraCMS } from './cms/booths';

const selectExhibitorsByKeyword = ENABLED_FEATURES.xtraCMS
  ? selectExhibitorsByKeywordFromXtraCMS
  : selectExhibitorsByKeywordFromOldCMS;

export const fetchSearchResults = createAsyncThunk(
  'searchResults/fetchAll',
  async ({ keyword, limit }, { getState }) => {
    const { participants, lastKeyword } = getState().searchResults;
    const participantCursor = lastKeyword === keyword ? participants.lastCursor : undefined;
    try {
      const {
        pageInfo: {
          endCursor,
          hasNextPage,
        },
        edges,
      } = (await fetchAllParticipants(participantCursor, keyword, limit))
        || { pageInfo: { endCursor: null, hasNextPage: false }, edges: [] };

      const participantResults = edges.map(({ cursor, node }) => {
        const id = node.uuid;
        return {
          ...node,
          id,
          cursor,
        };
      });
      const normalizedParticipantResults = normalize(participantResults, [participantEntity]);
      const exhibitorResults = selectExhibitorsByKeyword(keyword, { limit })(getState());
      const webinarResults = selectWebinarsByKeyword(keyword, { limit })(getState());

      return {
        keyword,
        participants: {
          entities: normalizedParticipantResults.entities.participants || {},
          lastCursor: (!lastKeyword.length || lastKeyword === keyword) ? endCursor : undefined,
          hasNextPage,
        },
        exhibitors: {
          entities: exhibitorResults.reduce((results, exhibitor) => {
            results[exhibitor.id] = exhibitor;
            return results;
          }, {}),
        },
        webinars: {
          entities: webinarResults.reduce((results, webinar) => {
            results[webinar.id] = webinar;
            return results;
          }, {}),
        },
      };
    } catch (error) {
      console.error(error);
    }
    return {
      keyword,
      participants: {},
      exhibitors: {},
      webinars: {},
    };
  },
  {
    condition: (_, { getState }) => {
      const { loading } = getState().searchResults;
      return !loading;
    },
  },
);

export const searchResultsSelector = createSelector(
  (state) => state.searchResults,
  ({ participants, exhibitors, webinars }) => ({
    participants: Object.values(participants.entities),
    exhibitors: Object.values(exhibitors.entities),
    webinars: Object.values(webinars.entities),
  }),
);

const initialState = {
  participants: {
    entities: {},
    lastCursor: undefined,
    hasNextPage: undefined,
  },
  exhibitors: {
    entities: {},
  },
  webinars: {
    entities: {},
  },
  lastKeyword: '',
  initialLoading: true,
  hasNextPage: true,
};

export const SearchResultsSlice = createSlice({
  name: 'searchResults',
  initialState,
  reducers: {
    resetSearchResults() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchSearchResults.pending]: (state) => {
      state.loading = true;
    },
    [fetchSearchResults.rejected]: (state) => {
      state.loading = false;
    },
    [fetchSearchResults.fulfilled]: (state, action) => {
      const participants = action.payload.participants.entities || {};
      if (state.lastKeyword === action.payload.keyword) {
        state.participants.entities = {
          ...state.participants.entities,
          ...participants,
        };
      } else {
        state.participants.entities = participants;
      }
      state.participants.lastCursor = action.payload.participants.lastCursor;
      state.participants.hasNextPage = action.payload.participants.hasNextPage;

      const exhibitors = action.payload.exhibitors.entities || {};
      if (state.lastKeyword === action.payload.keyword) {
        state.exhibitors.entities = {
          ...state.exhibitors.entities,
          ...exhibitors,
        };
      } else {
        state.exhibitors.entities = exhibitors;
      }

      const webinars = action.payload.webinars.entities || {};
      if (state.lastKeyword === action.payload.keyword) {
        state.webinars.entities = {
          ...state.webinars.entities,
          ...webinars,
        };
      } else {
        state.webinars.entities = webinars;
      }

      state.lastKeyword = action.payload.keyword;
      state.initialLoading = false;
      state.loading = false;
      state.hasNextPage = state.participants.hasNextPage;
    },
  },
});

export default SearchResultsSlice.reducer;
