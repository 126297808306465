import React, {
  useState,
  useMemo,
  useContext,
} from 'react';
import styled from 'styled-components';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';
import { Booth, DesignElement } from 'models/cms/booths';
import { BoothContext } from 'pages/booths/BoothProfileContainer';
import BoothContainer from '../../components/BoothContainer';
import ScreenBackground from '../../components/background/ScreenBackground';
import TanShelf from '../../components/brochureShelf/TanShelf';
import GenericCMSBoothBanner from '../../components/GenericCMSBoothBanner';
import BoothSaleContainer from './BoothSaleContainer';

const TopBannerContainer = styled.div<{ enableClick: boolean }>`
  position: absolute;
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/543/content/logo_container-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 200px;
  left: calc((100% - 400px) / 2);
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const TopBannerWrapper = styled.div`
  margin-top: 47.4px;
  height: 149.4px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowDeskContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/541/content/center_showdesk-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 260px;
  width: 470px;
  height: 400px;
  left: calc((100% - 470px) / 2);
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
`;

const LeftBannerContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/577/content/poster-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 201.5px;
  padding: 6.4px 9px 9px;
  position: absolute;
  bottom: -60px;
`;

const BrochureContainer = styled.div<{ enableClick: boolean }>`
  position: absolute;
  bottom: -60px;
  right: 120px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
  user-select: text;
`;

const LeftSaleContainer = styled(SaleContainer)`
  right: auto;
  left: 0;
`;

const CenterBannerContainer = styled.div`
  height: 206px;
  top: 7px;
  left: 1px;
  width: calc(100% - 1px);
  position: relative;
`;

export interface BannerProps {
  booth: Booth;
  handleClick: (type: string) => void;
  isPreview?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  booth,
  handleClick = () => null,
  isPreview = false,
}: BannerProps) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const leftColor: string = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'left_color')?.color, [booth.designElements]);
  const rightColor: string = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'right_color')?.color, [booth.designElements]);
  const leftBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'left_banner'), [booth.designElements]);
  const topBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode?.name === 'top_banner'), [booth.designElements]);
  const centerBanner: DesignElement = useMemo(() => (booth.designElements || []).find((designNode) => designNode.name === 'middle_banner'), [booth.designElements]);
  const showRightRepresentative = useMemo(() => !isPreview && !!(booth.designElements || []).find((designNode) => designNode.name === 'right_representative'), [isPreview, booth.designElements]);
  const showLeftRepresentative = useMemo(() => !isPreview && !!(booth.designElements || []).find((designNode) => designNode.name === 'left_representative'), [isPreview, booth.designElements]);
  const boothForMatomo = useContext(BoothContext);

  const onBannerClick = (key) => {
    window.trackingEvent('Booth', key, `${boothForMatomo.id} - ${boothForMatomo.boothNameForMatomo}`);
  };

  return (
    <BoothContainer needOffset={!isPreview}>
      <BackgroundContainer>
        <ScreenBackground
          boothColor={leftColor || rightColor || '#CF2F2F'}
          boothColorRight={rightColor || leftColor || '#CF2F2F'}
        />
      </BackgroundContainer>
      {
        topBanner && (
          <TopBannerContainer enableClick={!!booth.website} onClick={() => onBannerClick('Banner 2_Click')}>
            <TopBannerWrapper>
              <GenericCMSBoothBanner
                bannerData={{
                  ...topBanner,
                  actionUrl: topBanner?.actionUrl || booth.website,
                }}
              />
            </TopBannerWrapper>
          </TopBannerContainer>
        )
      }
      {
        centerBanner && (
          <ShowDeskContainer>
            <CenterBannerContainer onClick={() => onBannerClick('Banner 3_Click')}>
              <GenericCMSBoothBanner
                isDescriptionBanner
                onBannerClick={handleClick as any}
                autoPlayVideo={!isPreview}
                bannerData={centerBanner}
              />
            </CenterBannerContainer>
          </ShowDeskContainer>
        )
      }
      {
        leftBanner && (
          <LeftBannerContainer onClick={() => onBannerClick('Banner 1_Click')}>
            <GenericCMSBoothBanner
              bannerData={leftBanner}
            />
          </LeftBannerContainer>
        )
      }
      <BrochureContainer enableClick={booth.hasAttachment} onClick={() => handleClick('attachment')}>
        <TanShelf hasAttachment={booth.hasAttachment} />
      </BrochureContainer>
      {
        showRightRepresentative && (
          <SaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={null}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
      {
        showLeftRepresentative && (
          <LeftSaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-left" />
          </LeftSaleContainer>
        )
      }
    </BoothContainer>
  );
};

export default Banner;
