import styled from 'styled-components';
import { Link } from 'react-router-dom';

const CompanyLink = styled.a`
  display: block;
  font-weight: bold;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  & svg {
    width: 40px;
    height: 40px;
    vertical-align: middle;

    & path {
      fill: #484848;
    }
  }
`;

export const CompanyRouterLink = styled(Link)`
  display: block;
  font-weight: bold;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }

  & svg {
    width: 40px;
    height: 40px;
    vertical-align: middle;

    & path {
      fill: #484848;
    }
  }
`;

export default CompanyLink;
