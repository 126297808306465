import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ViewMoreButton from './ViewMoreButton';
import DescriptionGradientOverlay from './DescriptionGradientOverlay';

const DescriptionContainer = styled.div`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  height: 75%;
  position: relative;
  line-height: 24px;
  overflow: hidden;
  flex-grow: 1;
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: justify;
  text-overflow: ellipsis;
  word-break: break-word;
  padding: 10px;

  & p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const StyledViewMoreButton = styled(ViewMoreButton)`
  margin: 10px;
`;

export interface DescriptionBannerProps {
  htmlContent: string;
  onViewMoreButtonClick?: () => any;
}

const DescriptionBanner: React.FC<DescriptionBannerProps> = ({
  htmlContent,
  onViewMoreButtonClick = () => null,
}: DescriptionBannerProps) => (
  <>
    <DescriptionContainer>
      {ReactHtmlParser(htmlContent)}
      <DescriptionGradientOverlay />
    </DescriptionContainer>
    <StyledViewMoreButton handleClick={onViewMoreButtonClick} />
  </>
);

export default DescriptionBanner;
