import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import { useTranslation, Trans } from 'react-i18next';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Apps from 'apps';
import { APP } from 'appenv';
import LobbyFooterSwitch from '../../LobbyFooterSwitch';
import MobileBannerView from '../../MobileBannerView';

const RootContainer = styled.div`
  width: 100%;
  height: inherit;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const StyledLinkContainer = styled.div<{ background?: string }>`
  flex: 0 0 auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 24px 16px;
  padding-bottom: 49.56%;
  background: ${({ background }) => background || '#3FBDB1'};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
`;

const StyledText = styled.div`
  flex: 0 0 auto;
  font-weight: 900;
  font-size: 5vw;
  letter-spacing: -0.408px;
  color: #FFF;
`;

const SmallStyledText = styled(StyledText)`
  font-size: 3vw;
`;

const FooterWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
`;

const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
  position: absolute;
  right: 5%;
  color: #FFF;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MobileLobbyView = () => {
  const { t } = useTranslation(['lobby', 'landing', 'common']);

  const banners: any = (t('banners', '', { returnObjects: true }) || {});

  const showRightPageEntry = [
    Apps.ReedMonoK1007,
    Apps.ReedMWK1007,
    Apps.ReedAGRIToolGAR1014,
    Apps.ReedJPFood1014,
    Apps.ReedMJTokyo1014,
  ].includes(APP as any);

  return (
    <RootContainer>
      <MobileBannerView
        assets={banners.mobile}
      />
      <StyledLinkContainer>
        <StyledLink to={Routes.hall}>
          <TextContainer>
            <StyledText>
              <Trans
                defaults="To Exhibition Hall"
                i18nKey="lobby:entry.to_exhibition_hall"
              />
            </StyledText>
          </TextContainer>
          <StyledArrowForwardIosIcon />
        </StyledLink>
      </StyledLinkContainer>
      <StyledLinkContainer>
        <StyledLink to="" onClick={() => window.open(t('common:custom_page_source', Routes.custom), '_blank')}>
          <TextContainer>
            <Trans
              defaults="How to utilize virtual expo?"
              i18nKey="lobby:entry.how_to_use_oms"
              components={{
                b: <StyledText />,
                i: <SmallStyledText />,
              }}
            />
          </TextContainer>
          <StyledArrowForwardIosIcon />
        </StyledLink>
      </StyledLinkContainer>
      {showRightPageEntry && (
        <StyledLinkContainer>
          <StyledLink to={t('common:url.next_show')}>
            <TextContainer>
              <StyledText>
                <Trans
                  defaults="Space Booking for Next Show"
                  i18nKey="lobby:entry.next_show"
                />
              </StyledText>
            </TextContainer>
            <StyledArrowForwardIosIcon />
          </StyledLink>
        </StyledLinkContainer>
      )}
      <FooterWrapper>
        <Suspense fallback={null}>
          <LobbyFooterSwitch />
        </Suspense>
      </FooterWrapper>
    </RootContainer>
  );
};

export default MobileLobbyView;
