import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { WebinarPropType } from 'models/types/webinars';
import WebinarSpeakerItem from './WebinarSpeakerItem';

const SectionTitle = styled.h2`
  font-size: 18px;
  line-height: 24px;
  color: #484848;
`;

const WebinarSpeakersContainer = styled.div`
  border-top: 1px solid #DBDEE2;
  padding-top: 24px;
  margin-top: 18px;
`;

const WebinarSpeakers = ({ webinar }) => (
  <WebinarSpeakersContainer>
    <SectionTitle>
      <Trans defaults="SPEAKER(S)" i18nKey="speaker" count={webinar.speakers.length} />
    </SectionTitle>
    {
      webinar.speakers.map((speaker) => (
        <WebinarSpeakerItem
          key={speaker.id}
          webinarId={webinar.id}
          speaker={speaker}
        />
      ))
    }
  </WebinarSpeakersContainer>
);

WebinarSpeakers.propTypes = {
  webinar: WebinarPropType.isRequired,
};

export default WebinarSpeakers;
