/* eslint-disable camelcase */
import { normalize, schema } from 'normalizr';
import { FETCH_BANNER_URL } from 'appenv';
import {
  createAsyncThunk, createEntityAdapter, createSlice, createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';

export enum BannerPosition {
  left = 'left',
  right = 'right',
  middle = 'middle',
  mobile = 'mobile',
}

export enum BannerSourceType {
  image = 'image',
  video = 'video',
  embed = 'embed',
}

export interface Banner {
  id: number;
  sort: number;
  position: BannerPosition;
  type: BannerSourceType;
  src?: string;
  href?: string;
  thumbnail?: string;
  banner_fit?: string;
}

const bannerEntity = new schema.Entity('banners');

export const fetchBanners = createAsyncThunk(
  'banners/fetchAll',
  async () => {
    try {
      const response = await axios.get(FETCH_BANNER_URL);
      const banners = response.data.data.map((it) => {
        const {
          source_url, source, source_type, ...banner
        } = it;
        return { ...banner, src: source_url || source?.data?.full_url, type: source_type };
      });
      const normalized = normalize(banners, [bannerEntity]);
      return normalized.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const bannerAdapter = createEntityAdapter<Banner>();
export const {
  selectEntities: selectBannerEntities,
  selectAll: selectAllBanners,
  selectById: selectBannerById,
} = bannerAdapter.getSelectors((state: any) => state.banners);

export const selectBannersByPosition = (pos: BannerPosition) => createSelector(
  selectAllBanners,
  (banners) => banners.filter(({ position }) => pos === position),
);

export const bannerSlice = createSlice({
  name: 'banners',
  initialState: bannerAdapter.getInitialState({ isLoading: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBanners.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBanners.fulfilled, (state: any, action) => {
      bannerAdapter.upsertMany(state, action.payload.banners);
      state.isLoading = false;
    });
    builder.addCase(fetchBanners.rejected, (state: any) => {
      state.isLoading = false;
    });
  },
});

export default bannerSlice.reducer;
