import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoDataIcon from 'assets/pitchNoData.svg';
import BoothFileRow from 'components/BoothFileRow';

const RootContainer = styled.div`
  width: auto;
  max-width: 100%;
  margin: 0 24px;
  letter-spacing: 0.3px;
`;

const Placeholder = styled.div`
  text-align: center;
  margin-top: 50px;
`;

const Text = styled.div`
  line-height: 24px;
  color: #484848;
  font-weight: bold;
  margin-top: 20px;
`;

const BoothPitch = ({ attachments }) => (
  <>
    <RootContainer>
      {attachments.length > 0 ? attachments.map((attachment) => (
        <BoothFileRow
          key={attachment.id}
          bookmark={{
            id: attachment.id,
            // eslint-disable-next-line camelcase
            filename: attachment.file?.filename_download,
            fileUrl: attachment.file.data.full_url,
          }}
        />
      )) : (
        <Placeholder>
          <img src={NoDataIcon} alt="coming soon" />
          <Text>
            Coming Soon!
          </Text>
        </Placeholder>
      )}
    </RootContainer>
  </>
);

BoothPitch.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default BoothPitch;
