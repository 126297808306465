import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';
import NoDataIndicator from 'components/indicators/NoDataIndicator';
import BoothFileRow from 'components/BoothFileRow';
import { Bookmark } from 'models/types/bookmarks';

import BoothImage from './BoothImage';
import BoothVideo from './BoothVideo';

const BoothQuestionContainer = styled.div`
  padding: 12px 24px;
`;

const BoothQuestionTitle = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #A2AAB3;
  margin-bottom: 11px;
`;

const BoothQuestionDetails = styled.div`
  line-height: 17px;
  white-space: break-spaces;
  text-align: justify;
  word-break: break-word;

  & > h1, h2, h3 {
    line-height: initial;
  }
`;

const BoothQuestion = ({ question, answer, id }: {
  question: string;
  id: string;
  answer: any;
}) => {
  const locale = useSelector(selectLocale);
  const { __typename: typename } = answer;
  switch (typename) {
    case 'BoothRichtextContentElement': {
      const textAnswer = answer.contentRichtextHtml?.[locale] || '';
      const shouldShow = question && textAnswer && new DOMParser().parseFromString(textAnswer, 'text/html')?.body?.textContent;
      if (!shouldShow) return null;
      return (
        question?.trim() && textAnswer?.trim() && (
          <BoothQuestionContainer>
            <BoothQuestionTitle>
              {ReactHtmlParser(question)}
            </BoothQuestionTitle>
            <BoothQuestionDetails>
              {ReactHtmlParser(textAnswer)}
            </BoothQuestionDetails>
          </BoothQuestionContainer>
        )
      );
    }
    case 'BoothImageContentElement': {
      return (
        <BoothImage
          imageSrc={answer?.image?.[locale]?.originalImageUrl}
        />
      );
    }
    case 'BoothVideoContentElement': {
      return (
        <BoothVideo
          src={answer?.videoFile?.[locale]?.playbackUrl}
          thumbnail={answer?.image?.[locale]?.originalImageUrl}
        />
      );
    }
    case 'BoothFileContentElement':
      if (!answer?.file?.[locale]) {
        return answer.length === 1
          ? <NoDataIndicator />
          : null;
      }

      return (
        <BoothFileRow
          bookmark={{
            ...answer.file[locale],
            id,
          } as Bookmark}
        />
      );
    default:
      return null;
  }
};

export default BoothQuestion;
