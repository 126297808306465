import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import BannerSmallImg from 'assets/main_lobby/banner-container-small.png';
import BannerLargeImg from 'assets/main_lobby/banner-container-large.png';

const RootContainer = styled.div<{ size: string }>`
  background: url(${({ size }) => (size === 'large' ? BannerLargeImg : BannerSmallImg)});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ size }) => {
    switch (size) {
      case 'large':
        return 349;
      case 'small':
      default:
        return 307;
    }
  }}px;
  width: ${({ size }) => {
    switch (size) {
      case 'large':
        return 540;
      case 'small':
      default:
        return 360;
    }
  }}px;
`;

const SliderContainer = styled.div<{ size: string }>`
  background-color: #FFF;
  color: #484848;
  margin-top: ${({ size }) => {
    switch (size) {
      case 'large':
        return 60;
      case 'small':
      default:
        return 100;
    }
  }}px;
  height: ${({ size }) => {
    switch (size) {
      case 'large':
        return 280;
      case 'small':
      default:
        return 200;
    }
  }}px;
  width: ${({ size }) => {
    switch (size) {
      case 'large':
        return 540;
      case 'small':
      default:
        return 360;
    }
  }}px;
  overflow: hidden;

  & video {
    background-color: black;
  }
`;

const StyledSlider = styled(Slider)`
  .slick-prev, .slick-next {
    display: none !important;
    z-index: 1000;

    &::before {
      color: #484848;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-slide {
    height: 100%;
    background-color: #FFF;
  }

  &:hover {
    .slick-prev, .slick-next {
      display: block !important;
    }
  }
`;

const AspectRatioConatiner = styled.div<{ size: string }>`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ size }) => {
    switch (size) {
      case 'large':
        return '51.85%';
      case 'small':
      default:
        return '55.56%';
    }
  }};
`;

const AssetContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const StyledAnchor = styled.a`
  flex: 0 0 auto;
  margin: 0;
  text-decoration: none;
  user-select: all;
  pointer-events: all;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.div<{ src: string; bannerFit: string }>`
  background: url(${({ src }) => src});
  height: 100%;
  width: 100%;
  background-size: ${({ bannerFit }) => bannerFit};
  background-repeat: no-repeat;
  background-position: center;
`;

const CustomSlide = ({ children, size }) => (
  <AspectRatioConatiner size={size}>
    <AssetContainer>
      {children}
    </AssetContainer>
  </AspectRatioConatiner>
);

CustomSlide.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
};

const Banner711Template = ({
  className,
  size,
  assets,
  autoPlay,
}) => (
  <RootContainer className={className} size={size}>
    <SliderContainer size={size}>
      <StyledSlider
        arrows={assets.length > 1}
        autoplay={autoPlay}
        swipe={false}
        pauseOnHover
      >
        {
          assets.map(({
            src,
            href,
            banner_fit: bannerFit,
          }, index) => (
            <CustomSlide key={index} size={size}>
              {
                href && href.trim() !== '' ? (
                  <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
                    <StyledImg src={src} bannerFit={bannerFit} />
                  </StyledAnchor>
                ) : (
                  <StyledImg src={src} bannerFit={bannerFit} />
                )
              }
            </CustomSlide>
          ))
        }
      </StyledSlider>
    </SliderContainer>
  </RootContainer>
);

Banner711Template.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  assets: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    type: PropTypes.string,
  })),
  autoPlay: PropTypes.bool.isRequired,
};

Banner711Template.defaultProps = {
  assets: [],
  size: 'small',
  className: null,
};

export default Banner711Template;
