import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import fetchAllContacts from 'network/fetchAllContacts';

export const contactEntity = new schema.Entity('contacts', {}, {
  processStrategy: (entity) => {
    const { contact: { customAttendeeFields: customFields = {} } } = entity;
    const {
      vexpo_chat_tag: userTag,
      vexpo_chat_user_description: userDescription,
    } = customFields;
    entity.userTag = userTag;
    entity.userDescription = userDescription;
    return entity;
  },
});

export const fetchContactList = createAsyncThunk(
  'contacts/fetchAll',
  async (userId, { getState }) => {
    const { lastCursor } = getState().contacts;
    try {
      const {
        pageInfo: {
          endCursor,
          hasNextPage,
        },
        edges,
      } = await fetchAllContacts(userId, '', lastCursor);
      const contacts = edges.map(({ cursor, node }) => {
        const id = node.contact.uuid;
        return {
          ...node,
          id,
          cursor,
        };
      });
      const normalized = normalize(contacts, [contactEntity]);
      return {
        entities: normalized.entities,
        lastCursor: endCursor,
        hasNextPage,
      };
    } catch (error) {
      console.error(error);
    }
    return {
      entities: {},
    };
  },
  {
    condition: (_, { getState }) => {
      const { loading } = getState().contacts;
      return !loading;
    },
  },
);

const contactsAdapter = createEntityAdapter();

export const {
  selectById: selectContactsById,
  selectIds: selectContactIds,
  selectEntities: selectContactEntities,
  selectAll: selectAllContacts,
  selectTotal: selectTotalContacts,
} = contactsAdapter.getSelectors((state) => state.contacts);

const initialState = {
  ...contactsAdapter.getInitialState(),
  lastCursor: undefined,
  initialLoading: true,
  hasNextPage: true,
};

export const ContactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    resetContacts() {
      return initialState;
    },
  },
  extraReducers: {
    [fetchContactList.pending]: (state) => {
      state.loading = true;
    },
    [fetchContactList.fulfilled]: (state, action) => {
      const results = action.payload.entities.contacts || {};
      contactsAdapter.upsertMany(state, results);
      state.lastCursor = action.payload.lastCursor;
      state.hasNextPage = action.payload.hasNextPage;
      state.initialLoading = false;
      state.loading = false;
    },
  },
});

export default ContactsSlice.reducer;
