import styled from 'styled-components';

const BannerDescriptionImage = styled.img<{ objectFit: 'cover' | 'contain' }>`
  width: 100%;
  height: auto;
  object-fit: ${({ objectFit }) => objectFit};
  object-position: center;
`;

export default BannerDescriptionImage;
