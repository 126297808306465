/* eslint-disable camelcase */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import {
  CUSTOM_ORDER,
  APP,
  ENABLED_FEATURES,
} from 'appenv';
import _union from 'lodash/union';
import fetchAllExhibitors from 'network/fetchAllExhibitors';
import { CMSFile } from './cmsFile';

export interface BoothBanner {
  file: CMSFile;
  description: string;
  preview: CMSFile;
  link: string;
  video_preview?: CMSFile;
  video_thumbnail?: CMSFile;
  cloudflare_stream_id?: string;
}

export interface SCMPFileBlock {
  title: string;
  description: string;
  thumbnail: CMSFile;
  files: CMSFile[];
}

export interface Booth {
  id: number;
  booth_bg_color: string;
  booth_color: string;
  booth_color_right?: string;
  booth_number: string;
  contacts: any[];
  type?: 'b' | 'u';
  country: string;
  logo: CMSFile;
  meetup_url: string;
  website: string;
  sort: number;
  tags: any[];
  banners?: {
    top: BoothBanner;
    left: BoothBanner;
    center: BoothBanner;
    bottom: BoothBanner;
    right: BoothBanner;
  };
  livestreams?: { url: string; preview_url: string }[];
  video_call_url?: string;
  translations: {
    [local: string]: {
      description: string;
      extra_fields: any;
      language: string;
      name: string;
      vertical_banner: CMSFile;
      vertical_banner_detail: BoothBanner;
      description_detail: BoothBanner;
      logo_detail: BoothBanner;
    };
  };
  attachments?: CMSFile[];
  files?: SCMPFileBlock[];
  booth_design: string;
  sales_img: CMSFile;
}

const FilterOptionEntity = new schema.Entity('filterOption', {}, {
  processStrategy: (entity) => {
    let FilterOptions = {};
    entity.tags.forEach((tag) => {
      const tagSpitInLevel = tag.split(': ').map((attr) => (attr.trim()));
      if (tagSpitInLevel.length === 3) {
        const upperLevelTag = FilterOptions?.[tagSpitInLevel[0]]?.[tagSpitInLevel[1]]
          ? FilterOptions[tagSpitInLevel[0]][tagSpitInLevel[1]] : [];
        FilterOptions = {
          ...FilterOptions,
          [tagSpitInLevel[0]]: {
            ...FilterOptions[tagSpitInLevel[0]],
            [tagSpitInLevel[1]]: _union(upperLevelTag, [tagSpitInLevel[2]]),
          },
        };
      } else if (tagSpitInLevel.length === 2) {
        const oldFilterOptions = FilterOptions[tagSpitInLevel[0]] || [];
        FilterOptions = {
          ...FilterOptions,
          [tagSpitInLevel[0]]: _union(oldFilterOptions, [tagSpitInLevel[1]]),
        };
      }
    });
    return FilterOptions;
  },
  mergeStrategy: (entityA, entityB) => {
    let newEntity = {};
    Object.keys(entityA).forEach((key) => {
      if (Array.isArray(entityA[key])) {
        newEntity = {
          ...newEntity,
          [key]: _union(entityA[key] || [], newEntity[key] || []),
        };
      } else {
        Object.keys(entityA[key]).forEach((eachSubKey) => {
          newEntity = {
            ...newEntity,
            [key]: {
              ...newEntity[key] || {},
              [eachSubKey]: _union(entityA[key][eachSubKey] || [],
                newEntity?.[key]?.[eachSubKey] || []),
            },
          };
        });
      }
    });
    Object.keys(entityB).forEach((key) => {
      if (Array.isArray(entityB[key])) {
        newEntity = {
          ...newEntity,
          [key]: _union(entityB[key] || [], newEntity[key] || []).sort((a: string, b: string) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
        };
      } else {
        Object.keys(entityB[key]).forEach((subKey) => {
          newEntity = {
            ...newEntity,
            [key]: {
              ...newEntity[key] || {},
              [subKey]: _union(entityB[key][subKey] || [],
                newEntity?.[key]?.[subKey] || []).sort((a: string, b: string) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
            },
          };
        });
      }
    });
    return newEntity;
  },
  idAttribute: 'hihi',
});

export const exhibitorEntity = (filterOption) => (
  new schema.Entity('exhibitors', {}, {
    processStrategy: (entity) => {
      if (entity.tags || entity.translations) {
        entity.tags.forEach((tag) => {
          const tagSpitInLevel = tag.split(': ').map((attr) => (attr.trim()));
          if (tagSpitInLevel.length === 3) {
            if (filterOption[tagSpitInLevel[0]][tagSpitInLevel[1]].includes(tagSpitInLevel[2])) {
              if (entity?.[tagSpitInLevel[0]]?.[tagSpitInLevel[1]]) {
                entity[tagSpitInLevel[0]] = {
                  ...entity[tagSpitInLevel[0]],
                  [tagSpitInLevel[1]]: [
                    ...entity[tagSpitInLevel[0]][tagSpitInLevel[1]],
                    CUSTOM_ORDER[tagSpitInLevel[1]]
                      ? Object.values(CUSTOM_ORDER[tagSpitInLevel[1]]).indexOf(tagSpitInLevel[2])
                      : filterOption[tagSpitInLevel[0]][tagSpitInLevel[1]].indexOf(tagSpitInLevel[2]),
                  ],
                };
              } else {
                entity = {
                  ...entity,
                  [tagSpitInLevel[0]]: {
                    ...entity[tagSpitInLevel[0]],
                    [tagSpitInLevel[1]]: [
                      CUSTOM_ORDER[tagSpitInLevel[1]]
                        ? Object.values(CUSTOM_ORDER[tagSpitInLevel[1]]).indexOf(tagSpitInLevel[2])
                        : filterOption[tagSpitInLevel[0]][tagSpitInLevel[1]].indexOf(tagSpitInLevel[2]),
                    ],
                  },
                };
              }
            }
          } else if (tagSpitInLevel.length === 2) {
            const oldFilterOptions = entity[tagSpitInLevel[0]] || [];
            entity = {
              ...entity,
              [tagSpitInLevel[0]]: [
                ...oldFilterOptions,
                CUSTOM_ORDER[tagSpitInLevel[0]]
                  ? Object.values(CUSTOM_ORDER[tagSpitInLevel[0]]).indexOf(tagSpitInLevel[1])
                  : filterOption[tagSpitInLevel[0]].indexOf(tagSpitInLevel[1]),
              ],
            };
          }
        });
        entity.translations = entity.translations.reduce((result, currentValue) => {
          const descriptionDetail: BoothBanner = {
            description: currentValue.description,
            file: currentValue.description_file,
            link: currentValue.description_link,
            preview: currentValue.description_preview,
            video_preview: currentValue.description_video_preview,
            cloudflare_stream_id: currentValue.cloudflare_stream_id,
          };
          const verticalBannerDetail: BoothBanner = {
            description: currentValue.vertical_banner_description,
            file: currentValue.vertical_banner_file,
            link: currentValue.vertical_banner_link,
            preview: currentValue.vertical_banner,
            video_preview: currentValue.description_video_preview,
            cloudflare_stream_id: currentValue.cloudflare_stream_id,
          };
          const logoDetail: BoothBanner = {
            description: currentValue.logo_description,
            file: currentValue.logo_file,
            link: currentValue.logo_link,
            preview: currentValue.logo ?? entity.logo,
            video_preview: currentValue.description_video_preview,
            cloudflare_stream_id: currentValue.cloudflare_stream_id,
          };
          currentValue.description_detail = descriptionDetail;
          currentValue.logo_detail = logoDetail;
          currentValue.vertical_banner_detail = verticalBannerDetail;
          const locale = currentValue.language;
          result[locale] = currentValue;
          return result;
        }, {});
      }
      if (APP === 'scmp711') {
        entity.attachments = entity.attachments.map((section) => ({
          ...section,
          files: section.files.map((file) => ({
            file: file.file,
            id: file.id,
          })),
        }));
      }
      return entity;
    },
  })
);

export const fetchExhibitors = createAsyncThunk(
  'exhibitors/fetchAll',
  async () => {
    try {
      const result = await fetchAllExhibitors();
      const filterOption = normalize(result, [FilterOptionEntity]);
      let normalized = normalize(result, [exhibitorEntity(filterOption.entities.filterOption.undefined)]);
      normalized = {
        ...normalized,
        entities: {
          ...normalized.entities,
          filterOption: { ids: 0, ...filterOption.entities.filterOption.undefined },
        },
      };
      return normalized.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const exhibitorsAdapter = createEntityAdapter<Booth>({
  sortComparer: (a, b) => a.sort - b.sort,
});

export const {
  selectById: selectExhibitorById,
  selectIds: selectExhibitorIds,
  selectEntities: selectExhibitorEntities,
  selectAll: selectAllExhibitors,
  selectTotal: selectTotalExhibitors,
} = exhibitorsAdapter.getSelectors((state: any) => state.exhibitors);

export const selectExhibitorsByKeyword = (keyword: string, options: any = {}) => {
  const locale = options.locale || 'en';
  const limit = options.limit || undefined;
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    selectAllExhibitors,
    (exhibitors) => (
      exhibitors
        .filter((exhibitor) => (
          lowerCaseKeyword && (
            exhibitor.translations[locale].name.toLowerCase().includes(lowerCaseKeyword)
            || exhibitor.translations[locale].description.toLowerCase().includes(lowerCaseKeyword)
            || exhibitor.tags.some((tag) => tag.toLowerCase().includes(lowerCaseKeyword))
          )
        ))
        .slice(0, limit)
    ),
  );
};

export const filterExhibitorsByKeywords = (keyword) => {
  if (Object.keys(keyword).length > 0) {
    return createSelector(
      selectAllExhibitors,
      (exhibitors) => {
        let processedExhibitors = exhibitors.filter((exhibitor) => {
          let found = true;
          let sublevelfound = true;
          Object.keys(keyword).forEach((tag) => {
            if (Array.isArray(keyword[tag])) {
              if (keyword[tag].length > 0) {
                if (exhibitor[tag]) {
                  found = found && keyword[tag].some((eachKey) => (
                    exhibitor[tag].includes(eachKey)
                  ));
                } else {
                  found = false;
                }
              }
            } else {
              switch (APP) {
                case 'hkstp608': {
                  if (Object.keys(keyword[tag]).every((subKey) => !keyword[tag][subKey] || !keyword[tag][subKey].length)) {
                    break;
                  }
                  sublevelfound = Object.keys(keyword[tag]).some((subKey) => (
                    keyword[tag][subKey].some((subKeyIndex) => (
                      exhibitor?.[tag]?.[subKey]?.includes(subKeyIndex)
                    ))
                  ));
                  break;
                }
                default: {
                  Object.keys(keyword[tag]).forEach((subKey) => {
                    if (keyword[tag][subKey].length > 0) {
                      if (exhibitor?.[tag]?.[subKey]) {
                        sublevelfound = sublevelfound && keyword[tag][subKey].some((eachKey) => (
                          exhibitor[tag][subKey].includes(eachKey)
                        ));
                      } else {
                        found = false;
                      }
                    }
                  });
                }
              }
            }
          });
          return found && sublevelfound;
        });
        if (ENABLED_FEATURES.boothAutoSort) {
          processedExhibitors = processedExhibitors.sort((a, b) => (a?.translations['en']?.name.localeCompare(b?.translations['en']?.name)));
          // eslint-disable-next-line no-unused-vars
          processedExhibitors = processedExhibitors.sort((a, _) => (a?.type === 'u' ? -1 : 1));
        }
        return processedExhibitors;
      },
    );
  }
  return selectAllExhibitors;
};

export const getFilterOptionByExhibitor = () => (
  createSelector(
    selectAllExhibitors,
    (exhibitors) => {
      let FilterOptions = { count: {} };
      exhibitors.filter((exhibitor) => (
        exhibitor.tags.forEach((tag) => {
          const tagSpitInLevel = tag.split(': ').map((attr) => (attr.trim()));
          if (tagSpitInLevel.length === 3) {
            const upperLevelTag = FilterOptions?.[tagSpitInLevel[0]]?.[tagSpitInLevel[1]]
              ? FilterOptions[tagSpitInLevel[0]][tagSpitInLevel[1]] : [];
            FilterOptions = {
              ...FilterOptions,
              count: {
                ...FilterOptions.count,
                [tagSpitInLevel[1]]: {
                  ...FilterOptions.count[tagSpitInLevel[1]],
                  [tagSpitInLevel[2]]: FilterOptions.count?.[tagSpitInLevel[1]]?.[tagSpitInLevel[2]] ? FilterOptions.count[tagSpitInLevel[1]][tagSpitInLevel[2]] + 1 : 1,
                },
              },
              [tagSpitInLevel[0]]: {
                ...FilterOptions[tagSpitInLevel[0]],
                [tagSpitInLevel[1]]: _union(upperLevelTag, [tagSpitInLevel[2]]),
              },
            };
          } else if (tagSpitInLevel.length === 2) {
            const oldFilterOptions = FilterOptions[tagSpitInLevel[0]] || [];
            FilterOptions = {
              ...FilterOptions,
              count: {
                ...FilterOptions.count,
                [tagSpitInLevel[1]]: FilterOptions.count[tagSpitInLevel[1]] ? FilterOptions.count[tagSpitInLevel[1]] + 1 : 1,
              },
              [tagSpitInLevel[0]]: _union(oldFilterOptions, [tagSpitInLevel[1]]),
            };
          }
        })
      ));
      Object.keys(FilterOptions).forEach((tag) => {
        if (tag !== 'count') {
          if (Array.isArray(FilterOptions[tag])) {
            FilterOptions = {
              ...FilterOptions,
              [tag]: FilterOptions[tag].sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
            };
          } else {
            Object.keys(FilterOptions[tag]).forEach((eachSubKey) => {
              FilterOptions = {
                ...FilterOptions,
                [tag]: {
                  ...FilterOptions[tag],
                  [eachSubKey]: FilterOptions[tag][eachSubKey].sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1)),
                },
              };
            });
          }
        }
      });
      return FilterOptions;
    },
  )
);

export const exhibitorsSlice = createSlice({
  name: 'exhibitors',
  initialState: exhibitorsAdapter.getInitialState({ fetching: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExhibitors.pending, (state: any) => {
      state.fetching = true;
    });
    builder.addCase(fetchExhibitors.fulfilled, (state: any, action) => {
      Object.values(action.payload.exhibitors).forEach((booth: Booth) => {
        Object.values(booth.translations).forEach((boothTrans) => {
          boothTrans.name = boothTrans.name.replace(/ {2}/g, ' \u00A0');
        });
      });
      exhibitorsAdapter.upsertMany(state, action.payload.exhibitors);
      state.fetching = false;
    });
    builder.addCase(fetchExhibitors.rejected, (state: any) => {
      state.fetching = false;
    });
  },
});

export default exhibitorsSlice.reducer;
