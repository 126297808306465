import { en as enCommon, ja as jaCommon } from './common';
import { en as enLobby, ja as jaLobby } from './lobby';
import { en as enLanding, ja as jaLanding } from './landing';

export default {
  en: {
    common: enCommon,
    lobby: enLobby,
    landing: enLanding,
  },
  ja: {
    common: jaCommon,
    lobby: jaLobby,
    landing: jaLanding,
  },
};
