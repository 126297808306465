import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import groupBy from 'lodash/groupBy';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    maxWidth: '100%',
    margin: '24px',
    letterSpacing: '0.32px',
    color: '#484848',
  },
  item: {
    padding: '12px 0',
  },
  label: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#A2AAB3',
    marginBottom: '11px',
    textTransform: 'uppercase',
  },
  text: {
    lineHeight: '17px',
    whiteSpace: 'break-spaces',
    textAlign: 'justify',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '17px',
  },
  link: {
    fontSize: '17px',
    lineHeight: '24px',
    color: '#5C8DF3',
    whiteSpace: 'break-spaces',
    textAlign: 'justify',
    wordBreak: 'break-all',
  },
}));

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const groupContacts = (contacts) => groupBy(contacts, (c) => c.type);

const BoothProfile = ({
  name,
  description,
  website,
  contacts,
}) => {
  const classes = useStyles();
  const processedWebsite = useMemo(() => `http://${website.replace(/\b(https:\/\/|http:\/\/)/, '')}`, [website]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { email: emailContacts, ...groupedContacts } = useMemo(() => groupContacts(contacts), [contacts]);

  return (
    <>
      <div className={classes.root}>
        <h2>
          {name}
        </h2>
        {
          description && (
            <div className={classes.item}>
              <div className={classes.label}>
                COMPANY INTRODUCTION
              </div>
              <div className={classes.text}>
                {ReactHtmlParser(description)}
              </div>
            </div>
          )
        }
        {
          website && (
            <div className={classes.item}>
              <div className={classes.label}>
                WEBSITE
              </div>
              <a
                className={classes.link}
                href={processedWebsite}
                target="_blank"
                rel="noopener noreferrer"
              >
                {website}
              </a>
            </div>
          )
        }
        {
          groupedContacts && (
            Object.keys(groupedContacts).map((type) => (
              <div className={classes.item} key={type}>
                <div className={classes.label}>
                  {type}
                </div>
                <LinkContainer>
                  {groupedContacts[type].map((c) => (
                    <a
                      key={c.contact}
                      className={classes.link}
                      href={c.contact}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {c.contact}
                    </a>
                  ))}
                </LinkContainer>
              </div>
            ))
          )
        }
      </div>
    </>
  );
};

BoothProfile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default BoothProfile;
