import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UserAvatar from 'components/UserAvatar';
import { useSelector } from 'react-redux';
import CloseBtnGrayIcon from 'assets/closeBtnGray.svg';
import { UserSessionContext } from 'components/UserSession';
import { RONGCLOUD_CHATROOM_PREFIX } from 'appenv';

const Container = styled.div`
  width: 100%;
  height: 48px;
  background: #2D3D49;
  border-radius: 4px 4px 0 0;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
`;

const UserNameText = styled.div`
  margin-left: 16px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #FFF;
`;

const UnreadMessageCount = styled.div`
  position: absolute;
  min-width: 18px;
  width: fit-content;
  height: 14px;
  left: 30px;
  top: 8px;
  background: #FF3060;
  border: 0.5px solid #FFF;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 10px;
  line-height: 10px;
  color: #FFF;
  text-align: center;
`;

const CloseBtn = styled.div`
  position: absolute;
  height: 16px;
  width: 16px;
  right: 16px;
  top: 14px;
  cursor: pointer;
`;

const CloseBtnImg = styled.img`
  height: 16px;
  width: 16px;
`;

const ChatWindowHeader711 = ({
  setIsChatWindowShow,
  isChatWindowShow,
  userRole,
}) => {
  const { userSession } = useContext(UserSessionContext);
  const { customAttendeeFields: { vexpo_exhibitor_booth_id: exhibitorBoothID } } = userSession;
  const exhibitorChatroomID = `${RONGCLOUD_CHATROOM_PREFIX}_booth_${exhibitorBoothID}`;

  const conversations = useSelector((state) => state.conversations.entities);
  const unreadCount = (() => {
    if (userRole === 'attendee') {
      const attendeeConversations = Object.values(conversations).filter((chat) => (chat.conversationType !== 'group'));
      return Object.values(attendeeConversations).reduce((sum, each) => sum + each.unreadMessageCount, 0);
    }
    if (userRole === 'exhibitor') {
      const exhibiborConversations = Object.values(conversations).filter((chat) => (chat.conversationType !== 'group' || (chat.conversationType === 'group' && chat.id === exhibitorChatroomID)));
      return Object.values(exhibiborConversations).reduce((sum, each) => sum + each.unreadMessageCount, 0);
    }
    return Object.values(conversations).reduce((sum, each) => sum + each.unreadMessageCount, 0);
  })();


  return (
    <Container onClick={isChatWindowShow ? () => {} : setIsChatWindowShow}>
      <UserAvatar name={userSession?.name} />
      <UserNameText>
        {userSession?.name}
      </UserNameText>
      {
        unreadCount > 0 && (<UnreadMessageCount>{unreadCount}</UnreadMessageCount>)
      }
      {
        isChatWindowShow && (
          <CloseBtn onClick={setIsChatWindowShow}>
            <CloseBtnImg src={CloseBtnGrayIcon} alt="close" />
          </CloseBtn>
        )
      }
    </Container>
  );
};

ChatWindowHeader711.propTypes = {
  setIsChatWindowShow: PropTypes.func.isRequired,
  isChatWindowShow: PropTypes.bool.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default ChatWindowHeader711;
