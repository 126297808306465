import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { PAGE_NAMES } from 'appenv';

import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

const RootConatiner = styled.div`
  width: 480px;
  height: 160px;
  margin: 0 90px;
  position: relative;
`;

const TitleContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  color: #FFF;
  padding: 0 40px;
`;

const StyledBackdrop = styled.div`
  height: 100%;
  width: 100%;
  background-color: #0CA58B;
  opacity: 0.6;
  position: absolute;
`;

const StyledArrowForwardIcon = withStyles(() => ({
  root: {
    fontSize: '50px',
  },
}))(ArrowForwardIosRoundedIcon);

const StyledTitle = styled.div`
  max-width: 304px;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.408px;
  margin-right: 38px;
`;

const Webinar608Template = () => (
  <RootConatiner>
    <StyledBackdrop />
    <TitleContainer>
      <StyledTitle>
        {PAGE_NAMES.webinar}
      </StyledTitle>
      <StyledArrowForwardIcon />
    </TitleContainer>
  </RootConatiner>
);

export default Webinar608Template;
