import React, {
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { LocalizedProduct } from 'models/types/products';

import { useSelector, useDispatch } from 'react-redux';
import { envSwitch } from 'utils/envUtils';
import { useTranslation } from 'react-i18next';
import ProductTab from 'components/product/ProductTab';
import { fetchBoothProducts, selectBoothProductsByLocale } from 'models/boothProducts';
import { ProductDetailTabsContext } from './ProductDetailTabsContext';
import ProductDetailField from './ProductDetailField';

const Container = styled.div<{ isDesktop: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? '90%' : '100%')};
  max-width: 1200px;
  height: auto;
  margin: 0 auto;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: #FFF;
  letter-spacing: 0.3px;
  padding: 1px;
  margin-top: 40px;

  .MuiTab-wrapper {
    font-size: ${({ isDesktop }) => (isDesktop ? '24px' : '18px')};
  }

  button.MuiButtonBase-root.MuiTab-root {
    margin-right: ${({ isDesktop }) => (isDesktop ? '40px' : '20px')};
  }
`;

const StyledTabs = withStyles(createStyles({
  root: {
    margin: 24,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > div': {
      maxWidth: 139,
      width: '100%',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props: {
  value: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  scrollButtons: 'auto' | 'desktop' | 'off' | 'on';
  variant: 'scrollable' | 'standard' | 'fullWidth';
}) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#A2AAB3',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Lato',
    padding: '0',
    minWidth: 'auto',
    '&:focus': {
      opacity: 1,
    },
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props: {
  label: string;
}) => <Tab disableRipple {...props} />);

const TabPanel = ({ children, value, index }: {
  children: React.ReactElement;
  value: number;
  index: number;
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      children
    )}
  </div>
);

interface TabElement {
  id: string | number;
  title: string;
  Element: () => React.ReactElement;
  condition: () => boolean;
}

const ProductDescriptionContainer = ({
  product: {
    details = {},
    boothId,
  },
}: {
  product: LocalizedProduct;
}) => {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const { t } = useTranslation();
  const { activeTabKey, setActiveTabKey } = useContext(ProductDetailTabsContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBoothProducts(boothId));
  }, [boothId, dispatch]);

  const boothProducts = useSelector(selectBoothProductsByLocale);
  const isBoothProductsFetching = useSelector((state) => (state as any).boothProducts.fetching);

  const tabs = useMemo(() => {
    const result: TabElement[] = [
      {
        id: 'details',
        title: envSwitch([], t('product.details', 'Product Details')),
        Element: () => (
          <>
            {Object.values(details).map(ProductDetailField)}
          </>
        ),
        condition: () => true,
      },
      {
        id: 'recommendations',
        title: envSwitch([], t('product.recommendations', 'More Products')),
        Element: () => <ProductTab boothProducts={boothProducts} fetching={isBoothProductsFetching} />,
        condition: () => true,
      },
    ].filter((it) => it.condition());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return result;
  }, [t, details, boothProducts, isBoothProductsFetching]);

  const onTabChange = useCallback((_, index) => setActiveTabKey(index as number), [setActiveTabKey]);

  return (
    <Container isDesktop={isDesktop}>
      <StyledTabs
        scrollButtons="off"
        variant="scrollable"
        value={activeTabKey}
        aria-label="Product Description Tabs"
        onChange={onTabChange}
      >
        {tabs.map((it) => (
          <StyledTab
            key={it.id}
            label={it.title}
          />
        ))}
      </StyledTabs>
      <SwipeableViews
        axis="x"
        index={activeTabKey}
        disabled
        ignoreNativeScroll
        resistance
      >
        {tabs.map(({ title, Element }, index) => (
          <TabPanel key={title} value={activeTabKey} index={index}>
            <Element />
          </TabPanel>
        ))}
      </SwipeableViews>
    </Container>
  );
};

export default ProductDescriptionContainer;
