import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import { APP } from 'appenv';
import Apps from 'apps';
import { formatWebinarTimeRange } from 'utils/dateFormatters';
import { WebinarPropType } from 'models/types/webinars';

const SessionsContainer = styled.div``;

const SessionItemContainer = styled(Link)`
  display: block;
  background: ${({ color: [r, g, b] }) => `rgba(${r}, ${g}, ${b}, 0.2)`};
  border: ${({ color: [r, g, b] }) => `2px solid rgb(${r}, ${g}, ${b})`};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  text-decoration: none;
`;

const SessionTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #17181E;
`;

const SessionTime = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #999;
  margin-top: 10px;
`;

const locale = 'en';

const colors = [[180, 208, 83], [129, 197, 189], [228, 144, 69], [147, 124, 217]];

const WebinarSpeakerSessions = ({ webinars }) => (
  <SessionsContainer>
    {webinars.map((webinar, index) => (
      <SessionItemContainer
        key={index}
        color={APP === Apps.Mig0828 ? [219, 222, 226] : colors[index % colors.length]}
        to={Routes.webinarSession(webinar.id)}
      >
        <SessionTitle>{webinar.translations[locale].title}</SessionTitle>
        <SessionTime>{formatWebinarTimeRange(webinar.startAt, webinar.endAt)}</SessionTime>
      </SessionItemContainer>
    ))}
  </SessionsContainer>
);

WebinarSpeakerSessions.propTypes = {
  webinars: PropTypes.arrayOf(WebinarPropType).isRequired,
};

export default WebinarSpeakerSessions;
