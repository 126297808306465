import React from 'react';
import { APP } from 'appenv';
import Apps from 'apps';
import IEFallbackWrapper from 'components/IEFallbackWrapper';
import { envSwitch } from 'utils/envUtils';
import LazyBoothList from './LazyBoothList';
import IEBoothList from './IEBoothList';
import Hall826Template from './templates/Hall826Template';

export interface HallListComponentProps {
  exhibitors: any[];
  isFiltered: boolean;
  isDesktop: boolean;
  hasFilter: boolean;
}

const forceMSIEList = ['scmp711', Apps.Uzabase826];
const forceMSIE = forceMSIEList.includes(APP);

const DefaultHallListComponent = (props: HallListComponentProps) => (
  <IEFallbackWrapper
    fallback={<IEBoothList {...props} />}
    forceMSIE={forceMSIE}
  >
    <LazyBoothList {...props} />
  </IEFallbackWrapper>
);

const HallListComponent = envSwitch([
  [Apps.Uzabase826, Hall826Template],
], DefaultHallListComponent);

export default HallListComponent;
