import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import BoothContainer from '../components/BoothContainer';
import GenericBoothBanner from '../components/GenericBoothBanner';
import LogoDesk from '../components/desk/LogoDesk';
import BoothSaleContainer from '../components/BoothSaleContainer';
import BoothChair from '../components/BoothChair';
import LightBackground618 from '../components/background/LightBackground618';
import WhiteShelf from '../components/brochureShelf/WhiteShelf';
import 'react-image-lightbox/style.css';

const LogoContainer = styled.div`
  position: absolute;
  top: 355px;
  left: 250px;
  height: 250px;
  width: 430px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const ChairContainer = styled.div`
  position: absolute;
  width: 160px;
  height: 200px;
  bottom: -50px;
  left: -150px;
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
`;

const PosterContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/577/content/poster-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 201.5px;
  position: absolute;
  top: ${({ site }) => (site === 'right' ? '215px' : '215px')};
  left: ${({ site }) => (site === 'right' ? '' : '30px')};
  right: ${({ site }) => (site === 'right' ? '5px' : '')};
`;

const PosterInnerContainer = styled.div`
  width: 183px;
  height: 385px;
  position: absolute;
  left: 9px;
  top: 7px;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  height: 460px;
  width: 920px;
`;

const DescriptionContainer = styled.div`
  position: absolute;
  top: 99px;
  width: 369px;
  height: 215px;
  left: calc((100% - 357px) / 2);
`;

const LogoDeskBannerContainer = styled.div`
  position: absolute;
  width: 293px;
  top: 71px;
  left: 70px;
  height: 120px;
`;

const BrochureContainer = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const Booth002Template = ({ booth, handleClick, showRepsImg }) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const pullUpFile = booth.verticalBannerFile;

  return (
    <BoothContainer needOffset={showRepsImg}>
      <BackgroundContainer>
        <LightBackground618 boothColor={booth.boothColor || '#695E8A'} boothColorRight={booth.boothColorRight || booth.boothColor || '#CF2F2F'} />
      </BackgroundContainer>
      <DescriptionContainer>
        <GenericBoothBanner
          from="Banner 2"
          boothNumber={booth.id}
          handleClick={handleClick}
          isPreview={!showRepsImg}
          name={booth.name}
          bannerData={booth?.banner?.descriptionBanner}
          isDescriptionBanner
        />
      </DescriptionContainer>
      <LogoContainer>
        <LogoDesk
          deskColor={booth.boothColor || '#695E8A'}
        />
        <LogoDeskBannerContainer>
          <GenericBoothBanner
            name={booth.name}
            from="Banner 3"
            boothNumber={booth.id}
            handleClick={handleClick}
            isPreview={!showRepsImg}
            website={booth?.website}
            bannerData={booth?.banner?.logoBanner}
            objectFit="contain"
          />
        </LogoDeskBannerContainer>
      </LogoContainer>
      <PosterContainer site="left" onClick={() => handleClick('poster')}>
        <PosterInnerContainer>
          <GenericBoothBanner
            name={booth.name}
            from="Banner 1"
            isPreview={!showRepsImg}
            boothNumber={booth.id}
            handleClick={handleClick}
            bannerData={booth?.banner?.verticalBanner}
            objectFit="cover"
          />
        </PosterInnerContainer>
      </PosterContainer>
      <BrochureContainer enableClick={!!booth.attachments.length} onClick={() => handleClick('attachment')}>
        <WhiteShelf hasAttachment={!!booth.attachments.length} />
      </BrochureContainer>
      <ChairContainer>
        <BoothChair color={booth.boothColor || '#695E8A'} />
      </ChairContainer>
      {
        showRepsImg && (
          <SaleContainer id="sale">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={pullUpFile}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
    </BoothContainer>
  );
};

Booth002Template.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth002Template.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth002Template;
