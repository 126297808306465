import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';
import VideoIcon from 'components/icons/Video';
import { useHistory } from 'react-router-dom';
import { formatWebinarTimeRange } from 'utils/dateFormatters';
import { WebinarPropType } from 'models/types/webinars';
import { WebinarType } from 'models/webinars';
import Routes from '../../Routes';

const locale = 'en';

const WebinarItemContainer = styled.div`
  padding: 10px 30px 10px 10px;
  align-items: center;
  background-color: ${(props) => (props.isCurrent ? '#F4F8FF' : '#FFFFFF')};
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${(props) => (props.isCurrent ? '#F4F8FF' : '#f7faff')};
  }
`;

const WebinarTitleContainer = styled.div`
  display: flex;
  min-height: 36px;
  align-items: center;
`;

const WebinarTitle = styled.div`
  flex: 1 1;
  font-size: 16px;
  line-height: 22px;
`;

const WebinarTime = styled.div`
  margin: 6px 0 0 50px;
  font-size: 14px;
  line-height: 17px;
  color: #A2AAB3;
  white-space: nowrap;
`;

const WebinarStatusIndicatorContainer = styled.div`
  margin: 7px 8px 0 10px;
  max-height: 29px;
  min-height: 22px;
  width: 32px;
  display: flex;
  align-items: center;
  align-self: start;
  justify-content: center;
`;

const LiveIndicator = styled.div`
  width: 32px;
  height: 16px;
  background: #F77;
  color: #FFF;
  border-radius: 2px;
  content: "Live";
  font-size: 10px;
  text-align: center;
  line-height: 14px;
`;

const ComingIndicator = styled(LiveIndicator)`
  width: 32px;
  height: 16px;
  border-radius: 2px;
  background: #FFF;
  border: 1px solid #52B988;
  color: #52B988;
  content: "Coming";
  box-sizing: border-box;
  font-size: 8px;
  text-align: center;
  line-height: 14px;
`;

const WebinarStatusIndicator = ({ webinar }) => {
  const indicators = {
    onDemand: (<VideoIcon />),
    live: (<LiveIndicator>LIVE</LiveIndicator>),
    coming: (<ComingIndicator>Coming</ComingIndicator>),
  };

  const [showing, setShowing] = useState(indicators.onDemand);

  useEffect(() => {
    const update = () => {
      if (webinar.type === WebinarType.Live) {
        if (moment(webinar.startAt).isAfter(moment.now())) {
          setShowing(indicators.coming);
        } else if (moment(webinar.endAt).isAfter(moment.now())) {
          setShowing(indicators.live);
        } else {
          setShowing(indicators.onDemand);
        }
      } else {
        setShowing(indicators.onDemand);
      }
    };
    update();
    const taskId = setInterval(() => update(), 1000);
    return () => clearInterval(taskId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <WebinarStatusIndicatorContainer>{showing}</WebinarStatusIndicatorContainer>;
};

WebinarStatusIndicator.propTypes = { webinar: WebinarPropType.isRequired };

const FollowingWebinarItem = ({ webinar, isCurrent }) => {
  const history = useHistory();
  const gotoWebinar = () => history.push(Routes.webinarSession(webinar.id));
  return (
    <WebinarItemContainer isCurrent={isCurrent} onClick={gotoWebinar}>
      <WebinarTitleContainer>
        <WebinarStatusIndicator webinar={webinar} />
        <WebinarTitle>{webinar.translations[locale]?.title}</WebinarTitle>
      </WebinarTitleContainer>
      {webinar.startAt
      && <WebinarTime>{formatWebinarTimeRange(webinar.startAt, webinar.endAt)}</WebinarTime>}
    </WebinarItemContainer>
  );
};

FollowingWebinarItem.propTypes = {
  webinar: PropTypes.shape().isRequired,
  isCurrent: PropTypes.bool.isRequired,
};

export default FollowingWebinarItem;
