import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  margin-left: ${({ isSelfSent }) => (isSelfSent ? '12px' : 0)};
  margin-right: ${({ isSelfSent }) => (isSelfSent ? 0 : '12px')};
`;

const VideoCallIcon = ({ color, isSelfSent }) => (
  <Container isSelfSent={isSelfSent}>
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.8" d="M15.5339 3.26895L13.2839 4.56445V1.89258C13.2839 1.27207 12.7794 0.767578 12.1589 0.767578H1.75269C1.13218 0.767578 0.627686 1.27207 0.627686 1.89258V12.0176C0.627686 12.6381 1.13218 13.1426 1.75269 13.1426H12.1589C12.7794 13.1426 13.2839 12.6381 13.2839 12.0176V9.3457L15.5339 10.6412C15.9083 10.8574 16.3777 10.5867 16.3777 10.1561V3.75586C16.3777 3.32344 15.9083 3.05273 15.5339 3.26895ZM12.0183 11.877H1.89331V2.0332H12.0183V11.877ZM15.1121 8.94141L13.2839 7.89023V6.02168L15.1121 4.96875V8.94141Z" fill={color} />
    </svg>
  </Container>
);

VideoCallIcon.propTypes = {
  color: PropTypes.string.isRequired,
  isSelfSent: PropTypes.bool,
};

VideoCallIcon.defaultProps = {
  isSelfSent: false,
};

export default VideoCallIcon;
