export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/dc1d0272-6df4-4329-be2c-77b8218be5e1.png',
  ],
  url: {
    exhibition_website: 'https://www.medical-jpn.jp/en-gb.html',
    next_show: '/booth/aZrLNKUX1KMZT8bFBYEJUppd4SbjjaPi693AfYtFbi7j',
  },
  title: "3rd Int'l Medical and Elderly Care Expo Tokyo [MEDICAL JAPAN 2020 TOKYO] -VIRTUAL-",
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a310d3ce-7904-4484-8863-e98d7562312d.png',
  ],
  url: {
    exhibition_website: 'https://www.medical-jpn.jp/ja-jp.html',
    next_show: '/booth/aZrLNKUX1KMZT8bFBYEJUppd4SbjjaPi693AfYtFbi7j',
  },
  title: 'オンライン来場・商談サービス｜第3回 医療と介護の総合展【東京】',
};
