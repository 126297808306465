import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import { Webinar, WebinarType } from 'models/webinars';
import { LiveWebinarListOptions } from 'pages/webinar/live/LiveWebinarListOptions';
import Footer from 'components/footer/Footer';
import BackToTopButton from 'components/BackToTopButton';
import { BREAKPOINT } from '../constants';
import LiveWebinarTimeGroupContainer, { LiveWebinarTimeGroup } from './item/LiveWebinarTimeGroupContainer';

const RootContainer = styled.div<any>`
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: auto;
`;

const SizeWrapper = styled.div<{ displayTimeLine: boolean; isFullWidth: boolean }>`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  max-width: ${(props) => (props.isFullWidth ? '100%' : '1200px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 50px;

  @media (max-width: ${BREAKPOINT}px) {
    padding: 16px 0;
  }

  @media (min-width: ${BREAKPOINT}px) {
    padding: 28px 0;

    &::before {
      display: ${(props) => (props.displayTimeLine ? 'block' : 'none')};
      z-index: 5;
      content: '';
      position: absolute;
      top: 40px;
      left: 115px;
      background-color: #DBDEE2;
      height: calc(100% - 56px);
      width: 1px;
    }
  }
`;

const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 24px;
  justify-content: center;
`;

const LiveWebinarList = ({ webinars = [], options }: { webinars: Webinar[]; options: LiveWebinarListOptions }) => {
  const timeLine: LiveWebinarTimeGroup[] = useMemo(() => {
    const timeMap = webinars.filter((it) => it.kind === WebinarType.Live)
      .map((webinar) => ({
        timeMark: moment(webinar.startAt)
          .minute(Math.floor(moment(webinar.startAt).minute() / options.timeLine.intervalMinute) * options.timeLine.intervalMinute).second(0),
        webinar,
      }))
      .reduce((timeMapResult: { [isoTimeMark: string]: Webinar[] }, nextPair) => {
        const key = nextPair.timeMark.toISOString();
        if (!timeMapResult[key]) {
          timeMapResult[key] = [];
        }
        timeMapResult[key].push(nextPair.webinar);
        return timeMapResult;
      }, {});
    return Object.entries(timeMap)
      .map(([isoTime, inTimeWebinars]) => ({
        timeMark: moment(isoTime),
        webinars: inTimeWebinars.sort((a, b) => moment(a.startAt).diff(b.startAt)),
      }))
      .sort((a, b) => a.timeMark.diff(b.timeMark));
  }, [options.timeLine.intervalMinute, webinars]);

  const [scrollableTarget, setScrollableTarget] = useState<HTMLElement>();
  const scrollableTargetRef = useRef();

  useEffect(() => {
    setScrollableTarget(scrollableTargetRef.current);
  }, []);

  return (
    <RootContainer ref={scrollableTargetRef}>
      <SizeWrapper displayTimeLine={options.timeLine.enable} isFullWidth={options.multiTracks.enable}>
        {
          timeLine.map((timeGroup) => (
            <LiveWebinarTimeGroupContainer
              options={options}
              timeGroup={timeGroup}
              key={timeGroup.timeMark.toString()}
            />
          ))
      }
      </SizeWrapper>
      <FooterWrapper>
        <Footer hashState="/webinar" />
      </FooterWrapper>
      <BackToTopButton scrollableTarget={scrollableTarget} />
    </RootContainer>
  );
};

export default LiveWebinarList;
