/* eslint-disable camelcase */
import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useContext,
} from 'react';
import ReactPlayer from 'react-player';
import { BoothBanner } from 'models/exhibitors';
import 'objectFitPolyfill';

import { LightboxContext } from 'components/booth/CustomLightbox/LightboxContext';
import CustomLightbox from 'components/booth/CustomLightbox';
import GenericBoothBannerContainer from './GenericBoothBannerContainer';
import BannerDescriptionLink from './BannerDescriptionLink';
import BannerElement from './BannerElement';

interface GenericBoothBannerProps {
  name?: string;
  from?: string;
  boothNumber?: string | number;
  handleClick: (type: string) => void;
  bannerData: BoothBanner;
  isDescriptionBanner?: boolean;
  website?: string;
  objectFit?: 'cover' | 'contain';
  isPreview?: boolean;
  onShowPopup?: (popupType?: string) => void;
  onHidePopup?: (popupType?: string) => void;
  ref?: React.RefObject<ReactPlayer | HTMLVideoElement>;
}

declare global {
  interface Window {
    trackingEvent: (eventName: string, tabName: string, id: string | number) => any;
  }
}

const getContainerPadding = (isDescription: boolean) => (isDescription ? 16 : 0);

const GenericBoothBanner = React.forwardRef(({
  name = '',
  from = '',
  boothNumber = '',
  isDescriptionBanner = false,
  website = '',
  bannerData = {} as any,
  handleClick,
  objectFit = 'cover',
  isPreview = false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onShowPopup = () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onHidePopup = () => {},
}: GenericBoothBannerProps, ref: React.RefObject<ReactPlayer | HTMLVideoElement>) => {
  const [showLightbox, setShowLightbox] = useState(false);
  const { setUrl, setCfStreamId } = useContext(LightboxContext);

  useEffect(() => {
    (window as any).objectFitPolyfill();
  }, [bannerData, objectFit]);

  const {
    link = '',
    description = '',
    file,
    preview,
  } = bannerData;

  const fileFullUrl = file?.data?.full_url;
  const previewFullUrl = preview?.data?.full_url;
  const bannerLink = useMemo(
    () => {
      const noProtocolLink = (fileFullUrl || link || website || previewFullUrl)?.replace(/\b(https:\/\/|http:\/\/)/, '');
      return noProtocolLink ? `http://${noProtocolLink}` : undefined;
    },
    [fileFullUrl, link, previewFullUrl, website],
  );

  const fileType = file?.type || '';
  const previewType = preview?.type || '';
  const mappedFileType = useMemo(() => {
    switch (true) {
      case !!bannerData.cloudflare_stream_id: return 'cfStream';
      case fileType.startsWith('video'): return 'video';
      case fileType === 'application/pdf': return 'pdf';
      case !!(link || website): return 'link';
      case fileType.startsWith('image') || previewType.startsWith('image'): return 'image';
      default: return null;
    }
  }, [fileType, previewType, bannerData.cloudflare_stream_id, link, website]);

  const closeLightBox = useCallback(() => {
    setShowLightbox(false);
    onHidePopup(mappedFileType);
  }, [setShowLightbox, onHidePopup, mappedFileType]);

  const openLightBox = useCallback(() => {
    setShowLightbox(true);
    onShowPopup(mappedFileType);
  }, [setShowLightbox, onShowPopup, mappedFileType]);

  const bannerClickAction = useCallback((event) => {
    window.trackingEvent('Booth', `${from}_Click`, `${boothNumber} - ${name}`);
    event.preventDefault();
    if (!bannerLink) return;
    if (mappedFileType === 'cfStream') {
      onShowPopup('video');
      setCfStreamId(bannerData.cloudflare_stream_id);
    } else if (mappedFileType === 'video') {
      onShowPopup(mappedFileType);
      setUrl(bannerLink);
    } else {
      openLightBox();
    }
  }, [
    from,
    boothNumber,
    name,
    bannerLink,
    mappedFileType,
    onShowPopup,
    setCfStreamId,
    bannerData.cloudflare_stream_id,
    setUrl,
    openLightBox,
  ]);

  const isDescriptionDisplay = useMemo(() => (description && isDescriptionBanner), [description, isDescriptionBanner]);
  const containerPadding = useMemo(() => getContainerPadding(isDescriptionDisplay), [isDescriptionDisplay]);
  const isInlineVideoBanner = preview?.type?.startsWith('video');
  const isVideo = !!file?.type?.startsWith('video');

  // TODO: Move some of this logic to `BannerElement` component
  const bannerElementProps = {
    description,
    isPreview,
    objectFit,
    bannerData,
    handleClick,
    boothNumber,
    name,
    isDescriptionBanner,
    isVideo,
  };

  return (
    <GenericBoothBannerContainer padding={containerPadding}>
      {(containerPadding || isInlineVideoBanner) ? (
        <BannerElement {...bannerElementProps} ref={ref} />
      ) : (
        <BannerDescriptionLink onClick={bannerClickAction} hasLink={!!bannerLink}>
          <BannerElement {...bannerElementProps} ref={ref} />
        </BannerDescriptionLink>
      )}
      {bannerLink && (
        <CustomLightbox
          src={bannerLink}
          // eslint-disable-next-line camelcase
          cfStreamId={bannerData?.cloudflare_stream_id}
          onClose={closeLightBox}
          type={mappedFileType}
          open={showLightbox}
        />
      )}
    </GenericBoothBannerContainer>
  );
});

export default GenericBoothBanner;
