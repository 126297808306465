import styled from 'styled-components';

const StyledAnchor = styled.a`
  margin: 0;
  text-decoration: none;
  user-select: all;
  pointer-events: all;
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default StyledAnchor;
