import React, {
  createRef, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Scrollbars } from 'react-custom-scrollbars';

import SpeakerItem from './SpeakerItem';

const RootContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin: 0 -24px;
`;

const StyledScrollbars = styled(Scrollbars)`
  margin: 0 24px;
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const StyledChevronLeftIcon = withStyles(() => ({
  root: {
    display: ({ active }) => (active === 'true' ? 'block' : 'none'),
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
}))(ChevronLeftIcon);

const StyledChevronRightIcon = withStyles(() => ({
  root: {
    display: ({ active }) => (active === 'true' ? 'block' : 'none'),
    position: 'absolute',
    height: '100%',
    top: 0,
    right: 0,
    cursor: 'pointer',
  },
}))(ChevronRightIcon);

const locale = 'en';

const DesktopSpeakerList = ({ webinarId, speakers }) => {
  const speakerListRef = createRef();
  const [chevronLeftActive, setChevronLeftActive] = useState(false);
  const [chevronRightActive, setChevronRightActive] = useState(false);

  const onChevronLeftClick = () => {
    speakerListRef.current.view.scrollBy({
      top: 0,
      left: -speakerListRef.current.getClientWidth() / 3,
      behavior: 'smooth',
    });
  };

  const onChevronRightClick = () => {
    speakerListRef.current.view.scrollBy({
      top: 0,
      left: speakerListRef.current.getClientWidth() / 3,
      behavior: 'smooth',
    });
  };

  const isChevronLeftActive = useCallback(() => {
    if (!speakerListRef.current) return false;
    return speakerListRef.current.getScrollLeft() > 0;
  }, [speakerListRef]);
  const isChevronRightActive = useCallback(() => {
    if (!speakerListRef.current) return false;
    return speakerListRef.current.getScrollLeft() + speakerListRef.current.getClientWidth() < speakerListRef.current.getScrollWidth();
  }, [speakerListRef]);

  const setupChevrons = useCallback(() => {
    setChevronLeftActive(isChevronLeftActive());
    setChevronRightActive(isChevronRightActive());
  }, [isChevronLeftActive, isChevronRightActive]);

  useEffect(() => {
    setupChevrons();
  }, [setupChevrons]);

  return (
    <RootContainer>
      <StyledChevronLeftIcon
        active={`${chevronLeftActive}`}
        onClick={onChevronLeftClick}
      />
      <StyledScrollbars
        ref={speakerListRef}
        autoHeight
        style={{ width: 'auto' }}
        onScrollStop={setupChevrons}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}
        renderThumbVertical={() => <div />}
        renderTrackVertical={() => <div />}
      >
        <ListContainer ref={speakerListRef}>
          {
            speakers.map(({
              id, avatar, translations,
            }) => {
              const {
                name, company, title,
              } = translations[locale];
              return (
                <SpeakerItem
                  key={id}
                  id={id}
                  webinarId={webinarId}
                  name={name}
                  company={company}
                  title={title}
                  avatar={avatar}
                />
              );
            })
          }
        </ListContainer>
      </StyledScrollbars>
      <StyledChevronRightIcon
        active={`${chevronRightActive}`}
        onClick={onChevronRightClick}
      />
    </RootContainer>
  );
};

DesktopSpeakerList.propTypes = {
  webinarId: PropTypes.number.isRequired,
  speakers: PropTypes.arrayOf(PropTypes.shape()),
};

DesktopSpeakerList.defaultProps = {
  speakers: [],
};

export default DesktopSpeakerList;
