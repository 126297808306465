import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tanShelfImg from 'assets/booths/tan-shelf.png';

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const BannerImg = styled.img`
  position: absolute;
  width: 55px;
  height: 78px;
  left: 14px;
  top: 3px;
`;

const TanShelf = ({ pullUpBanner }) => (
  <>
    <StyledImg src={tanShelfImg} />
    {pullUpBanner && <BannerImg src={pullUpBanner} />}
  </>
);

TanShelf.propTypes = {
  pullUpBanner: PropTypes.string,
};

TanShelf.defaultProps = {
  pullUpBanner: '',
};

export default TanShelf;
