import React from 'react';
import styled from 'styled-components';
import EventLogo707 from 'assets/event-logo-707.png';
import EventXtraLogo from 'assets/eventxtra-logo-dark.png';

const RootContainer = styled.div`
  border-radius: 4px;
  padding: 4px 8px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.5);
`;

const PoweredByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoList = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const StyledText = styled.div`
  flex: 0 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 6px;
  color: black;
`;

const StyledAnchor = styled.div`
  flex: 0 0 auto;
  margin-top: 8px;
  font-family: Lato, "sans-serif";
`;

const StyledImg = styled.img`
  max-width: 180px;
  max-height: 30px;
  margin-bottom: 3px;
`;

const LeftLogo = styled(StyledImg)`
  max-height: 40px;
`;

const PicoXBranding: React.FC<any> = () => (
  <RootContainer>
    <StyledAnchor>
      <PoweredByContainer>
        <StyledText>Powered by</StyledText>
        <LogoList>
          <a href="http://www.globallinkmp.com/" target="_blank" rel="noopener noreferrer">
            <LeftLogo src={EventLogo707} />
          </a>
          <a href="https://eventxtra.com/virtual-exhibition-v/?organiser=EventXtra&domain=eventxtra.com&utm_source=eventxtra&utm_medium=internal_referral&utm_campaign=virtual_page_banner#/hall" target="_blank" rel="noopener noreferrer" title="Powered by EventXtra">
            <StyledImg src={EventXtraLogo} />
          </a>
        </LogoList>
      </PoweredByContainer>
    </StyledAnchor>
  </RootContainer>
);

export default PicoXBranding;
