export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/f4bb5ac0-5dd7-4428-9ffa-dbd60d881a70.png',
  ],
  url: {
    exhibition_website: 'https://www.edix-expo.jp/en-gb.html',
  },
  title: 'Education Expo Japan 【EDIX Tokyo】 -VIRTUAL-',
};


export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/b9e2fdf9-8d4c-440e-a2c3-d4585a7a0cfa.png',
  ],
  url: {
    exhibition_website: 'https://www.edix-expo.jp/ja-jp.html',
  },
  title: 'オンライン来場・商談サービス｜第11回 教育 総合展（EDIX東京）',
};
