import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <g>
    <path d="M12.0728 1.62039C6.27432 1.62039 1.57275 6.32195 1.57275 12.1204C1.57275 17.9188 6.27432 22.6204 12.0728 22.6204C17.8712 22.6204 22.5728 17.9188 22.5728 12.1204C22.5728 6.32195 17.8712 1.62039 12.0728 1.62039ZM12.0728 20.8391C7.25869 20.8391 3.354 16.9345 3.354 12.1204C3.354 7.30633 7.25869 3.40164 12.0728 3.40164C16.8868 3.40164 20.7915 7.30633 20.7915 12.1204C20.7915 16.9345 16.8868 20.8391 12.0728 20.8391Z" />
    <path d="M16.9337 11.818L9.99385 6.77896C9.74541 6.59849 9.40088 6.77427 9.40088 7.0813V17.1594C9.40088 17.4665 9.74775 17.6399 9.99385 17.4618L16.9337 12.4227C17.1399 12.2727 17.1399 11.968 16.9337 11.818ZM10.8962 14.9586V9.28208L14.8032 12.1204L10.8962 14.9586Z" />
  </g>,
  'WatchNow',
  { viewBox: '0 0 25 25', height: '25px', width: '25px' },
);
