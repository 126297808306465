import React from 'react';
import { APP } from 'appenv';

const LobbyFooterSwitch = React.lazy(() => {
  switch (APP) {
    case 'polyu618':
      return import('./PolyUFooter');
    default:
      return import('components/footer/Footer');
  }
});

export default LobbyFooterSwitch;
