import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import { useSelector } from 'react-redux';
import ErrorIcon from 'assets/errorIcon.svg';
import { selectMessagesByTargetId } from 'models/messages';
import ChatMessageContainer from './ChatMessageContainer';
import ChatWindowFooter from './ChatWindowFooter';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 44px);
  background-color: #F0F1F2;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-direction: column;
`;

const ErrorMsgContainer = styled.div`
  background-color: #EDD5D1;
  display: flex;
  height: 40px;
  padding: 11px 16px;
  color: #484848;
  font-size: 14px;
`;

const ErrorImg = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 16px;
`;

const ChatBody = styled.div`
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 10px 20px 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const UserInfo = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #484848;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
`;

const UserTag = styled.span`
  border-radius: 2px;
  background-color: #FFB7B5;
  color: #FFF;
  font-size: 12px;
  padding: 0 5px;
  margin-left: 9px;
`;

const UserDescription = styled.div`
  color: #484848;
  font-size: 12px;
  margin-top: 8px;
`;

const LoadingIndicatorContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F1F2;
`;

const MoreMessagesBtn = styled.div`
  text-align: center;
  letter-spacing: -0.408px;
  color: #5C8DF3;
  cursor: pointer;
  margin-bottom: 16px;
`;

const SnackbarContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
`;

const StyledSnackbar = withStyles(() => ({
  root: {
    position: 'relative',
    transform: 'none',
    left: 0,
  },
}))(Snackbar);

const ChatWindow = ({
  joiningChat,
  status,
  sendMessage,
  resendMessage,
  getHistoryMsg,
  hasMoreMsg,
  handleAvatarOnclick,
}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const messageRef = useRef();
  const { targetId } = useSelector((state) => state.chatTarget);
  const messages = useSelector(selectMessagesByTargetId(targetId));
  const [autoscroll, setAutoscroll] = useState(true);
  const [firstMsgId, setFirstMsgId] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (joiningChat) return;
    if (status === 'offline') {
      setErrorMsg('Network unavailable, check network');
    } else if (status === 'disconnected') {
      setErrorMsg('Cannot connect to the chatroom');
    } else {
      setErrorMsg('');
    }
  }, [status]);

  useEffect(() => {
    if (autoscroll && messageRef.current) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    } else if (firstMsgId) {
      const targetComponent = document.getElementById(firstMsgId);
      messageRef.current.scrollTop = targetComponent ? targetComponent?.offsetTop - targetComponent?.clientHeight : 0;
      setFirstMsgId(null);
    }
  }, [messages]);

  const onScroll = (e) => {
    setAutoscroll(e.target.scrollHeight <= e.target.scrollTop + e.target.clientHeight);
  };

  const fetchMoreMessages = () => {
    setFirstMsgId((messages[0] || {}).id);
    getHistoryMsg(null);
  };

  if (joiningChat) {
    return (
      <LoadingIndicatorContainer>
        <CircularLoadingIndicator />
      </LoadingIndicatorContainer>
    );
  }

  return (
    <Container>
      {
        errorMsg && (
          <ErrorMsgContainer>
            <ErrorImg src={ErrorIcon} alt="error" />
            {errorMsg}
          </ErrorMsgContainer>
        )
      }
      <SnackbarContainer>
        <StyledSnackbar
          open={alertOpen}
          autoHideDuration={3000}
          onClose={() => setAlertOpen(false)}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => setAlertOpen(false)}
            severity="success"
          >
            All messages will be delivered within minutes
          </MuiAlert>
        </StyledSnackbar>
      </SnackbarContainer>
      <ChatBody
        ref={messageRef}
        onScroll={onScroll}
      >
        {
          hasMoreMsg && (
            <MoreMessagesBtn onClick={fetchMoreMessages}>
              {t('chat.load_earlier')}
            </MoreMessagesBtn>
          )
        }
        {
          messages.map((msg, index) => {
            let dateFlag = '';
            if (index > 0) {
              const lastMsg = messages[index - 1];
              const lastMsgDate = moment(lastMsg.sentTime);
              dateFlag = lastMsgDate.format('DD/MM/YYYY');
            }
            return (
              <ChatMessageContainer
                key={msg.id}
                msg={msg}
                dateFlag={dateFlag}
                handleAvatarOnclick={handleAvatarOnclick}
                resendMessage={resendMessage}
              />
            );
          })
        }
      </ChatBody>
      <ChatWindowFooter
        joiningChat={joiningChat}
        sendMessage={sendMessage}
        setAlertOpen={setAlertOpen}
      />
    </Container>
  );
};

ChatWindow.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  sendMessage: PropTypes.func.isRequired,
  resendMessage: PropTypes.func.isRequired,
  getHistoryMsg: PropTypes.func.isRequired,
  hasMoreMsg: PropTypes.bool.isRequired,
  handleAvatarOnclick: PropTypes.func.isRequired,
};

export default ChatWindow;

export {
  UserInfo,
  UserTag,
  UserDescription,
};
