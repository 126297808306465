import React, { useCallback, useState } from 'react';
import ReactPlayer from 'react-player';
import playButton from 'assets/play-button.png';
import StyledImg from 'components/mobileBanner/StyledImg';
import StyledAnchor from 'components/mobileBanner/StyledAnchor';
import ImageLightbox from 'components/booth/CustomLightbox/ImageLightbox';

import { MobileBoothBannerAsset } from './MobileBoothBannerAsset';

const VideoPlayer = ({
  url,
  key,
  thumbnail,
  onPlayVideo,
  onPauseVideo,
}: {
  url: string;
  thumbnail: string;
  key: string;
  onPauseVideo: () => void;
  onPlayVideo: () => void;
}) => (
  <ReactPlayer
    light={thumbnail || true}
    key={key}
    url={url}
    controls
    playIcon={<img src={playButton} alt="Play Button" />}
    width="100%"
    onPlay={onPlayVideo}
    onBuffer={onPlayVideo}
    onStart={onPlayVideo}
    onReady={onPlayVideo}
    onPause={onPauseVideo}
    onClick={onPlayVideo}
    height="100%"
    config={{
      file: {
        attributes: {
          autoPlay: true,
          muted: true,
          onLoadStart: onPlayVideo,
          disablePictureInPicture: true,
          controlsList: 'nodownload noremoteplayback',
        },
      },
    }}
    playsinline
    style={{ zIndex: 100, backgroundColor: 'black' }}
  />
);

const MobileBoothBanner = ({
  asset: {
    src,
    type,
    href,
    thumbnail,
    banner_fit: bannerFit,
  },
  isPlaying,
  index,
  onPauseVideo,
  onPlayVideo,
  onShowPopup,
  onHidePopup,
}: {
  asset: MobileBoothBannerAsset;
  isPlaying: boolean;
  index: number;
  onPauseVideo: () => void;
  onPlayVideo: () => void;
  onShowPopup: () => void;
  onHidePopup: () => void;
}) => {
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);

  const openImageOverlay = useCallback(() => {
    setImageLightboxOpen(true);
    if (onShowPopup) {
      onShowPopup();
    }
  }, [onShowPopup]);

  const closeImageOverlay = useCallback(() => {
    setImageLightboxOpen(false);
    if (onHidePopup) {
      onHidePopup();
    }
  }, [onHidePopup]);

  switch (type) {
    case 'cfStream':
      return (
        <VideoPlayer
          key={`${index}-${isPlaying === false}`}
          url={`https://videodelivery.net/${src}/manifest/video.m3u8`}
          onPauseVideo={onPauseVideo}
          onPlayVideo={onPlayVideo}
          thumbnail={thumbnail}
        />
      );
    case 'video':
      return (
        <VideoPlayer
          key={`${index}-${isPlaying === false}`}
          url={src}
          onPauseVideo={onPauseVideo}
          onPlayVideo={onPlayVideo}
          thumbnail={thumbnail}
        />
      );
    case 'image':
    default:
      return (
        href && href.trim() !== '' ? (
          <StyledAnchor draggable={false} href={href} target="_blank" rel="noopener noreferrer">
            <StyledImg img={src} bannerFit={bannerFit} />
          </StyledAnchor>
        ) : (
          <>
            <StyledImg img={src} bannerFit={bannerFit} onClick={openImageOverlay} />
            <ImageLightbox
              src={src}
              open={imageLightboxOpen}
              onClose={closeImageOverlay}
            />
          </>
        )
      );
  }
};

export default MobileBoothBanner;
