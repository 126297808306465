import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const SliderImageLightbox = ({ src, onClose, open }: {
  src: string;
  onClose: () => void;
  open: boolean;
}) => open && (
  <Lightbox
    mainSrc={src}
    onCloseRequest={onClose}
  />
);

export default SliderImageLightbox;
