import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { styled as muiStyled } from '@material-ui/core/styles';
import Truncate from 'react-truncate';
import { BRAND_COLOR } from 'appenv';
import FilePreviewIcon from 'assets/filePreviewIcon.svg';
import FileCancelBtn from 'assets/fileCancelBtn.svg';

import getFileSizeText from './utils/getFileSizeText';

const StyledFilePreview = styled.div`
  flex: 0 0 auto;
  background-color: #FFF;
  width: 70%;
  height: 64px;
  border-radius: 4px;
  border: 1px solid #DBDEE2;
  margin: 16px 24px;
  display: flex;
  align-items: center;
  position: relative;
`;

const FileInfo = styled.div`
  width: 70%;
  margin-left: 10px;
`;

const FileTypeIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-left: 16px;
`;

const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #17181E;
  font-size: 14px;
`;

const FileSize = styled.div`
  color: #999;
  font-size: 12px;
`;

const StyledCancelBtn = styled.img`
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
`;

const FilePreviewErrorMsg = styled.div`
  color: #E2654D;
  font-size: 14px;
`;

const StyledCircularProgress = muiStyled(CircularProgress)({
  color: BRAND_COLOR || '#1976D2',
});

const ChatWindowFilePreview = ({
  fileName,
  fileSize,
  onFileCancel,
  isUploading,
  hasError,
}) => (
  <StyledFilePreview>
    <FileTypeIcon src={FilePreviewIcon} alt="file" />
    <FileInfo>
      <FileName>
        <Truncate lines={1} ellipsis="...">
          {fileName}
        </Truncate>
      </FileName>
      <FileSize>
        {getFileSizeText(fileSize)}
      </FileSize>
      {
        isUploading && (
          <StyledCircularProgress size={20} />
        )
      }
      {
        hasError && (
          <FilePreviewErrorMsg>
            Upload error
          </FilePreviewErrorMsg>
        )
      }
    </FileInfo>
    <StyledCancelBtn onClick={onFileCancel} src={FileCancelBtn} alt="cancel" />
  </StyledFilePreview>
);

ChatWindowFilePreview.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.number.isRequired,
  onFileCancel: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default ChatWindowFilePreview;
