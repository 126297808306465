import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const BoothContactsContainer = styled.div`
  width: auto;
  max-width: 100%;
  margin: 0 24px;
  letter-spacing: 0.32px;

  & a {
    font-size: 17px;
    line-height: 24px;
    color: #5C8DF3;
    white-space: break-spaces;
    text-align: justify;
    word-break: break-all;
  }
`;

const ContactBlock = styled.div`
  padding: 12px 0;
`;

const ContactLabel = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #A2AAB3;
  margin-bottom: 11px;
`;

const ContactInfo = styled.div`
  line-height: 17px;
  white-space: break-spaces;
  text-align: justify;
`;

const BoothContacts = ({
  contacts,
}: {
  contacts: {
    type: string;
    contact: string;
  }[];
}) => (
  <BoothContactsContainer>
    {contacts.map((contactInfo) => {
      let content = contactInfo.contact;
      let text = contactInfo.contact;
      if (contactInfo.type === 'email') {
        text = content.replace('>', '').replace('<', '- ');
        content = `mailto:${content}`;
      }
      return content && (
        <ContactBlock key={contactInfo.type}>
          <ContactLabel>
            <Trans defaults={contactInfo.type.toUpperCase()} i18nKey={`booth.${contactInfo.type.toLowerCase()}`} />
          </ContactLabel>
          <ContactInfo>
            {content.startsWith('http') || content.startsWith('mailto') ? <a href={content} target="_blank" rel="noopener noreferrer">{text}</a> : text}
          </ContactInfo>
        </ContactBlock>
      );
    })}
  </BoothContactsContainer>
);

export default BoothContacts;
