import LogoEn1 from 'assets/main_lobby/top-logo-902-en-1.png';
import LogoEn2 from 'assets/main_lobby/top-logo-902-en-2.png';
import LogoJa1 from 'assets/main_lobby/top-logo-902-ja-1.png';
import LogoJa2 from 'assets/main_lobby/top-logo-902-ja-2.png';

const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  logos: [LogoEn2, LogoEn1],
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/bc44e73f-8ccc-4be5-8f60-75e22ed95a7b.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/1b22529f-99ec-4d71-aa4d-3668eb6c1c3f.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/3f25c19d-9d77-4c25-8241-884d80ecfc20.png',
        'https://www.lifestyle-expo.jp/ls_jmw_top_en/',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
  ...en.banners.right,
];

export const ja = {
  logos: [LogoJa2, LogoJa1],
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/b17c8b3e-16af-4b51-949d-a9981184e305.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/393dd222-1638-49e1-b42d-27e49768c566.png',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/64759975-f6f8-4baf-9bd0-f6e35e55efc4.png',
        'https://www.lifestyle-expo.jp/ls_jmw_top/',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];
