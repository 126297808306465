import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const RootContainer = styled.div`
  width: ${({ size }) => {
    switch (size) {
      case 'large':
        return 480;
      case 'small':
      default:
        return 300;
    }
  }}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BannerWiresPlaceholder = styled.div`
  height: ${({ size }) => {
    switch (size) {
      case 'large':
        return 96;
      case 'small':
      default:
        return 116;
    }
  }}px;
  width: ${({ size }) => {
    switch (size) {
      case 'large':
        return 300;
      case 'small':
      default:
        return 240;
    }
  }}px;
  border-left: 1px solid rgba(0, 0, 0, 0.5);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
`;

const SliderContainer = styled.div`
  background-color: #FFF;
  color: #484848;
  height: ${({ size }) => {
    switch (size) {
      case 'large':
        return 240;
      case 'small':
      default:
        return 200;
    }
  }}px;
  width: ${({ size }) => {
    switch (size) {
      case 'large':
        return 480;
      case 'small':
      default:
        return 300;
    }
  }}px;
`;

const StyledSlider = styled(Slider)`
  .slick-prev, .slick-next {
    display: none !important;
    z-index: 1000;

    &::before {
      color: #484848;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-slide {
    height: 100%;
    background-color: #FFF;
  }

  &:hover {
    .slick-prev, .slick-next {
      display: block !important;
    }
  }
`;

const AspectRatioConatiner = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 50%;
`;

const AssetContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const CustomSlide = ({ children, size }) => (
  <AspectRatioConatiner size={size}>
    <AssetContainer>
      {children}
    </AssetContainer>
  </AspectRatioConatiner>
);

CustomSlide.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(['small', 'large']).isRequired,
};

const StyledAnchor = styled.a`
  flex: 0 0 auto;
  margin: 0;
  text-decoration: none;
  user-select: all;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
  height: 100%;
`;

const StyledVideo = styled.video`
  height: 100%;
  width: 100%;
  user-select: all;
  pointer-events: all;
`;

const StyledIframe = styled.iframe`
  height: 100%;
  width: 100%;
  user-select: all;
  pointer-events: all;
`;

const BannerView = ({ className, size, assets }) => (
  <RootContainer className={className} size={size}>
    <BannerWiresPlaceholder size={size} />
    <SliderContainer size={size}>
      <StyledSlider
        arrows={assets.length > 1}
        autoplay
        pauseOnHover
      >
        {
          assets.map(({ src, type, href }, index) => {
            switch (type) {
              case 'embed':
                return (
                  <CustomSlide key={index} size={size}>
                    <StyledIframe src={src} allow="fullscreen" />
                  </CustomSlide>
                );
              case 'video':
                return (
                  <CustomSlide key={index} size={size}>
                    <StyledVideo src={src} controls />
                  </CustomSlide>
                );
              case 'image':
              default:
                return (
                  <CustomSlide key={index} size={size}>
                    {
                      href && href.trim() !== '' ? (
                        <StyledAnchor href={href} target="_blank" rel="noopener noreferrer">
                          <StyledImg src={src} />
                        </StyledAnchor>
                      ) : (
                        <StyledImg src={src} />
                      )
                    }
                  </CustomSlide>
                );
            }
          })
        }
      </StyledSlider>
    </SliderContainer>
  </RootContainer>
);

BannerView.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  assets: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    type: PropTypes.string,
  })),
};

BannerView.defaultProps = {
  className: '',
  size: 'small',
  assets: [],
};

export default BannerView;
