import { FETCH_PRODUCT_URL } from 'appenv';
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { selectLocale } from './localization';
import { Product } from './types/products';
import getLocalizedProduct from './helpers/getLocalizedProduct';

const getProductsById = async (productId: string): Promise<Product> => {
  const PRODUCT_URL = `${FETCH_PRODUCT_URL}/products/${productId}`;
  const productResponse = await fetch(PRODUCT_URL);
  if (productResponse.status === 200) {
    const product = await productResponse.json();
    delete product.createdOn;
    delete product.lastModifiedOn;
    return product;
  }
  throw new Error('Product is not found');
};

const productsByIdAdapter = createEntityAdapter<Product>();

export const {
  selectById: selectProductById,
} = productsByIdAdapter.getSelectors((state: any) => state.selectedProduct);

export const selectProductByIdAndLocale = createSelector(
  [selectProductById, selectLocale],
  getLocalizedProduct,
);

export const fetchProductsById = createAsyncThunk(
  'products/fetchById',
  async (productId: string) => {
    try {
      const result = await getProductsById(productId);
      return result;
    } catch (error) {
      console.error(error);
    }
    return {} as Product;
  },
);

export const selectedProductSlice = createSlice({
  name: 'selectedProduct',
  initialState: productsByIdAdapter.getInitialState({
    fetching: true,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductsById.pending, (state: any) => {
      state.fetching = true;
      productsByIdAdapter.removeAll(state);
    });
    builder.addCase(fetchProductsById.rejected, (state: any) => {
      state.fetching = false;
    });
    builder.addCase(fetchProductsById.fulfilled, (state: any, action) => {
      state.fetching = false;
      productsByIdAdapter.upsertOne(state, action.payload);
    });
  },
});

export default selectedProductSlice.reducer;
