import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import ModalTitleBar from '../ModalTitleBar';

const BookmarksModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 1200px;
  width: 100%;
  max-height: 620px;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border-radius: 16px;
  padding: 20px;
  
  &:focus{
    outline:none;
  }
`;

const StyledIFrame = styled.iframe`
  height: 100%;
  width: 100%;
`;

const LiveStreamModal = ({ open, onClose, liveStreamLink }) => (
  <Modal open={open} onClose={onClose} style={{ zIndex: 500000 }}>
    <BookmarksModalContainer>
      <ModalTitleBar onClose={onClose}> Live stream </ModalTitleBar>
      <StyledIFrame src={liveStreamLink} title="Live stream" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </BookmarksModalContainer>
  </Modal>
);

LiveStreamModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  liveStreamLink: PropTypes.string.isRequired,
};

export default LiveStreamModal;
