import React, { Suspense, useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import {
  PAGE_NAMES, APP, EVENT_BANNERS, ENABLED_FEATURES,
} from 'appenv';
import { selectBannersByPosition, BannerPosition } from 'models/banners';
import { useSelector } from 'react-redux';
import HallSph706Img1 from 'assets/main_lobby/hall-sph706-1.svg';
import HallSph706Img2 from 'assets/main_lobby/hall-sph706-2.svg';
import HallSph706Img3 from 'assets/main_lobby/hall-sph706-3.svg';
import { envSwitch } from 'utils/envUtils';
import MobileBannerView from '../../MobileBannerView';
import LobbyFooterSwitch from '../../LobbyFooterSwitch';

const RootContainer = styled.div`
  width: 100%;
  height: inherit;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const StyledLinkContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 24px 16px;
  padding-bottom: 49.56%;
  background: ${({ background }) => `url(${background})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #FFF;
  background-size: 100% 80%;
`;

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  overflow: hidden;
`;

const StyledText = styled.div`
  flex: 0 0 auto;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.408px;
  color: #484848;
`;

const FooterWrapper = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-end;
`;

const nonEngRegex = new RegExp(/([A-Za-z0-9$&+,:;=?@#|'<>.^*()%!-\\ ]*)(.*)/);
const getTranslatedName = (name) => (envSwitch([
  ['sph706', () => {
    if (!name) return [name, null];
    const matched = name.match(nonEngRegex);
    return [matched[1].trim(), matched[2].trim()];
  }],
], () => [name, null])());

const MobileLobbyView = () => {
  const [showHallText, showWebinarText] = useMemo(() => [false, true], []);
  const [webinarName, webinarTranslatedName] = useMemo(() => getTranslatedName(PAGE_NAMES.webinar), []);

  const WebinarLink = (() => {
    switch (APP) {
      default: return (
        <StyledLink to={Routes.webinar}>
          {showWebinarText && (
            <>
              <StyledText>
                {webinarName}
              </StyledText>
              {webinarTranslatedName && (
                <StyledText>
                  {webinarTranslatedName}
                </StyledText>
              )}
            </>
          )}
        </StyledLink>
      );
    }
  })();

  const HallLink = (() => {
    switch (APP) {
      default: return (
        <StyledLink to={Routes.hall}>
          {showHallText && (
            <StyledText>
              {PAGE_NAMES.hall}
            </StyledText>
          )}
        </StyledLink>
      );
    }
  })();

  const mobileBanners = useSelector(selectBannersByPosition(BannerPosition.mobile));

  return (
    <RootContainer>
      <MobileBannerView
        assets={ENABLED_FEATURES.cmsBanner && mobileBanners?.length > 0 ? mobileBanners : EVENT_BANNERS.mobile}
      />
      <StyledLinkContainer background={HallSph706Img1}>
        {HallLink}
      </StyledLinkContainer>
      <StyledLinkContainer background={HallSph706Img2}>
        {HallLink}
      </StyledLinkContainer>
      <StyledLinkContainer background={HallSph706Img3}>
        {HallLink}
      </StyledLinkContainer>
      <StyledLinkContainer>
        {WebinarLink}
      </StyledLinkContainer>
      <FooterWrapper>
        <Suspense fallback={null}>
          <LobbyFooterSwitch />
        </Suspense>
      </FooterWrapper>
    </RootContainer>
  );
};

export default MobileLobbyView;
