import React from 'react';
import PropTypes from 'prop-types';
import MainLight from 'assets/booths/main-light.png';
import styled from 'styled-components';
import mainBoard from 'assets/booths/main-board-nolight.png';

const BackgroundContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background: url(${mainBoard});
  background-position: center center;
  background-repeat: no-repeat;
`;

const BoothColorOverlay = styled.div`
  top: 77px;
  position: relative;
  height: calc(100% - 78px);
  display: inline-block;
  width: 202px;
  background-color: ${({ boothColor }) => boothColor};
`;

const BoothColorOverlayLeft = styled(BoothColorOverlay)`
  margin-left: 37px;
`;

const BoothColorOverlayRight = styled(BoothColorOverlay)`
  margin-right: 40px;
`;

const FillerFlex = styled.div`
  display: inline-flex;
  flex-grow: 1;
`;

const LightContainer = styled.img`
  display: block;
  position: relative;
  top: -524px;
  left: 0;
  margin: 0 auto;
`;

const LightBackground618 = ({ boothColor, boothColorRight }) => (
  <>
    <BackgroundContainer>
      <BoothColorOverlayLeft boothColor={boothColor} />
      <FillerFlex />
      <BoothColorOverlayRight boothColor={boothColorRight} />
    </BackgroundContainer>
    <LightContainer src={MainLight} alt="Background Lights" />
  </>
);

LightBackground618.propTypes = {
  boothColor: PropTypes.string,
  boothColorRight: PropTypes.string,
};

LightBackground618.defaultProps = {
  boothColor: undefined,
  boothColorRight: undefined,
};

export default LightBackground618;
