import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import styled from 'styled-components';
import Grow from '@material-ui/core/Grow';
import { useSelector } from 'react-redux';

import { APP, VIDEO_CALL_URL } from 'appenv';
import { selectLocale } from 'models/localization';
import salesImg from 'assets/booths/sales.png';
import maleRight from 'assets/booths/sale-male-right.png';
import maleLeft from 'assets/booths/sale-male-left.png';
import BusinessPopover from 'pages/booths/components/BusinessPopover';
import { Booth } from 'models/cms/booths';
import 'objectFitPolyfill';

const SaleInfoContainer = styled.div`
  position: absolute;
  left: -70px;
  bottom: 480px;
  width: 320px;
`;

const SaleImg = styled.img<{ shouldEnableSalesRepClick: boolean }>`
  height: auto;
  max-height: 480px;
  position: absolute;
  top: 50%;
  cursor: ${({ shouldEnableSalesRepClick }) => (shouldEnableSalesRepClick ? 'pointer' : 'initial')};
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  object-fit: contain;
`;

const BubbleArrow = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  background: #FFF;
  border-radius: 2px;
  box-shadow:
    3px 3px 3px -2px rgba(0, 0, 0, 0.2),
    3px 3px 4px 0 rgba(0, 0, 0, 0.14),
    1px 1px 8px -5px rgba(0, 0, 0, 0.12);
  transform: rotate(45deg) translate(-50%, 8px);
`;

const enableSalesRepClick = [];

export interface BoothSaleContainerProps {
  booth: Booth;
  sale?: string;
}

const BoothSaleContainer: React.FC<BoothSaleContainerProps> = ({
  booth, sale,
}: BoothSaleContainerProps) => {
  const [showRepsInfo, setShowRepsInfo] = useState(false);

  const shouldEnableSalesRepClick = useMemo(() => enableSalesRepClick.includes(APP), []);

  const onRepsClick = useCallback(() => {
    setShowRepsInfo(!showRepsInfo);
  }, [setShowRepsInfo, showRepsInfo]);

  useEffect(() => {
    setTimeout(() => {
      setShowRepsInfo(true);
    }, 500);
  }, []);

  useEffect(() => {
    window.objectFitPolyfill();
  }, []);

  const locale = useSelector(selectLocale);
  const rightRepresentative = useMemo(
    () => booth.designElements.find((it) => it.name === 'right_representative')?.image?.[locale]?.originalImageUrl,
    [booth.designElements, locale],
  );

  const renderedSalesImg = useMemo(() => {
    if (rightRepresentative) return rightRepresentative;
    switch (sale) {
      case 'male-left':
        return maleLeft;
      case 'sales':
        return salesImg;
      default:
        return maleRight;
    }
  }, [sale, rightRepresentative]);

  const tabs = booth?.rootContentElement?.tabs || [];
  const emailContacts = useMemo(
    () => tabs
      .flatMap((it) => it.body)
      // eslint-disable-next-line no-underscore-dangle
      .filter((it) => it.__typename === 'BoothQuestionContentElement' && it?.answer?.contentRichtextHtml?.[locale])
      .map((it) => ({
        type: 'email',
        contact: it.answer.contentRichtextHtml[locale].match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)?.[0],
      }))
      .filter((it) => it.contact),
    [tabs, locale],
  );

  return (
    <>
      <SaleImg
        key={renderedSalesImg}
        src={renderedSalesImg}
        shouldEnableSalesRepClick={shouldEnableSalesRepClick}
        onClick={shouldEnableSalesRepClick ? onRepsClick : undefined}
      />
      <Grow
        in={showRepsInfo}
        timeout={800}
        style={{ transformOrigin: 'bottom' }}
      >
        <SaleInfoContainer>
          <BusinessPopover
            boothId={booth.id}
            boothName={booth.name[locale]}
            emailContacts={emailContacts}
            meetupUrl={booth.meetupEnabled ? booth.appointmentBookingUrl : ''}
            livestreams={booth.livestreams}
            videoCallUrl={booth.videoCallUrl}
          />
          <BubbleArrow />
        </SaleInfoContainer>
      </Grow>
    </>
  );
};

export default BoothSaleContainer;
