import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { UserSessionContext } from 'components/UserSession';
import removeContact from 'network/removeContact';
import ChatContext from 'components/chat/ChatContext';
import UserAvatar from 'components/UserAvatar';
import ChatIcon from 'assets/chat-filled.svg';
import TrashIcon from 'assets/trash.svg';

const StyledIconBtn = styled.div`
  cursor: pointer;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }
`;

const NameCell = styled(TableCell)`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  border: none;
`;

const StyledName = styled.div`
  margin-left: 11px;
`;

const DataCell = styled(TableCell)`
  font-size: 12px;
  border: none;
`;

const Divider = styled(TableCell)`
  background-color: #DBDEE2;
  height: 1px;
  padding: 0;
  border: none;
`;

const ContactListItem = ({
  contactId,
  name,
  role,
  email,
  description,
  createdAt,
}) => {
  const { userSession } = useContext(UserSessionContext);
  const { showChatWindow } = useContext(ChatContext);
  const handleChatOnClick = () => {
    showChatWindow(contactId, 'private', name);
    window.trackingEvent('1on1Chatroom_Enter', 'Business_Contact_List_Click', contactId);
  };

  const formattedDate = moment(createdAt).format('DD MMM YYYY');
  const handleRemoveContact = async () => {
    const userId = userSession?.rongcloudUserId;
    const success = await removeContact(userId, contactId);
  };

  return (
    <>
      <TableRow>
        <NameCell>
          <UserAvatar name={name} />
          <StyledName>{name}</StyledName>
        </NameCell>
        <DataCell>{email}</DataCell>
        <DataCell>{role}</DataCell>
        <DataCell>{description}</DataCell>
        <DataCell>{formattedDate}</DataCell>
        <DataCell>
          <StyledIconBtn onClick={handleChatOnClick}>
            <img src={ChatIcon} alt="chat" />
          </StyledIconBtn>
        </DataCell>
        <DataCell>
          <StyledIconBtn onClick={handleRemoveContact}>
            <img src={TrashIcon} alt="delete" />
          </StyledIconBtn>
        </DataCell>
      </TableRow>
      <TableRow>
        <Divider colSpan={7} />
      </TableRow>
    </>
  );
};

ContactListItem.propTypes = {
  contactId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
};

export default ContactListItem;
