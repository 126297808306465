import { request } from 'graphql-request';
import { API_URL, EVENT_TOKEN } from 'appenv';

const fetchAllParticipantsQuery = `
  query fetchAllParticipants(
    $eventToken: String!,
    $afterCursor: String,
    $keyword: String,
    $limit: Int,
  ) {
    attendeesByEventToken(
      eventToken: $eventToken
      keyword: $keyword
      first: $limit,
      after: $afterCursor
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          name
          customAttendeeFields
          uuid
        }
      }
    }
  }
`;

function fetchAllParticipants(
  afterCursor = '',
  keyword = '',
  limit = 20,
) {
  return request(API_URL, fetchAllParticipantsQuery, {
    eventToken: EVENT_TOKEN,
    afterCursor,
    keyword,
    limit,
  }).then((result) => {
    const { attendeesByEventToken } = result;
    return attendeesByEventToken;
  });
}

export default fetchAllParticipants;
