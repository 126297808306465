export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/554a654b-8b81-4c43-9ec5-9d3ce0e496b8.png',
  ],
  url: {
    exhibition_website: 'https://www.jpfood.jp/en-gb.html',
    next_show: '/booth/M5bLew8XaKhGsVGXcBL4Ff6WJWTLBUjpNMVrNFpQuLD',
  },
  title: '4th "JAPAN’S FOOD" EXPORT FAIR -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/cb832825-9a9a-4c59-810d-5a10f61683d0.png',
  ],
  url: {
    exhibition_website: 'https://www.jpfood.jp/ja-jp.html',
    next_show: '/booth/M5bLew8XaKhGsVGXcBL4Ff6WJWTLBUjpNMVrNFpQuLD',
  },
  title: 'オンライン来場・商談サービス｜第４回 “日本の食品”輸出EXPO',
};
