import styled from 'styled-components';

export const HorizontalDivider = styled.div`
  height: ${(props) => props.thick ?? 1}px;
  background: #DBDEE2;
  margin: 24px 0;
`;

export const VerticalDivider = styled.div`
  width: ${(props) => props.thick ?? 1}px;
  background: #DBDEE2;
  margin: 0 24px;
`;
