export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/8913d56e-e266-4f98-9fe8-cd1637a8e87f.png',
  ],
  url: {
    exhibition_website: 'https://www.fashion-tokyo.jp/en-gb.html',
    next_show: '/booth/bZbMhVqbYPst7fdSsJhGBvhGEzRvUc9M6YNEtzkMHvB1',
  },
  title: 'FASHION WORLD TOKYO 2020 OCTOBER –VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/2fcd6ab2-d1a6-4415-836c-8edd7daf5162.png',
  ],
  url: {
    exhibition_website: 'https://www.fashion-tokyo.jp/ja-jp.html',
    next_show: '/booth/bZbMhVqbYPst7fdSsJhGBvhGEzRvUc9M6YNEtzkMHvB1',
  },
  title: 'オンライン来場・商談サービス｜第11回 ファッションワールド 東京 [秋]',
};
