import React from 'react';
import styled from 'styled-components';
import backgroundSvg from 'assets/booths/cuhk925/background.svg';
import BoothFloor from './BoothFloor';
import BoothPanel from './BoothPanel';
import BoothColor from './BoothColor';

const Container = styled.div`
  width: 90%;
  height: 90%;
  position: absolute;
`;

const BackgroundImg = styled.img`
  width: 100%;
  position: relative;
  top: 40px;
  z-index: 1;
`;

interface BackgroundProps {
  leftColor: string;
  rightColor: string;
}

const BoothBackground: React.FC<BackgroundProps> = ({ leftColor, rightColor }: BackgroundProps) => (
  <Container>
    <BackgroundImg src={backgroundSvg} />
    <BoothColor color={leftColor} site="left" />
    <BoothColor color={rightColor} site="right" />
    <BoothPanel />
    <BoothFloor boothColor="#FDF793" />
  </Container>
);

export default BoothBackground;
