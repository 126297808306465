import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ExportIcon from 'assets/export.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1020px;
  margin: auto;
  padding: 16px 0;
`;

const TitleText = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

const ContactCountText = styled.span`
  color: #999998;
  font-size: 16px;
  margin-left: 16px;
`;

const ExportBtn = styled.div`
  border: 2px solid #DBDEE2;
  border-radius: 2px;
  width: 100px;
  font-size: 14px;
  font-weight: bold;
  color: #484848;
  padding: 6px 17px;
  display: flex;
  align-items: center;
  background-color: #FFF;
  cursor: pointer;
  transition: opacity .2s;

  &:hover {
    opacity: .8;
  }
`;

const StyledIcon = styled.img`
  max-width: 16px;
  max-height: 16px;
  margin-right: 4px;
`;

const ContactsHeader = ({ contactCount, exportContactData }) => (
  <Container>
    <div>
      <TitleText>
        Business Cards
      </TitleText>
      <ContactCountText>
        {`${contactCount} Business Card(s)`}
      </ContactCountText>
    </div>
    <ExportBtn onClick={exportContactData}>
      <StyledIcon src={ExportIcon} alt="export" />
      Export
    </ExportBtn>
  </Container>
);

ContactsHeader.propTypes = {
  contactCount: PropTypes.number.isRequired,
  exportContactData: PropTypes.func.isRequired,
};

export default ContactsHeader;
