enum Apps {
  HKSTP608 = 'hkstp608',
  Jetro817 = 'jetro817',
  Lighthouse710 = 'lighthouse710',
  Ms624 = 'ms624',
  Neoventure1012 = 'neoventure1012',
  Paloalto623 = 'paloalto623',
  PicoX707 = 'picox707',
  PolyU618 = 'polyu618',
  SCMP711 = 'scmp711',
  SPH706 = 'sph706',
  Uzabase826 = 'uzabase826',
  VirtualDemo = 'xtra',
  Sph818 = 'sph818',
  Mig0828 = 'mig0828',
  Emsd831 = 'emsd831',
  VirtualPressCon = 'xtrapresscon',
  XtraCMS = 'xtracms',
  Reed902 = 'reed902',
  ReedMonoN909 = 'reedMonoN909',
  ReedCosme909 = 'reedCosme909',
  ReedUi909 = 'reedUi909',
  ReedSoumn916 = 'reedSoumn916',
  ReedEdix916 = 'reedEdix916',
  Cuhk925 = 'cuhk925',
  ReedSoumn918 = 'reedSoumn918',
  ReedEdix918 = 'reedEdix918',
  Eventage824 = 'eventage824',
  pwc918 = 'pwc918',
  Vfc925 = 'vfc925',
  Bmi923 = 'bmi923',
  ReedMonoK1007 = 'reedMonoK1007',
  ReedMWK1007 = 'reedMWK1007',
  ReedAGRIToolGAR1014 = 'reedAGRIToolGAR1014',
  ReedJPFood1014 = 'reedJPFood1014',
  ReedMJTokyo1014 = 'reedMJTokyo1014',
  ReedNwnAutonRo1021 = 'reedNwnAutonRo1021',
  ReedContentLJ1021 = 'reedContentLJ1021',
  ReedFaAki1027 = 'reedFaAki1027',
  ReedIOFT1027 = 'reedIOFT1027',
  ReedIJTaki1028 = 'reedIJTaki1028',
  ReedAIaki1028 = 'reedAIaki1028',
  ReedITaki1028 = 'reedITaki1028',
  ReedCBW1028 = 'reedCBW1028',
}

export const reedApps = [
  Apps.Reed902,
  Apps.ReedMonoN909,
  Apps.ReedCosme909,
  Apps.ReedUi909,
  Apps.ReedSoumn916,
  Apps.ReedEdix916,
  Apps.ReedMonoK1007,
  Apps.ReedMWK1007,
  Apps.ReedAGRIToolGAR1014,
  Apps.ReedJPFood1014,
  Apps.ReedMJTokyo1014,
  Apps.ReedNwnAutonRo1021,
  Apps.ReedContentLJ1021,
  Apps.ReedFaAki1027,
  Apps.ReedIOFT1027,
  Apps.ReedIJTaki1028,
  Apps.ReedAIaki1028,
  Apps.ReedITaki1028,
  Apps.ReedCBW1028,
];

export const useCachedCMSDataApps = [
  ...reedApps,
];

export default Apps;
