import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import PlayButton from 'assets/play-button.png';

import BoothNameContainer from './BoothNameContainer';
import DescriptionGradientOverlay from './DescriptionGradientOverlay';
import ViewMoreButton from './ViewMoreButton';
import BannerPlayButtonImage from './BannerPlayButtonImage';

const DescriptionContainer = styled.div<{ isDescriptionBanner: boolean }>`
  position: relative;
  line-height: 24px;
  overflow: ${({ isDescriptionBanner }) => (isDescriptionBanner ? 'hidden' : 'auto')};
  height: 100%;
  flex-grow: 1;
  margin-block-start: 0;
  margin-block-end: 0;
  text-align: justify;
  text-overflow: ellipsis;

  & p {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const BannerTextElement = ({
  isDescriptionBanner,
  name,
  boothNumber,
  handleClick,
  description,
  isVideo,
}: {
  name: string;
  boothNumber: string | number;
  handleClick: (type: string) => void;
  description: string;
  isVideo: boolean;
  isDescriptionBanner: boolean;
}) => (
  <>
    {isDescriptionBanner && (
      <BoothNameContainer
        name={name}
        boothNumber={boothNumber}
      />
    )}
    <DescriptionContainer isDescriptionBanner={isDescriptionBanner}>
      {ReactHtmlParser(description)}
      {isDescriptionBanner && (<DescriptionGradientOverlay />)}
      {!isDescriptionBanner && isVideo && (<BannerPlayButtonImage src={PlayButton} />)}
    </DescriptionContainer>
    {isDescriptionBanner && <ViewMoreButton handleClick={handleClick} />}
  </>
);

export default BannerTextElement;
