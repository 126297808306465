import axios from 'axios';
import { FETCH_PRODUCT_URL } from 'appenv';
import { eventToken } from 'network/common/cmsConfig';

const productCategoriesByEventToken = async () => {
  try {
    const categoriesResponse = await axios.get(`${FETCH_PRODUCT_URL}/categories?eventToken=${eventToken}`);
    return categoriesResponse.data;
  } catch (e) {
    return null;
  }
};

export default productCategoriesByEventToken;
