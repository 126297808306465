import React, { useState } from 'react';
import { BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { BoothTaxonomies } from 'models/cms/events';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';
import FilterCheckboxOption from './FilterCheckboxOption';

const RootContainer = styled.div`
  width: 100%;
  padding: 12px 0;
`;

const FilterTitleContainer = styled.div<{ singleLevel?: boolean; selected?: boolean }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ singleLevel }) => (singleLevel ? 'flex-start' : 'space-between')};
  transition: all 0.3s;
  color:
    ${({ selected, singleLevel }) => {
    const selectedColor = BRAND_COLOR || '#0CA58B';
    const notSelectedColor = singleLevel ? '#484848' : '#A2AAB3';
    return selected ? selectedColor : notSelectedColor;
  }};
`;

const FilterTitleText = styled.span`
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  padding: 12px 0;
  display: inline-block;
  text-transform: none;
`;

const StyledCheckbox = withStyles(() => ({
  colorPrimary: {
    color: BRAND_COLOR || '#0CA58B',
  },
  root: {
    '&$checked': {
      color: BRAND_COLOR || '#0CA58B',
    },
  },
  checked: {},
}))(Checkbox);

export interface FilterCategoryContainerProps {
  filter: BoothTaxonomies;
  selectedFilter: string[];
  setSelectedFilter: (updater: string[] | ((filter: string[]) => string[])) => null;
}

const FilterCategoryContainer: React.FC<FilterCategoryContainerProps> = ({
  filter,
  selectedFilter,
  setSelectedFilter,
}: FilterCategoryContainerProps) => {
  const locale = useSelector(selectLocale);
  const [open, setOpen] = useState(true);

  const isSingleLevel = !Array.isArray(filter.subcategories);
  const isSelected = !isSingleLevel || !!selectedFilter.find((it) => it === filter.id);

  const onFilterTitleContainerClick = () => {
    if (isSingleLevel) {
      setSelectedFilter((prev) => {
        const index = prev.findIndex((it) => it === filter.id);
        if (index === -1) return [...Array.from(new Set([...prev, filter.id]))];
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
    }
    setOpen((prev) => !prev);
  };

  return (
    <RootContainer>
      <FilterTitleContainer
        singleLevel={isSingleLevel}
        selected={isSelected}
        onClick={onFilterTitleContainerClick}
      >
        {isSingleLevel && (
          <StyledCheckbox
            checked={isSelected}
            color="primary"
          />
        )}
        <FilterTitleText>
          {filter.name[locale] || Object.values(filter.name).filter((it) => it)[0]}
        </FilterTitleText>
        {!isSingleLevel && (
          open
            ? <RemoveIcon style={{ paddingLeft: '4px' }} />
            : <AddIcon style={{ paddingLeft: '4px' }} />
        )}
      </FilterTitleContainer>
      {!isSingleLevel && (
        <Collapse isOpened={open}>
          {filter.subcategories.map((sub) => (
            <FilterCheckboxOption
              key={sub.id}
              filter={sub}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          ))}
        </Collapse>
      )}
    </RootContainer>
  );
};

export default FilterCategoryContainer;
