import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const SliderRichText = ({ text }: {text: string}) => (
  <RootContainer>
    {ReactHtmlParser(text)}
  </RootContainer>
);

export default SliderRichText;
