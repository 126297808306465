import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { rgbaToRGB } from 'utils/colorUtils';
import BoundingRectChangeDetector from 'components/utils/BoundingRectChangeDetector';

const ShowMoreContainer = styled.div`
  max-height: ${(props) => props.maxHeight};
  overflow: hidden;
  position: relative;
`;

const ShowMoreText = styled.div`
  width: 100%;
  position: ${(props) => (props.isShowMore ? 'unset' : 'absolute')};
  bottom: 0;
  font-size: 16px;
  line-height: 16px;
  padding-top: ${(props) => (props.isShowMore ? '0' : '48px')};
  display: ${(props) => (props.enabled ? 'block' : 'none')};
  color: #5C8DF3;
  cursor: pointer;
  background: ${(props) => (props.isShowMore ? 'unset' : `linear-gradient(rgba(${rgbaToRGB(props.backgroundRGBA)},0) 0%, rgba(${rgbaToRGB(props.backgroundRGBA)},1) 75%, rgba(${rgbaToRGB(props.backgroundRGBA)},1) 100%)`)};
`;

const ShowMore = ({
  className, children, maxHeight, backgroundRGBA,
}) => {
  const { t } = useTranslation();
  const [isOverHeight, setIsOverHeight] = useState();
  const [isShowMore, setIsShowMore] = useState();

  return (
    <ShowMoreContainer className={className} maxHeight={isShowMore ? 'unset' : `${maxHeight}px`}>
      <BoundingRectChangeDetector onChange={(bounding) => setIsOverHeight(bounding.height > maxHeight)} detectPosition={false}>
        {children}
      </BoundingRectChangeDetector>
      <ShowMoreText enabled={isOverHeight} onClick={() => setIsShowMore(!isShowMore)} isShowMore={isShowMore} backgroundRGBA={backgroundRGBA}>
        {isShowMore ? t('show.less') : t('show.more')}
      </ShowMoreText>
    </ShowMoreContainer>
  );
};

ShowMore.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.number.isRequired,
  backgroundRGBA: PropTypes.arrayOf(PropTypes.number),
};

ShowMore.defaultProps = {
  className: '',
  backgroundRGBA: [255, 255, 255, 0.2],
};

export default ShowMore;
