import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const TextContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  color: #484848;
`;

const BoothPlainText = ({ text }) => (
  <TextContainer>
    {ReactHtmlParser(text)}
  </TextContainer>
);

BoothPlainText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default BoothPlainText;
