/* eslint-disable camelcase */
import {
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';

const CHECK_CATEGORY = 'CHECK_CATEGORY';
const UNCHECK_CATEGORY = 'UNCHECK_CATEGORY';
const CLEAR_CATEGORY = 'CLEAR_CATEGORY';

interface CheckedProduct {
  type: 'CHECK_CATEGORY' | 'UNCHECK_CATEGORY' | 'CLEAR_CATEGORY';
  payload?: {
    id: string;
  };
}

const selectedProductsCategoryAdapter = createEntityAdapter<CheckedProduct>();

export const checkCategory = (categoryId: string): CheckedProduct => ({
  type: CHECK_CATEGORY,
  payload: {
    id: categoryId,
  },
});

export const uncheckCategory = (categoryId: string): CheckedProduct => ({
  type: UNCHECK_CATEGORY,
  payload: {
    id: categoryId,
  },
});

export const uncheckAllCategories = (): CheckedProduct => ({
  type: CLEAR_CATEGORY,
});

export const {
  selectAll: selectCheckedProductCategories,
  selectIds: selectCheckedProductIds,
} = selectedProductsCategoryAdapter.getSelectors((state: any) => state.productCategories.selected);

export const selectedProductsCategorySlice = createSlice({
  name: 'selectedProductCategories',
  initialState: selectedProductsCategoryAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(CHECK_CATEGORY, (state: any, action: any) => {
      selectedProductsCategoryAdapter.addOne(state, action.payload);
    });
    builder.addCase(UNCHECK_CATEGORY, (state: any, action: any) => {
      selectedProductsCategoryAdapter.removeOne(state, action.payload.id);
    });
    builder.addCase(CLEAR_CATEGORY, (state: any) => {
      selectedProductsCategoryAdapter.removeAll(state);
    });
  },
});

export default selectedProductsCategorySlice.reducer;
