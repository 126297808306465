import React from 'react';
import styled from 'styled-components';
import EmptyStateImg from 'assets/search-empty-state.svg';
import { useSelector } from 'react-redux';
import { selectTotalBookmarkCount, selectAllBookmarks } from 'models/bookmarks';
import BoothFileRow from 'components/BoothFileRow';
import { selectAllDefaultBookmarks, selectTotalDefaultBookmarks } from 'models/defaultBookmarks';

const NoBookmarkedAttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
`;

const EmptyStateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const EmptyStateText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #484848;
  padding: 24px;
`;

const NoBookmarkedAttachment = () => (
  <NoBookmarkedAttachmentContainer>
    <EmptyStateContainer>
      <div>
        <img src={EmptyStateImg} alt="Coming Soon" />
        <EmptyStateText>
          No content yet :(
        </EmptyStateText>
        Bookmarked files will appear here
      </div>
    </EmptyStateContainer>
  </NoBookmarkedAttachmentContainer>
);

const BookmarkListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: ${({ hasAttachment }) => (hasAttachment ? 'flex-start' : 'center')};
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BookmarkList = () => {
  const newBookmarksTotal = useSelector(selectTotalBookmarkCount);
  const defaultBookmarksTotal = useSelector(selectTotalDefaultBookmarks);
  const newBookmarks = useSelector(selectAllBookmarks);
  const defaultBookmarks = useSelector(selectAllDefaultBookmarks);

  const hasAttachment = !!(newBookmarksTotal + defaultBookmarksTotal);
  return (
    <>
      <BookmarkListContainer hasAttachment={hasAttachment}>
        {hasAttachment ? (
          <>
            {defaultBookmarks.map(({ attachment }) => (
              <BoothFileRow
                key={attachment.id}
                bookmark={{
                  id: attachment.id,
                  // eslint-disable-next-line camelcase
                  filename: attachment?.filename_download,
                  // eslint-disable-next-line camelcase
                  fileUrl: attachment?.data?.full_url,
                }}
                removable
              />
            ))}
            {newBookmarks.map((bookmark) => (
              <BoothFileRow
                key={bookmark.id}
                bookmark={bookmark}
              />
            ))}
          </>
        ) : <NoBookmarkedAttachment />}
      </BookmarkListContainer>
    </>
  );
};

export default BookmarkList;
