export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/98632105-59e2-4748-880c-2d291514a06c.png',
  ],
  url: {
    exhibition_website: 'https://www.japan-mfg-nagoya.jp/en/',
  },
  title: 'Manufacturing World Nagoya 2020 -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/4be63c15-de36-42ad-b5e0-ab6334e64e98.png',
  ],
  url: {
    exhibition_website: 'https://www.japan-mfg-nagoya.jp/',
  },
  title: 'オンライン来場・商談サービス｜第5回 [名古屋] ものづくり ワールド',
};
