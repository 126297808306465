import moment, { MomentInput } from 'moment';

export const getWebinarTimeRange = (startAt: MomentInput, endAt: MomentInput) => {
  const startAtMoment = moment(startAt);
  const endAtMoment = moment(endAt);
  const from = `${startAtMoment.format('hh:mmA')}`;
  const to = `${endAtMoment.format('hh:mmA')}`;
  const length = moment.duration(endAtMoment.diff(startAt)).asMinutes();
  const date = startAtMoment.format('ddd, MMM DD');
  const timezone = `GMT${moment(startAt).format('Z')}`;
  return {
    from, to, length, date, timezone,
  };
};

export const formatWebinarTimeRange = (startAt: MomentInput, endAt: MomentInput) => {
  const {
    from, to, length, date,
  } = getWebinarTimeRange(startAt, endAt);
  return `${from} - ${to} (${length}mins) ${date}`;
};
