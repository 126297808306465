export const isObject = (obj: any): obj is Record<string, any> => typeof obj === 'object' && obj !== null;

export const mapLocalizable = (obj: any) => {
  if (!isObject(obj)) return obj;
  if (Array.isArray(obj)) {
    return obj.map((it) => mapLocalizable(it));
  }
  if (Array.isArray(obj.nodes) && Object.keys(obj).length === 1) {
    return obj.nodes.map((node) => mapLocalizable(node));
  }
  if (Array.isArray(obj.values) && Object.keys(obj).length === 1) {
    const grouped = obj.values
      .reduce((sum: any, { locale, value }: any) => ({ ...sum, [locale]: value }), {}) || {};
    return mapLocalizable(grouped);
  }
  return Object.fromEntries(
    Object.entries(obj)
      .map(([key, value]) => [key, mapLocalizable(value)]),
  );
};
