import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { UserSessionContext } from 'components/UserSession';
import ChatContext from 'components/chat/ChatContext';
import { APP } from 'appenv';

import Routes from 'Routes';
import UserAvatar from 'components/UserAvatar';
import IconContact from 'assets/contact.svg';
import IconLogout from '../icons/Logout';

const UserAccountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 6px;
  max-width: 200px;
`;

const UserAccountButton = withStyles(() => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
  },
}))(Button);

const UserNameContainer = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #484848;
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledBtn = styled.div`
  color: #484848;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const ContactText = styled.span`
  margin-left: 6px;
`;

const LogoutText = styled.span`
  margin-left: 12px;
`;

const StyledContactIcon = styled.img`
  max-width: 24px;
`;

const enableContacts = () => (APP === 'xtra');

const UserAccountView = () => {
  const { userSession, setUserSession } = useContext(UserSessionContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { disconnect } = useContext(ChatContext);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onGoToContacts = () => {
    window.location.href = Routes.contacts;
  };

  const onLogoutClick = () => {
    disconnect();
    setUserSession({});
  };

  return (
    <UserAccountContainer>
      <UserAccountButton onClick={handleClick}>
        <UserNameContainer>
          {t('greeting', { name: userSession.firstName })}
        </UserNameContainer>
        <UserAvatar name={userSession.firstName} />
      </UserAccountButton>
      <Menu
        id="user-settings-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {
          enableContacts() && (
            <MenuItem onClick={onGoToContacts}>
              <StyledBtn>
                <StyledContactIcon alt="my contact" src={IconContact} />
                <ContactText>
                  My Contacts
                </ContactText>
              </StyledBtn>
            </MenuItem>
          )
        }
        <MenuItem onClick={onLogoutClick}>
          <StyledBtn>
            <IconLogout color="#484848" />
            <LogoutText>
              {t('logout')}
            </LogoutText>
          </StyledBtn>
        </MenuItem>
      </Menu>
    </UserAccountContainer>
  );
};

export default UserAccountView;
