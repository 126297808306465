import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const WebinarTrackTagContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 2px 10px;
  border-radius: 2px;
  display: inline-block;
  margin-top: 18px;
  color: ${(props) => props.trackColor};

  &::before {
    position: absolute;
    content: '';
    background: ${(props) => props.trackColor};
    width: 100%;
    height: 100%;
    opacity: 0.2;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    content: '';
    border-radius: 2px;
    width: 2px;
    background: ${(props) => props.trackColor};
  }
`;
const locale = 'en';

const WebinarTrackTag = ({ track }) => (
  <WebinarTrackTagContainer trackColor={track.color}>
    {track.translations[locale]?.name}
  </WebinarTrackTagContainer>
);

WebinarTrackTag.propTypes = {
  track: PropTypes.shape().isRequired,
};

export default WebinarTrackTag;
