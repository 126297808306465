import React, {
  useEffect, useCallback, useContext, useState,
} from 'react';
import { ENABLED_FEATURES, RONGCLOUD_CHATROOM_PREFIX } from 'appenv';
import styled from 'styled-components';
import 'objectFitPolyfill';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';
import { LocalizedProduct } from 'models/types/products';
import { selectLocale } from 'models/localization';
import { useSelector } from 'react-redux';
import { selectExhibitorById } from 'models/exhibitors';
import { selectBoothById } from 'models/cms/booths';
import ChatroomIcon from 'components/icons/Chatroom';
import CompanyLink from 'components/product/CompanyLink';
import CompanyName from 'components/product/CompanyName';
import ChatContext from 'components/chat/ChatContext';

const ChatLink = styled(CompanyLink)`
  margin: 16px 0 8px;

  & svg {
    height: 24px;
  }
`;

const ProductItemContainer = styled(Link)`
  min-height: 300px;
  width: 330px;
  padding: 16px;
  margin: 8px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #FFF;
  align-self: initial;
  text-decoration: none;
  color: inherit;

  @media (max-width: 360px) {
    max-width: 100%;
  }

  @media (min-width: 960px) {
    background-color: rgba(255, 255, 255, 0.9);
    transition: background-color 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
  }
`;

const ProductImageContainer = styled.div`
  position: relative;
  height: 0;
  padding-top: 100%;
`;

const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
`;

const ProductTagsContainer = styled.div`
  width: 100%;
`;

declare global {
  interface Window {
    objectFitPolyfill: () => unknown;
  }
}

const ProductTag = styled.div`
  border-radius: 8px;
  margin: 4px;
  font-size: 0.75em;
  color: #484848;
  height: 20px;
  width: 100%;
  line-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
`;

const ProductSpacer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const NoImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
`;

const ProductItemTags = ({ tags }: { tags: string[] }) => (
  <ProductTagsContainer>
    <ProductTag>
      {ReactHtmlParser(tags.map((tag) => `#${tag}`).join(' &nbsp;&nbsp;'))}
    </ProductTag>
  </ProductTagsContainer>
);

const selectById: (state: any, id: any) => any = ENABLED_FEATURES.xtraCMS
  ? selectBoothById
  : selectExhibitorById;

const ProductItem = ({
  product: {
    id,
    image,
    name,
    boothId,
    tags = [],
    oneLiner,
  },
  className,
  hideChatLink = false,
}: {
  product: LocalizedProduct;
  className?: string;
  hideChatLink?: boolean;
}) => {
  useEffect(() => {
    window.objectFitPolyfill();
  });
  const [errorLoading, setErrorLoading] = useState(false);
  const locale = useSelector(selectLocale);
  const booth = useSelector((state) => selectById(state, boothId)) || {};

  const { showChatWindow } = useContext(ChatContext);
  const localizedBoothName = ENABLED_FEATURES.xtraCMS ? (booth.name || {})[locale] : (booth.translations || {})[locale]?.name;
  const openChatWindow = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    showChatWindow(`${RONGCLOUD_CHATROOM_PREFIX}_booth_${booth.id}`, 'group', localizedBoothName);
  }, [booth.id, showChatWindow, localizedBoothName]);
  const setImageError = useCallback(() => setErrorLoading(true), []);

  return (
    <ProductItemContainer to={`/products/${id}`} className={className}>
      <ProductImageContainer>
        {(!errorLoading || !image?.originalImageUrl) ? (
          <ProductImage
            src={image?.originalImageUrl}
            data-object-fit="contain"
            data-object-position="center"
            onError={setImageError}
          />
        ) : (
          <NoImageContainer />
        )}
      </ProductImageContainer>
      <h4>{name}</h4>
      <div>{ReactHtmlParser(oneLiner)}</div>
      <ProductSpacer />
      <ProductItemTags tags={tags} />
      {!hideChatLink && localizedBoothName && (
        <ChatLink href="#" onClick={openChatWindow}>
          <ChatroomIcon />
          <CompanyName>{localizedBoothName}</CompanyName>
        </ChatLink>
      )}
    </ProductItemContainer>
  );
};

export default ProductItem;
