import React from 'react';
import PropTypes from 'prop-types';

import BamBooBoothImg from 'assets/booths/ms624/bamboo.png';
import CherryPicksImg from 'assets/booths/ms624/cherrypicjs.png';
import HkrfidImg from 'assets/booths/ms624/hkrfid.png';
import MadGazeImg from 'assets/booths/ms624/Mad Gaze.png';
import RacefitImg from 'assets/booths/ms624/racefit.png';
import RibImg from 'assets/booths/ms624/rib.png';
import WebOnAsiaImg from 'assets/booths/ms624/Web-on Asia.png';

import BoothTemplate from './BoothTemplate';

export const VideoSources = [
  'https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/59f664a4-0dd4-4f64-a61a-808dd890b24a.mp4',
  'https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/563dbaf2-a621-42f2-8017-439aa17d1b40.mp4',
  'https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/049d2112-2f91-4798-be71-f19b015b99cd.mp4',
  'https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/8ef1d5a7-569c-40e5-a372-2f49802f2056.mp4',
  'https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/295f794c-691b-4e8e-9cd1-27bc86eb8f7d.mp4',
  'https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/e2402671-9681-4e7b-9a16-58f574034b47.mp4',
  'https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/3f267e7b-dcf0-47ec-a915-6239a94233ee.mp4',
];

const Booth624Template = ({ booth, showRepsImg, showPlayBtn }) => {
  switch (booth.boothNumber) {
    case '1':
      return (
        <BoothTemplate
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
          videoSrc="https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/59f664a4-0dd4-4f64-a61a-808dd890b24a.mp4"
          top={226}
          left={335}
          boothImg={BamBooBoothImg}
        />
      );
    case '2':
      return (
        <BoothTemplate
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
          videoSrc="https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/563dbaf2-a621-42f2-8017-439aa17d1b40.mp4"
          top={205}
          left={285}
          boothImg={CherryPicksImg}
        />
      );
    case '3':
      return (
        <BoothTemplate
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
          videoSrc="https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/049d2112-2f91-4798-be71-f19b015b99cd.mp4"
          top={220}
          left={245}
          boothImg={HkrfidImg}
        />
      );
    case '4':
      return (
        <BoothTemplate
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
          videoSrc="https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/8ef1d5a7-569c-40e5-a372-2f49802f2056.mp4"
          top={155}
          left={275}
          boothImg={MadGazeImg}
        />
      );
    case '5':
      return (
        <BoothTemplate
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
          videoSrc="https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/295f794c-691b-4e8e-9cd1-27bc86eb8f7d.mp4"
          top={222}
          left={292}
          boothImg={RacefitImg}
        />
      );
    case '6':
      return (
        <BoothTemplate
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
          videoSrc="https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/e2402671-9681-4e7b-9a16-58f574034b47.mp4"
          top={226}
          left={294}
          boothImg={RibImg}
        />
      );
    case '7':
      return (
        <BoothTemplate
          showRepsImg={showRepsImg}
          showPlayBtn={showPlayBtn}
          videoSrc="https://contents-virtual.eventxtra.com/uploads/msmakerspace_staging/originals/3f267e7b-dcf0-47ec-a915-6239a94233ee.mp4"
          top={125}
          left={410}
          boothImg={WebOnAsiaImg}
        />
      );
    default:
      return null;
  }
};

Booth624Template.propTypes = {
  booth: PropTypes.shape().isRequired,
  showRepsImg: PropTypes.bool,
  showPlayBtn: PropTypes.bool,
};

Booth624Template.defaultProps = {
  showRepsImg: false,
  showPlayBtn: false,
};

export default Booth624Template;
