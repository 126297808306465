import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  EVENT_NAME,
  PAGE_NAMES,
  BRAND_COLOR,
  MULTITRACKS_IDS,
  APP,
} from 'appenv';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  selectAllLiveWebinars,
  selectAllOnDemandWebinars,
  selectIsWebinarsLoading,
} from 'models/webinars';
import Routes from 'Routes';
import TimeZoneSwitch from 'components/TimeZoneSwitch';
import { useTranslation } from 'react-i18next';

import LiveListContainer from 'pages/webinar/live/LiveListContainer';
import { BREAKPOINT } from 'pages/webinar/constants';
import { envSwitch } from 'utils/envUtils';
import LiveWebinarTracksList from 'pages/webinar/live/LiveWebinarTracksList';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import Apps, { reedApps } from 'apps';
import OnDemandListContainer from './OnDemandListContainer';

const RootContainer = styled.div`
  height: 100%;
  overflow: hidden;

  @media (min-width: ${BREAKPOINT}px) {
    background-color: #FFF;
  }

  @media (max-width: ${BREAKPOINT}px) {
    background-color: #FFF;
  }
`;

const BreadcrumbTimeZoneContainer = styled.div`
  display: block;
`;

const PageWrapper = styled.div`
  position: relative;
  min-width: 100%;
  height: 100%;
  overflow: auto;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch;

  @media (max-width: ${BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const PageContentContainer = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledTabsContainer = withStyles(() => ({
  root: {
    flexShrink: 0,
    [`@media (max-width: ${BREAKPOINT}px)`]: {
      backgroundColor: '#FFF',
    },
  },
  flexContainer: {
    borderBottom: '2px solid #DBDEE2',
  },
  indicator: {
    backgroundColor: BRAND_COLOR || '#0CA58B',
    height: '4px',
  },
}))(Tabs);

const StyledTab = withStyles(() => ({
  root: {
    color: '#A2AAB3',
    fontSize: '18px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
}))(Tab);

const StyledBreadcrumbs = styled(XtraBreadcrumbs)`
  @media (max-width: ${BREAKPOINT}px) {
    max-width: 100vw;
    width: auto;
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;

  @supports (-webkit-touch-callout: none) {
    overflow: initial;
  }

  @media (min-width: ${BREAKPOINT}px) {
    height: 100%;
    padding-top: ${(props) => (props.reserveBreadcrumbSpace ? '48px' : '0')};
  }
`;


const MobileTimeZoneSwitch = styled(TimeZoneSwitch)`
  padding: 4px 24px;
  display: flex;
  flex-direction: row-reverse;

  @media (min-width: 960px) {
    display: none;
  }
`;

const BreadcrumbPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
`;

const WebinarPageContainer = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const history = useHistory();
  const liveWebinars = useSelector(selectAllLiveWebinars());
  const onDemandWebinars = useSelector(selectAllOnDemandWebinars());
  const isLoading = useSelector(selectIsWebinarsLoading);

  useEffect(() => {
    if (history.action === 'POP') {
      const lastTab = localStorage.getItem('lastWebinarTab');
      if (lastTab) {
        setActiveTab(parseInt(lastTab, 10));
      }
    } else {
      localStorage.setItem('lastWebinarTab', 0);
    }
  }, [history]);

  const onTabClick = (_, selectedTab) => {
    setActiveTab(selectedTab);
    localStorage.setItem('lastWebinarTab', selectedTab);
  };

  useEffect(() => {
    window.trackingEvent('MainMenu_Webinar', 'Enter', '');
  }, []);

  /**
   * @type {LiveWebinarListOptions}
   */
  const options = {
    multiTracks: {
      enable: MULTITRACKS_IDS.length !== 0,
      multiTracksId: MULTITRACKS_IDS,
    },
    timeLine: {
      enable: envSwitch([
        ['paloalto623', false],
        ['lighthouse710', false],
        [Apps.Mig0828, false],
      ], true),

      intervalMinute: envSwitch([
        [[Apps.Paloalto623, Apps.PicoX707, Apps.Mig0828, Apps.Eventage824], 15],
        [[Apps.SCMP711], 30],
      ], 20),
    },
    daySelector: {
      enable: envSwitch([
        ['ms624', false],
      ], true),
    },
    webinarTabs: envSwitch([
      ['paloalto623', ['live', 'onDemand']],
    ], ['live', 'onDemand']),
  };

  const tabs = Object.entries({
    live: {
      title: t('webinar.time_framed', 'Live'),
      element: (<LiveListContainer webinars={liveWebinars} isLoading={isLoading} options={options} />),
      show: liveWebinars.length > 0,
    },
    onDemand: {
      title: t('webinar.on_demand', 'On Demand'),
      element: (<OnDemandListContainer webinars={onDemandWebinars} isLoading={isLoading} />),
      show: onDemandWebinars.length > 0,
    },
  })
    .filter(([key, value]) => options.webinarTabs.indexOf(key) !== -1 && value.show)
    .map(([, value]) => value);

  const showTabSwitcher = tabs.length > 1;
  const hideBreadcrumbsOverride = useMemo(() => [Apps.SCMP711].includes(APP), []);

  const pageTitle = PAGE_NAMES.webinar || t('page.webinar', 'Webinars');
  const eventName = useMemo(() => {
    if (reedApps.includes(APP)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  return (
    <RootContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | ${eventName}`}</title>
        <meta name="description" content={`${pageTitle} page of ${eventName}.`} />
      </Helmet>
      <PageWrapper>
        {options.multiTracks.enable && <LiveWebinarTracksList multiTrackOptions={options.multiTracks} />}
        <BreadcrumbPageContainer>
          <BreadcrumbTimeZoneContainer>
            <StyledBreadcrumbs
              paths={[{ name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby }, { name: PAGE_NAMES.webinar || t('page.webinar', 'Webinars') }]}
            />
            <MobileTimeZoneSwitch />
          </BreadcrumbTimeZoneContainer>
          <MainContainer reserveBreadcrumbSpace={!hideBreadcrumbsOverride || (showTabSwitcher && !options.multiTracks.enable)}>
            {showTabSwitcher && (
              <StyledTabsContainer value={activeTab} onChange={onTabClick} centered>
                {tabs.map((tab) => <StyledTab key={tab.title} label={tab.title} />)}
              </StyledTabsContainer>
            )}
            <PageContentContainer>
              {tabs.map((tab) => tab.element)[activeTab]}
            </PageContentContainer>
          </MainContainer>
        </BreadcrumbPageContainer>
      </PageWrapper>
    </RootContainer>
  );
};

export default WebinarPageContainer;
