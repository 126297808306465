import { EVENT_TOKEN, API_URL } from 'appenv';
import Apps, { reedApps } from 'apps';
import { envSwitch } from 'utils/envUtils';

export const apiUrl = envSwitch([
  [[Apps.Jetro817, Apps.Mig0828, Apps.Uzabase826, Apps.Eventage824, ...reedApps], 'https://api-virtual.eventxtra.com/graphql'],
], API_URL);

export const eventToken = envSwitch([
  [Apps.Jetro817, 'gbtyes6dGZHmhdKTiu4RYxyw'],
  [Apps.Mig0828, 'KKMnYc8zU2UTkxrBGptpCWyk'],
  [Apps.Reed902, 'P5fqMS24CCsFk8sZ4HhdJQCV'],
  [Apps.ReedMonoN909, 'mCMz7BsfwgRiB8b88usCGQFZ'],
  [Apps.ReedCosme909, 'sjeTxYwcdp3xv6tDPhdkitT9'],
  [Apps.ReedUi909, 'UuLkUt9zW4TFagEQXLThA6oz'],
  [Apps.ReedSoumn916, 'MAdSPZaKXxrP9h86ukDxfWmd'],
  [Apps.ReedEdix916, '6Rx958Uc8DNH5vtqJYrZsW4H'],
  [Apps.Uzabase826, '4vBLu6V8A6hg2dacHXpsh225'],
  [Apps.Emsd831, '8xa5oDrcinQeyTBzXXJNfjmp'],
  [Apps.Eventage824, 'uHSX3LEfwBWj3GCg2fqcP1dr'],
  [Apps.ReedMonoK1007, 'FqfDA7YPknCpeQFFMuQFotR2'],
  [Apps.ReedMWK1007, 'HuhBC5XrnmePtj72YX5QEKzK'],
  [Apps.ReedAGRIToolGAR1014, 'Ga9LkUBeMnPcTGFLAJ6RZCH2'],
  [Apps.ReedJPFood1014, 'QsF6jwd5eAXYt4VEvszgufgm'],
  [Apps.ReedMJTokyo1014, '9APqoZSFf17GeEDCdbqo1qio'],
  [Apps.ReedNwnAutonRo1021, 'b8AaFEFX76vLzaQeSer2VF8M'],
  [Apps.ReedContentLJ1021, 'dXMpBLC2J9nG89tLWHRGG8PF'],
  [Apps.ReedFaAki1027, 'SXPzvYmcgMFYxudjrsVxvH5Z'],
  [Apps.ReedIOFT1027, 'FJTJYrNbUpSH6nKcAQsvgLyw'],
  [Apps.ReedIJTaki1028, 'BdtXsS5LWuN2yxJxamBxWcPp'],
  [Apps.ReedAIaki1028, 'tJTrqrQhmES33eEh7acpBiwb'],
  [Apps.ReedITaki1028, 'urcgd8SYkggbxc2QwDU33YDe'],
  [Apps.ReedCBW1028, 'ZzFx3iM2jLQfYDFvnR8LfiD5'],
], EVENT_TOKEN);
