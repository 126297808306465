import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Moment } from 'moment-timezone';
import { BREAKPOINT } from 'pages/webinar/constants';
import { BRAND_COLOR } from 'appenv';
import { selectWebinarTracksMap, Webinar } from 'models/webinars';
import { useSelector } from 'react-redux';
import LiveWebinarTracksGroupContainer, { LiveWebinarTracksGroup } from 'pages/webinar/live/item/LiveWebinarTracksGroupContainer';
import { LiveWebinarListOptions } from 'pages/webinar/live/LiveWebinarListOptions';
import { selectMomentLocalizer } from 'models/localization';

export interface LiveWebinarTimeGroup {
  timeMark: Moment;
  webinars: Webinar[];
}

const TimelineIndicatorContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 5;
  position: absolute;
  font-weight: bold;
  color: ${BRAND_COLOR || '#0CA58B'};

  @media (min-width: ${BREAKPOINT}px) {
    width: 120px;
    margin-top: 30px;
  }

  @media (max-width: ${BREAKPOINT}px) {
    top: 5px;
    margin-left: 10px;
  }
`;

const TimelineIndicatorText = styled.div`
  flex: 0 0 auto;
  text-align: center;

  @media (min-width: ${BREAKPOINT}px) {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.408px;
    color: ${BRAND_COLOR || '#0CA58B'};
  }

  @media (max-width: ${BREAKPOINT}px) {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #282B40;
  }
`;

const TimelineIndicatorIcon = styled.div`
  background-color: ${BRAND_COLOR || '#0CA58B'};
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-left: 12px;

  @media (max-width: ${BREAKPOINT}px) {
    display: none;
  }
`;

const RootContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINT}px) {
    padding-top: 28px;
    flex-direction: column;
  }
`;

const TrackGroupsContainer = styled.div`
  flex: 1 1 auto;
  padding-left: 75px;
  padding-right: 75px;
  width: 0;
  background: #FFF;

  @media (max-width: ${BREAKPOINT}px) {
    padding: 10px;
    width: 100%;
  }
`;


const TimeLineComponent = ({ timeGroup }: { timeGroup: LiveWebinarTimeGroup }) => {
  const momentLocalizer = useSelector(selectMomentLocalizer);
  return (
    <>
      <TimelineIndicatorText />
      {momentLocalizer(timeGroup.timeMark).format('h:mmA')}
      <TimelineIndicatorIcon />
    </>
  );
};

const LiveWebinarTimeGroupContainer = ({ timeGroup, options }: { timeGroup: LiveWebinarTimeGroup; options: LiveWebinarListOptions }) => {
  const webinarTracksMap = useSelector(selectWebinarTracksMap());

  const { multiTracksId } = options.multiTracks;

  const tracksGroups: LiveWebinarTracksGroup[] = useMemo(() => {
    const ungrouped: LiveWebinarTracksGroup[] = timeGroup.webinars
      .filter((webinar) => multiTracksId.indexOf(webinar.track?.id) === -1)
      .map((webinar) => ({
        tracks: [webinarTracksMap[webinar.track?.id]],
        trackIdWebinarMap: { [webinar.track?.id]: webinar },
      }));

    const multiTrackGrouped: LiveWebinarTracksGroup = {
      tracks: Object.values(webinarTracksMap).filter((track) => multiTracksId.indexOf(track.id) !== -1),
      trackIdWebinarMap: timeGroup.webinars
        .filter((webinar) => multiTracksId.indexOf(webinar.track?.id) !== -1)
        .reduce((trackIdWebinarMap, webinar) => {
          trackIdWebinarMap[webinar.track.id] = webinar;
          return trackIdWebinarMap;
        }, {}),
    };

    return [...ungrouped, ...(Object.keys(multiTrackGrouped.trackIdWebinarMap).length === 0 ? [] : [multiTrackGrouped])];
  }, [multiTracksId, timeGroup.webinars, webinarTracksMap]);

  return (
    <RootContainer>
      <TimelineIndicatorContainer>
        {options.timeLine.enable && <TimeLineComponent timeGroup={timeGroup} />}
      </TimelineIndicatorContainer>
      <TrackGroupsContainer>
        {
          tracksGroups.map((group) => (
            <LiveWebinarTracksGroupContainer
              key={Object.values(group.trackIdWebinarMap).map((it) => it.id).join(',')}
              tracksGroup={group}
              options={options}
            />
          ))
        }
      </TrackGroupsContainer>
    </RootContainer>
  );
};


export default LiveWebinarTimeGroupContainer;
