import React, {
  useState,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { UserSessionContext } from '../UserSession';
import ChatMessageInputField from './ChatMessageInputField';
import ChatWindowFilePreview from './ChatWindowFilePreview';

const ChatWindowFooter = ({
  joiningChat,
  sendMessage,
  setAlertOpen,
}) => {
  const { userSession } = useContext(UserSessionContext);
  const [file, setFile] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const fileInputRef = useRef();
  const { type } = useSelector((state) => state.chatTarget);

  const hasFile = Boolean(fileInputRef?.current?.value);

  const onFileCancel = () => {
    setFile({});
    setHasError(false);
    fileInputRef.current.value = null;
  };

  const canSendMessage = () => {
    const customAttendeeFields = userSession.customAttendeeFields || {};
    return type !== 'system' || Boolean(customAttendeeFields.vexpo_organizer);
  };

  return (
    <>
      {
        canSendMessage() && (
          <ChatMessageInputField
            joiningChat={joiningChat}
            sendMessage={sendMessage}
            setAlertOpen={setAlertOpen}
            file={file}
            setFile={setFile}
            hasFile={hasFile}
            fileInputRef={fileInputRef}
            setIsUploading={setIsUploading}
            setHasError={setHasError}
          />
        )
      }
      {
        hasFile && (
          <ChatWindowFilePreview
            fileName={file.name}
            fileSize={file.size}
            onFileCancel={onFileCancel}
            isUploading={isUploading}
            hasError={hasError}
          />
        )
      }
    </>
  );
};

ChatWindowFooter.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  setAlertOpen: PropTypes.func.isRequired,
};

export default ChatWindowFooter;
