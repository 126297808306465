import React from 'react';
import ReduxLocaleObserver from 'i18n/ReduxLocaleObserver';

const ReduxObserver = () => (
  <>
    <ReduxLocaleObserver />
  </>
);

export default ReduxObserver;
