import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMomentLocalizer } from 'models/localization';
import Routes from 'Routes';
import { WebinarType } from 'models/webinars';
import { SearchFieldContext } from 'components/SearchFieldContext';
import TextHighlighter from 'components/TextHighlighter';
import WebinarIcon from 'assets/icon-webinar.svg';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #17181E;
  background-color: #FFF;
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '54px';
      case 'standard':
      default:
        return '90px';
    }
  }};
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
  max-height:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '32px';
      case 'standard':
      default:
        return '64px';
    }
  }};
  max-width:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '32px';
      case 'standard':
      default:
        return '64px';
    }
  }};
`;

const InfoContainer = styled.div`
  margin-left: 16px;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
`;

const NameContainer = styled.div`
  flex: 1 0 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #262626;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DateTimeContainer = styled.div`
  flex: 1 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #242424;
  margin-top: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const WebinarSearchResultItem = ({
  type,
  id,
  name,
  startAt,
  endAt,
  variant,
}) => {
  const { searchKeyword } = useContext(SearchFieldContext);
  const momentLocalizer = useSelector(selectMomentLocalizer);

  switch (type) {
    case WebinarType.Live: {
      const startTime = momentLocalizer(startAt);
      const endTime = momentLocalizer(endAt);
      const timeZoneOffset = `GMT${momentLocalizer(startAt).format('Z')}`;

      return (
        <StyledLink to={Routes.webinarSession(id)} variant={variant}>
          <StyledImg src={WebinarIcon} variant={variant} />
          <InfoContainer>
            <NameContainer>
              <TextHighlighter
                searchWords={[searchKeyword]}
                textToHighlight={name}
              />
            </NameContainer>
            <DateTimeContainer>
              { `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}, ${startTime.format('MMM DD')} (${timeZoneOffset})` }
            </DateTimeContainer>
          </InfoContainer>
        </StyledLink>
      );
    }
    case WebinarType.OnDemand:
    default: {
      return (
        <StyledLink to={Routes.webinarSession(id)} variant={variant}>
          <StyledImg src={WebinarIcon} variant={variant} />
          <InfoContainer>
            <NameContainer>
              <TextHighlighter
                searchWords={[searchKeyword]}
                textToHighlight={name}
              />
            </NameContainer>
          </InfoContainer>
        </StyledLink>
      );
    }
  }
};

WebinarSearchResultItem.propTypes = {
  type: PropTypes.oneOf(Object.values(WebinarType)),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  startAt: PropTypes.string,
  endAt: PropTypes.string,
  timezone: PropTypes.string,
  variant: PropTypes.oneOf([
    'compact',
    'standard',
  ]),
};

WebinarSearchResultItem.defaultProps = {
  startAt: undefined,
  endAt: undefined,
  timezone: undefined,
  variant: 'standard',
};

export default WebinarSearchResultItem;
