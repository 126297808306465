import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const videoCallInvitationAcceptMutation = `
mutation VideoCallParticipantAccept($participantId: String!, $roomToken: String!) {
  videoCallParticipantAccept(input: {
    participantId: $participantId,
    roomToken: $roomToken
  }) {
    success
    errors {
      message
      type
      path
    }
  }
}

`;

const AcceptVideoCallInvitation = (participantId: string, roomToken: string): Promise<any> => request(
  API_URL,
  videoCallInvitationAcceptMutation,
  {
    participantId,
    roomToken,
  },
).then(({ videoCallParticipantAccept }) => videoCallParticipantAccept);

export default AcceptVideoCallInvitation;
