import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import productsByBoothId from 'network/productsByBoothId';
import { Product, LocalizedProduct } from 'models/types/products';
import getLocalizedProduct from 'models/helpers/getLocalizedProduct';
import { selectLocale } from './localization';

const boothProductsAdapter = createEntityAdapter<Product>({
  sortComparer: (a, b) => (a.sort || 0) - (b.sort || 0),
});
export const {
  selectAll: selectAllProducts,
} = boothProductsAdapter.getSelectors((state: any) => state.boothProducts);

export const fetchBoothProducts = createAsyncThunk(
  'products/fetchBoothProducts',
  async (boothId: string) => {
    try {
      const result = await productsByBoothId(boothId);
      return result;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const selectBoothProductsByLocale = createSelector(
  [selectAllProducts, selectLocale],
  (products: Product[], locale: string) => products.map(
    (product) => getLocalizedProduct(product, locale) as LocalizedProduct,
  ),
);

export const boothProductSlice = createSlice({
  name: 'boothProducts',
  initialState: boothProductsAdapter.getInitialState({ fetching: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBoothProducts.pending, (state: any) => {
      boothProductsAdapter.removeAll(state);
      state.fetching = true;
    });
    builder.addCase(fetchBoothProducts.rejected, (state: any) => {
      state.fetching = false;
    });
    builder.addCase(fetchBoothProducts.fulfilled, (state: any, action) => {
      state.fetching = false;
      boothProductsAdapter.upsertMany(state, action.payload);
    });
  },
});

export default boothProductSlice.reducer;
