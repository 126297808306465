import { request } from 'graphql-request';
import { API_URL, EVENT_TOKEN } from 'appenv';

const attendeeByUniqueTokenQuery = `
  query attendeeByUniqueToken($eventToken: String!, $uniqueToken: String!) {
    attendeeByUniqueToken(
      eventToken: $eventToken,
      uniqueToken: $uniqueToken
    ) {
      name
      firstName
      lastName
      email
      tickets {
        nodes {
          id
          name
        }
      }
      rongcloudToken
      customAttendeeFields
      rongcloudUserId
    }
  }
`;

function attendeeByUniqueToken(attendeeUniqueToken) {
  return request(API_URL, attendeeByUniqueTokenQuery, {
    eventToken: EVENT_TOKEN,
    uniqueToken: attendeeUniqueToken,
  }).then((result) => {
    const attendee = result.attendeeByUniqueToken;
    attendee.ticket = attendee?.tickets?.nodes?.[0];
    delete attendee.tickets;
    return attendee;
  });
}

export default attendeeByUniqueToken;
