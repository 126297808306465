import { en as enCommon, ja as jaCommon } from './common';
import {
  en as enLobby, ja as jaLobby, zhHans as zhHansLobby, zhHant as zhHantLobby,
} from './lobby';
import { en as enLanding, ja as jaLanding } from './landing';

export default {
  en: {
    common: enCommon,
    lobby: enLobby,
    landing: enLanding,
  },
  ja: {
    common: jaCommon,
    lobby: jaLobby,
    landing: jaLanding,
  },
  'zh-Hans': {
    lobby: zhHansLobby,
  },
  'zh-Hant': {
    lobby: zhHantLobby,
  },
};
