import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import BookmarkIcon from '@material-ui/icons/BookmarkBorder';

const RootContainer = styled.div`
  flex: 0 0 auto;
`;

const BookmarksButton = ({ openBookmarks }) => (
  <RootContainer>
    <IconButton color="inherit" aria-label="bookmarks" onClick={openBookmarks}>
      <BookmarkIcon />
    </IconButton>
  </RootContainer>
);

BookmarksButton.propTypes = {
  openBookmarks: PropTypes.func.isRequired,
};

export default BookmarksButton;
