import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import playButtonImg from 'assets/play-button.png';

import BoothTemplateContainer from './BoothTemplateContainer';
import VideoModal from './VideoModal';

const BoothImgContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
`;

const BoothImg = styled.img`
  width: auto;
  max-height: 100%;
`;

const PlayButton = styled.img`
  position: absolute;
  top: ${({ top }) => (`${top}px`)};
  left: ${({ left }) => (`${left}px`)};
  cursor: pointer;
`;

const BoothTemplate = ({
  videoSrc,
  showRepsImg,
  showPlayBtn,
  top,
  left,
  boothImg,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <BoothTemplateContainer
      boothComponent={(
        <BoothImgContainer>
          <BoothImg src={boothImg} />
          {showPlayBtn
            && (
            <>
              <PlayButton top={top} left={left} src={playButtonImg} onClick={() => { setIsOpen(!isOpen); }} />
              <VideoModal isOpen={isOpen} src={videoSrc} onClose={() => { setIsOpen(false); }} />
            </>
            )}
        </BoothImgContainer>
      )}
      showRepsImg={showRepsImg}
    />
  );
};

BoothTemplate.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  showRepsImg: PropTypes.bool,
  showPlayBtn: PropTypes.bool,
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  boothImg: PropTypes.string.isRequired,
};

BoothTemplate.defaultProps = {
  showRepsImg: false,
  showPlayBtn: false,
};

export default BoothTemplate;
