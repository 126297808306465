import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    maxWidth: '100%',
    margin: '0 24px',
    letterSpacing: '0.32px',
    color: '#484848',
  },
  text: {
    lineHeight: '17px',
    whiteSpace: 'break-spaces',
    textAlign: 'justify',
    '& strong': {
      fontFamily: 'Lato',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '14px',
      color: '#A2AAB3',
      marginBottom: '11px',
    },
    '& a': {
      lineHeight: '17px',
      whiteSpace: 'break-spaces',
      textAlign: 'justify',
      color: '#5C8DF3',
      wordBreak: 'break-all',
    },
  },
}));

const BoothProfile = ({
  name,
  introduction,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2>
        {ReactHtmlParser(name)}
      </h2>
      <div className={classes.text}>
        {ReactHtmlParser(introduction)}
      </div>
    </div>
  );
};

BoothProfile.propTypes = {
  name: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
};


export default BoothProfile;
