import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

const highlightStyle = {
  color: '#F5415B',
  background: 'transparent',
};

const TextHighlighter = ({
  searchWords,
  textToHighlight,
}) => (
  <Highlighter
    searchWords={searchWords}
    textToHighlight={textToHighlight}
    highlightStyle={highlightStyle}
  />
);

TextHighlighter.propTypes = {
  searchWords: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(RegExp),
    ]),
  ).isRequired,
  textToHighlight: PropTypes.string.isRequired,
};

export default TextHighlighter;
