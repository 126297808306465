import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import SearchResultContainer from './SearchResultContainer';

const RootContainer = styled.div`
  width: 100%;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  overflow: hidden !important;
`;

const EndMessage = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #999;
  text-align: center;
`;

const ParticipantSearchResultContainer = ({
  participants,
  fetchMore,
  scrollableTarget,
}) => {
  const { t } = useTranslation();
  const { hasNextPage } = useSelector((s) => s.searchResults);

  return (
    <RootContainer>
      <StyledInfiniteScroll
        dataLength={participants.length}
        hasChildren={participants.length > 0}
        next={fetchMore}
        hasMore={hasNextPage}
        loader={<CircularLoadingIndicator key="loading-indicator" size={32} />}
        endMessage={<EndMessage>{t('search_end_of_result', 'End of Results')}</EndMessage>}
        scrollableTarget={scrollableTarget}
        scrollThreshold={1}
      >
        <SearchResultContainer
          type="participant"
          results={participants}
        />
      </StyledInfiniteScroll>
    </RootContainer>
  );
};

ParticipantSearchResultContainer.propTypes = {
  participants: PropTypes.arrayOf(PropTypes.shape()),
  fetchMore: PropTypes.func,
  scrollableTarget: PropTypes.instanceOf(Element),
};

ParticipantSearchResultContainer.defaultProps = {
  participants: [],
  fetchMore: () => { },
  scrollableTarget: document.body,
};

export default ParticipantSearchResultContainer;
