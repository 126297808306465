import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Apps, { reedApps } from 'apps';
import { APP } from 'appenv';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EventLogo from 'assets/event-logo.png';
import EventXtraLogo from 'assets/eventxtra-logo-dark.png';
import PolyUSPEEDLogo from 'assets/polyu-speed-logo.png';
import PaloaltoLogo from 'assets/event-logo-623.png';
import PicoXLogo from 'assets/event-logo-707.png';
import MSlogo from 'assets/ms624_logo.png';
import SCMPLogo from 'assets/scmp711_logo.png';
import LighthouseLogo from 'assets/lighthouse710_logo.jpg';
import Sph706Logo from 'assets/sph706_logo.png';
import Neoventure1012Logo from 'assets/event-logo-1012-neoventure.png';
import Jetro817Logo from 'assets/jetro817_logo.png';
import Uzabase826Logo from 'assets/event-logo-826-uzabase.png';
import Mig0828Logo from 'assets/mig0828_logo.png';
import Emsd831Logo from 'assets/emsd0831_logo.jpg';
import Eventage824Logo from 'assets/eventage-logo.png';
import Pwc918Logo from 'assets/pwc918_logo.png';
import Bmi923Logo from 'assets/Bmi923logo.png';
import DesktopAppBar from './DesktopAppBar';
import MobileAppBar from './MobileAppBar';

export default function XtraAppBar({
  hideSearchBar, hideUser, hideHelp, hideDrawer,
}) {
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const [bookmarksModalOpen, setBookmarksModalOpen] = useState(false);

  const openBookmarks = () => {
    // if there have new enter point please let allen know.
    window.trackingEvent('Event Navigation', 'MainMenu_Click', 'Bookmark');
    setBookmarksModalOpen(true);
  };

  const closeBookmarks = () => {
    setBookmarksModalOpen(false);
  };

  const { t } = useTranslation();

  const logoUrlList = React.useMemo(() => {
    if (reedApps.includes(APP)) {
      return t('common:logos', '', { returnObjects: true }) || [];
    }
    switch (APP) {
      case 'polyu618':
        return [PolyUSPEEDLogo];
      case 'paloalto623':
        return [PaloaltoLogo];
      case 'picox707':
        return [PicoXLogo];
      case 'ms624':
        return [MSlogo];
      case 'scmp711':
        return [SCMPLogo];
      case 'hkstp608':
        return [EventLogo];
      case 'lighthouse710':
        return [LighthouseLogo];
      case 'sph706':
      case Apps.Sph818:
        return [Sph706Logo];
      case Apps.Neoventure1012:
        return [Neoventure1012Logo];
      case Apps.Jetro817:
        return [Jetro817Logo];
      case Apps.Uzabase826:
        return [Uzabase826Logo];
      case Apps.Mig0828:
        return [Mig0828Logo];
      case Apps.Emsd831:
        return [Emsd831Logo];
      case Apps.Eventage824:
        return [Eventage824Logo];
      case Apps.pwc918:
        return [Pwc918Logo];
      case Apps.Bmi923:
        return [Bmi923Logo];
      default:
        return [EventXtraLogo];
    }
  }, [t]);

  const hideUserBaseOnEvent = useMemo(() => (APP === 'ms624' || hideUser), [hideUser]);
  const hideHelpButtonBaseOnEvent = useMemo(() => (APP === 'ms624' || hideHelp), [hideHelp]);

  return isDesktop ? (
    <DesktopAppBar
      logos={logoUrlList}
      hideSearchBar={hideSearchBar}
      hideUser={hideUserBaseOnEvent}
      hideHelp={hideHelpButtonBaseOnEvent}
      bookmarksModalOpen={bookmarksModalOpen}
      openBookmarks={openBookmarks}
      closeBookmarks={closeBookmarks}
    />
  ) : (
    <MobileAppBar
      logos={logoUrlList}
      hideSearchBar={hideSearchBar}
      hideHelp={hideHelpButtonBaseOnEvent}
      hideDrawer={hideDrawer}
      bookmarksModalOpen={bookmarksModalOpen}
      openBookmarks={openBookmarks}
      closeBookmarks={closeBookmarks}
    />
  );
}

XtraAppBar.propTypes = {
  hideSearchBar: PropTypes.bool,
  hideUser: PropTypes.bool,
  hideHelp: PropTypes.bool,
  hideDrawer: PropTypes.bool,
};

XtraAppBar.defaultProps = {
  hideSearchBar: false,
  hideUser: false,
  hideHelp: false,
  hideDrawer: false,
};
