import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

const RootContainer = styled.div`
  padding: 8px 16px;
  background-color: #FFF;
  display: flex;
  align-items: center;
`;

const SearchField = styled.div`
  flex: 1 1 auto;
  background: rgba(142, 142, 147, 0.12);
  border-radius: 10px;
  display: flex;
  align-items: center;
`;

const StyledSearchIcon = withStyles(() => ({
  root: {
    margin: '0 10px',
    color: '#A0AAB4',
  },
}))(SearchIcon);

const SearchInput = withStyles(() => ({
  root: {
    height: '36px',
    fontSize: '16px',
    lineHeight: '24px',
  },
}))(InputBase);

const CancelButton = withStyles(() => ({
  root: {
    flex: '0 0 auto',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#999999',
    margin: '8px 14px 8px 16px',
  },
}))(ButtonBase);

const SearchFieldContainer = ({
  searchText,
  onChange,
  onCancel,
}) => (
  <RootContainer>
    <SearchField>
      <StyledSearchIcon fontSize="small" />
      <SearchInput
        fullWidth
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        value={searchText}
        onChange={onChange}
      />
    </SearchField>
    <CancelButton onClick={onCancel}>
      Cancel
    </CancelButton>
  </RootContainer>
);

SearchFieldContainer.propTypes = {
  searchText: PropTypes.string,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};

SearchFieldContainer.defaultProps = {
  searchText: '',
  onChange: () => {},
  onCancel: () => {},
};

export default SearchFieldContainer;
