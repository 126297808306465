import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LogoPlaceholder from 'assets/logoPlaceholder.svg';
import Booth608Description from '../components/Booth608Description';
import BoothSaleContainer from '../components/BoothSaleContainer';
import BannerLightboxImageView from '../components/BannerLightboxImageView';

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  z-index: 10;
  right: -220px;
  top: 150px;
`;

const Container = styled.div`
  position: absolute;
  width: 900px;
  height: 600px;
  z-index: 1;
  left: -50px;
`;

const ShowDeskContainer = styled.div`
  position: absolute;
  height: 170px;
  width: 295px;
  top: 115px;
  left: 317px;
  z-index: 10;
`;

const ColorFlag = styled.div`
  background: linear-gradient(126deg, #0CA58B 25%, #0E7ABC 75%);
  position: absolute;
  width: 132px;
  top: 81px;
  left: 29px;
  height: 188px;
  z-index: 5;
`;

const LogoContainer = styled.div`
  background-color: #FFF;
  position: absolute;
  width: 193px;
  height: 111px;
  right: 75px;
  top: 62px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const BoothLogo = styled.img`
  flex: 0 0 auto;
  max-height: 100%;
  max-width: 100%;
`;

const Placeholder = styled.img`
  width: 32px;
  height: 32px;
`;

const FloorContainer = styled.div`
  position: absolute;
  bottom: -43px;
  left: -250px;
`;

const TemplateContainer = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  margin: 0 auto;
`;

const Booth608Template = ({ booth, handleClick, showRepsImg }) => (
  <TemplateContainer>
    <Container>
      <StyledImg src="https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/580/content/table-.png" />
    </Container>
    <LogoContainer>
      {
        booth.logo ? (<BoothLogo src={booth.logo} />) : (<Placeholder src={LogoPlaceholder} />)
      }
    </LogoContainer>
    {
      booth.verticalBanner ? (
        <BannerLightboxImageView
          thumbnailSrc={booth.verticalBanner}
          fullSizeSrc={booth.verticalBannerFullImg}
          alt="vertical-banner"
        />
      ) : (
        <ColorFlag />
      )
    }
    <ShowDeskContainer>
      <Booth608Description
        handleClick={handleClick}
        name={booth.name}
        boothNumber={booth.boothNumber}
        description={booth.description}
      />
    </ShowDeskContainer>
    <FloorContainer>
      <svg width="1431" height="142" viewBox="0 0 1431 142" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path d="M1370.11 112.338H50.1113L353.077 -17.2041H1071.34L1370.11 112.338Z" fill={booth.isFundingKind ? '#282B40' : '#0CA58B'} />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect x="-9.88867" y="0.336914" width="1440" height="141.318" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </FloorContainer>
    {
      showRepsImg && (
        <SaleContainer id="reps">
          <BoothSaleContainer booth={booth} sale="sales" />
        </SaleContainer>
      )
    }
  </TemplateContainer>
);

Booth608Template.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth608Template.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth608Template;
