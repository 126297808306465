import React from 'react';
import PropTypes from 'prop-types';
import {
  APP,
  CUSTOM_ORDER,
  FILTER_CUSTOM_LABEL,
} from 'appenv';
import Apps, { reedApps } from 'apps';
import GenericBoothFilter from './filterTemplates/GenericBoothFilter';
import MobileHallFilter608 from './filterTemplates/MobileHallFilter608';
import GenericCMSBoothFilter from './filterTemplates/cms/GenericCMSBoothFilter';
import GenericCMSMobileFilter from './filterTemplates/cms/GenericCMSMobileFilter';

const BoothFilterSwitch = ({ ...props }) => {
  if (reedApps.includes(APP)) {
    return (
      <>
        <GenericCMSBoothFilter {...props} />
        <GenericCMSMobileFilter {...props} />
      </>
    );
  }
  switch (APP) {
    case Apps.Eventage824:
    case Apps.Jetro817:
    case Apps.Emsd831:
      return (
        <>
          <GenericCMSBoothFilter {...props} />
          <GenericCMSMobileFilter {...props} />
        </>
      );
    case Apps.SPH706:
    case Apps.VirtualPressCon:
    case Apps.VirtualDemo:
    case Apps.Sph818:
      return (
        <GenericBoothFilter
          {...props}
          customOrder={CUSTOM_ORDER}
          filterCustomLabel={FILTER_CUSTOM_LABEL}
        />
      );
    case 'polyu618':
      return (
        <GenericBoothFilter
          {...props}
          customOrder={CUSTOM_ORDER}
          filterCustomLabel={FILTER_CUSTOM_LABEL}
        />
      );
    case 'paloalto623':
      return (
        <GenericBoothFilter
          {...props}
          customOrder={CUSTOM_ORDER}
          filterCustomLabel={FILTER_CUSTOM_LABEL}
        />
      );
    case 'hkstp608':
      return (
        <>
          <GenericBoothFilter
            {...props}
            customOrder={CUSTOM_ORDER}
            filterCustomLabel={FILTER_CUSTOM_LABEL}
          />
          <MobileHallFilter608
            {...props}
            customOrder={CUSTOM_ORDER}
            filterCustomLabel={FILTER_CUSTOM_LABEL}
          />
        </>
      );
    default:
      return null;
  }
};

BoothFilterSwitch.propTypes = {
  withFilter: PropTypes.bool,
};

BoothFilterSwitch.defaultProps = {
  withFilter: false,
};

export default BoothFilterSwitch;
