import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Truncate from 'react-truncate';

import ChatContext from 'components/chat/ChatContext';
import { SearchFieldContext } from 'components/SearchFieldContext';
import UserAvatar from 'components/UserAvatar';
import ChatroomIcon from 'components/icons/Chatroom';
import TextHighlighter from 'components/TextHighlighter';

const RootContainer = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background-color: #FFF;
  border-radius: 4px;
  height:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '54px';
      case 'standard':
      default:
        return '90px';
    }
  }};
  margin-bottom:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '0px';
      case 'standard':
      default:
        return '10px';
    }
  }};
`;

const UserInfoContainer = styled.div`
  flex: 1 1 auto;
  margin-left: 10px;
  width: 60%;
`;

const UserInfo = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.408px;
  color: #484848;
  max-width: 100%;
  overflow: hidden;
`;

const UserTag = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: -0.408px;
  color: #FFF;
  border-radius: 2px;
  background-color: rgb(255, 66, 66);
  opacity: 0.4;
  padding: 0 5px;
  margin-left: 8px;
`;

const UserDescription = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: #484848;
  margin-top: 8px;
`;

const StyledChatroomIcon = styled(ChatroomIcon)`
  cursor: pointer;
`;

const ParticipantSearchResultItem = ({
  uuid,
  name,
  userTag,
  userDescription,
  variant,
}) => {
  const { showChatWindow } = useContext(ChatContext);
  const { searchKeyword } = useContext(SearchFieldContext);

  const onPrivateChatBtnClick = () => {
    showChatWindow(uuid, 'private', name);
    window.trackingEvent('OpenPrivateChatWindow', 'SearchField', uuid);
  };

  return (
    <RootContainer variant={variant}>
      <UserAvatar name={name} />
      <UserInfoContainer>
        <UserInfo>
          <TextHighlighter
            searchWords={[searchKeyword]}
            textToHighlight={name}
          />
          {
            userTag && (
              <UserTag>
                {userTag}
              </UserTag>
            )
          }
        </UserInfo>
        {
          userDescription.length > 0 && (
            <UserDescription>
              <Truncate lines={2} ellipsis="...">
                {userDescription}
              </Truncate>
            </UserDescription>
          )
        }
      </UserInfoContainer>
      <StyledChatroomIcon
        color="#484848"
        width="24px"
        height="24px"
        onClick={onPrivateChatBtnClick}
      />
    </RootContainer>
  );
};

ParticipantSearchResultItem.propTypes = {
  uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  userTag: PropTypes.string,
  userDescription: PropTypes.string,
  variant: PropTypes.oneOf([
    'compact',
    'standard',
  ]),
};

ParticipantSearchResultItem.defaultProps = {
  userTag: '',
  userDescription: '',
  variant: 'standard',
};

export default ParticipantSearchResultItem;
