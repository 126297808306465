/* eslint-disable camelcase */
import { normalize, schema } from 'normalizr';
import { FETCH_LANDING_AGENDA_URL } from 'appenv';
import {
  createAsyncThunk, createEntityAdapter, createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';

export interface LandingAgenda {
  id: number;
  hideContent: boolean;
  hideSpeaker: boolean;
  webinar: {id: number};
  translations: {
    formatted_title: string;
    formatted_description: string;
  };
}

const defaultBookmarkEntity = new schema.Entity('landingAgenda');

export const fetchLandingAgenda = createAsyncThunk(
  'landingAgenda/fetchAll',
  async () => {
    try {
      const response = await axios.get(FETCH_LANDING_AGENDA_URL);
      const items = response.data.data.map((data: any) => ({
        id: data.id,
        hideContent: data.hide_content,
        hideSpeaker: data.hide_speaker,
        webinar: {
          ...data.webinar,
          translations: data.webinar.translations?.reduce((sum: any, translation) => ({ ...sum, [translation.language]: translation }), {}),
        },
        translations: data.translations?.reduce((sum: any, translation) => ({ ...sum, [translation.language]: translation }), {}),
      }));
      const normalized = normalize(items, [defaultBookmarkEntity]);
      return normalized.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const landingAgendaAdapter = createEntityAdapter<LandingAgenda>();
export const {
  selectEntities: selectLandingAgendaEntities,
  selectAll: selectAllLandingAgenda,
  selectById: selectLandingAgendaById,
} = landingAgendaAdapter.getSelectors((state: any) => state.landingAgenda);

export const landingAgendaSlice = createSlice({
  name: 'landingAgenda',
  initialState: landingAgendaAdapter.getInitialState({ isLoading: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLandingAgenda.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(fetchLandingAgenda.fulfilled, (state: any, action) => {
      landingAgendaAdapter.upsertMany(state, action.payload.landingAgenda);
      state.isLoading = false;
    });
    builder.addCase(fetchLandingAgenda.rejected, (state: any) => {
      state.isLoading = false;
    });
  },
});

export default landingAgendaSlice.reducer;
