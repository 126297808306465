import React from 'react';
import styled from 'styled-components';
import { BRAND_COLOR } from 'appenv';
import { BREAKPOINT } from 'pages/webinar/constants';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { styled as muiStyled } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const BreadcrumbsContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 100;
  padding: 16px 24px;
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINT}px) {
    width: 100%;
    padding: 4px 24px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${BRAND_COLOR};
`;

const StyledBreadcrumbs = muiStyled(Breadcrumbs)({
  display: 'flex',
  flexShrink: 0,
  width: '100%',
  fontFamily: 'inherit',
});

export interface XtraBreadcrumbsItem {
  name: string;
  to?: string;
}

export interface XtraBreadcrumbsProps {
  paths: XtraBreadcrumbsItem[];
  className?: string;
}

const XtraBreadcrumbs = ({ paths, className }: XtraBreadcrumbsProps) => (
  <BreadcrumbsContainer className={className}>
    <StyledBreadcrumbs aria-label="breadcrumb" separator=">">
      {
        paths.map((pathItem) => (pathItem.to
          ? <StyledLink key={pathItem.name + pathItem.to} to={pathItem.to}>{pathItem.name}</StyledLink>
          : <span key={pathItem.name + pathItem.to}>{pathItem.name}</span>
        ))
      }
    </StyledBreadcrumbs>
  </BreadcrumbsContainer>
);

export default XtraBreadcrumbs;
