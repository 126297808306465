import React from 'react';
import styled from 'styled-components';
import LobbyLine from 'assets/main_lobby/lobby-line.svg';

const RootContainer = styled.div`
  width: 100%;
  max-height: 240px;
  margin: 0 30px;
  position: relative;
  flex-direction: column;
`;

const LobbyImageContainer = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const LobbyLineContainer = styled.img`
  display: block;
  margin-bottom: -2px;
  margin-top: -1px;
  width: 100%;
  height: auto;
`;

const LobbyLink711Template = ({ background }: { background: string }) => (
  <RootContainer>
    <LobbyLineContainer src={LobbyLine} alt="line-top" />
    <LobbyImageContainer src={background} />
    <LobbyLineContainer src={LobbyLine} alt="line-bottom" />
  </RootContainer>
);

export default LobbyLink711Template;
