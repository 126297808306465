import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const addContactMutation = `
  mutation addContact($userRongcloudId: String!, $contactRongcloudId: String!) {
    addContact(input: {
      userUuid: $userRongcloudId,
      contactUuid: $contactRongcloudId
    }) {
      success
    }
  }
`;

function addContact(userRongcloudId, contactRongcloudId) {
  return request(API_URL, addContactMutation, {
    userRongcloudId,
    contactRongcloudId,
  }).then(({ addContact: { success } }) => success);
}

export default addContact;
