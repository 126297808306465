import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EventXtraLogo from 'assets/eventxtra-logo-white.svg';

const RootContainer = styled.div`
  height: 70px;
  width: 185px;
  border-radius: 4px;
  position: relative;
`;

const StyledBackdrop = styled.div`
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.4;
  position: absolute;
  border-radius: 4px;
`;

const PoweredByContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

const StyledText = styled.div`
  flex: 0 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  color: #FFF;
  margin-bottom: 6px;
`;

const StyledAnchor = styled.a`
  flex: 0 0 auto;
  margin-top: 8px;
  line-height: 50px;
  font-family: Lato, "sans-serif";
`;

const StyledImg = styled.img`
  max-width: 70px;
  max-height: 20px;
  margin-bottom: 3px;
`;

const CopyrightText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: #FFF;
  margin-bottom: 6px;
`;

const EventXtraBranding = ({ url }) => (
  <RootContainer>
    <StyledBackdrop />
    <StyledAnchor href={url} target="_blank" rel="noopener noreferrer" title="Powered by EventXtra">
      <PoweredByContainer>
        <CopyrightText>Copyright &#169; Reed Exhibitions Japan Ltd.</CopyrightText>
        <StyledText>Powered by</StyledText>
        <StyledImg src={EventXtraLogo} />
      </PoweredByContainer>
    </StyledAnchor>
  </RootContainer>
);

EventXtraBranding.propTypes = {
  url: PropTypes.string,
};

EventXtraBranding.defaultProps = {
  url: '',
};

export default EventXtraBranding;
