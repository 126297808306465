import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NoDataIndicator from 'components/indicators/NoDataIndicator';

const RootContainer = styled.div`
  width: auto;
  max-width: 100%;
  margin: 20px;
  letter-spacing: 0.3px;
`;

const ItemContainer = styled.div`
  padding-bottom: 12px;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled.a`
  white-space: break-spaces;
  text-align: justify;
  font-size: 17px;
  line-height: 24px;
  color: #5C8DF3;
  word-break: break-all;
  margin-bottom: 5px;
  width: fit-content;
`;

const BoothLink = ({ links }) => (
  <RootContainer>
    {
      links?.length > 0
        ? (
          <ItemContainer>
            <LinkContainer>
              {
                links.map((link) => (
                  <StyledLink key={link.url} href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.title}
                  </StyledLink>
                ))
              }
            </LinkContainer>
          </ItemContainer>
        )
        : <NoDataIndicator />
    }
  </RootContainer>
);

BoothLink.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

export default BoothLink;
