import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const groupChatJoinMutation = `
  mutation groupChatJoin($uniqueToken: String!, $groupId: String!) {
    groupChatJoin(input: {
      uniqueToken: $uniqueToken,
      groupId: $groupId
    }) {
      success
    }
  }
`;

function groupChatJoin(attendeeUniqueToken, groupId) {
  return request(API_URL, groupChatJoinMutation, {
    uniqueToken: attendeeUniqueToken,
    groupId,
  }).then(({ groupChatJoin: { success } }) => success);
}

export default groupChatJoin;
