import styled from 'styled-components';

const SliderImage = styled.div<{ img: string; onClick?: any }>`
  flex: 0 0 auto;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`;
export default SliderImage;
