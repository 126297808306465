import {
  createSelector, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import moment, { Moment, MomentInput } from 'moment-timezone';
import Apps, { reedApps } from 'apps';
import { envSwitch } from 'utils/envUtils';
import { AvailableLocale } from 'i18n/types';
import { APP } from 'appenv';

export interface LocalizationState {
  useLocalTime: boolean;
  eventTimezone: string;
  locale: AvailableLocale;
  availableLocales: AvailableLocale[];
}

type MomentLocalizer = (time: MomentInput) => Moment;

export const availableLocales: AvailableLocale[] = envSwitch([
  [[Apps.VirtualDemo], ['en', 'ja', 'id-ID', 'zh-Hans', 'ko']],
  [[Apps.ReedJPFood1014, Apps.ReedIJTaki1028], ['ja', 'en', 'zh-Hans', 'zh-Hant']],
  [[Apps.ReedSoumn916, Apps.ReedCBW1028], ['ja']],
  [reedApps, ['ja', 'en']],
  [Apps.VirtualDemo, ['en', 'ja', 'id-ID', 'zh-Hans', 'ko']],
  [Apps.XtraCMS, ['en', 'ja', 'id-ID', 'zh-Hans']],
], ['en']);

export const initialState: LocalizationState = {
  useLocalTime: true,
  eventTimezone: moment.tz.guess(),
  locale: availableLocales[0],
  availableLocales,
};

export const selectLocalizationState = (state: any) => state.localization as LocalizationState;
export const selectMomentLocalizer: ((state: any) => MomentLocalizer) = createSelector(
  [selectLocalizationState],
  (state: LocalizationState) => {
    const { eventTimezone } = state;
    return state.useLocalTime
      ? (time) => moment(time).local()
      : (time) => moment(time).tz(eventTimezone);
  },
);

export const selectLocale = (state: any): AvailableLocale => state.localization.locale;

export const selectAvailableLocales = (state: any): AvailableLocale[] => state.localization.availableLocales;

export const localizationSlice = createSlice({
  name: 'localization',
  initialState,
  reducers: {
    setUseLocalTime: (state, action: PayloadAction<boolean>) => {
      state.useLocalTime = action.payload;
    },
    setEventTimeZone: (state, action: PayloadAction<string>) => {
      state.eventTimezone = action.payload;
    },
    setLocale: (state, action: PayloadAction<AvailableLocale>) => {
      state.locale = action.payload;
    },
    setAvailableLocales: (state, action: PayloadAction<AvailableLocale[]>) => {
      state.availableLocales = action.payload;
    },
    setLocaleByDetection: (state) => {
      const locale = localStorage.getItem('locale');

      if ([Apps.ReedMJTokyo1014, Apps.ReedMWK1007].includes(APP as any) && !state.availableLocales.includes(locale as any)) {
        return;
      }

      const detected = [
        locale,
        ...(navigator.languages || []),
        ...[(navigator as any)?.userLanguage].filter((it) => it),
      ];
      const matched = detected.find((it) => state.availableLocales.includes(it));
      if (matched) state.locale = matched;
    },
  },
});

export default localizationSlice.reducer;
