const KB = 1000;
const MB = 1000000;
const GB = 1000000000;

const getFileSizeText = (fileSize) => {
  if (fileSize < 1 * KB) {
    return `${fileSize}B`;
  }
  if (fileSize < 1 * MB) {
    return `${(fileSize / KB).toFixed(1)}kB`;
  }
  if (fileSize < 1 * GB) {
    return `${(fileSize / MB).toFixed(1)}MB`;
  }
  console.warn('File size limit exceeded.');
  return '';
};

export default getFileSizeText;
