import EventTitleImgSource from 'assets/landing/background-event-title-902.png';
import EventTitleImgSourceJA from 'assets/landing/background-event-title-902-ja.png';

export const en = {
  titleImg: EventTitleImgSource,
  message: {
    titles: ['Welcome to', 'Online Meeting Service'],
    contents: ["You Can View Exhibitors's Product Information and", 'Arrange Onsite/Online Meeting Appointments with Exhibitors.'],
  },
  button: {
    onlineMeetingService: 'What is "Online Meeting Service"?',
    register: {
      lifeStyle: 'LIFESTYLE Week >>',
      japanMarket: 'Japan Marketing Week >>',
    },
    login: 'LOGIN',
  },
  link: {
    exhibition: 'Go to Exhibition Website >>',
  },
  text: {
    registrations: ['Registrations ', '(Choose the show of your interest)'],
    eventDate: 'Sept. 2(Wed) - 4(Fri), 2020',
  },
};

export const ja = {
  titleImg: EventTitleImgSourceJA,
  message: {
    titles: ['オンライン商談サービスへようこそ！'],
    contents: ['アポイント予約、製品情報・動画の閲覧ができます'],
  },
  button: {
    onlineMeetingService: 'オンライン商談サービス とは？',
    register: {
      lifeStyle: 'ライフスタイル Week >>',
      japanMarket: 'Japan マーケティング Week >>',
    },
    login: 'ログイン',
  },
  link: {
    exhibition: '展示会ホームページは、こちら',
  },
  text: {
    registrations: ['利用登録はこちら ', '(ご希望の展示会をお選びください)'],
    eventDate: '2020年9月2日(水)～4日(金)',
  },
};
