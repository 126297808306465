import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Truncate from 'react-truncate';

import UserAvatar from 'components/UserAvatar';
import {
  UserInfo,
  UserTag,
  UserDescription,
} from './ChatWindow';

const MemberContainer = styled.div`
  cursor: pointer;
  padding: 10px 16px;
  display: flex;
`;

const UserInfoText = styled.div`
  width: 70%;
  margin-left: 10px;
`;

const GroupMemberListItem = ({
  name,
  userTag,
  userDescription,
  handleAvatarOnclick,
}) => (
  <MemberContainer onClick={handleAvatarOnclick}>
    <UserAvatar name={name} />
    <UserInfoText>
      <UserInfo>
        {name}
        {
          userTag && (
            <UserTag>{userTag}</UserTag>
          )
        }
      </UserInfo>
      <UserDescription>
        <Truncate lines={2} ellipsis="...">
          {userDescription}
        </Truncate>
      </UserDescription>
    </UserInfoText>
  </MemberContainer>
);

GroupMemberListItem.propTypes = {
  name: PropTypes.string.isRequired,
  userTag: PropTypes.string,
  userDescription: PropTypes.string,
  handleAvatarOnclick: PropTypes.func.isRequired,
};

GroupMemberListItem.defaultProps = {
  userTag: '',
  userDescription: '',
};

export default GroupMemberListItem;
