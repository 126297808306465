export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/73ab9868-94bc-45cb-8668-78364ca59c9c.png',
  ],
  url: {
    exhibition_website: 'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top_en/',
  },
  title: 'COSME Week [OSAKA] -VIRTUAL- / LIFESTYLE Week [OSAKA] -VIRTUAL- / Japan Marketing Week [Osaka] -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/d208f962-9681-4c73-9850-2e94c419e3d0.png',
  ],
  url: {
    exhibition_website: 'https://www.lifestyle-expo-k.jp/ls_jmw_cosme_top/',
  },
  title: 'オンライン来場・商談サービス｜COSME Week 大阪／ライフスタイルWeek[関西]／JapanマーケティングWeek[関西]',
};
