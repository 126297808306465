import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import Booth618Container from './components/Booth618Container';
import ScreenBackground from '../../components/background/ScreenBackground';
import TanShelf from '../../components/brochureShelf/TanShelf';
import BoothDescription from '../../components/BoothDescription';
import BoothPullUpBanner from '../../components/BoothPullUpBanner';
import BoothSaleContainer from '../../components/BoothSaleContainer';

const Booth618AContainer = styled(Booth618Container)`
  margin-bottom: ${({ needOffset }) => (needOffset ? '110px' : 'inherit')};
`;

const LogoContainer = styled.div`
  position: absolute;
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/543/content/logo_container-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 200px;
  z-index: 100;
  left: calc((100% - 400px) / 2);
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const LogoImgContainer = styled.div`
  margin-top: 47.4px;
  height: 149.4px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImg = styled.img`
  flex: 0 0 auto;
  max-width: 100%;
  max-height: 100%;
`;

const ShowDeskContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/541/content/center_showdesk-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 900;
  position: absolute;
  top: 260px;
  width: 470px;
  height: 400px;
  left: calc((100% - 470px) / 2);
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
`;

const PosterContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/577/content/poster-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 200px;
  position: absolute;
  bottom: -60px;
  z-index: 100;
`;

const BrochureContainer = styled.div`
  position: absolute;
  bottom: -60px;
  z-index: 100;
  right: 120px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
  z-index: 999;
`;

const Booth618TemplateA = ({ booth, handleClick, showRepsImg }) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const pullUpThumbnail = booth.verticalBannerFullImg;
  const pullUpFile = booth.verticalBannerFile;

  return (
    <Booth618AContainer needOffset={showRepsImg}>
      <LogoContainer enableClick={!!(booth.logoDirectLink || booth.website)}>
        <LogoImgContainer onClick={() => handleClick('logo')}>
          <LogoImg alt="Logo" src={booth.logo} />
        </LogoImgContainer>
      </LogoContainer>
      <ShowDeskContainer>
        <BoothDescription
          handleClick={handleClick}
          name={booth.name}
          boothNumber={booth.boothNumber}
          description={booth.description}
        />
      </ShowDeskContainer>
      <BackgroundContainer>
        <ScreenBackground boothColor={booth.boothColor || '#CF2F2F'} />
      </BackgroundContainer>
      <PosterContainer>
        <BoothPullUpBanner
          pullUpBanner={pullUpThumbnail}
          mainSrc={pullUpFile}
          setShowBannerModal={setShowBannerModal}
        />
      </PosterContainer>
      <BrochureContainer enableClick={!!booth.attachments.length} onClick={() => handleClick('attachment')}>
        <TanShelf hasAttachment={!!booth.attachments.length} />
      </BrochureContainer>
      {
        showRepsImg && (
          <SaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={pullUpFile}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
    </Booth618AContainer>
  );
};

Booth618TemplateA.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth618TemplateA.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth618TemplateA;
