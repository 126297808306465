export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/6f809e1e-63be-469f-a6d7-274589d0ca77.png',
  ],
  url: {
    exhibition_website: 'https://www.wsew-build.jp/en-gb/expo.html',
  },
  title: 'World Smart Energy Week OSAKA / JAPAN BUILD OSAKA　-VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/7c85d5bc-f4a3-480a-8ff1-2f496595ba60.png',
  ],
  url: {
    exhibition_website: 'https://www.wsew-build.jp/ja-jp/expo.html',
  },
  title: 'オンライン来場・商談サービス｜[関西]スマートエネルギーWeek／[関西]住宅・ビル・施設Week',
};
