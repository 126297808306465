import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LightZero from 'assets/booths/light0.png';
import LightOne from 'assets/booths/light1.png';
import LightTwo from 'assets/booths/light2.png';
import LightThree from 'assets/booths/light3.png';
import lightRay from 'assets/booths/light-ray.png';
import line1 from 'assets/booths/line1.png';
import line2 from 'assets/booths/line2.png';
import BoothColor from '../BoothColor';

const BackgroundImg = styled.img`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
`;

const LineImg = styled.img`
  position: absolute;
  left: ${({ site }) => (site === 'right' ? '' : '85px')};
  top: 130px;
  height: 420px;
  width: 220px;
  transform:
  ${({ boothColor, site }) => {
    if (boothColor) {
      if (site === 'left') {
        return 'scale(1.3) rotate(180deg)';
      }
      return 'scale(1.3)';
    }
    return null;
  }};
  right: ${({ site }) => (site === 'right' ? '90px' : '')};
`;

const LightContainer = styled.div`
  position: absolute;
  left:
    ${({ index }) => {
    if (index === 0) {
      return '130px';
    }
    if (index === 1) {
      return '250px';
    }
    return null;
  }};
  right:
    ${({ index }) => {
    if (index === 3) {
      return '200px';
    }
    if (index === 2) {
      return '300px';
    }
    return null;
  }};
  top:
  ${({ index }) => {
    if (index === 0 || index === 3) {
      return '15px';
    }
    return '40px;';
  }};
`;

const LightImg = styled.img`
  position: absolute;
`;

const LightRayImg = styled.img`
  position: absolute;
  top: 30px;
  left:
    ${({ site }) => {
    if (site === 'right') {
      return '-30px';
    }
    return null;
  }};
`;

const ScreenBackground = ({ boothColor, boothColorRight = boothColor }) => (
  <>
    <BackgroundImg src="https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/540/content/bg-.png" alt="Booth" />
    <BoothColor boothColor={boothColor} site="left" />
    <BoothColor boothColor={boothColorRight} site="right" />
    <LightContainer index={0}>
      <LightImg src={LightZero} />
      <LightRayImg src={lightRay} />
    </LightContainer>
    <LightContainer index={1}>
      <LightImg src={LightOne} />
      <LightRayImg src={lightRay} />
    </LightContainer>
    <LightContainer index={2}>
      <LightImg src={LightTwo} />
      <LightRayImg src={lightRay} site="right" />
    </LightContainer>
    <LightContainer index={3}>
      <LightImg src={LightThree} />
      <LightRayImg src={lightRay} site="right" />
    </LightContainer>
    <LineImg src={line1} site="left" />
    <LineImg src={line2} site="right" />
  </>
);

ScreenBackground.propTypes = {
  boothColor: PropTypes.string,
  boothColorRight: PropTypes.string,
};

ScreenBackground.defaultProps = {
  boothColor: undefined,
  boothColorRight: undefined,
};

export default ScreenBackground;
