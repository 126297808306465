import request, { gql } from 'graphql-request';
import {
  CommonLocalizableString, CommonLocalizableImage, CommonBoothTier,
} from 'network/common/cmsFragment';
import { apiUrl, eventToken } from 'network/common/cmsConfig';

interface FetchMetaBoothsProps {
  locales: string[];
  count?: number;
  cursor?: string;
}

const fetchMetaBoothsQuery = gql`
  ${CommonLocalizableString}
  ${CommonLocalizableImage}
  ${CommonBoothTier}

  query fetchMetaBoothsQuery(
    $eventToken: String!
    $locales: [Locale!]!
    $count: Int
    $cursor: String
  ) {
    data: eventByToken(eventToken: $eventToken) {
      booths(first: $count, after: $cursor) {
        pageInfo {
          hasNextPage
          endCursor
        }
        nodes {
          id
          appliedDesignCodename
          appointmentBookingUrl
          meetupEnabled
          website
          boothNumber
          description {
            ...CommonLocalizableString
          }
          name {
            ...CommonLocalizableString
          }
          logo {
            ...CommonLocalizableImage
          }
          tier {
            ...CommonBoothTier
          }
        }
      }
    }
  }
`;

const fetchMetaBooths = async (config: FetchMetaBoothsProps) => request(apiUrl, fetchMetaBoothsQuery, { ...config, eventToken })
  .then((response) => response.data);

export default fetchMetaBooths;
