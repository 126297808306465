import styled from 'styled-components';

const BreadcrumbsNoFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-basis: 0;
  align-items: center;
`;

export default BreadcrumbsNoFilterContainer;
