import moment from 'moment';
import remoteTimeNow from 'libs/remote-time-now';
import { APP, EVENT_REGISTER_START_TIME, EVENT_REGISTER_END_TIME } from 'appenv';
import { reedApps } from 'apps';

const isChatroomDisabledByReed = () => {
  if (!reedApps.includes(APP as any)) return false;
  const now = remoteTimeNow();
  return !now.isBetween(moment(EVENT_REGISTER_START_TIME), moment(EVENT_REGISTER_END_TIME));
};

export default isChatroomDisabledByReed;
