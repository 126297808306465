export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/6dc03dea-985a-4b4e-9d5c-d8f5868d5dde.png',
  ],
  url: {
    exhibition_website: 'https://www.cbw-expo.jp/en-gb.html',
    next_show: '/booth/dZejAy5UzQ6jRm2b81egrXXaqnM4o7uvVXeVh9gPW3N7',
  },
  title: 'Communications & Broadcasting Week 2020　-VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/056549a6-c75e-4bfe-b2bc-e519a1a89c7d.png',
  ],
  url: {
    exhibition_website: 'https://www.cbw-expo.jp/',
    next_show: '/booth/dZejAy5UzQ6jRm2b81egrXXaqnM4o7uvVXeVh9gPW3N7',
  },
  title: 'オンライン来場・商談サービス｜第4回 通信・放送Week',
};
