import React, {
  useEffect, useContext, useMemo, useRef,
} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  EVENT_NAME,
  ENABLED_FEATURES,
  APP,
  ENV,
  EVENT_LANDING_REDIRECTION,
} from 'appenv';
import { reedApps, useCachedCMSDataApps } from 'apps';
import Routes from 'Routes';
import { useDispatch, batch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchExhibitors } from 'models/exhibitors';
import { fetchWebinars } from 'models/webinars';
import { fetchXtraEvent } from 'models/event';
import { fetchBanners } from 'models/banners';
import { EventDatetimeContext } from 'components/EventDatetime';
import { UserSessionContext } from 'components/UserSession';

import { fetchCachedCMSData } from 'models/cms/common/thunks';
import { fetchMetaBooths } from 'models/cms/booths/reducers';
import { fetchXtraEvent as fetchCMSXtraEvent, fetchBoothTaxonomies } from 'models/cms/events/reducers';

import AboutPage from 'pages/about/AboutPage';
import { localizationSlice, selectLocale } from 'models/localization';
import NetworkCheckModal from 'components/modals/network_check';
import ContentPageFrame from 'components/ContentPageFrame';
import { fetchDefaultBookmarks } from 'models/defaultBookmarks';
import CustomPageContainer from 'pages/custom';
import ProductPage from 'pages/product';
import ProductDetailContainer from 'pages/product_detail';
import BoothPreviewPage from 'pages/preview/BoothPreview';
import AuthRoute from './AuthRoute';
import MainLobbyContainer from '../pages/main_lobby/MainLobbyContainer';
import BoothProfileRootContainer from '../pages/booths';
import HallPage from '../pages/hall/HallPage';
import WebinarPageContainer from '../pages/webinar/WebinarPageContainer';
import SearchResultPageContainer from '../pages/search/SearchResultPageContainer';
import LandingTemplateSwitch from '../pages/landing/LandingTemplateSwitch';
import PageNoFound from '../pages/PageNoFound';
import WebinarDetailContainer from '../pages/webinar_detail/WebinarDetailContainer';
import WebinarSpeakerDetailsContainer from '../pages/webinar_detail/speaker_detail/WebinarSpeakerDetailsContainer';
import ParticipantPageContainer from '../pages/participant/ParticipantPageContainer';
import ContactsPageContainer from '../pages/contacts/ContactsPageContainer';

const AppRouterHelmet = () => {
  const { t } = useTranslation();
  const locale = useSelector(selectLocale);
  const displayTitle = useMemo(() => {
    if (reedApps.includes(APP)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  return (
    <Helmet htmlAttributes={{ lang: locale }}>
      <meta charSet="utf-8" />
      <title>{displayTitle}</title>
      <meta name="description" content={displayTitle} />
    </Helmet>
  );
};

const AppRouter = () => {
  const init = useRef({
    locale: false,
    xtraCMS: false,
  });
  const dispatch = useDispatch();
  const { hasEventStarted, hasEventEnded } = useContext(EventDatetimeContext);
  const { userSession } = useContext(UserSessionContext);

  useEffect(() => {
    if (!init.current.locale) {
      init.current.locale = true;
      if (reedApps.includes(APP)) {
        dispatch(localizationSlice.actions.setLocaleByDetection());
      }
    }
    if ((
      !hasEventStarted
      || (hasEventEnded && EVENT_LANDING_REDIRECTION.eventEndedRedirectToLanding)
      || !userSession?.authToken
    ) && ENV === 'production') {
      return;
    }
    if (ENABLED_FEATURES.xtraCMS && !init.current.xtraCMS) {
      init.current.xtraCMS = true;
      const task = () => {
        dispatch(fetchCMSXtraEvent()).then(() => {
          batch(() => {
            dispatch(fetchBoothTaxonomies());
            dispatch(fetchMetaBooths());
          });
        });
      };
      if (useCachedCMSDataApps.includes(APP)) {
        dispatch(fetchCachedCMSData()).then((result) => {
          if (result?.type?.endsWith('rejected')) {
            console.error('ERROR: Cannot access cached CMS data');
            task();
          }
        });
      } else {
        task();
      }
    }
    dispatch(fetchXtraEvent()).then(({ payload: eventPayload }) => {
      batch(() => {
        if (ENABLED_FEATURES.cmsBanner) dispatch(fetchBanners());
        if (ENABLED_FEATURES.defaultBookmarks) dispatch(fetchDefaultBookmarks());
        if (!ENABLED_FEATURES.xtraCMS) dispatch(fetchExhibitors());
        dispatch(localizationSlice.actions.setEventTimeZone(eventPayload[0]?.timezone));
        dispatch(fetchWebinars(eventPayload[0]));
      });
    });
  }, [dispatch, hasEventStarted, hasEventEnded, userSession]);

  return (
    <BrowserRouter>
      <AppRouterHelmet />
      {ENABLED_FEATURES.networkCheck && <NetworkCheckModal />}
      <ContentPageFrame>
        <Switch>
          {APP === 'ms624' && (
            <AuthRoute
              exact
              path="/about"
              route="about"
              component={AboutPage}
            />
          )}
          <AuthRoute
            exact
            path={[Routes.root, Routes.lobby]}
            route="lobby"
            component={MainLobbyContainer}
          />
          <AuthRoute
            path={Routes.booth()}
            route="booth"
            component={BoothProfileRootContainer}
          />
          <AuthRoute
            exact
            path={Routes.hall}
            route="hall"
            component={HallPage}
          />
          <AuthRoute
            exact
            path={Routes.webinar}
            route="webinar"
            component={WebinarPageContainer}
          />
          <AuthRoute
            exact
            path={Routes.webinarSpeaker()}
            route="webinar"
            component={WebinarSpeakerDetailsContainer}
          />
          <AuthRoute
            exact
            path={Routes.webinarSession()}
            route="webinar"
            component={WebinarDetailContainer}
          />
          <AuthRoute
            exact
            path={Routes.participant}
            route="participant"
            component={ParticipantPageContainer}
          />
          <AuthRoute
            exact
            path={Routes.search}
            route="search"
            component={SearchResultPageContainer}
          />
          <AuthRoute
            exact
            path={Routes.products}
            route="product"
            component={ProductPage}
          />
          <AuthRoute
            exact
            path={Routes.productDetail()}
            route="productDetail"
            component={ProductDetailContainer}
          />
          {ENABLED_FEATURES.customPage && (
            <AuthRoute
              exact
              path={Routes.custom}
              route="custom"
              component={CustomPageContainer}
            />
          )}
          <AuthRoute
            exact
            path={Routes.landing}
            route="landing"
            component={LandingTemplateSwitch}
          />
          <AuthRoute
            exact
            path={Routes.contacts}
            route="contacts"
            component={ContactsPageContainer}
          />
          {ENABLED_FEATURES.xtraCMS && (
            <AuthRoute
              exact
              path={Routes.boothPreview}
              route="boothPreview"
              component={BoothPreviewPage}
            />
          )}
          <Route component={PageNoFound} />
        </Switch>
      </ContentPageFrame>
    </BrowserRouter>
  );
};

export default AppRouter;
