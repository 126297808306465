import React from 'react';
import { APP } from 'appenv';
import Apps, { reedApps } from 'apps';
import Banner608Template from './templates/Banner608Template';
import Banner618Template from './templates/Banner618Template';
import Banner623Template from './templates/Banner623Template';
import Banner624Template from './templates/Banner624Template';
import Banner711Template from './templates/Banner711Template';
import Banner710Template from './templates/Banner710Template';
import Banner706Template from './templates/Banner706Template';
import Banner707Template from './templates/Banner707Template';
import BannerReedTemplate from './templates/BannerReedTemplate';

const BannerTemplateSwitch = (props) => {
  if (reedApps.includes(APP)) {
    return <BannerReedTemplate {...props} />;
  }

  switch (APP) {
    case 'polyu618':
      return (
        <Banner618Template {...props} />
      );
    case 'paloalto623':
      return (
        <Banner623Template {...props} />
      );
    case 'ms624':
      return <Banner624Template {...props} />;
    case 'scmp711':
      return <Banner711Template {...props} />;
    case 'lighthouse710':
      return (
        <Banner710Template {...props} />
      );
    case 'sph706':
      return (
        <Banner706Template {...props} />
      );
    case Apps.PicoX707:
      return (
        <Banner707Template {...props} />
      );
    default:
      return (
        <Banner608Template {...props} />
      );
  }
};

export default BannerTemplateSwitch;
