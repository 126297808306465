import React from 'react';
import styled from 'styled-components';
import BannerImage from './BannerImage';

const StyledLink = styled.a`
  width: 100%;
  height: 100%;
`;

export interface BannerPDFProps {
  pdfUrl: string;
  thumbnailUrl?: string;
}

const BannerPDF: React.FC<BannerPDFProps> = ({
  pdfUrl,
  thumbnailUrl,
}: BannerPDFProps) => (
  pdfUrl
    ? (
      <StyledLink href={pdfUrl} target="_blank" rel="noopener noreferrer">
        {thumbnailUrl && (
        <BannerImage
          src={thumbnailUrl}
          alt="pdf Banner"
          data-object-fit="contain"
          data-object-position="center"
        />
        )}
      </StyledLink>
    )
    : (
      thumbnailUrl && (
      <BannerImage
        src={thumbnailUrl}
        alt="pdf Banner"
        data-object-fit="contain"
        data-object-position="center"
      />
      )
    )
);

export default BannerPDF;
