import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <path
    d="M9.91104 4.9399H13.9417L18.1486 0.732926C18.4591 0.422521 18.9623 0.422521 19.2727 0.732926C19.5831 1.04333 19.5831 1.54659 19.2727 1.857L16.1898 4.93987H21.5043C22.3823 4.93987 23.094 5.65162 23.094 6.52955V19.2469C23.094 20.1248 22.3823 20.8366 21.5043 20.8366H2.4283C1.55034 20.8366 0.838623 20.1248 0.838623 19.2469V6.52955C0.838623 5.65162 1.55034 4.9399 2.4283 4.9399H7.6629L4.58002 1.857C4.26959 1.54659 4.26959 1.04333 4.58002 0.732926C4.8904 0.422521 5.39366 0.422521 5.70407 0.732926L9.91104 4.9399ZM14.1056 13.6148C14.1514 13.5781 14.1931 13.5365 14.2297 13.4906C14.504 13.1478 14.4484 12.6477 14.1056 12.3734L10.8732 9.78749C10.7322 9.67474 10.5571 9.61331 10.3766 9.61331C9.93768 9.61331 9.58181 9.96918 9.58181 10.4082V15.58C9.58181 15.7605 9.64323 15.9357 9.75598 16.0766C10.0302 16.4194 10.5304 16.4749 10.8732 16.2007L14.1056 13.6148H14.1056Z"
  />,
  'Bookmark',
  { viewBox: '0 0 24 21' },
);
