import React from 'react';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const RootContainer = styled.div`
  padding: 0 24px 16px;
`;

const DescriptionTitle = styled.h2`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-top: 0;
  margin-bottom: 0;
`;

const ProductDetailField = ({ title, content }: {
  title: string;
  content: string;
}) => (
  <RootContainer>
    <DescriptionTitle>{title}</DescriptionTitle>
    {ReactHtmlParser(content)}
  </RootContainer>
);

export default ProductDetailField;
