import React, {
  useState,
  useEffect,
  Suspense,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';
import Apps from 'apps';
import { EVENT_BANNERS, APP, ENABLED_FEATURES } from 'appenv';
import { selectBannersByPosition, BannerPosition } from 'models/banners';
import BannerView from './BannerTemplateSwitch';
import HallView from './HallTemplateSwitch';
import WebinarView from './WebinarTemplateSwitch';
import LobbyFooterSwitch from './LobbyFooterSwitch';

const RootContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 1380px;

  @media (max-width: 1380px) {
    transform: scale(${({ scale }) => scale});
    transform-origin: top left;
  }
`;

const BannerContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  margin-bottom: 102.35px;
`;

const LeftBannerView = styled(BannerView)`
  flex: 1 0 auto;
`;

const MiddleBannerView = styled(BannerView)`
  flex: 1 0 auto;
  margin-left: 40px;
  margin-right: 40px;
`;

const RightBannerView = styled(BannerView)`
  flex: 1 0 auto;
`;

const HallContainer = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 40px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const FooterWrapper = styled.div``;

const DesktopLobbyView = () => {
  const [scale, setScale] = useState(1);
  const containerRef = React.createRef();

  const onWindowResize = throttle(() => {
    if (containerRef.current) {
      const windowWidth = window.innerWidth;
      const containerWidth = containerRef.current.offsetWidth;

      const newScale = windowWidth / containerWidth;

      setScale(newScale);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [onWindowResize]);

  const leftBanners = useSelector(selectBannersByPosition(BannerPosition.left));
  const sortedLeftBanners = useMemo(() => leftBanners.sort((a, b) => a?.sort - b?.sort), [leftBanners]);
  const rightBanners = useSelector(selectBannersByPosition(BannerPosition.right)).sort((a, b) => a?.sort - b?.sort);
  const sortedRightBanners = useMemo(() => rightBanners.sort((a, b) => a?.sort - b?.sort), [rightBanners]);
  const middleBanners = useSelector(selectBannersByPosition(BannerPosition.middle)).sort((a, b) => a?.sort - b?.sort);
  const sortedMiddleBanners = useMemo(() => middleBanners.sort((a, b) => a?.sort - b?.sort), [middleBanners]);

  const autoPlay = useMemo(() => {
    const autoPlayFlags = {
      left: true,
      middle: true,
      right: true,
    };
    if (APP === 'polyu618') {
      autoPlayFlags.middle = false;
    } else if (APP === 'paloalto623') {
      autoPlayFlags.middle = false;
    } else if (APP === Apps.SPH710) {
      autoPlayFlags.middle = false;
      autoPlayFlags.left = sortedLeftBanners.length && sortedRightBanners.length;
      autoPlayFlags.right = sortedLeftBanners.length && sortedRightBanners.length;
    }
    return autoPlayFlags;
  }, [sortedLeftBanners.length, sortedRightBanners.length]);

  return (
    <RootContainer>
      <PageContainer
        ref={containerRef}
        scale={scale}
      >
        <BannerContainer>
          <LeftBannerView assets={ENABLED_FEATURES.cmsBanner && sortedLeftBanners?.length > 0 ? sortedLeftBanners : EVENT_BANNERS.left} autoPlay={autoPlay.left} />
          <MiddleBannerView size="large" assets={ENABLED_FEATURES.cmsBanner && sortedMiddleBanners?.length > 0 ? sortedMiddleBanners : EVENT_BANNERS.middle} autoPlay={autoPlay.middle} />
          <RightBannerView assets={ENABLED_FEATURES.cmsBanner && sortedRightBanners?.length > 0 ? sortedRightBanners : EVENT_BANNERS.right} autoPlay={autoPlay.right} />
        </BannerContainer>
        <HallContainer>
          <StyledLink to={Routes.hall}>
            <HallView />
          </StyledLink>
          <StyledLink to={Routes.webinar}>
            <WebinarView />
          </StyledLink>
        </HallContainer>
      </PageContainer>
      <FooterWrapper>
        <Suspense fallback={null}>
          <LobbyFooterSwitch />
        </Suspense>
      </FooterWrapper>
    </RootContainer>
  );
};

export default DesktopLobbyView;
