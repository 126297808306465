import React, { useEffect } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { APP } from 'appenv';

import BackgroundImg from 'assets/background.jpg';
import Background618Img from 'assets/background-618-plain.png';
import Background623Img from 'assets/background-lobby-623.png';
import Background710Img from 'assets/background-710.jpg';
import Background711Img from 'assets/background-711.jpg';

import { envSwitch } from 'utils/envUtils';
import DesktopFancyLobbyViewTemplate from 'pages/main_lobby/templates/DesktopFancyLobbyViewTemplate';
import Apps, { reedApps } from 'apps';
import DesktopLobbyViewReedTemplate from './templates/DesktopLobbyViewReedTemplate';
import DesktopLobbyView from './DesktopLobbyView';
import MobileLobbyView from './MobileLobbyView';
import MobileLobbyView706Template from './templates/MobileLobbyView706Template';
import MobileLobbyViewReedTemplate from './templates/MobileLobbyViewReedTemplate';
import Background624Container from './templates/Background624Conatiner';

const backgroundImage = () => {
  switch (APP) {
    case 'polyu618':
      return `url(${Background618Img})`;
    case 'paloalto623':
      return `url(${Background623Img})`;
    case 'ms624':
      return 'url()';
    case 'scmp711':
      return `url(${Background711Img})`;
    case 'lighthouse710':
      return `url(${Background710Img})`;
    case 'sph706':
      return 'url()';
    case Apps.HKSTP608:
      return `url(${BackgroundImg})`;
    default:
      return 'url()';
  }
};

const backgroundImageMobile = () => {
  switch (APP) {
    case 'polyu618':
      return Background618Img;
    default:
      return '';
  }
};

const RootContainer = styled.div`
  height: 100%;
  overflow: hidden;
  background: url(${backgroundImageMobile()});
  background-color: #F0F1F2;

  @media (min-width: 960px) {
    background: ${backgroundImage()};
    background-position: ${({ bgPosition }) => (bgPosition)};
    background-repeat: no-repeat;
    background-size: 1440px auto;
  }

  @media (min-width: 1440px), (min-height: 960px) {
    background-size: cover;
  }
`;

const PageContentContainer = styled.div`
  min-width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const MainLobbyContainer = () => {
  useEffect(() => {
    window.trackingEvent('MainMenu_Lobby', 'Enter', '');
  }, []);

  const isDesktop = useMediaQuery('(min-width: 960px)');

  const desktopLobbyView = envSwitch([
    [[Apps.PicoX707,
      Apps.VirtualDemo,
      Apps.VirtualPressCon,
      Apps.SPH706,
      Apps.Jetro817,
      Apps.Mig0828,
      Apps.Sph818,
      Apps.Uzabase826,
      Apps.Emsd831,
      Apps.Eventage824,
      Apps.pwc918,
    ], <DesktopFancyLobbyViewTemplate />],
    [reedApps, <DesktopLobbyViewReedTemplate />],
  ], <DesktopLobbyView />);

  const mobileLobbyView = envSwitch([
    [Apps.SPH706, <MobileLobbyView706Template />],
    [reedApps, <MobileLobbyViewReedTemplate />],
  ], <MobileLobbyView />);

  return (
    <RootContainer bgPosition={APP === 'paloalto623' ? 'left bottom' : 'center center'}>
      {
        APP === Apps.Ms624 && isDesktop && (
          <Background624Container />
        )
      }
      <PageContentContainer>
        {isDesktop ? desktopLobbyView : mobileLobbyView}
      </PageContentContainer>
    </RootContainer>
  );
};

export default MainLobbyContainer;
