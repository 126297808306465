import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import { selectGroupMembersByGroupId } from 'models/chatTargetGroupMembers';
import { UserSessionContext } from 'components/UserSession';
import GroupMemberListItem from '../GroupMemberListItem';

const Container = styled.div`
  border-top: ${({ isMobile }) => (isMobile ? '1px solid #DBDEE2' : 'none')};
  background-color: #FFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
`;

const TitleText = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 16px;
  color: #484848;
  font-weight: normal;
  font-size: 12px;
  border-top: 1px solid #DBDEE2;
  border-bottom: 1px solid #DBDEE2;
`;

const ListContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const GroupMemberList711 = ({
  joiningChat,
  handleAvatarOnclick,
  isMobile,
}) => {
  const { targetId } = useSelector((state) => state.chatTarget);
  const { userSession } = useContext(UserSessionContext);
  const allMembers = useSelector(selectGroupMembersByGroupId(targetId));

  const filterMemberForAttendee = (member) => (
    // eslint-disable-next-line camelcase
    member?.customAttendeeFields?.vexpo_chat_tag?.toLowerCase() === 'exhibitor'
  );
  const boothExhibitors = allMembers.filter(filterMemberForAttendee);

  return (
    <Container isMobile={isMobile}>
      {
        !isMobile && (
          <TitleText>Group Members</TitleText>
        )
      }
      <ListContainer>
        {
          joiningChat && <CircularLoadingIndicator />
        }
        {
          !joiningChat && boothExhibitors && boothExhibitors.map((member) => {
            const {
              name,
              uuid,
              customAttendeeFields: {
                vexpo_chat_tag: userTag,
                vexpo_chat_user_description: userDescription,
              },
            } = member;
            const isSelf = userSession.rongcloudUserId === uuid;

            return (
              <GroupMemberListItem
                key={uuid}
                name={name}
                userTag={userTag}
                userDescription={userDescription}
                handleAvatarOnclick={() => handleAvatarOnclick('PublicChatroom_GroupMembersAvatar_Click', isSelf, uuid, name)}
              />
            );
          })
        }
      </ListContainer>
    </Container>
  );
};

export default GroupMemberList711;

GroupMemberList711.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  handleAvatarOnclick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};
