import React, {
  useCallback,
  useContext,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, batch } from 'react-redux';
import Sticky from 'react-sticky-el';
import { useTranslation } from 'react-i18next';

import { UserSessionContext } from 'components/UserSession';
import useURLSearchParams from 'hooks/useURLSearchParams';
import { SearchFieldContext } from 'components/SearchFieldContext';
import {
  searchResultsSelector,
  SearchResultsSlice,
} from 'models/searchResults';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import AllSearchResultContainer from 'components/search/AllSearchResultContainer';
import SearchResultContainer from 'components/search/SearchResultContainer';
import ParticipantSearchResultContainer from 'components/search/ParticipantSearchResultContainer';
import EmptyStatePlaceholder from 'components/search/EmptyStatePlaceholder';

const RootContainer = styled.div`
  flex: 1 0 auto;
  margin: auto;
  width: 100%;
  max-width: 800px;
  margin-bottom: 48px;
`;

const TitleContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #A2AAB3;
  padding: 24px 0;
  background-color: #F0F2F5;
`;

const SearchResultLoader = ({ scrollableTarget, activeTab }) => {
  const { t } = useTranslation();
  const query = useURLSearchParams();
  const isDataReady = useSelector((state) => (
    !((state.cms.booths.fetching && state.exhibitors.fetching) || state.webinars.isLoading)
  ));
  const { initialLoading } = useSelector((state) => state.searchResults);

  const {
    exhibitors,
    webinars,
    participants,
  } = useSelector(searchResultsSelector);

  const { canSee } = useContext(UserSessionContext);
  const {
    searchKeyword,
    setSearchKeyword,
    fetchSearchResults,
  } = useContext(SearchFieldContext);

  const fetchMore = useCallback(() => {
    fetchSearchResults({ newSearchText: searchKeyword });
  }, [fetchSearchResults, searchKeyword]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isDataReady) {
      batch(() => {
        dispatch(SearchResultsSlice.actions.resetSearchResults());
        fetchMore();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataReady, searchKeyword]);

  useEffect(() => {
    setSearchKeyword(query.get('searchText') || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  if (initialLoading) {
    return (
      <RootContainer>
        <TitleContainer>
          {t(
            'search.search_result',
            `Search result of ${searchKeyword}`,
            { query: searchKeyword },
          )}
        </TitleContainer>
        <CircularLoadingIndicator />
      </RootContainer>
    );
  }

  const resultsPresent = (exhibitors && exhibitors.length > 0)
    || (webinars && webinars.length > 0)
    || (participants && participants.length > 0);

  return (
    <RootContainer>
      <Sticky
        scrollElement={scrollableTarget}
        stickyStyle={{ zIndex: 10 }}
      >
        <TitleContainer>
          {t(
            'search.search_result',
            `Search result of ${searchKeyword}`,
            { query: searchKeyword },
          )}
        </TitleContainer>
      </Sticky>
      {
        resultsPresent ? (
          <>
            {
              activeTab === 0 && (
                <AllSearchResultContainer
                  showResult
                  exhibitors={exhibitors}
                  webinars={webinars}
                  participants={participants}
                  fetchMore={fetchMore}
                  scrollableTarget={scrollableTarget}
                />
              )
            }
            {
              activeTab === 1 && (
                <SearchResultContainer
                  type="exhibitor"
                  results={exhibitors}
                />
              )
            }
            {
              activeTab === 2 && (
                <SearchResultContainer
                  type="webinar"
                  results={webinars}
                />
              )
            }
            {
              canSee('participant') && activeTab === 3 && (
                <ParticipantSearchResultContainer
                  participants={participants}
                  fetchMore={fetchMore}
                  scrollableTarget={scrollableTarget}
                />
              )
            }
          </>
        ) : (
          <EmptyStatePlaceholder />
        )
      }
    </RootContainer>
  );
};

SearchResultLoader.propTypes = {
  scrollableTarget: PropTypes.instanceOf(Element),
  activeTab: PropTypes.number.isRequired,
};

SearchResultLoader.defaultProps = {
  scrollableTarget: document.body,
};

export default SearchResultLoader;
