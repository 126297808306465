export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/f0dbd41d-7aff-4085-a6a3-4957d4cb8780.png',
  ],
  url: {
    exhibition_website: 'https://www.ijt-aki.jp/en-gb.html',
    next_show: '/booth/dznKLiKwWSrbH7uDG1M1C5GLTDzb7sCt7bHrnLm797Zd',
  },
  title: 'IJT AUTUMN 2020 -VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/691d8ca3-7064-4824-b092-ed661ad2685a.png',
  ],
  url: {
    exhibition_website: 'https://www.ijt-aki.jp/ja-jp.html',
    next_show: '/booth/dznKLiKwWSrbH7uDG1M1C5GLTDzb7sCt7bHrnLm797Zd',
  },
  title: 'オンライン来場・商談サービス｜第8回 国際宝飾展[秋]',
};

export const zhHans = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/35969be2-1d40-4785-9e12-1f5bbfa58b76.png',
  ],
  url: {
    exhibition_website: 'https://www.ijt-aki.jp/zh-cn.html',
    next_show: '/booth/dznKLiKwWSrbH7uDG1M1C5GLTDzb7sCt7bHrnLm797Zd',
  },
  title: '线上商谈服务系统——第8届  秋季东京国际珠宝展',
};

export const zhHant = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/5c25e5ab-d0b7-4ddd-b2f3-50d78f1456c4.png',
  ],
  url: {
    exhibition_website: 'https://www.ijt-aki.jp/zh-hk.html',
    next_show: '/booth/dznKLiKwWSrbH7uDG1M1C5GLTDzb7sCt7bHrnLm797Zd',
  },
  title: '綫上商談服務系統——第8屆  秋季東京國際珠寶展',
};
