import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ShowMore from 'components/utils/ShowMore';
import SpeakerItem from './SpeakerItem';

const RootContainer = styled.div`
  overflow: hidden;
`;

const locale = 'en';

const MobileSpeakerList = ({ webinarId, speakers, backgroundRGBA }) => (
  <RootContainer>
    <ShowMore maxHeight={140} backgroundRGBA={backgroundRGBA}>
      {
        speakers.map(({
          id, avatar, translations,
        }) => {
          const {
            name, company, title, bio,
          } = translations[locale];
          return (
            <SpeakerItem
              key={id}
              id={id}
              webinarId={webinarId}
              name={name}
              company={company}
              title={title}
              avatar={avatar}
              bio={bio}
            />
          );
        })
      }
    </ShowMore>
  </RootContainer>
);

MobileSpeakerList.propTypes = {
  webinarId: PropTypes.number.isRequired,
  speakers: PropTypes.arrayOf(PropTypes.shape()),
  backgroundRGBA: PropTypes.arrayOf(PropTypes.number),
};

MobileSpeakerList.defaultProps = {
  speakers: [],
  backgroundRGBA: [255, 255, 255, 0.2],
};

export default MobileSpeakerList;
