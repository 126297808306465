import React from 'react';
import styled from 'styled-components';

import Background624Img from 'assets/background-lobby-ms624.png';

const RootContainer = styled.div`
  position: fixed;
  width: 100%;
  background-color: #E0E0E0;
  z-index: -1000;
`;

const BacdgroundImgContainer = styled.div`
  margin-top: 156.46px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
`;

const Background624Container = () => (
  <RootContainer>
    <BacdgroundImgContainer>
      <StyledImg src={Background624Img} alt="background" />
    </BacdgroundImgContainer>
  </RootContainer>
);

export default Background624Container;
