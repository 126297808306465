import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { APP } from 'appenv';
import Apps from 'apps';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Grow from '@material-ui/core/Grow';
import salesImg from 'assets/booths/sales.png';
import maleRight from 'assets/booths/sale-male-right.png';
import maleLeft from 'assets/booths/sale-male-left.png';
import sphSaleImg from 'assets/booths/sph-sale-person.png';
import BusinessPopover from './BusinessPopover';

const SaleInfoContainer = styled.div`
  position: absolute;
  left: -70px;
  bottom: 480px;
  width: 320px;
`;

const SaleImg = styled.img`
  height: 480px;
  position: absolute;
  bottom: 0;
  cursor: ${({ shouldDisableSalesRepClick }) => (!shouldDisableSalesRepClick ? 'pointer' : 'initial')};
  left: 50%;
  transform: translateX(-50%);
`;

const BubbleArrow = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: -8px;
  left: 140px;
  background: #FFF;
  border-radius: 2px;
  box-shadow:
    3px 3px 3px -2px rgba(0, 0, 0, 0.2),
    3px 3px 4px 0 rgba(0, 0, 0, 0.14),
    1px 1px 8px -5px rgba(0, 0, 0, 0.12);
  transform: rotate(45deg);
`;

const disableSalesRepClick = ['polyu618', 'scmp711', Apps.SPH706];

const BoothSaleContainer = ({ booth, sale }) => {
  const [showRepsInfo, setShowRepsInfo] = useState(false);

  const shouldDisableSalesRepClick = useMemo(() => disableSalesRepClick.includes(APP), []);

  const onReqsClick = useCallback(() => {
    setShowRepsInfo(!showRepsInfo);
  }, [setShowRepsInfo, showRepsInfo]);

  useEffect(() => {
    setTimeout(() => {
      setShowRepsInfo(true);
    }, 500);
  }, []);

  const renderedSalesImg = useMemo(() => {
    if (booth.salesImg) return booth.salesImg;
    if (APP === Apps.SPH706) return sphSaleImg;
    switch (sale) {
      case 'male-left':
        return maleLeft;
      case 'sales':
        return salesImg;
      default:
        return maleRight;
    }
  }, [sale, booth.salesImg]);

  return (
    <>
      <SaleImg key={renderedSalesImg} src={renderedSalesImg} shouldDisableSalesRepClick={shouldDisableSalesRepClick} onClick={!shouldDisableSalesRepClick ? onReqsClick : undefined} />
      <Grow
        in={showRepsInfo}
        timeout={800}
        style={{ transformOrigin: 'bottom' }}
      >
        <SaleInfoContainer>
          <BusinessPopover
            boothId={booth.id}
            boothName={booth.name}
            emailContacts={booth.emailContacts}
            meetupUrl={booth.meetupUrl}
            livestreams={booth.livestreams}
            videoCallUrl={booth.videoCallUrl}
          />
          <BubbleArrow />
        </SaleInfoContainer>
      </Grow>
    </>
  );
};

BoothSaleContainer.propTypes = {
  booth: PropTypes.shape().isRequired,
  sale: PropTypes.string.isRequired,
};

export default BoothSaleContainer;
