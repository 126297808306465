import styled from 'styled-components';

const SliderBulletList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
`;

export default SliderBulletList;
