import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import NoBackgroundPosterPanel from 'assets/booths/poster-nobg.png';
import RegularPosterPanel from 'assets/booths/poster.png';
import BoothContainer from '../../components/BoothContainer';
import ScreenBackground from '../../components/background/ScreenBackground';
import TanShelf from '../../components/brochureShelf/TanShelf';
import GenericBoothBanner from '../../components/GenericBoothBanner';
import BoothSaleContainer from '../../components/BoothSaleContainer';

const LogoContainer = styled.div`
  position: absolute;
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/543/content/logo_container-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 200px;
  left: calc((100% - 400px) / 2);
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const LogoImgContainer = styled.div`
  margin-top: 47.4px;
  height: 149.4px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowDeskContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/541/content/center_showdesk-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 260px;
  width: 470px;
  height: 400px;
  left: calc((100% - 470px) / 2);
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
`;

const PosterContainer = styled.div`
  background: url(${({ hasFile }) => (hasFile ? NoBackgroundPosterPanel : RegularPosterPanel)});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 200px;
  padding: 6.5px 9px 9.6px;
  position: absolute;
  bottom: -60px;
`;

const BrochureContainer = styled.div`
  position: absolute;
  bottom: -60px;
  right: 120px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
`;

const DescriptionContainer = styled.div`
  height: 206px;
  top: 7px;
  position: relative;
`;

const Booth711Template = ({ booth, handleClick, showRepsImg }) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const pullUpFile = booth.verticalBannerFile;
  const hasFile = useMemo(() => Object.keys(booth.banners.left).some((bannerKey) => !!(booth.banners.left[bannerKey])), [booth]);

  return (
    <BoothContainer needOffset={showRepsImg}>
      <BackgroundContainer>
        <ScreenBackground boothColor={booth.boothColor || '#FCB415'} boothColorRight={booth.boothColorRight || booth.boothColor || '#22576E'} />
      </BackgroundContainer>
      <LogoContainer enableClick={!!(booth.logoDirectLink || booth.website)}>
        <LogoImgContainer>
          <GenericBoothBanner
            name={booth.name}
            from="Banner 2"
            boothNumber={booth.id}
            handleClick={handleClick}
            bannerData={booth?.banners?.top[0]}
          />
        </LogoImgContainer>
      </LogoContainer>
      <ShowDeskContainer>
        <DescriptionContainer>
          <GenericBoothBanner
            handleClick={handleClick}
            name={booth.name}
            from="Banner 3"
            boothNumber={booth.id}
            bannerData={booth?.banners?.center[0]}
            isDescriptionBanner
          />
        </DescriptionContainer>
      </ShowDeskContainer>
      <PosterContainer hasFile={hasFile}>
        <GenericBoothBanner
          name={booth.name}
          from="Banner 1"
          boothNumber={booth.id}
          handleClick={handleClick}
          bannerData={booth?.banners?.left[0]}
        />
      </PosterContainer>
      <BrochureContainer enableClick={!!booth.attachments.length} onClick={() => handleClick('attachment')}>
        <TanShelf hasAttachment={!!booth.attachments.length} />
      </BrochureContainer>
      {
        showRepsImg && (
          <SaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={pullUpFile}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
    </BoothContainer>
  );
};

Booth711Template.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth711Template.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth711Template;
