import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ArrowFowardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { Collapse } from 'react-collapse';
import { BRAND_COLOR } from 'appenv';
import { withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import { BoothTaxonomies } from 'models/cms/events';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';
import FilterCheckboxOption from './FilterCheckboxOption';
import '../../index.css';

const MobileHallFilterContainer = styled.div`
  width: 100%;
  background-color: #FFF;
  border-bottom: 0.5px solid #DBDEE2;
  position: relative;
  z-index: 1110;

  @media only screen and (min-width: 960px) {
    display: none;
  }
`;

const MobileFilterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const MobileFilterOption = styled.div`
  padding: 12px 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-size: 14px;
  color: #999;

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }

  @media only screen and (max-width: 370px) {
    font-size: 11px;
  }
`;

const MobileFilterSelectContainer = styled.div`
  width: 100%;
  padding: 8px;
  transition: all 0.3s;
`;

const FilterListContainer = styled.div`
  width: 100%;
  max-height: 350px;
  overflow: auto;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 20px 16px;
  display: inline-flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const FilterTitleText = styled.span`
  font-weight: bold;
  font-size: 15px;
  padding: 9px 0;
  display: inline-block;
  text-transform: none;
`;

const ArrowContainer = styled.div<{ selected?: boolean }>`
  transform: ${({ selected }) => (selected ? 'rotate(-90deg) translateX(-2px)' : 'rotate(90deg) translateX(2px)')};
  display: inline-block;
  transition: inherit;
  padding: 4px;
`;

const FilterTitleContainer = styled.div<{ singleLevel?: boolean; selected?: boolean }>`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ singleLevel }) => (singleLevel ? 'flex-start' : 'space-between')};
  transition: all 0.3s;
  color:
    ${({ selected, singleLevel }) => {
    const selectedColor = BRAND_COLOR || '#0CA58B';
    const notSelectedColor = singleLevel ? '#484848' : '#A2AAB3';
    return selected ? selectedColor : notSelectedColor;
  }};
`;

const ClearAllButton = withStyles(() => ({
  containedPrimary: {
    textTransform: 'capitalize',
    color: '#808080',
    backgroundColor: '#F0F1F2',
    marginRight: '16px',
    flex: '0 1 auto',
    '&:hover': {
      color: '#808080',
      backgroundColor: '#F0F1F2',
    },
  },
}))(Button);

const ShowExhibitorsButton = withStyles(() => ({
  containedPrimary: {
    textTransform: 'capitalize',
    color: '#FFF',
    backgroundColor: BRAND_COLOR || '#0CA58B',
    flex: '1 1 auto',
    '&:hover': {
      color: '#FFF',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
}))(Button);

const BottomButtonContainer = ({ resetSelectedFilter, numOfExhibitors, onClose }) => {
  const { t } = useTranslation();
  return (
    <ButtonContainer>
      <ClearAllButton
        variant="contained"
        color="primary"
        onClick={resetSelectedFilter}
      >
        {t('filter.clear_all', 'Clear all')}
      </ClearAllButton>
      <ShowExhibitorsButton
        variant="contained"
        color="primary"
        onClick={onClose}
      >
        {`Show ${numOfExhibitors} Exhibitors`}
      </ShowExhibitorsButton>
    </ButtonContainer>
  );
};

BottomButtonContainer.propTypes = {
  resetSelectedFilter: PropTypes.func.isRequired,
  numOfExhibitors: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

const GreyBackground = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const StyledCheckbox = withStyles(() => ({
  colorPrimary: {
    color: BRAND_COLOR || '#0CA58B',
  },
  root: {
    '&$checked': {
      color: BRAND_COLOR || '#0CA58B',
    },
  },
  checked: {},
}))(Checkbox);

const MobileFilterCategoryContainerRootContainer = styled.div`
  width: 100%;
  padding: 0 2%;
`;

const MobileFilterCategoryContainer = ({
  filter,
  selectedFilter,
  setSelectedFilter,
}: any) => {
  const locale = useSelector(selectLocale);
  const [open, setOpen] = useState(false);

  const isSingleLevel = !Array.isArray(filter.subcategories);
  const isSelected = !isSingleLevel || !!selectedFilter.find((it: any) => it === filter.id);

  const onFilterTitleContainerClick = () => {
    if (isSingleLevel) {
      setSelectedFilter((prev) => {
        const index = prev.findIndex((it) => it === filter.id);
        if (index === -1) return [...Array.from(new Set([...prev, filter.id]))];
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
    }
    setOpen((prev) => !prev);
  };

  return (
    <MobileFilterCategoryContainerRootContainer>
      <FilterTitleContainer
        singleLevel={isSingleLevel}
        selected={isSelected}
        onClick={onFilterTitleContainerClick}
      >
        {isSingleLevel && (
          <StyledCheckbox
            checked={isSelected}
            color="primary"
          />
        )}
        <FilterTitleText>
          {filter.name[locale] || Object.values(filter.name).filter((it) => it)[0]}
        </FilterTitleText>
        {!isSingleLevel && (
          open
            ? <RemoveIcon style={{ paddingLeft: '4px' }} />
            : <AddIcon style={{ paddingLeft: '4px' }} />
        )}
      </FilterTitleContainer>
      {!isSingleLevel && (
        <Collapse isOpened={open}>
          {filter.subcategories.map((sub) => (
            <FilterCheckboxOption
              key={sub.id}
              filter={sub}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
          ))}
        </Collapse>
      )}
    </MobileFilterCategoryContainerRootContainer>
  );
};

export interface GenericCMSMobileFilterProps {
  filterOptions: BoothTaxonomies[];
  selectedFilter: string[];
  setSelectedFilter: (updater: string[] | ((filter: string[]) => string[])) => null;
  resetSelectedFilter: () => any;
  numOfExhibitors: number;
}

const GenericCMSMobileFilter: React.FC<GenericCMSMobileFilterProps> = ({
  filterOptions,
  selectedFilter,
  setSelectedFilter,
  resetSelectedFilter,
  numOfExhibitors,
}: GenericCMSMobileFilterProps) => {
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 960px)', { defaultMatches: true });

  if (isDesktop) return null;

  return (
    <>
      {openTab && <GreyBackground />}
      <MobileHallFilterContainer>
        <MobileFilterWrapper>
          <MobileFilterOption onClick={() => setOpenTab((prev) => !prev)}>
            Categories
            {
              selectedFilter.length > 0
              && ` (${t('filter.total_filtered_results', 'Filtering results: %{total}', { total: numOfExhibitors })})`
            }
            <ArrowContainer selected={openTab}>
              <ArrowFowardIcon style={{ fontSize: '12px' }} />
            </ArrowContainer>
          </MobileFilterOption>
        </MobileFilterWrapper>
        <Collapse isOpened={openTab}>
          <MobileFilterSelectContainer>
            <FilterListContainer>
              {filterOptions.map((filter) => (
                <MobileFilterCategoryContainer
                  key={filter.id}
                  selectedFilter={selectedFilter}
                  filter={filter}
                  setSelectedFilter={setSelectedFilter}
                />
              ))}
            </FilterListContainer>
            <BottomButtonContainer
              numOfExhibitors={numOfExhibitors}
              resetSelectedFilter={resetSelectedFilter}
              onClose={() => setOpenTab(false)}
            />
          </MobileFilterSelectContainer>
        </Collapse>
      </MobileHallFilterContainer>
    </>
  );
};

export default GenericCMSMobileFilter;
