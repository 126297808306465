import defaultENTranslation from '../../common/en.json';
import defaultJATranslate from '../../common/ja.json';

export const en = {
  page: {
    ...defaultENTranslation.page,
    lobby: 'Lobby',
    hall: 'Exhibition Hall',
    webinar: 'Webinar',
    products: 'Search by Products',
    custom: 'How to utilize virtual expo?',
  },
};

export const ja = {
  page: {
    lobby: 'エントランス',
    hall: '展示会場',
    products: '製品から探す',
    custom: 'オンライン来場・商談サービスの使い方',
  },
  chat: {
    ...defaultJATranslate.chat,
    no_permission: '他社のチャットルームに入ることはできません',
  },
};

export const zhHans = {
  page: {
    lobby: '首页',
    hall: '展览会场',
    products: '搜索产品',
    custom: '在线观展・商谈指南',
  },
};

export const zhHant = {
  page: {
    lobby: '首頁',
    hall: '展覽會場',
    products: '搜尋展品',
    custom: '線上觀展/商談服務使用方式',
  },
};
