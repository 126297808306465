import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector, batch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import Sticky from 'react-sticky-el';
import { useTranslation } from 'react-i18next';
import { APP, PAGE_NAMES } from 'appenv';
import Apps from 'apps';
import {
  fetchParticipantList,
  selectAllParticipants,
  ParticipantsSlice,
} from 'models/participants';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import ParticipantListItem from './ParticipantListItem';

const RootContainer = styled.div`
  max-width: 860px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px auto 32px;
`;

const StyledStickyContainer = styled(Sticky)`
  width: calc(100% + 8px);
  z-index: 10;
  background-color: #F0F2F5;
`;

const HeaderText = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.408px;
  text-transform: capitalize;
  color: #484848;
  align-self: flex-start;
  padding: 16px 4px;
  background-color: #F0F2F5;

  @media (max-width: 960px) {
    padding-left: 20px;
  }
`;

const StyledPaper = withStyles(() => ({
  root: {
    width: '100%',
    padding: '10px 0',
  },
  rounded: {
    borderRadius: '8px',
  },
}))(Paper);

const ParticipantListContainer = ({ scrollableTarget }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const participants = useSelector(selectAllParticipants);
  const {
    initialLoading,
    hasNextPage,
  } = useSelector((state) => state.participants);

  const fetchMore = useCallback(() => {
    dispatch(fetchParticipantList());
  }, [dispatch]);

  useEffect(() => {
    batch(() => {
      dispatch(ParticipantsSlice.actions.resetParticipants());
      fetchMore();
    });
  }, [dispatch, fetchMore]);

  const header = useMemo(() => {
    if (APP === Apps.Eventage824) return PAGE_NAMES.participant;
    return t('page.participants', PAGE_NAMES.participant);
  }, [t]);

  return (
    <RootContainer>
      <StyledStickyContainer
        scrollElement={scrollableTarget}
        stickyStyle={{ zIndex: 10 }}
      >
        <HeaderText>
          {header}
        </HeaderText>
      </StyledStickyContainer>
      <StyledPaper>
        {initialLoading && <CircularLoadingIndicator size={32} />}
        {
          !initialLoading && (
            <InfiniteScroll
              dataLength={participants.length}
              next={fetchMore}
              hasMore={hasNextPage}
              loader={<CircularLoadingIndicator key="loading-indicator" size={32} />}
              scrollableTarget={scrollableTarget}
              scrollThreshold={1}
            >
              {
                participants.map((participant) => {
                  const {
                    name,
                    uuid,
                    customAttendeeFields: {
                      vexpo_chat_tag: userTag,
                      vexpo_chat_user_description: userDescription,
                    },
                  } = participant;
                  return (
                    <ParticipantListItem
                      key={uuid}
                      uuid={uuid}
                      name={name}
                      userTag={userTag}
                      userDescription={userDescription}
                    />
                  );
                })
              }
            </InfiniteScroll>
          )
        }
      </StyledPaper>
    </RootContainer>
  );
};

ParticipantListContainer.propTypes = {
  scrollableTarget: PropTypes.instanceOf(Element),
};

ParticipantListContainer.defaultProps = {
  scrollableTarget: document.body,
};

export default ParticipantListContainer;
