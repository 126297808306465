import React, {
  useMemo,
} from 'react';
import styled from 'styled-components';
import { Booth } from 'models/exhibitors';
import Footer from 'components/footer/Footer';
import BoothPreview from './BoothPreview826Template';

const FLEX_BREAKPOINT = 500;

const ExhibitorsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 960px) {
    height: 100%;
    width: 100%;
    min-height: 600px;
    flex-direction: row;
    position: relative;
    
    & > div {
      position: absolute;
    }

    & > div:nth-child(2) {
      right: 0;
    }

    & > div:nth-child(3) {
      bottom: 0;
    }
  }
`;

const FooterContainer = styled.div`
  margin-top: 40px;
  justify-content: center;

  @media (max-width: 959px) and media (max-height: ${FLEX_BREAKPOINT - 1}px) {
    display: flex;
    flex-basis: 100%;
  }
`;

const IEBoothListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  overflow: auto;
  flex-direction: column;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Hall826Template = ({
  exhibitors = [],
}: {
  exhibitors: Booth[];
}) => {
  const basicExhibitors = useMemo(() => exhibitors.filter((booth) => booth?.type !== 'u'), [exhibitors]);

  return (
    <MainContainer>
      <IEBoothListContainer>
        <ExhibitorsContainer>
          {basicExhibitors.map((booth, index) => (
            <BoothPreview
              key={booth.id}
              booth={booth}
              index={index}
            />
          ))}
        </ExhibitorsContainer>
        <FooterContainer>
          <Footer hashState="/hall" />
        </FooterContainer>
      </IEBoothListContainer>
    </MainContainer>
  );
};

export default Hall826Template;
