import styled from 'styled-components';

const BannerDescriptionLink = styled.a<{ hasLink: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'unset')};
`;

export default BannerDescriptionLink;
