export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/95a55e08-87e4-4ab8-97cd-86669ab9a925.png',
  ],
  url: {
    exhibition_website: 'https://www.material-expo.jp/en-gb.html',
    next_show: '/booth/6G3cHjSanYH6JqfDJ28QJS6QWLVoCwvAsZSGtqpvxyV',
  },
  title: '8th Highly-functional Material Week OSAKA　-VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/01795dfe-4b88-42ba-a6e5-241779a61a7a.png',
  ],
  url: {
    exhibition_website: 'https://www.material-expo.jp/',
    next_show: '/booth/6G3cHjSanYH6JqfDJ28QJS6QWLVoCwvAsZSGtqpvxyV',
  },
  title: 'オンライン来場・商談サービス｜第8回 [関西] 高機能素材Week',
};
