import React, { useContext } from 'react';
import styled from 'styled-components';
import { BoothPitchAttachmentRow, BoothPitchWebinarRow } from 'components/booth/AttachmentRow';
import ShowMore from 'components/utils/ShowMore';
import ReactHtmlParser from 'react-html-parser';
import { CMSFile } from 'models/cmsFile';

const MAX_HEIGHT = 240;

const AttachmentImage = styled.div<{ src: string }>`
  background: url(${({ src }) => src});
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  height: ${MAX_HEIGHT}px;
  width: 180px;
  float: left;
  margin-right: 16px;
`;

const FeaturedDescriptionText = styled.p`
  text-align: justify;
`;

const FeaturedDescriptionTitle = styled.p`
  font-weight: bold;
  text-transform: capitalize;
`;

const FeaturedAttachmentBlockContainer = styled.div<{ upgraded: boolean }>`
  &:not(:first-child) {
    ${({ upgraded }) => upgraded && 'border-top: 1px solid #DBDEE2;'}
  }
`;

const StyledShowMore = styled(ShowMore)`
  box-sizing: unset;
`;

const FeaturedAttachmentBlock = ({
  attachments,
  title,
  webinar,
  thumbnail,
  upgraded,
  description,
  trackingBooth,
}: {
  attachments: unknown[];
  webinar: Record<any, any>;
  title: string;
  upgraded: boolean;
  thumbnail: CMSFile;
  description: string;
  trackingBooth: string;
}) => (
  <FeaturedAttachmentBlockContainer upgraded={upgraded}>
    {upgraded && title && (
    <StyledShowMore
      maxHeight={MAX_HEIGHT + 64}
    >
      <div>
        {/* eslint-disable-next-line camelcase */}
        {thumbnail && <AttachmentImage src={thumbnail?.data?.thumbnails?.find((thumb) => thumb.url.endsWith('directus-medium-contain')).url} aria-label="Attachment Illustration" />}
        <FeaturedDescriptionTitle>{title}</FeaturedDescriptionTitle>
        <FeaturedDescriptionText>{ReactHtmlParser(description)}</FeaturedDescriptionText>
      </div>
    </StyledShowMore>
    )}
    {attachments.map((attachment: any) => (
      <BoothPitchAttachmentRow
        trackingBooth={trackingBooth}
        attachment={attachment}
        key={attachment.id}
      />
    ))}
    {webinar && (!Array.isArray(webinar)) && (
    <BoothPitchWebinarRow
      trackingBooth={trackingBooth}
      webinar={webinar}
      key={webinar.id}
    />
    )}
  </FeaturedAttachmentBlockContainer>
);

export default FeaturedAttachmentBlock;
