import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Sticky from 'react-sticky-el';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PAGE_NAMES } from 'appenv';

import { UserSessionContext } from 'components/UserSession';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import SearchResultContainer from './SearchResultContainer';

const RootContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  overflow: hidden !important;
`;

const StyledSectionTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #999;
  padding: 16px 0;
  background-color: #F0F2F5;
`;

const EndMessage = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #999;
  text-align: center;
`;

const AllSearchResultContainer = ({
  showResult,
  exhibitors,
  webinars,
  participants,
  fetchMore,
  scrollableTarget,
}) => {
  const { t } = useTranslation();
  const { canSee } = useContext(UserSessionContext);
  const { hasNextPage } = useSelector((s) => s.searchResults);
  const dataLength = exhibitors.length + webinars.length + participants.length;

  return (
    <RootContainer>
      {
        showResult && (
          <StyledInfiniteScroll
            dataLength={dataLength}
            hasChildren={dataLength > 0}
            next={fetchMore}
            hasMore={hasNextPage}
            loader={<CircularLoadingIndicator key="loading-indicator" size={32} />}
            endMessage={<EndMessage>{t('search.end_of_result', 'End of Results')}</EndMessage>}
            scrollableTarget={scrollableTarget}
            scrollThreshold={1}
          >
            {
              exhibitors.length > 0 && (
                <>
                  <Sticky
                    scrollElement={scrollableTarget}
                    stickyStyle={{ zIndex: 10 }}
                  >
                    <StyledSectionTitle>
                      {PAGE_NAMES.exhibitors || t('page.exhibitors', 'Exhibitors')}
                    </StyledSectionTitle>
                  </Sticky>
                  <SearchResultContainer
                    type="exhibitor"
                    results={exhibitors}
                  />
                </>
              )
            }
            {
              webinars.length > 0 && (
                <>
                  <Sticky
                    scrollElement={scrollableTarget}
                    stickyStyle={{ zIndex: 10 }}
                  >
                    <StyledSectionTitle>
                      {PAGE_NAMES.webinar || t('page.webinar', 'Webinars')}
                    </StyledSectionTitle>
                  </Sticky>
                  <SearchResultContainer
                    type="webinar"
                    results={webinars}
                  />
                </>
              )
            }
            {
              canSee('participant') && participants.length > 0 && (
                <>
                  <Sticky
                    scrollElement={scrollableTarget}
                    stickyStyle={{ zIndex: 10 }}
                  >
                    <StyledSectionTitle>
                      {PAGE_NAMES.participant || t('page.participants', 'Participants')}
                    </StyledSectionTitle>
                  </Sticky>
                  <SearchResultContainer
                    type="participant"
                    results={participants}
                  />
                </>
              )
            }
          </StyledInfiniteScroll>
        )
      }
    </RootContainer>
  );
};

AllSearchResultContainer.propTypes = {
  showResult: PropTypes.bool,
  exhibitors: PropTypes.arrayOf(PropTypes.shape()),
  webinars: PropTypes.arrayOf(PropTypes.shape()),
  participants: PropTypes.arrayOf(PropTypes.shape()),
  fetchMore: PropTypes.func,
  scrollableTarget: PropTypes.instanceOf(Element),
};

AllSearchResultContainer.defaultProps = {
  showResult: false,
  exhibitors: [],
  webinars: [],
  participants: [],
  fetchMore: () => {},
  scrollableTarget: document.body,
};

export default AllSearchResultContainer;
