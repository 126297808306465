import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const CloseBtnContainer = styled.div`
  display: inline-block;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

const ModalTitleBar = ({ children, onClose }) => (
  <TitleBar>
    <Title>{children}</Title>
    <CloseBtnContainer onClick={onClose}>
      <CloseIcon />
    </CloseBtnContainer>
  </TitleBar>
);

ModalTitleBar.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalTitleBar;
