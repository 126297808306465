import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';
import ArrowFowardIcon from '@material-ui/icons/ArrowForwardIosRounded';
import FilterOptionContainer from './FilterOptionContainer';
import FilterCheckboxOption from './FilterCheckboxOption';

const StyledFilterOptionContainer = styled(FilterOptionContainer)`
  cursor: pointer;
`;

const FilterArrow = styled.div`
  float: right;
  transition: all 0.3s;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

const LabelContainer = styled.div`
  display: inline-block;
`;

const FilterOption = ({
  label,
  options,
  onClick,
  selectedFilter,
  filterOptions,
  topCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <StyledFilterOptionContainer
        selected={selectedFilter.length}
        onClick={() => (setIsOpen(!isOpen))}
      >
        <LabelContainer>
          {label}
          {selectedFilter.length > 0 && ` (${selectedFilter.length})`}
        </LabelContainer>
        <FilterArrow isOpen={isOpen}>
          <ArrowFowardIcon style={{ fontSize: '12px' }} />
        </FilterArrow>
      </StyledFilterOptionContainer>
      {options?.length > 0 && (
        <Collapse isOpened={isOpen}>
          {options?.map((eachOption, index) => (
            <FilterCheckboxOption
              index={index}
              key={eachOption}
              label={eachOption}
              onClick={onClick}
              selectedOption={selectedFilter}
              category={label}
              sumOfCategory={filterOptions?.count?.[label]?.[eachOption]}
              topCategory={topCategory}
            />
          ))}
        </Collapse>
      )}
    </>
  );
};

FilterOption.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedFilter: PropTypes.arrayOf(PropTypes.number),
  onClick: PropTypes.func.isRequired,
  filterOptions: PropTypes.shape(),
  topCategory: PropTypes.string,
};

FilterOption.defaultProps = {
  filterOptions: {},
  selectedFilter: [],
  topCategory: '',
};

export default FilterOption;
