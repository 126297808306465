import axios from 'axios';
import { FETCH_CACHED_CMS_DATA_URL } from 'appenv';
import { eventToken } from './common/cmsConfig';

const fetchCachedCMSData = async () => {
  const response = await axios.get(
    `${FETCH_CACHED_CMS_DATA_URL}/event_${eventToken}_data`,
  );
  try {
    return typeof response.data === 'string'
      ? JSON.parse(
        response.data
          .split('=>').join(':')
          .split('nil').join('null'),
      )
      : response.data;
  } catch (err) {
    console.log(err);
    return null;
  }
};

export default fetchCachedCMSData;
