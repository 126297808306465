export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/9453888f-80af-4c72-bcd6-e544d9c7622a.png',
  ],
  url: {
    exhibition_website: 'https://www.automotiveworld-nagoya.jp/en-gb.html',
    next_show: '/booth/gwXdqP4FUpg2S6S4bdbXxSsMZE6tszgwDAKqbsxdzZRn',
  },
  title: '3rd AUTOMOTIVE WORLD Nagoya　-VIRTUAL-',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/71db7d3f-aea5-4a02-9a05-34024ce558db.png',
  ],
  url: {
    exhibition_website: 'https://www.automotiveworld-nagoya.jp/',
    next_show: '/booth/gwXdqP4FUpg2S6S4bdbXxSsMZE6tszgwDAKqbsxdzZRn',
  },
  title: 'オンライン来場・商談サービス｜第3回 名古屋 オートモーティブ ワールド',
};
