export const en = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/c8647d3e-ca73-490e-8e9c-f2969a94666a.png',
  ],
  url: {
    exhibition_website: 'https://www.japan-it-autumn.jp/en-gb.html',
    next_show: '/booth/5T6PmpYw62gEh1YfYvHVanBFxvWUH68frpEf2RpEJphe',
  },
  title: '11th Japan IT Week Autumn -VIRTUAL- ',
};

export const ja = {
  logos: [
    'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/62fb62ef-2562-4536-aaec-364212f9f14c.png',
  ],
  url: {
    exhibition_website: 'https://www.japan-it-autumn.jp/ja-jp.html',
    next_show: '/booth/5T6PmpYw62gEh1YfYvHVanBFxvWUH68frpEf2RpEJphe',
  },
  title: 'オンライン来場・商談サービス｜第11回 Japan IT Week 秋',
};
