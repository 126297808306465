import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import { styled as muiStyled } from '@material-ui/core/styles';

const handleAvatarColorType = (letter) => {
  switch (letter) {
    case 'A':
    case 'L':
    case 'W':
      return '#CF1322';
    case 'B':
    case 'M':
    case 'X':
      return '#722ED1';
    case 'C':
    case 'N':
    case 'Y':
      return '#1D39C4';
    case 'D':
    case 'O':
    case 'Z':
      return '#096DD9';
    case 'E':
    case 'P':
      return '#1890FF';
    case 'F':
    case 'Q':
      return '#00BCD4';
    case 'G':
    case 'R':
      return '#FA541C';
    case 'H':
    case 'S':
      return '#FA8C16';
    case 'I':
    case 'T':
      return '#009688';
    case 'J':
    case 'U':
      return '#4CAF50';
    case 'K':
    case 'V':
      return '#A2AAB3';
    default:
      return '#A2AAB3';
  }
};

const StyledUserAvatar = styled.div`
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
  color: #FFF;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'unset')};
  background-color: ${({ backgroundColor }) => (backgroundColor)};
  display: flex;
  justify-content: center;
`;

const StyledPersonIcon = muiStyled(PersonIcon)({
  width: '100%',
  height: '100%',
  padding: '10%',
});

const UserAvatar = ({ name, handleOnClick, children }) => {
  const initial = !!name && name.length > 0 ? name[0].toUpperCase() : '';
  const backgroundColor = handleAvatarColorType(initial);

  return (
    <StyledUserAvatar clickable={handleOnClick} onClick={handleOnClick} backgroundColor={backgroundColor}>
      {
        children || initial || <StyledPersonIcon />
      }
    </StyledUserAvatar>
  );
};

UserAvatar.propTypes = {
  name: PropTypes.string,
  handleOnClick: PropTypes.func,
  children: PropTypes.element,
};

UserAvatar.defaultProps = {
  name: '',
  handleOnClick: null,
  children: null,
};

export default UserAvatar;
