import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    maxWidth: '100%',
    margin: '0 20px',
    letterSpacing: '0.3px',
    color: '#484848',
  },
  item: {
    margin: '12px 0',
  },
  label: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#A2AAB3',
    paddingTop: '4px',
    marginBottom: '11px',
  },
  text: {
    lineHeight: '24px',
    whiteSpace: 'break-spaces',
    textAlign: 'justify',
    paddingBottom: '8px',
  },
  bold: {
    color: '#484848',
    fontWeight: 'bold',
  },
}));

const BoothDetails = ({
  industryVerticals,
  solutionDescription,
  fundingStage,
  businessModel,
  strategicPlan,
  teamStructure,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        {
          industryVerticals && (
            <div className={classes.item}>
              <div className={classes.label}>
                INDUSTRY VERTICALS
              </div>
              {
                Object.entries(industryVerticals).map(([category, content]) => (
                  <div key={category} className={classes.text}>
                    <span className={classes.bold}>
                      {category}
                      : &nbsp;
                    </span>
                    {content.join(', ')}
                  </div>
                ))
              }
            </div>
          )
        }
        {
          solutionDescription && (
            <div className={classes.item}>
              <div className={classes.label}>
                HOW WE SERVE
              </div>
              <div className={classes.text}>
                {ReactHtmlParser(solutionDescription)}
              </div>
            </div>
          )
        }
        {
          fundingStage && (
            <>
              <br />
              <div className={classes.item}>
                <div className={classes.label}>
                  FUNDING STAGE
                </div>
                <div className={classes.text}>
                  {fundingStage}
                </div>
              </div>
            </>
          )
        }
        {
          businessModel && (
            <div className={classes.item}>
              <div className={classes.label}>
                OUR BUSINESS MODEL
              </div>
              <div className={classes.text}>
                {ReactHtmlParser(businessModel)}
              </div>
            </div>
          )
        }
        {
          strategicPlan && (
            <div className={classes.item}>
              <div className={classes.label}>
                OUR PLAN
              </div>
              <div className={classes.text}>
                {ReactHtmlParser(strategicPlan)}
              </div>
            </div>
          )
        }
        {
          teamStructure && (
            <div className={classes.item}>
              <div className={classes.label}>
                OUR TEAM
              </div>
              <div className={classes.text}>
                {teamStructure}
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};

BoothDetails.propTypes = {
  industryVerticals: PropTypes.shape().isRequired,
  solutionDescription: PropTypes.string.isRequired,
  fundingStage: PropTypes.string.isRequired,
  businessModel: PropTypes.string.isRequired,
  strategicPlan: PropTypes.string.isRequired,
  teamStructure: PropTypes.string.isRequired,
};

export default BoothDetails;
