import React, { useMemo, useEffect } from 'react';
import { EVENT_NAME, APP } from 'appenv';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import HtmlParser from 'react-html-parser';
import { CircularLoadingHider } from 'components/indicators/CircularLoadingIndicator';
import { HorizontalDivider } from 'components/utils/Divider';
import groupBy from 'lodash/groupBy';
import { selectAllWebinars, selectWebinarById } from '../../../models/webinars';
import WebinarSpeakerSessions from './WebinarSpeakerSessions';
import ShowMore from '../../../components/utils/ShowMore';

const locale = 'en';

const ContentContainer = styled.div`
  display: flex;
  padding: 40px 80px;
  align-items: center;
  justify-content: center;

  @media (max-width: 960px) {
    padding: 16px;
  }
`;

const ContentSizeLimit = styled.div`
  max-width: 820px;
  width: 100%;
`;

const SpeakerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 26px;
  flex-direction: column;

  @media only screen and (min-width: 960px) {
    justify-content: start;
    flex-direction: row;
  }
`;

const SpeakerName = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  color: #484848;
  margin-top: 16px;

  @media only screen and (min-width: 960px) {
    margin-top: 0;
  }
`;

const SpeakerAvatar = styled(Avatar)`
  width: 100px !important;
  height: 100px !important;
`;

const SpeakerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 960px) {
    margin-left: 24px;
    align-items: flex-start;
  }
`;

const SpeakerInfoRow = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: #484848;
  margin-top: 16px;

  @media only screen and (min-width: 960px) {
    margin-top: 8px;
  }
`;

const SpeakerAboutMe = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: #484848;
`;

const SectionTitle = styled.h2`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #484848;
`;

const ContactContainer = styled.div`
  margin-top: 24px;
`;

const ContactTitle = styled.div`
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  color: #A2AAB3;
`;

const ContactLink = styled.a`
  display: block;
  margin-top: 11px;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.31875px;
  color: #5C8DF3;
  text-decoration: none;
`;

const WebinarSpeakerDetailsContainer = () => {
  const { webinarId, speakerId } = useParams();
  const { t } = useTranslation();

  /** @type {Webinar[]} */
  const webinars = useSelector(selectAllWebinars);

  /** @type {Webinar} */
  const webinar = useSelector((s) => selectWebinarById(s, webinarId));

  const speaker = useMemo(() => (
    webinar?.speakers?.find((findingSpeaker) => findingSpeaker.id === parseInt(speakerId, 10)) ?? null
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [webinar, speakerId]);

  const speakerName = speaker?.translations[locale]?.name;
  useEffect(() => {
    if (speakerId) {
      window.trackingEvent('MainMenu_Webinar', 'Speaker_DetailPage_Enter', `Speaker-${speakerId} - ${speakerName}`);
    }
  }, [speakerId, speakerName]);

  const speakerWebinars = useMemo(() => (
    webinars.filter((it) => (
      it.speakers.find((findSpeaker) => (
        findSpeaker.translations.en.name === speaker?.translations?.en?.name
      ))
    ))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [webinars, speaker]);

  const speakerContacts = speaker?.contacts || {};
  const contacts = useMemo(() => groupBy(Object.values(speakerContacts), (s) => s.type), [speakerContacts]);

  const loaded = useMemo(() => !!webinar && !!webinars && !!speaker && !!speakerWebinars, [webinar, webinars, speaker, speakerWebinars]);

  const [showShowMoreOnAbout, showSession] = useMemo(() => {
    switch (APP) {
      case 'scmp711': return [false, false];
      default: return [true, true];
    }
  }, []);

  return (
    <>
      {loaded && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${speaker.translations[locale].name} | ${EVENT_NAME}`}</title>
          <meta name="description" content={`Detail of ${speaker.translations[locale].name}, speaker in ${EVENT_NAME}.`} />
        </Helmet>
      )}
      <CircularLoadingHider condition={loaded}>
        {loaded && (
          <ContentContainer>
            <ContentSizeLimit>
              <SpeakerHeader>
                {/* eslint-disable-next-line camelcase */}
                <SpeakerAvatar src={speaker.profile_picture_file?.data?.full_url ?? speaker.profile_picture_url} />
                <SpeakerInfo>
                  <SpeakerName>{speaker.translations[locale].name}</SpeakerName>
                  <SpeakerInfoRow>{speaker.translations[locale].company}</SpeakerInfoRow>
                  <SpeakerInfoRow>{speaker.translations[locale].title}</SpeakerInfoRow>
                </SpeakerInfo>
              </SpeakerHeader>
              {
                Object.keys(contacts).length > 0 && (
                  <>
                    <SectionTitle>Contact Info</SectionTitle>
                      {contacts.email && (
                        <ContactContainer>
                          <ContactTitle>Email</ContactTitle>
                          {contacts.email.map(({ contact }) => (<ContactLink key={contact} href={`mailto:${contact}`}>{contact}</ContactLink>))}
                        </ContactContainer>
                      )}
                    {contacts.phone && (
                      <ContactContainer>
                        <ContactTitle>Phone</ContactTitle>
                        {contacts.phone.map(({ contact }) => (<ContactLink key={contact} href={`tel:${contact.split(' ').join('')}`}>{contact}</ContactLink>))}
                      </ContactContainer>
                    )}
                    {Object.entries(contacts).map(([type, data]) => {
                      if (type === 'email' || type === 'phone') return null;
                      return (
                        <ContactContainer key={type}>
                          <ContactTitle>{type}</ContactTitle>
                          {data.map(({ contact }) => (<ContactLink key={contact} href={contact}>{contact}</ContactLink>))}
                        </ContactContainer>
                      );
                    })}
                  </>
                )
              }
              {
                (speaker.translations[locale]?.bio?.trim()?.length ?? 0) > 0 && (
                  <>
                    {Object.keys(contacts).length > 0 && <HorizontalDivider />}
                    <SectionTitle>{t('webinar.about_me', 'About me')}</SectionTitle>
                    <SpeakerAboutMe>
                      {
                        showShowMoreOnAbout
                          ? (
                            <ShowMore maxHeight={150}>
                              {HtmlParser(speaker.translations[locale].bio)}
                            </ShowMore>
                          )
                          : <>{HtmlParser(speaker.translations[locale].bio)}</>
                      }
                    </SpeakerAboutMe>
                  </>
                )
              }
              {showSession && (
                <>
                  {(Object.keys(contacts).length > 0 || (speaker.translations[locale]?.bio?.trim()?.length ?? 0) > 0) && (
                    <HorizontalDivider />
                  )}
                  <SectionTitle>{t('webinar.sessions', 'Sessions')}</SectionTitle>
                  <WebinarSpeakerSessions webinars={speakerWebinars} />
                </>
              )}
            </ContentSizeLimit>
          </ContentContainer>
        )}
      </CircularLoadingHider>
    </>
  );
};

export default WebinarSpeakerDetailsContainer;
