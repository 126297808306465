import React, { useContext, useMemo } from 'react';
import { BRAND_COLOR } from 'appenv';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import Tab, { TabProps } from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { Booth } from 'models/exhibitors';
import { CMSFile } from 'models/cmsFile';

import BoothContacts from '../../components/BoothContacts';
import BoothProfile from '../../components/BoothProfile';
import BoothFeaturedPitch from '../../components/BoothFeaturedPitch';
import { BoothProfileTabsContext } from '../../components/BoothProfileTabsContext';

const Container = styled.div<{ isDesktop: boolean }>`
  width: ${({ isDesktop }) => (isDesktop ? '70%' : '100%')};
  height: auto;
  margin: 0 auto;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  background-color: #fff;
  letter-spacing: 0.3px;

  & > div {
    overflow: ${({ isDesktop }) => (isDesktop ? 'hidden' : 'unset')};
  }

  .MuiTab-wrapper {
    font-size: ${({ isDesktop }) => (isDesktop ? '24px' : '18px')};
  }

  button.MuiButtonBase-root.MuiTab-root{
    margin-right: ${({ isDesktop }) => (isDesktop ? '40px' : '20px')};
  }
`;

interface TabsPropsFixed extends Omit<TabsProps, 'onChange'> {
  onChange:
    | ((event: React.ChangeEvent<Record<string, unknown>>, value: any) => void)
    | ((event: React.FormEvent<HTMLButtonElement>) => void);
}

const StyledTabs = withStyles({
  root: {
    padding: '24px 24px 0px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '4px',
    '& > div': {
      maxWidth: 139,
      width: '100%',
      backgroundColor: BRAND_COLOR || '#0CA58B',
    },
  },
// eslint-disable-next-line react/jsx-props-no-spreading
})((props: TabsPropsFixed) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#A2AAB3',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: 'Lato',
    padding: '0',
    minWidth: 'unset',
    '&:focus': {
      opacity: 1,
    },
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
// eslint-disable-next-line react/jsx-props-no-spreading
}))((props: TabProps) => <Tab disableRipple {...props} />);

const TabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && (
      children
    )}
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

declare global {
  interface Window {
    trackingEvent: (eventName: string, tabName: string, id: string | number) => any;
  }
}

interface ProcessedBooth extends Booth {
  attachments: CMSFile[];
  name: string;
  description: string;
  emailContacts: unknown[];
  extraFields: unknown;
  coreTechnology: string;
  hashtags: string;
}

const TabBar = ({ tabs, activeTabKey, onActiveTabKeyChange }: {
  tabs: Record<string, BoothTab>;
  activeTabKey: number;
  onActiveTabKeyChange: (event: React.ChangeEvent<Record<string, unknown>>, tabKey: number) => void;
}) => (
  <StyledTabs scrollButtons="off" variant="scrollable" value={activeTabKey} onChange={onActiveTabKeyChange} aria-label="Booth Tabs">
    {Object.keys(tabs).map((tab) => (
      tabs[tab].show && (<StyledTab label={tabs[tab].label} />)
    ))}
  </StyledTabs>
);

const TabView = ({ tabs, activeTabKey }: {
  tabs: Record<string, BoothTab>;
  activeTabKey: number;
}) => (
  <SwipeableViews
    axis="x"
    index={activeTabKey}
    disabled
    ignoreNativeScroll
    resistance
  >
    {Object.keys(tabs).filter((tab) => tabs[tab].show).map((tab, index) => (
      <TabPanel key={tab} value={activeTabKey} index={index}>
        {tabs[tab].el}
      </TabPanel>
    ))}
  </SwipeableViews>
);

interface BoothTab {
  label: string;
  show: boolean;
  el: React.ReactElement;
}

const BoothProfileTabsContainer = ({
  booth: {
    id,
    attachments,
    name,
    description,
    website,
    contacts,
    type,
    emailContacts,
    extraFields,
    coreTechnology,
    hashtags,
  },
  isDesktop,
}: {
  booth: ProcessedBooth;
  isDesktop: boolean;
}) => {
  const { activeTabKey, setActiveTabKey } = useContext(BoothProfileTabsContext);
  const onActiveTabKeyChange = (event, tabKey) => {
    window.trackingEvent('Booth', `Booth_Profile_Tab_${tabKey + 1}_Click`, `${id} - ${name}`);
    setActiveTabKey(tabKey);
  };

  const hasAttachments = attachments?.length > 0;

  const oneLiner = extraFields['1-liner'] || '';

  const tabs: Record<string, BoothTab> = useMemo(() => ({
    description: {
      label: 'Developer',
      show: true,
      el: (
        <BoothProfile
          name={name}
          description={description}
          website={website}
          coreTechnology={coreTechnology}
          hashtags={hashtags}
          contacts={emailContacts}
          oneLiner={oneLiner}
          key="profile"
        />
      ),
    },
    files: {
      label: 'Project(s)',
      show: hasAttachments,
      el: (
        <BoothFeaturedPitch trackingBooth={`${id} - ${name}`} upgraded={type === 'u'} attachments={attachments} key="attachments" />
      ),
    },
    contacts: {
      label: 'Contact Us',
      show: contacts.filter((contact) => !!contact?.type).length > 0,
      el: (
        <BoothContacts contacts={contacts} key="contacts" />
      ),
    },
  }), [
    name,
    description,
    website,
    coreTechnology,
    hashtags,
    emailContacts,
    oneLiner,
    hasAttachments,
    id,
    type,
    attachments,
    contacts,
  ]);

  return (
    <Container isDesktop={isDesktop}>
      <TabBar
        tabs={tabs}
        activeTabKey={activeTabKey}
        onActiveTabKeyChange={onActiveTabKeyChange}
      />
      <TabView
        tabs={tabs}
        activeTabKey={activeTabKey}
      />
    </Container>
  );
};

export default BoothProfileTabsContainer;
