import React from 'react';
import styled from 'styled-components';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';

const RootContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledH2 = styled.h2`
  margin-left: 16px;
`;

const StyledCircularLoadingIndicator = styled(CircularLoadingIndicator)`
  margin: 0;
`;

const AuthenticatingIndicator = () => (
  <RootContainer>
    <StyledCircularLoadingIndicator />
    <StyledH2>Authenticating ...</StyledH2>
  </RootContainer>
);

export default AuthenticatingIndicator;
