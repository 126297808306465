import merge from 'lodash/merge';
import Apps from 'apps';
import { envSwitch } from 'utils/envUtils';
import en from './common/en.json';
import idID from './common/id-ID.json';
import zhHans from './common/zh-Hans.json';
import zhHant from './common/zh-Hant.json';
import ja from './common/ja.json';
import ko from './common/ko.json';

import reed from './extra/reed';
import reed902 from './extra/reed902';
import reedMonoN909 from './extra/reedMonoN909';
import reedCosme909 from './extra/reedCosme909';
import reedUi909 from './extra/reedUi909';
import reedSoumn916 from './extra/reedSoumn916';
import reedEdix916 from './extra/reedEdix916';
import reedMonoK1007 from './extra/reedMonoK1007';
import reedMWK1007 from './extra/reedMWK1007';
import reedAGRIToolGAR1014 from './extra/reedAGRIToolGAR1014';
import reedJPFood1014 from './extra/reedJPFood1014';
import reedMJTokyo1014 from './extra/reedMJTokyo1014';
import reedNwnAutonRo1021 from './extra/reedNwnAutonRo1021';
import reedContentLJ1021 from './extra/reedContentLJ1021';
import reedFaAki1027 from './extra/reedFaAki1027';
import reedIOFT1027 from './extra/reedIOFT1027';
import reedIJTaki1028 from './extra/reedIJTaki1028';
import reedAIaki1028 from './extra/reedAIaki1028';
import reedITaki1028 from './extra/reedITaki1028';
import reedCBW1028 from './extra/reedCBW1028';

export const commonResources = {
  en: { translation: en },
  'id-ID': { translation: idID },
  'zh-Hans': { translation: zhHans },
  'zh-Hant': { translation: zhHant },
  ja: { translation: ja },
  ko: { translation: ko },
};

export const extraResources = envSwitch([
  [Apps.Reed902, [reed, reed902]],
  [Apps.ReedMonoN909, [reed, reedMonoN909]],
  [Apps.ReedCosme909, [reed, reedCosme909]],
  [Apps.ReedUi909, [reed, reedUi909]],
  [Apps.ReedSoumn916, [reed, reedSoumn916]],
  [Apps.ReedEdix916, [reed, reedEdix916]],
  [Apps.ReedMonoK1007, [reed, reedMonoK1007]],
  [Apps.ReedMWK1007, [reed, reedMWK1007]],
  [Apps.ReedAGRIToolGAR1014, [reed, reedAGRIToolGAR1014]],
  [Apps.ReedJPFood1014, [reed, reedJPFood1014]],
  [Apps.ReedMJTokyo1014, [reed, reedMJTokyo1014]],
  [Apps.ReedNwnAutonRo1021, [reed, reedNwnAutonRo1021]],
  [Apps.ReedContentLJ1021, [reed, reedContentLJ1021]],
  [Apps.ReedFaAki1027, [reed, reedFaAki1027]],
  [Apps.ReedIOFT1027, [reed, reedIOFT1027]],
  [Apps.ReedIJTaki1028, [reed, reedIJTaki1028]],
  [Apps.ReedAIaki1028, [reed, reedAIaki1028]],
  [Apps.ReedITaki1028, [reed, reedITaki1028]],
  [Apps.ReedCBW1028, [reed, reedCBW1028]],
], [] as any);

export const resources = extraResources
  .reduce((sum: any, extra: any) => merge(sum, extra), commonResources);
