import React from 'react';

import createSvgIcon from 'components/icons/utils/createSvgIcon';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M0.268066 537.406L1145.77 537.756L1025.86 330.692L1033.14 87.1125L1041.18 78.3683C1041.18 78.3683 1063.85 10.443 1068.47 0.649414C821.599 31.709 563.398 49.897 78.8965 1.62877L109.746 87.2524L110.446 347.621L106.388 349.44L0.268066 537.406Z"
  />,
  'BoothBackdrop',
  { viewBox: '0 0 1146 538' },
);
