import useURLSearchParams from 'hooks/useURLSearchParams';
import { useHistory } from 'react-router-dom';
import {
  useCallback, useMemo, useEffect, useState,
} from 'react';
import Routes from 'Routes';
import { ENABLED_FEATURES } from 'appenv';

const isAllLeafKvPairEmpty = (selectedOptions) => {
  if (typeof selectedOptions === 'object' && selectedOptions !== null) {
    return Object.keys(selectedOptions)
      .every((key) => isAllLeafKvPairEmpty(selectedOptions[key]));
  }
  return (selectedOptions !== null && !!selectedOptions.length);
};

const useCMSFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState(() => {
    try {
      const fromStorage = JSON.parse(localStorage.getItem('boothFilter'));
      if (!Array.isArray(fromStorage)) return [];
      return fromStorage || [];
    } catch {
      return [];
    }
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      localStorage.setItem('boothFilter', JSON.stringify(selectedFilter));
    }, 500);
    return () => clearTimeout(timer);
  }, [selectedFilter]);

  const resetSelectedFilter = useCallback(() => setSelectedFilter([]), [setSelectedFilter]);

  return {
    selectedFilter,
    setSelectedFilter,
    resetSelectedFilter,
    subLevelOnClick: () => null,
    onClick: () => null,
  };
};

const useFilter = () => {
  const query = useURLSearchParams();
  const history = useHistory();

  const setSelectedFilter = useCallback((filter) => {
    localStorage.removeItem('boothFilter');

    if (isAllLeafKvPairEmpty(filter)) {
      query.delete('selectedFilter');
    } else {
      query.set('selectedFilter', JSON.stringify(filter));
    }

    history.replace({
      pathname: Routes.hall,
      search: `?${query.toString()}`,
      state: history.location.state,
    });
  }, [query, history]);

  const selectedFilter = useMemo(() => {
    try {
      const fromQuery = JSON.parse(query.get('selectedFilter'));
      const fromStorage = JSON.parse(localStorage.getItem('boothFilter'));
      if (fromQuery === null && !isAllLeafKvPairEmpty(fromStorage)) setSelectedFilter(fromStorage);
      return fromQuery || fromStorage || {};
    } catch {
      setSelectedFilter({});
      return {};
    }
  }, [setSelectedFilter, query]);

  useEffect(() => {
    localStorage.setItem('boothFilter', JSON.stringify(selectedFilter));
  }, [selectedFilter]);

  const subLevelOnClick = useCallback((category, index, subCategory) => {
    let newSearchResult;
    if (selectedFilter?.[subCategory]?.[category]) {
      if (selectedFilter[subCategory][category].includes(index)) {
        newSearchResult = {
          ...selectedFilter,
          [subCategory]: {
            ...selectedFilter[subCategory],
            [category]: selectedFilter[subCategory][category].filter((eachSelectedFilter) => eachSelectedFilter !== index),
          },
        };
      } else {
        newSearchResult = {
          ...selectedFilter,
          [subCategory]: {
            ...selectedFilter[subCategory],
            [category]: [...selectedFilter[subCategory][category], index],
          },
        };
      }
    } else {
      newSearchResult = {
        ...selectedFilter,
        [subCategory]: {
          ...selectedFilter[subCategory],
          [category]: [index],
        },
      };
    }
    setSelectedFilter(newSearchResult);
  }, [selectedFilter, setSelectedFilter]);

  const onClick = useCallback((category, index) => {
    let newSearchResult;
    if (selectedFilter[category]) {
      if (selectedFilter[category].includes(index)) {
        newSearchResult = {
          ...selectedFilter,
          [category]: selectedFilter[category].filter((eachSelectedFilter) => eachSelectedFilter !== index),
        };
      } else {
        newSearchResult = {
          ...selectedFilter,
          [category]: [...selectedFilter[category], index],
        };
      }
    } else {
      newSearchResult = {
        ...selectedFilter,
        [category]: [index],
      };
    }
    setSelectedFilter(newSearchResult);
  }, [selectedFilter, setSelectedFilter]);

  const resetSelectedFilter = useCallback(() => setSelectedFilter({}), [setSelectedFilter]);

  return {
    selectedFilter,
    setSelectedFilter,
    subLevelOnClick,
    onClick,
    resetSelectedFilter,
  };
};

export default ENABLED_FEATURES.xtraCMS
  ? useCMSFilter
  : useFilter;
