import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EmptyStatePlaceholder from 'components/search/EmptyStatePlaceholder';
import SearchResultList from './SearchResultList';

const RootContainer = styled.div`
  margin: 16px 0;
  overflow: hidden;
`;

const SearchResultContainer = ({
  type,
  results,
}) => (
  <RootContainer>
    {
      results.length > 0 ? (
        <SearchResultList
          type={type}
          results={results}
        />
      ) : (
        <EmptyStatePlaceholder />
      )
    }
  </RootContainer>
);

SearchResultContainer.propTypes = {
  type: PropTypes.oneOf([
    'exhibitor',
    'webinar',
    'participant',
  ]),
  results: PropTypes.arrayOf(PropTypes.shape()),
};

SearchResultContainer.defaultProps = {
  type: 'exhibitor',
  results: [],
};

export default SearchResultContainer;
