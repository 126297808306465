import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import Truncate from 'react-truncate';

import Routes from 'Routes';
import { SearchFieldContext } from 'components/SearchFieldContext';
import TextHighlighter from 'components/TextHighlighter';
import LogoPlaceholder from 'assets/company-logo-placeholder.svg';

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #17181E;
  background-color: #FFF;
  border-radius: 4px;
  padding: 10px 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '54px';
      case 'standard':
      default:
        return '90px';
    }
  }};
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const StyledImg = styled.img`
  flex: 0 0 auto;
  max-height:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '32px';
      case 'standard':
      default:
        return '64px';
    }
  }};
  max-width:
    ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return '32px';
      case 'standard':
      default:
        return '64px';
    }
  }};
`;

const InfoContainer = styled.div`
  margin-left: 16px;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
`;

const NameContainer = styled.div`
  flex: 1 0 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #262626;
  margin-bottom: 5px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DescriptionContainer = styled.div`
  flex: 1 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #242424;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ExhibitorSearchResultItem = ({
  id,
  name,
  logo,
  description,
  variant,
}) => {
  const { searchKeyword } = useContext(SearchFieldContext);

  return (
    <StyledLink to={Routes.booth(id)} variant={variant}>
      {logo && <StyledImg src={logo} variant={variant} />}
      <InfoContainer>
        <NameContainer>
          <TextHighlighter
            searchWords={[searchKeyword]}
            textToHighlight={name}
          />
        </NameContainer>
        <DescriptionContainer>
          <Truncate lines={1} ellipsis="...">
            {ReactHtmlParser(description)}
          </Truncate>
        </DescriptionContainer>
      </InfoContainer>
    </StyledLink>
  );
};

ExhibitorSearchResultItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string,
  description: PropTypes.string,
  variant: PropTypes.oneOf([
    'compact',
    'standard',
  ]),
};

ExhibitorSearchResultItem.defaultProps = {
  logo: LogoPlaceholder,
  description: '',
  variant: 'standard',
};

export default ExhibitorSearchResultItem;
