import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';

const Container = styled.div`
  position: absolute;
  top: 0;
  padding: 10px;
  color: #FFF;
  width: 100%;
  height: 100%;
  background: linear-gradient(150.97deg, #0CA58B 25%, #0E7ABC 75%);
  display: flex;
  flex-direction: column;
`;

const DescriptionContainer = styled.div`
  flex: 1 1 auto;
  position: relative;
  line-height: 18px;
  font-size: 14px;
  overflow: hidden;
  text-align: justify;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DescriptionText = styled.div`
  flex: 0 0 auto;
  height: 72px;
  width: 100%;
  overflow: hidden;
  mask-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0)
  );
`;

const FlexboxContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NameText = styled.div`
  font-size: 16px;
  font-weight: 900;
`;

const BoothNumContainer = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
`;

const ProfileBtn = styled.button`
  flex: 0 0 auto;
  width: 102px;
  background: #FFF;
  border-radius: 4px;
  color: #484848;
  padding: 4px 8px;
  text-decoration: unset;
  cursor: pointer;
`;

const BoothNumberText = styled.div`
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
  margin-top: 8px;
`;

const Booth608Description = ({
  name,
  boothNumber,
  description,
  handleClick,
}) => (
  <Container>
    <FlexboxContainer>
      <NameText>{(boothNumber || '') + name}</NameText>
      {
        boothNumber && (
          <BoothNumContainer>
            <div>Table</div>
            <BoothNumberText>
              {boothNumber}
            </BoothNumberText>
          </BoothNumContainer>
        )
      }
    </FlexboxContainer>
    <DescriptionContainer>
      <DescriptionText>
        {ReactHtmlParser(description)}
      </DescriptionText>
    </DescriptionContainer>
    <ProfileBtn
      onClick={() => handleClick('desk')}
    >
      View More
    </ProfileBtn>
  </Container>
);

Booth608Description.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  boothNumber: PropTypes.string,
  description: PropTypes.string,
};

Booth608Description.defaultProps = {
  boothNumber: null,
  description: '',
};

export default Booth608Description;
