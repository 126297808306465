import { availableLocales } from 'models/localization';
import { APP } from 'appenv';
import Apps, { reedApps } from 'apps';

const findAvailableLocale = (obj: Record<string, any> = {}, locale: string) => {
  let val = obj[locale];
  let resolvedAvailableLocales: string[] = availableLocales;
  if (reedApps.includes(APP as Apps) && (locale || '').startsWith('zh')) {
    resolvedAvailableLocales = ['en', 'zh-Hans', 'zh-Hant', 'ja'];
  }
  let localeIdx = 0;
  while (!val && localeIdx < resolvedAvailableLocales.length) {
    val = obj[resolvedAvailableLocales[localeIdx]];
    localeIdx += 1;
  }
  return val;
};
export default findAvailableLocale;
