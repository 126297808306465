import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Collapse } from 'react-collapse';

import CollapseIcon from 'assets/collapseIcon.svg';
import { useTranslation } from 'react-i18next';
import ChatConversationListItem711 from './ChatConversationListItem711';

const StyledCollapseBtn = styled.div`
  font-size: 14px;
  color: #484848;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.5s ease;

  :hover {
    background-color: #F0F1F2;
  }
`;

const StyledCollapseIcon = styled.img`
  margin: 6px 8px 6px 6px;
  vertical-align: middle;
  transition: transform 0.2s ease;
  transform: ${({ isOpened }) => (isOpened ? 'rotate(0deg)' : 'rotate(-180deg)')};
`;

const CollapsibleChatGroupView711 = ({
  chatList,
  type,
  setChatTarget,
  userRole,
}) => {
  const [isChatOpened, setIsChatOpened] = useState(true);
  const { t } = useTranslation();

  let btnText = '';
  switch (type) {
    case 'group':
      btnText = t(
        'chat.joined_rooms.group',
        `Joined Chatrooms (${chatList.length})`,
        { chatListLength: chatList.length },
      );
      break;
    case 'private':
      btnText = t(
        'chat.joined_rooms.private',
        `Direct Messages (${chatList.length})`,
        { chatListLength: chatList.length },
      );
      break;
    default:
  }

  return (
    <>
      <StyledCollapseBtn onClick={() => setIsChatOpened(!isChatOpened)}>
        <StyledCollapseIcon
          isOpened={isChatOpened}
          src={CollapseIcon}
          alt="collapse icon"
        />
        {btnText}
      </StyledCollapseBtn>
      <Collapse isOpened={isChatOpened}>
        {
          chatList.map((conversation) => (
            <ChatConversationListItem711
              key={conversation.id}
              conversation={conversation}
              setChatTarget={setChatTarget}
              userRole={userRole}
            />
          ))
        }
      </Collapse>
    </>
  );
};

CollapsibleChatGroupView711.propTypes = {
  chatList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    conversationType: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    tag: PropTypes.string,
    latestMessage: PropTypes.shape({
      messageType: PropTypes.string,
      content: PropTypes.shape({
        content: PropTypes.string,
        user: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    }),
    unreadMessageCount: PropTypes.number,
  })).isRequired,
  type: PropTypes.oneOf(['group', 'private']).isRequired,
  setChatTarget: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default CollapsibleChatGroupView711;
