import React from 'react';
import styled from 'styled-components';

const BoothImageContainer = styled.img`
  width: 100%;
  height: auto;
`;

const BoothImage = ({ imageSrc }: {
  imageSrc: string;
}) => <BoothImageContainer src={imageSrc} />;

export default BoothImage;
