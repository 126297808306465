import React, { useCallback } from 'react';
import { APP } from 'appenv';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import playButton from 'assets/play-button.png';

const PosterImgContainer = styled.div`
  margin-top: 6.7px;
  margin-left: 9px;
  height: 383.4px;
  width: 182.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 100%;
`;

const CenteredPlayImg = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const BoothPullUpBanner = ({
  pullUpBanner,
  mainSrc,
  setShowBannerModal,
  isVideo,
}) => {
  const posterOnClick = useCallback(() => {
    if (APP === 'polyu618') {
      window.open(mainSrc || pullUpBanner);
    } else {
      setShowBannerModal(true);
    }
  }, [pullUpBanner, mainSrc, setShowBannerModal]);
  return (
    <PosterImgContainer onClick={posterOnClick}>
      <StyledImg src={pullUpBanner} />
      {isVideo && <CenteredPlayImg src={playButton} alt="Play Button" />}
    </PosterImgContainer>
  );
};

BoothPullUpBanner.propTypes = {
  pullUpBanner: PropTypes.string.isRequired,
  mainSrc: PropTypes.string,
  setShowBannerModal: PropTypes.func.isRequired,
  isVideo: PropTypes.bool,
};

BoothPullUpBanner.defaultProps = {
  mainSrc: '',
  isVideo: false,
};

export default BoothPullUpBanner;
