const Routes = {
  root: '/',
  landing: '/landing',
  lobby: '/main-lobby',
  booth: (boothId) => (boothId ? `/booth/${boothId}` : '/booth/:boothId'),
  hall: '/hall',
  webinar: '/webinar',
  webinarSpeaker: (webinarId, speakerId) => (
    !!webinarId && !!speakerId ? `/webinar/${webinarId}/speaker/${speakerId}` : '/webinar/:webinarId/speaker/:speakerId'
  ),
  webinarSession: (webinarId) => (webinarId ? `/webinar/${webinarId}` : '/webinar/:webinarId'),
  search: '/search',
  custom: '/custom',
  products: '/products',
  productDetail: (productId) => (productId ? `/products/${productId}` : '/products/:productId'),
  participant: '/participant',
  contacts: '/contacts',
  boothPreview: '/preview/booth/:boothId',
};

export default Routes;
