import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from '@material-ui/core';
import ModalTitleBar from '../ModalTitleBar';
import BookmarkList from './BookmarkList';

const BookmarksModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  max-width: 1200px;
  width: 100%;
  max-height: 620px;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFF;
  border-radius: 16px;
  padding: 20px;

  & :focus {
    outline: none;
  }
`;

const BookmarksModal = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose}>
    <BookmarksModalContainer>
      <ModalTitleBar onClose={onClose}> My Bookmark </ModalTitleBar>
      <BookmarkList />
    </BookmarksModalContainer>
  </Modal>
);

BookmarksModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default BookmarksModal;
