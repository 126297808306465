import React from 'react';
import styled from 'styled-components';
import NoDataIndicator from 'components/indicators/NoDataIndicator';
import { BoothContentElement } from 'models/cms/booths';
import { useSelector } from 'react-redux';
import { selectLocale } from 'models/localization';
import BoothFileRow from 'components/BoothFileRow';
import { Bookmark } from 'models/types/bookmarks';
import BoothRichtext from './BoothRichtext';
import BoothQuestion from './BoothQuestion';
import BoothImage from './BoothImage';
import BoothVideo from './BoothVideo';

const TabContainerSwitcherContainer = styled.div`
  width: auto;
  max-width: 100%;
  letter-spacing: 0.32px;
  color: #484848;
`;

const TabContentSwitcher = ({ contents }: { contents: BoothContentElement[] }) => {
  const locale = useSelector(selectLocale);
  if (contents.length) {
    return (
      <TabContainerSwitcherContainer>
        {contents.map(({ __typename: typeName, ...content }) => {
          switch (typeName) {
            case 'BoothRichtextContentElement':
              return (
                <BoothRichtext
                  key={content.id}
                  content={content.contentRichtextHtml[locale]}
                />
              );
            case 'BoothQuestionContentElement':
              return (
                <BoothQuestion
                  key={content.id}
                  id={content.id}
                  question={content.question[locale]}
                  answer={content.answer}
                />
              );
            case 'BoothImageContentElement':
              return (
                <BoothImage
                  key={content.id}
                  imageSrc={content.image[locale].originalImageUrl}
                />
              );
            case 'BoothVideoContentElement':
              return (
                <BoothVideo
                  key={content.id}
                  src={content.videoFile[locale].playbackUrl}
                  thumbnail={content.image[locale].originalImageUrl}
                />
              );
            case 'BoothFileContentElement':
              if (!content?.file?.[locale]) {
                return contents.length === 1
                  ? <NoDataIndicator />
                  : null;
              }

              return (
                <BoothFileRow
                  key={content.id}
                  bookmark={{
                    ...content.file[locale],
                    id: content.id,
                  } as Bookmark}
                />
              );
            default: return null;
          }
        })}
      </TabContainerSwitcherContainer>
    );
  }

  return (
    <NoDataIndicator />
  );
};

export default TabContentSwitcher;
