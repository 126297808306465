import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';
import { envSwitch } from 'utils/envUtils';
import Apps from 'apps';

const useStyles = makeStyles(() => ({
  root: {
    width: 'auto',
    maxWidth: '100%',
    margin: '0 24px',
    letterSpacing: '0.32px',
    color: '#484848',
  },
  item: {
    padding: '12px 0',
  },
  label: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#A2AAB3',
    marginBottom: '11px',
  },
  text: {
    lineHeight: '17px',
    whiteSpace: 'break-spaces',
    textAlign: 'justify',
  },
  link: {
    lineHeight: '17px',
    whiteSpace: 'break-spaces',
    textAlign: 'justify',
    color: '#5C8DF3',
    wordBreak: 'break-all',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '17px',
  },
}));

const BoothProfile = ({
  name,
  description,
  website,
  coreTechnology,
  hashtags,
  contacts,
  oneLiner,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const processedWebsite = useMemo(() => `http://${website.replace(/\b(https:\/\/|http:\/\/)/, '')}`, [website]);

  const introductionLabelName = envSwitch([
    [Apps.Lighthouse710, 'WHO WE ARE'],
  ], 'COMPANY INTRODUCTION');

  return (
    <>
      <div className={classes.root}>
        <h2>
          {ReactHtmlParser(name)}
        </h2>
        {
          coreTechnology && (
            <>
              <div className={classes.item}>
                <div className={classes.text}>
                  {ReactHtmlParser(oneLiner)}
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.label}>
                  CORE TECHNOLOGY
                </div>
                <div className={classes.text}>
                  {ReactHtmlParser(coreTechnology)}
                </div>
              </div>
            </>
          )
        }
        {
          description && (
            <div className={classes.item}>
              <div className={classes.label}>
                {introductionLabelName}
              </div>
              <div className={classes.text}>
                {ReactHtmlParser(description)}
              </div>
            </div>
          )
        }
        {
          website && (
            <div className={classes.item}>
              <div className={classes.label}>
                {t('booth.website', 'WEBSITE')}
              </div>
              <a
                className={classes.link}
                href={processedWebsite}
                target="_blank"
                rel="noopener noreferrer"
              >
                {ReactHtmlParser(website)}
              </a>
            </div>
          )
        }
        {contacts && contacts.length > 0 && contacts.map((contactInfo, index) => {
          const content = contactInfo.contact;
          if (contactInfo.type === 'email') {
            return null;
          }
          return content && (
            <div key={index} className={classes.item}>
              <div className={classes.label}>
                {t(`booth.${contactInfo.type.toLowerCase()}`, contactInfo.type.toUpperCase())}
              </div>
              <div className={classes.text}>
                {content.startsWith('http') ? <a className={classes.link} href={content} target="_blank" rel="noopener noreferrer">{ReactHtmlParser(content)}</a> : ReactHtmlParser(content)}
              </div>
            </div>
          );
        })}
        {
          hashtags && (
            <div className={classes.item}>
              <div className={classes.label}>
                HASHTAGS
              </div>
              <div className={classes.text}>
                {ReactHtmlParser(hashtags)}
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};

BoothProfile.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  website: PropTypes.string.isRequired,
  coreTechnology: PropTypes.string,
  hashtags: PropTypes.string.isRequired,
  oneLiner: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({
    contact: PropTypes.string,
    newItem: PropTypes.bool,
    type: PropTypes.string,
  })).isRequired,
};

BoothProfile.defaultProps = {
  coreTechnology: undefined,
};

export default BoothProfile;
