import styled from 'styled-components';

const Booth618Container = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  margin: 0 auto;
`;

export default Booth618Container;
