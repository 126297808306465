import styled, { StyledComponent } from 'styled-components';

import { BREAKPOINT } from '../constants';

interface WebinarRootContainerProps {
  isMultiTrackSession?: boolean;
  trackColor?: string;
}

export default styled.div<WebinarRootContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  color: #484848;
  position: relative;
  border: 2px solid ${(props) => (props.trackColor || '#DBDEE2')};
  background: #FFF;

  @media (min-width: ${BREAKPOINT}px) {
    height: ${(props) => (props.isMultiTrackSession ? '360px' : 'fit-content')};
    margin: 12px 0;
    margin-top: 0;
  }

  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.2;
    background: ${(props) => props.trackColor || '#FFF'};
  }

  @media (max-width: ${BREAKPOINT}px) {
    margin: 16px 0;
    margin-top: 0;
  }
` as StyledComponent<'div', any, WebinarRootContainerProps>;
