import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import Routes from 'Routes';
import Apps from 'apps';
import { envSwitch } from 'utils/envUtils';

const rootContainerCss = envSwitch([
  [Apps.Lighthouse710, css`
    min-width: unset;
  `],
], '');

const RootContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  min-width: 250px;
  align-items: center;
  margin-right: 40px;
  padding: 16px 0 0;
  cursor: pointer;
  overflow: hidden;
  /* stylelint-disable-next-line value-keyword-case */
  ${rootContainerCss}
`;

const profileContainerCss = envSwitch([
  [Apps.Lighthouse710, css`
    width: unset;
  `],
], '');

const ProfileContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 58px;
  overflow: hidden;
  flex: 1 1 auto;
  width: 0;
  /* stylelint-disable-next-line value-keyword-case */
  ${profileContainerCss}
`;

const NameContainer = styled.div`
  white-space: nowrap;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #484848;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CompanyTitleContainer = styled.div`
  white-space: nowrap;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SpeakerItem = ({
  name, company, title, avatar, id, webinarId,
}) => (
  <StyledLink to={Routes.webinarSpeaker(webinarId, id)}>
    <RootContainer>
      <StyledAvatar alt={name} src={avatar} />
      <ProfileContainer>
        {name && <NameContainer>{ name }</NameContainer>}
        {company && <CompanyTitleContainer>{ company }</CompanyTitleContainer>}
        {title && <CompanyTitleContainer>{ title }</CompanyTitleContainer>}
      </ProfileContainer>
    </RootContainer>
  </StyledLink>
);

SpeakerItem.propTypes = {
  name: PropTypes.string,
  company: PropTypes.string,
  title: PropTypes.string,
  avatar: PropTypes.string,
  id: PropTypes.number.isRequired,
  webinarId: PropTypes.number.isRequired,
};

SpeakerItem.defaultProps = {
  name: '',
  company: '',
  title: '',
  avatar: '',
};

export default SpeakerItem;
