import React from 'react';
import { Webinar, WebinarTrack } from 'models/webinars';
import styled from 'styled-components';
import LiveWebinarItem from 'pages/webinar/live/item/LiveWebinarItem';
import MultiTracksHorizontalScrollbarHider from 'pages/webinar/live/item/MultiTracksHorizontalScrollbarHider';
import { BREAKPOINT } from 'pages/webinar/constants';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LiveWebinarListOptions } from 'pages/webinar/live/LiveWebinarListOptions';

export interface LiveWebinarTracksGroup {
  trackIdWebinarMap: { [trackId: number]: Webinar };
  tracks: WebinarTrack[];
}

const RootContainer = styled.div<{showTimeLine: boolean}>`
  position: relative;

  @media (min-width: ${BREAKPOINT}px) {
    margin-left: -75px;
    margin-right: -75px;

    &::before {
      z-index: 1;
      content: "";
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.8);
      width: ${(props) => (props.showTimeLine ? 148 : 75)}px;
    }

    &::after {
      z-index: 1;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: rgba(255, 255, 255, 0.8);
      width: 75px;
    }
  }
`;

const GroupedWebinarTracksContainer = styled.div<{keepTimeLineSpace: boolean; isMultiTrackSession: boolean}>`
  margin-left: ${(props) => (props.keepTimeLineSpace ? 150 : 75)}px;
  margin-right: 75px;
  display: flex;
  flex-direction: row;
  width: calc(100% - ${(props) => (props.keepTimeLineSpace ? 150 : 75)}px - 75px);

  &::after {
    content: "";
    display: block;
    width: 75px;
    flex: 0 0 auto;
  }

  @media (max-width: ${BREAKPOINT}px) {
    width: 100%;
    margin: 0;
    flex-direction: column;

    &::after {
      display: none;
    }
  }
`;

const WebinarWrapper = styled.div<{isMultiTrackSession: boolean; totalTracks: number}>`
  min-width: ${(props) => (props.isMultiTrackSession ? '300px' : '100%')};
  overflow: hidden;
  flex: 1 0 ${(props) => (props.isMultiTrackSession ? `${100 / props.totalTracks}%` : '100%')};

  @media (min-width: ${BREAKPOINT}px) {
    &:not(:last-child) {
      padding-right: 16px;
    }
  }
`;

interface TrackGroupProps {
  tracksGroup: LiveWebinarTracksGroup;
  options: LiveWebinarListOptions;
}

const GrouppedWebinarTracks = ({ tracksGroup, options }: TrackGroupProps) => {
  const isMultiTracks = tracksGroup.tracks.length > 1;
  return (
    <GroupedWebinarTracksContainer isMultiTrackSession={isMultiTracks} keepTimeLineSpace={options.timeLine.enable}>
      {
        tracksGroup.tracks.map((track) => tracksGroup.trackIdWebinarMap[track?.id])
          .filter((webinar) => !!webinar).map((webinar) => (
            <WebinarWrapper isMultiTrackSession={isMultiTracks} totalTracks={tracksGroup.tracks.length} key={webinar.id}>
              {webinar && <LiveWebinarItem webinar={webinar} multiTrackOptions={options.multiTracks} />}
            </WebinarWrapper>
          ))
      }
    </GroupedWebinarTracksContainer>
  );
};

const LiveWebinarTracksGroupContainer = ({ tracksGroup, options }: TrackGroupProps) => {
  const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINT}px)`);
  return (
    <RootContainer showTimeLine={options.timeLine.enable}>
      <MultiTracksHorizontalScrollbarHider maxHeight={isDesktop ? 500 : 99999} options={options}>
        <GrouppedWebinarTracks tracksGroup={tracksGroup} options={options} />
      </MultiTracksHorizontalScrollbarHider>
    </RootContainer>
  );
};

export default LiveWebinarTracksGroupContainer;
