import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

const StyledBanner = styled.img`
  position: absolute;
  max-height: 188px;
  max-width: 132px;
  top: 81px;
  left: 29px;
  z-index: 5;
  cursor: pointer;
`;

const BannerLightboxImageView = ({ thumbnailSrc, fullSizeSrc, alt }) => {
  const [showLightbox, setShowLightbox] = useState(false);

  const onImgClick = () => {
    setShowLightbox(true);
  };

  const onLightboxClose = () => {
    setShowLightbox(false);
  };

  return (
    <>
      <StyledBanner
        src={thumbnailSrc}
        alt={alt}
        onClick={onImgClick}
      />
      {
        showLightbox && (
          <Lightbox
            mainSrc={fullSizeSrc}
            onCloseRequest={onLightboxClose}
          />
        )
      }
    </>
  );
};

BannerLightboxImageView.propTypes = {
  thumbnailSrc: PropTypes.string.isRequired,
  fullSizeSrc: PropTypes.string,
  alt: PropTypes.string,
};

BannerLightboxImageView.defaultProps = {
  fullSizeSrc: '',
  alt: '',
};

export default BannerLightboxImageView;
