const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
    next_show: 'Space Booking for Next Show',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/188360c5-b8ef-4d9f-b835-9e8d62583bb4.jpg',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/973b5a3b-7332-4c13-9cdc-266d6ef596a3.jpg',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/050183f8-a54f-44c4-ab73-c8dde1b157d4.jpg',
        'https://www.ai-expo-at.jp/inv_en/',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/f9e918a8-aafe-4326-8869-1de2429ff06a.jpg',
        'https://www.ai-expo-at.jp/en-gb.html',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
  ...en.banners.right,
];

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
    next_show: '次年度 出展に興味のある方',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/6a26ae36-fa3d-4665-aa82-77e036dc7a08.jpg',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/7f55f29d-a5b4-4e9e-8c35-fd53dccc78e0.jpg',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/fb9a3365-51e7-430b-bc87-4a9cb3b7dbc0.jpg',
        'https://www.ai-expo-at.jp/gate_inv/',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/1b6ac451-0d4b-4b7a-885e-aeb28fd6a6c4.jpg',
        'https://www.ai-expo-at.jp/gate/',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];
