import React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const ImageLightbox = ({ src, onClose }) => (
  <Lightbox
    mainSrc={src}
    onCloseRequest={onClose}
  />
);

ImageLightbox.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImageLightbox;
