/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  Redirect,
} from 'react-router-dom';
import Routes from 'Routes';
import { UserSessionContext } from 'components/UserSession';

const AuthRoute = ({ route, ...props }) => {
  const {
    canSee, firstVisibleRoute,
  } = useContext(UserSessionContext);

  if (!canSee(route)) {
    return <Redirect to={Routes[firstVisibleRoute]} />;
  }

  return (
    <Route {...props} />
  );
};

AuthRoute.propTypes = {
  route: PropTypes.string,
};

AuthRoute.defaultProps = {
  route: 'lobby',
};

export default AuthRoute;
