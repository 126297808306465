import { APP } from 'appenv';
import styled from 'styled-components';

const StyledImgBackgroundSize = (() => {
  switch (APP) {
    case 'ms624': return 'contain';
    default: return 'auto 100%';
  }
})();

const StyledImg = styled.div<{ bannerFit: string; img: string }>`
  flex: 0 0 auto;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-image: ${({ img }) => `url(${img})`};
  background-repeat: no-repeat;
  background-size: ${({ bannerFit }) => bannerFit || StyledImgBackgroundSize};
  background-position: center;
`;
export default StyledImg;
