import React, {
  useState,
  createRef,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {
  useHistory,
  useLocation,
  matchPath,
} from 'react-router-dom';
import debounce from 'lodash/debounce';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

import Routes from 'Routes';
import useURLSearchParams from 'hooks/useURLSearchParams';
import { SearchFieldContext } from 'components/SearchFieldContext';

import SearchResultListPopover from './SearchResultListPopover';

const RootContainer = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SearchInputContainer = styled.div`
  width: ${({ active }) => (active ? '240px' : 0)};
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const SearchInputField = withStyles((theme) => ({
  root: {
    color: 'inherit',
    backgroundColor: '#FFF',
    border: ({ active }) => `1px solid ${active === 'true' ? '#DBDEE2' : '#FFF'}`,
    borderRadius: '60px',
    width: '100%',
    transition: 'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  input: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: '1em',
    width: '100%',
  },
}))(InputBase);

const SearchField = () => {
  const history = useHistory();
  const location = useLocation();

  const {
    searchKeyword,
    fetchSearchResultsWithDeboune,
  } = useContext(SearchFieldContext);

  const query = useURLSearchParams();

  const searchInputRef = createRef();
  const [showInput, setShowInput] = useState((location.state || {}).showInput);
  const [searchText, setSearchText] = useState(query.get('searchText') || '');

  const [anchorEl, setAnchorEl] = useState(null);

  const updateHistory = (newSearchText) => {
    query.set('searchText', newSearchText);
    history.push({
      pathname: Routes.search,
      search: `?${query.toString()}`,
      state: { showInput: true },
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouneUpdateHistory = useCallback(debounce(updateHistory, 2000), []);

  const onSeacrhInputChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    if (
      matchPath(location.pathname, {
        path: [Routes.search],
        exact: true,
      })
    ) {
      debouneUpdateHistory(newSearchText);
    } else {
      setAnchorEl(event.currentTarget);
      fetchSearchResultsWithDeboune({
        newSearchText,
        limit: 5,
      });
    }
  };

  const navigateToAllResults = () => {
    if (
      searchText !== '' && !matchPath(location.pathname, {
        path: [Routes.search],
        exact: true,
      })
    ) {
      setAnchorEl(null);
      updateHistory(searchText);
    }
  };

  const onCancel = () => {
    if (anchorEl) {
      setAnchorEl(null);
    } else if (showInput) {
      setShowInput(false);
      setSearchText('');
    }
  };

  const onKeyUp = (event) => {
    switch (event.keyCode) {
      case 13:
        navigateToAllResults();
        break;
      case 27:
        onCancel();
        break;
      default:
    }
  };

  const onSearchIconClick = () => {
    setShowInput(true);
    searchInputRef.current.focus();
    // if there have new enter point please let allen know.
    window.trackingEvent('Event Navigation', 'MainMenu_Click', 'Search');
  };

  return (
    <RootContainer>
      <SearchInputContainer active={showInput}>
        <SearchInputField
          active={`${showInput}`}
          value={searchText}
          onChange={onSeacrhInputChange}
          onKeyUp={onKeyUp}
          onBlur={onCancel}
          inputProps={{ 'aria-label': 'search input' }}
          inputRef={searchInputRef}
        />
      </SearchInputContainer>
      <IconButton
        color="inherit"
        aria-label="search"
        onClick={onSearchIconClick}
        disabled={showInput}
      >
        <SearchIcon />
      </IconButton>
      <SearchResultListPopover
        anchorEl={anchorEl}
        open={Boolean(showInput && anchorEl && searchKeyword)}
        onClose={onCancel}
        navigateToAllResults={navigateToAllResults}
      />
    </RootContainer>
  );
};

export default SearchField;
