import Reed902LogoEn from 'assets/reed902_logo.png';
import Reed902LogoJa from 'assets/reed902_logo_jp.png';

export const en = {
  logos: [Reed902LogoEn],
  url: {
    exhibition_website: 'https://www.lifestyle-expo.jp/ls_jmw_top_en/',
  },
  title: 'LIFESTYLE Week [TOKYO] -VIRTUAL- / Japan Marketing Week [Tokyo] -VIRTUAL-',
};

export const ja = {
  logos: [Reed902LogoJa],
  url: {
    exhibition_website: 'https://www.lifestyle-expo.jp/ls_jmw_top/',
  },
  title: 'オンライン来場・商談サービス｜ライフスタイル Week[夏] ／ Japan マーケティング Week[夏]',
};
