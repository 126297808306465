import { withStyles } from '@material-ui/core/styles';
import { BRAND_COLOR } from 'appenv';
import ButtonBase from '@material-ui/core/ButtonBase';

const ActionButton = withStyles(() => ({
  root: {
    color: '#FFF',
    backgroundColor: BRAND_COLOR || '#0CA58B',
    height: '40px',
    borderRadius: '2px',
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '16px',
    textTransform: 'uppercase',
    fontFamily: 'Lato',
    opacity: 'initial',
    width: '100%',
    flex: '0 0 auto',
  },
  disabled: {
    backgroundColor: BRAND_COLOR || 'rgba(12, 165, 139)',
    opacity: '0.4',
  },
}))(ButtonBase);

export default ActionButton;
