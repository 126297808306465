import {
  createSlice,
} from '@reduxjs/toolkit';

export const ChatTargetSlice = createSlice({
  name: 'chatTarget',
  initialState: {},
  reducers: {
    setTarget(state, action) {
      return action.payload;
    },
  },
});

export const {
  setTarget,
} = ChatTargetSlice.actions;

export default ChatTargetSlice.reducer;
