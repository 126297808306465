import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { selectAvailableLocales } from 'models/localization';
import fetchXtraEventRequest from 'network/fetchXtraEvent';
import fetchBoothTaxonomiesQueryRequest, { FetchBoothTaxonomiesQueryProps } from 'network/fetchBoothTaxonomies';
import { eventsAdapter } from './selectors';
import { fetchCachedCMSData } from '../common/thunks';
import { CMSEvent, eventEntity } from './types';

export const fetchBoothTaxonomies = createAsyncThunk(
  'events/fetchBoothTaxonomies',
  async ({ boothTaxonomiesCursor }: FetchBoothTaxonomiesQueryProps = {}, { getState, dispatch }) => {
    try {
      const state = getState();
      const locales = selectAvailableLocales(state);
      const response = await fetchBoothTaxonomiesQueryRequest({ locales, boothTaxonomiesCursor });

      const { pageInfo } = response.boothTaxonomies;
      delete response.boothTaxonomies.pageInfo;
      if (pageInfo.hasNextPage) dispatch(fetchBoothTaxonomies({ boothTaxonomiesCursor: pageInfo.endCursor }));

      const { entities } = normalize<CMSEvent>([response], [eventEntity]);
      return entities.events;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const fetchXtraEvent = createAsyncThunk(
  'events/fetchXtraEvent',
  async () => {
    try {
      const response = await fetchXtraEventRequest();
      const { entities } = normalize<CMSEvent>([response], [eventEntity]);
      return entities.events;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState: eventsAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCachedCMSData.fulfilled, (state, action) => {
      eventsAdapter.upsertMany(state, action.payload.events);
    });

    builder.addCase(fetchXtraEvent.fulfilled, (state, action) => {
      eventsAdapter.upsertMany(state, action.payload || {});
    });
    builder.addCase(fetchBoothTaxonomies.fulfilled, (state, action) => {
      const updates = Object.entries(action.payload)
        .map(([key, value]) => ({
          id: key,
          changes: {
            boothTaxonomies: [
              ...(state.entities[key]?.boothTaxonomies || []),
              ...value.boothTaxonomies,
            ],
          },
        }));
      eventsAdapter.updateMany(state, updates);
    });
  },
});

export default eventsSlice.reducer;
