import {
  en as enCommon, ja as jaCommon, zhHans as zhHansCommon, zhHant as zhHantCommon,
} from './common';
import {
  en as enLobby, ja as jaLobby, zhHans as zhHansLobby, zhHant as zhHantLobby,
} from './lobby';
import { en as enLanding, ja as jaLanding, zhHans as zhHansLanding } from './landing';

export default {
  en: {
    common: enCommon,
    lobby: enLobby,
    landing: enLanding,
  },
  ja: {
    common: jaCommon,
    lobby: jaLobby,
    landing: jaLanding,
  },
  'zh-Hans': {
    common: zhHansCommon,
    lobby: zhHansLobby,
    landing: zhHansLanding,
  },
  'zh-Hant': {
    common: zhHantCommon,
    lobby: zhHantLobby,
    landing: zhHansLanding,
  },
};
