import React, {
  useMemo, useRef, useState, useCallback,
} from 'react';
import styled from 'styled-components';
import { selectAvailableLocales, selectLocale, localizationSlice } from 'models/localization';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import TranslateIcon from '@material-ui/icons/Translate';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { AvailableLocale, LanguageCodeTranslationMap } from 'i18n/types';
import { reedApps } from 'apps';
import { envSwitch } from 'utils/envUtils';

const SelectedLocaleContainer = styled.span`
  display: none;
  margin: 0 4px 0 8px;

  @media screen and (min-width: 960px) {
    display: block;
  }
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  display: none;

  @media screen and (min-width: 960px) {
    display: block;
  }
`;

const StyledButton = withStyles(() => ({
  root: {
    minWidth: 'unset',
    fontFamily: "Lato, 'Noto Sans SC', sans-serif",
    font: 'inherit',
  },
}))(Button);

const StyledMenuItem = withStyles(() => ({
  root: {
    minWidth: '150px',
    textTransform: 'uppercase',
    fontFamily: 'unset',
  },
}))(MenuItem);

const languageCodeTranslation: LanguageCodeTranslationMap = {
  en: 'English',
  ja: '日本語',
  'id-ID': 'Indonesian',
  'zh-Hans': '简体中文',
  'zh-Hant': '繁體中文',
  ko: '한국어',
};

interface LocaleSelectorProps {
  showSelectedLocale?: boolean;
}

const defaultIshowSelectedLocale = envSwitch([
  [reedApps, true],
], false);

const LocaleSelector: React.FC<LocaleSelectorProps> = ({ showSelectedLocale = defaultIshowSelectedLocale }: LocaleSelectorProps) => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const availableLocales = useSelector(selectAvailableLocales);

  const containerRef = useRef();
  const [openMenu, setOpenMenu] = useState(false);
  const handleMenuOpen = () => setOpenMenu(true);
  const handleMenuClose = () => setOpenMenu(false);

  const onItemClick = useCallback((newLocale: AvailableLocale) => {
    dispatch(localizationSlice.actions.setLocale(newLocale));
    handleMenuClose();
  }, [dispatch]);

  const items = useMemo(
    () => availableLocales.map((it) => (
      <StyledMenuItem key={it} value={it} onClick={() => onItemClick(it)}>
        {languageCodeTranslation[it] || it}
      </StyledMenuItem>
    )),
    [availableLocales, onItemClick],
  );

  const menuStyle = useMemo(() => ({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  }), []);

  return (
    <div ref={containerRef}>
      <StyledButton aria-controls="locale-selector" aria-haspopup="true" onClick={handleMenuOpen}>
        <TranslateIcon />
        {showSelectedLocale && (
          <>
            <SelectedLocaleContainer>{languageCodeTranslation[locale] || locale}</SelectedLocaleContainer>
            <StyledArrowDropDownIcon />
          </>
        )}
      </StyledButton>
      <Menu
        id="locale-selector"
        anchorEl={containerRef.current}
        keepMounted
        open={openMenu}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={menuStyle.anchorOrigin as any}
        transformOrigin={menuStyle.transformOrigin as any}
      >
        {items}
      </Menu>
    </div>
  );
};


export default LocaleSelector;
