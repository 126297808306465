import React, {
  ReactElement,
  LazyExoticComponent,
  Suspense,
  useMemo,
  useEffect,
} from 'react';
import { APP, PAGE_NAMES } from 'appenv';
import Routes from 'Routes';
import styled from 'styled-components';
import { Booth } from 'models/exhibitors';
import Footer from 'components/footer/Footer';
import XtraBreadcrumbs from 'components/XtraBreadcrumbs';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'pages/hall/LazyBoothList/HeaderComponent';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMoreMetaBooths, selectBoothPageInfo } from 'models/cms/booths';
import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import BoothPreview from './BoothPreview';
import BreadcrumbsNoFilterContainer from './BreadcrumbsNoFilterContainer';

const IEBreadcrumbsNoFilterContainer = styled(BreadcrumbsNoFilterContainer)`
  flex-basis: 100%;
`;

const IEBoothListContainer = styled.div`
  min-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 0 auto;
`;

let HallPageHeader: LazyExoticComponent<() => ReactElement> | (() => ReactElement) = () => <div />;
if (APP === 'polyu618') {
  HallPageHeader = React.lazy(() => import('./PolyU618Header'));
}

const hideBreadcrumbsList = ['scmp711'];

const InvisibleSeparator = styled.div`
  flex-basis: 100%;
  height: 0;
`;

const MainContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`;

const StyledHeaderComponent = styled(HeaderComponent)`
  position: relative;
`;

export const CMSFetchMoreIndicator = () => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(selectBoothPageInfo);

  useEffect(() => {
    if (pageInfo.fetching) return;
    dispatch(fetchMoreMetaBooths());
  }, [dispatch, pageInfo]);

  if (!pageInfo.fetching) return null;

  return <CircularLoadingIndicator />;
};

const IEBoothList = ({
  exhibitors = [],
  isDesktop,
  hasFilter,
  isFiltered,
}: {
  isDesktop: boolean;
  exhibitors: Booth[];
  hasFilter: boolean;
  isFiltered: boolean;
}) => {
  const { t } = useTranslation();
  const upgradedExhibitors = useMemo(() => exhibitors.filter((booth) => booth?.type === 'u'), [exhibitors]);
  const basicExhibitors = useMemo(() => exhibitors.filter((booth) => booth?.type !== 'u'), [exhibitors]);

  const showFilterResult = isFiltered && exhibitors.length > 0;

  return (
    <MainContainer>
      {showFilterResult && (
        <StyledHeaderComponent
          isDesktop={isDesktop}
          isFiltered={isFiltered}
          filterLength={exhibitors.length}
          headerOffset={0}
          hasFilter={hasFilter}
        />
      )}
      <IEBoothListContainer>
        {!(hasFilter && isDesktop) && !hideBreadcrumbsList.includes(APP) && (
          <IEBreadcrumbsNoFilterContainer>
            <XtraBreadcrumbs paths={[{ name: PAGE_NAMES.lobby || t('page.lobby', 'Lobby'), to: Routes.lobby }, { name: PAGE_NAMES.hall }]} />
          </IEBreadcrumbsNoFilterContainer>
        )}
        <Suspense fallback={null}>
          {
            !showFilterResult && <HallPageHeader />
          }
        </Suspense>
        {upgradedExhibitors.map((booth) => (
          <BoothPreview
            key={booth.id}
            booth={booth}
          />
        ))}
        {upgradedExhibitors.length > 0 && <InvisibleSeparator />}
        {basicExhibitors.map((booth) => (
          <BoothPreview
            key={booth.id}
            booth={booth}
          />
        ))}
      </IEBoothListContainer>
      <CMSFetchMoreIndicator />
      <Footer hashState="/hall" />
    </MainContainer>
  );
};

export default IEBoothList;
