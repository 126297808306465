import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <path
    d="M8.93311 0.34668H2.93311C2.33637 0.34668 1.76408 0.583732 1.34212 1.00569C0.920164 1.42765 0.683111 1.99994 0.683111 2.59668V14.5967C0.682587 14.7288 0.716993 14.8588 0.782845 14.9734C0.848698 15.088 0.943658 15.1831 1.05811 15.2492C1.17213 15.315 1.30146 15.3497 1.43311 15.3497C1.56476 15.3497 1.6941 15.315 1.80811 15.2492L5.93311 12.8642L10.0581 15.2492C10.1724 15.314 10.3017 15.3476 10.4331 15.3467C10.5645 15.3476 10.6938 15.314 10.8081 15.2492C10.9226 15.1831 11.0175 15.088 11.0834 14.9734C11.1492 14.8588 11.1836 14.7288 11.1831 14.5967V2.59668C11.1831 1.99994 10.9461 1.42765 10.5241 1.00569C10.1021 0.583732 9.52985 0.34668 8.93311 0.34668Z"
    fill="#EAB53E"
  />,
  'Bookmarked',
  { viewBox: '0 0 12 16' },
);
