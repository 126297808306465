/* eslint-disable camelcase */
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import { APP } from 'appenv';
import * as moment from 'moment-timezone';
import { XtraEvent } from 'models/event';
import fetchAllWebinars from 'network/fetchAllWebinars';

export enum WebinarType {
  Live = 'time_framed',
  OnDemand = 'on_demand'
}

export interface WebinarDay {
  id: number;
  name: string;
  date: string;
}

export interface WebinarSpeaker {
  id: number;
  profile_picture_url: string;
  translations: {
    [locale: string]: {
      name: string;
      company: string;
      title: string;
      bio: string;
    };
  };
}

export interface WebinarTrack {
  id: number;
  color: string;
  track_name: string;
  sort: 2;
  translations: {
    [locale: string]: {
      name: string;
    };
  };
}

export interface Webinar {
  id: number;
  webinar_day: WebinarDay;
  start_time: string;
  startAt: string;
  end_time: string;
  endAt: string;
  timezone: string;
  kind: WebinarType;
  speakers: WebinarSpeaker[];
  track: WebinarTrack;
  translations: {
    [locale: string]: {
      id: number;
      title: string;
      description: string;
      url: string;
    };
  };
}

const translationsToLocaleMap = (translations) => translations.reduce((result, currentValue) => {
  const locale = currentValue.language;
  result[locale] = currentValue;
  return result;
}, {});

export const webinarEntity = new schema.Entity<Webinar>('webinars', {}, {
  processStrategy: (entity) => {
    entity.translations = translationsToLocaleMap(entity.translations || [{ language: 'en' }]);
    if (APP !== 'hkstp608') {
      entity.speakers = entity.speakers.map((speaker) => speaker.webinar_speaker || [{ language: 'en' }]);
    }
    entity.speakers.forEach((speaker) => {
      // eslint-disable-next-line camelcase
      speaker.avatar = speaker.profile_picture_file?.data?.thumbnails?.find((thumb) => thumb.url.endsWith('thumbnail'))?.url ?? speaker.profile_picture_url;
      speaker.translations = translationsToLocaleMap(speaker.translations);
    });
    if (entity.track != null && entity.track.translations != null) {
      entity.track.translations = translationsToLocaleMap(entity.track.translations);
    }
    entity.startAt = entity.webinar_day && entity.start_time
      ? moment.tz(`${entity.webinar_day.date}T${entity.start_time}`, entity.timezone).toISOString()
      : null;
    entity.endAt = entity.webinar_day && entity.end_time
      ? moment.tz(`${entity.webinar_day.date}T${entity.end_time}`, entity.timezone).toISOString()
      : null;
    return entity;
  },
});

export const fetchWebinars = createAsyncThunk(
  'webinars/fetchAll',
  async (event: XtraEvent) => {
    try {
      const result = await fetchAllWebinars();
      result.forEach((entity) => {
        entity.timezone = event.timezone;
      });
      const normalized = normalize(result, [webinarEntity]);
      return normalized.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const webinarsAdapter = createEntityAdapter<Webinar>();
export const {
  selectEntities: selectWebinarEntities,
  selectAll: selectAllWebinars,
  selectById: selectWebinarById,
} = webinarsAdapter.getSelectors((state: any) => state.webinars);

export const selectAllLiveWebinars = () => createSelector(
  selectAllWebinars,
  (webinars) => webinars.filter((webinar) => (
    webinar.kind === WebinarType.Live
  )),
);

export const selectAllOnDemandWebinars = () => createSelector(
  selectAllWebinars,
  (webinars) => webinars.filter((webinar) => (
    webinar.kind === WebinarType.OnDemand
  )),
);

export const selectIsWebinarsLoading = (state) => state.webinars.isLoading;

export const selectWebinarTracksMap = () => createSelector(
  selectAllWebinars,
  (webinars) => webinars.map((webinar: Webinar) => webinar.track)
    .filter((it) => it != null)
    .reduce((tracksMap, next) => {
      tracksMap[next.id] = next;
      return tracksMap;
    }, {}) as { [id: number]: WebinarTrack },
);

export const selectWebinarsByKeyword = (keyword, options: any = {}) => {
  const locale = options.locale || 'en';
  const limit = options.limit || undefined;
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    selectAllWebinars,
    (webinars: Webinar[]) => (
      webinars
        .filter((webinar) => (
          lowerCaseKeyword && (
            webinar.translations[locale]?.title?.toLowerCase()
              ?.includes(lowerCaseKeyword)
          )
        ))
        .slice(0, limit)
    ),
  );
};

export const webinarsSlice = createSlice({
  name: 'webinars',
  initialState: webinarsAdapter.getInitialState({ isLoading: true }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWebinars.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWebinars.fulfilled, (state: any, action) => {
      webinarsAdapter.upsertMany(state, action.payload?.webinars || []);
      state.isLoading = false;
    });
    builder.addCase(fetchWebinars.rejected, (state: any) => {
      state.isLoading = false;
    });
  },
});

export default webinarsSlice.reducer;
