import React from 'react';

import createSvgIcon from './utils/createSvgIcon';

export default createSvgIcon(
  <path d="M9.213 9.636L4.475 8.93A1.92 1.92 0 012.96 7.703L.942 2.294a.968.968 0 01.545-1.24.923.923 0 01.747.035l16.358 8.159a.97.97 0 010 1.726l-16.358 8.16a.932.932 0 01-1.258-.442.98.98 0 01-.034-.764L2.96 12.52a1.92 1.92 0 011.515-1.227l4.738-.706a.48.48 0 000-.95z" />,
  'Send',
  {
    viewBox: '0 0 20 20',
    color: '#A2AAB3',
  },
);
