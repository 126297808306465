import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import uniq from 'lodash/uniq';
import fetchRongcloudGroup from 'network/fetchRongcloudGroup';

const groupMemberEntity = new schema.Entity('chatTargetGroupMembers', {}, {
  processStrategy: (entity) => {
    const { customAttendeeFields: customFields = {} } = entity;
    const {
      vexpo_chat_tag: userTag,
      vexpo_chat_user_description: userDescription,
    } = customFields;
    entity.userTag = userTag;
    entity.userDescription = userDescription;
    return entity;
  },
});

export const fetchRongcloudGroupMemberList = createAsyncThunk(
  'chatTargetGroupMembers/fetchAllByGroupId',
  async ({ groupId, lastCursor }, { dispatch, getState }) => {
    try {
      const {
        rongcloudUsers: {
          pageInfo: {
            hasNextPage,
            endCursor,
          },
          edges,
        },
      } = await fetchRongcloudGroup(groupId, lastCursor);
      const existingGroupMembers = getState().chatTargetGroupMembers.entities;
      const groupMembers = edges.map(({ cursor, node }) => {
        const id = node.uuid;
        const existingRecord = existingGroupMembers[id] || {};
        const groupIds = existingRecord.groupIds || [];
        return {
          ...node,
          id,
          groupIds: uniq([
            ...groupIds,
            groupId,
          ]),
          cursor,
        };
      });
      const normalized = normalize(groupMembers, [groupMemberEntity]);
      const nextCursor = hasNextPage ? endCursor : null;
      if (nextCursor) {
        dispatch(fetchRongcloudGroupMemberList({ groupId, lastCursor: nextCursor }));
      }
      return normalized.entities;
    } catch (error) {
      console.error(error);
    }
    return {};
  },
);

const chatTargetGroupMembersAdapter = createEntityAdapter({
  sortComparer: (a, b) => {
    if (a.userTag && b.userTag) {
      return `${a.userTag}${a.name}`.localeCompare(`${b.userTag}${b.name}`);
    }
    if (a.userTag) return -1;
    if (b.userTag) return 1;

    return a.name.localeCompare(b.name);
  },
});

export const {
  selectById: selectGroupMembersById,
  selectIds: selectGroupMemberIds,
  selectEntities: selectGroupMemberEntities,
  selectAll: selectAllGroupMembers,
  selectTotal: selectTotalGroupMembers,
} = chatTargetGroupMembersAdapter.getSelectors((state) => state.chatTargetGroupMembers);

export const selectGroupMembersByGroupId = (groupId) => createSelector(
  selectAllGroupMembers,
  (groupMembers) => groupMembers.filter((member) => (
    member.groupIds?.includes(groupId)
  )),
);

export const ChatTargetGroupMembersSlice = createSlice({
  name: 'chatTargetGroupMembers',
  initialState: chatTargetGroupMembersAdapter.getInitialState(),
  extraReducers: {
    [fetchRongcloudGroupMemberList.fulfilled]: (state, action) => {
      chatTargetGroupMembersAdapter.upsertMany(state, action.payload.chatTargetGroupMembers);
    },
  },
});

export default ChatTargetGroupMembersSlice.reducer;
