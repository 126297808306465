import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

import InsertInvitationIcon from 'components/icons/Calendar';
import EmailIcon from 'components/icons/EmailSmall';
import ChatroomIcon from 'components/icons/Chatroom';
import Apps, { reedApps } from 'apps';
import {
  BRAND_COLOR,
} from 'appenv';
import { envSwitch } from 'utils/envUtils';

const Container = styled(Paper)`
  max-width: 280px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  min-height: 92px;
  justify-content: center;
  margin-bottom: 48px;
`;

const ChatroomIconContainer = styled(Paper)`
  transform: scale(1.4);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  position: absolute;
  top: -15px;
  left: 8px;
  color: ${BRAND_COLOR || '#0CA58B'};
`;

const TitleContainer = styled.div`
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 24px;
  margin-bottom: 8px;
`;

const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 360px;
  flex-wrap: wrap;
`;

const StyledHyperLink = styled.a`
  text-decoration: none;
  color: ${BRAND_COLOR || '#0CA58B'};

  &:visited {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const ChatMessage = styled.span`
  font-weight: bold;
`;

const BtnContainer = styled.div`
  flex: 0 0 auto;
  min-width: 80px;
  max-width: 120px;
  position: relative;
  font-size: 0.7rem;
  color: ${BRAND_COLOR || '#0CA58B'} !important;

  & svg {
    fill: ${BRAND_COLOR || '#0CA58B'};
  }
`;

const ChatWindowInfo = () => {
  const { t } = useTranslation();
  const boothGreeting = envSwitch([
    [Apps.Uzabase826, 'こんにちは！お気軽にご連絡ください！'],
  ], t('booth.greeting', 'Hi, how can I help you?'));

  const chatMessage = envSwitch([
    ['hkstp608', 'Public Chat'],
    [reedApps, ReactHtmlParser(t('booth.chat', 'Chat'))],
  ], t('booth.chat', 'Chat'));

  return (
    <Container elevation={1}>
      <TitleContainer>
        {boothGreeting}
      </TitleContainer>
      <BtnGroupContainer>
        <BtnContainer>
          <ChatroomIconContainer elevation={6}>
            <ChatroomIcon width={24} height={22} color={BRAND_COLOR} />
            <ChatMessage>
              {chatMessage}
            </ChatMessage>
          </ChatroomIconContainer>
        </BtnContainer>
        <StyledHyperLink target="_blank" rel="noopener noreferrer">
          <BtnContainer>
            <EmailIcon width={20} height={18} color={BRAND_COLOR || '#0CA58B'} />
            <div>{t('booth.leave_a_message', 'Leave a Message')}</div>
          </BtnContainer>
        </StyledHyperLink>
        <StyledHyperLink target="_blank" rel="noopener noreferrer">
          <BtnContainer>
            <InsertInvitationIcon width={20} height={18} color={BRAND_COLOR || '#0CA58B'} />
            <div>{t('booth.meetup', 'Meetup')}</div>
          </BtnContainer>
        </StyledHyperLink>
      </BtnGroupContainer>
    </Container>
  );
};

export default ChatWindowInfo;
