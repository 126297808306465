import React from 'react';

import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';

export default createSvgIcon(
  <>
    <rect opacity="0.01" x="3.64453" y="3.01123" width="18" height="18" fill="black" />
    <path fillRule="evenodd" clipRule="evenodd" d="M19.8443 18.4768H18.9803V8.90079C18.9803 8.60559 18.7355 8.36079 18.4403 8.36079H14.2643V5.01279C14.2643 4.83999 14.1851 4.68159 14.0411 4.58079C13.9043 4.47999 13.7243 4.45119 13.5587 4.50159L6.7187 6.66159C6.4955 6.73359 6.3443 6.94239 6.3443 7.17999V18.484H5.4443C5.1491 18.484 4.9043 18.7288 4.9043 19.024C4.9043 19.3192 5.1491 19.564 5.4443 19.564H19.8443C20.1395 19.564 20.3843 19.3192 20.3843 19.024C20.3843 18.7288 20.1395 18.4768 19.8443 18.4768ZM14.3005 9.4408H17.9005V18.4408H14.3005V9.4408ZM7.42456 7.56165L13.1846 5.74005V18.4337H7.42456V7.56165Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.04488 11.9609H11.5649C11.8601 11.9609 12.1049 12.1985 12.1049 12.5009C12.1049 12.7961 11.8601 13.0409 11.5649 13.0409H9.04488C8.74968 13.0409 8.50488 12.7961 8.50488 12.5009C8.50488 12.2057 8.74968 11.9609 9.04488 11.9609ZM9.04488 14.5529H11.5649C11.8601 14.5529 12.1049 14.7977 12.1049 15.0929C12.1049 15.3881 11.8601 15.6329 11.5649 15.6329H9.04488C8.74968 15.6329 8.50488 15.3881 8.50488 15.0929C8.50488 14.7977 8.74968 14.5529 9.04488 14.5529Z" />
  </>,
  'Building',
);
