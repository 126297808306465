import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const groupVideoRoomCreateMutation = `
  mutation groupVideoCallRoomCreate($boothId: ID!, $participantId: String!) {
    groupVideoCallRoomCreate(input: {
      boothId: $boothId,
      participantId: $participantId,
    }) {
      success
      videoCallRoom {
        token
        status
      }
      roomAdmin {
        uuid
      }
      errors {
        path
        message
        type
        details {
          key
          value
        }
      }
    }
  }
`;

const createGroupVideoCallRoom = (boothId: string, participantId: string): Promise<any> => request(
  API_URL,
  groupVideoRoomCreateMutation,
  {
    boothId,
    participantId,
  },
).then(({ groupVideoCallRoomCreate }) => groupVideoCallRoomCreate);

export default createGroupVideoCallRoom;
