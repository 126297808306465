import styled from 'styled-components';
import React, { ReactElement, useEffect } from 'react';

const BoundingRectChangeDetectorContainer = styled.div<{ hideOverflow: boolean }>`
  overflow: ${(props) => (props.hideOverflow ? 'hidden' : 'auto')};
`;

export interface BoundingRectChangeDetectorOptions {
  children: ReactElement;
  onChange: (DOMRect) => void;
  checkInterval?: number;
  hideOverflow?: boolean;
  detectSize?: boolean;
  detectPosition?: boolean;
}

const BoundingRectChangeDetector = ({
  children, onChange, checkInterval = 30, hideOverflow = true, detectSize = true, detectPosition = true,
}: BoundingRectChangeDetectorOptions) => {
  const childrenRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    let lastBoundingRect = null;
    let stop = false;

    const tick = () => {
      if (!stop) {
        if (lastBoundingRect == null) {
          lastBoundingRect = childrenRef.current?.getBoundingClientRect;
        } else if (childrenRef.current != null) {
          const compare = childrenRef.current.getBoundingClientRect();
          const positionChanged = detectPosition && (
            lastBoundingRect.left !== compare.left
            || lastBoundingRect.right !== compare.right
            || lastBoundingRect.top !== compare.top
            || lastBoundingRect.bottom !== compare.bottom);
          const sizeChanged = (detectSize && (
            lastBoundingRect.height !== compare.height
            || lastBoundingRect.width !== compare.width));
          if (positionChanged || sizeChanged) {
            lastBoundingRect = childrenRef.current.getBoundingClientRect();
            onChange(lastBoundingRect);
          }
        }
        setTimeout(tick, checkInterval);
      }
    };
    tick();

    return () => {
      stop = true;
    };
  }, [onChange, checkInterval, childrenRef, detectPosition, detectSize]);

  return (
    <BoundingRectChangeDetectorContainer
      ref={childrenRef}
      hideOverflow={hideOverflow}
    >
      {children}
    </BoundingRectChangeDetectorContainer>
  );
};


export default BoundingRectChangeDetector;
