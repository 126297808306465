import React from 'react';
import styled from 'styled-components';
import { BRAND_COLOR } from 'appenv';

const BannerProfileButton = styled.button`
  background: ${BRAND_COLOR || '#5C8DF3'};
  border-radius: 4px;
  font-family: Lato, sans-serif;
  border: 0;
  display: block;
  color: #FFF;
  padding: 8px 16px;
  min-height: 32px;
  align-self: flex-start;
  text-decoration: unset;
  cursor: pointer;
`;

export interface ViewMoreButtonProps {
  handleClick: (type: string) => void;
  className?: string;
}

const ViewMoreButton: React.FC<ViewMoreButtonProps> = ({
  handleClick,
  className = '',
}: ViewMoreButtonProps) => (
  <BannerProfileButton
    className={className}
    onClick={() => handleClick('desk')}
  >
    View More
  </BannerProfileButton>
);

export default ViewMoreButton;
