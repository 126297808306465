import {
  createSlice,
} from '@reduxjs/toolkit';

export const RtcCallingStatusSlice = createSlice({
  name: 'rtcCallingStatus',
  initialState: 'available',
  reducers: {
    setCallStatus(state, action) {
      return action.payload;
    },
  },
});

export const {
  setCallStatus,
} = RtcCallingStatusSlice.actions;

export default RtcCallingStatusSlice.reducer;
