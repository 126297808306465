export const en = {
  titleImg: 'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/f275f519-eb2c-429c-9e71-3f1c93810e88.png',
};

export const ja = {
  titleImg: 'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/122b96d0-ce68-4bbb-acb0-ab884914d22c.png',
};

export const zhHans = {
  titleImg: 'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/be5b8d4d-51a4-4077-a6a7-6ba5267bb996.png',
};
