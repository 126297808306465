import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import BoothContainer from '../components/BoothContainer';
import ScreenBackground from '../components/background/ScreenBackground';
import TanShelf from '../components/brochureShelf/TanShelf';
import GenericBoothBanner from '../components/GenericBoothBanner';
import BoothSaleContainer from '../components/BoothSaleContainer';

const LogoContainer = styled.div`
  position: absolute;
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/543/content/logo_container-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 400px;
  height: 200px;
  left: calc((100% - 400px) / 2);
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const LogoImgContainer = styled.div`
  margin-top: 47.4px;
  height: 149.4px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShowDeskContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/541/content/center_showdesk-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 260px;
  width: 470px;
  height: 400px;
  left: calc((100% - 470px) / 2);
`;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 20px;
`;

const PosterContainer = styled.div`
  background: url(https://cdn.eventxtra.com/ckeditor/pictures/data/000/002/577/content/poster-.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  width: 201.5px;
  padding: 6.4px 9px 9px;
  position: absolute;
  bottom: -60px;
`;

const BrochureContainer = styled.div`
  position: absolute;
  bottom: -60px;
  right: 120px;
  cursor: ${({ enableClick }) => (enableClick ? 'pointer' : 'initial')};
`;

const SaleContainer = styled.div`
  position: absolute;
  width: 200px;
  height: 480px;
  right: -80px;
  top: 200px;
`;

const DescriptionContainer = styled.div`
  height: 206px;
  top: 7px;
  position: relative;
`;

const Booth001Template = ({ booth, handleClick, showRepsImg }) => {
  const [showBannerModal, setShowBannerModal] = useState(false);
  const pullUpFile = booth.verticalBannerFile;

  return (
    <BoothContainer needOffset={showRepsImg}>
      <BackgroundContainer>
        <ScreenBackground
          boothColor={booth.boothColor || '#CF2F2F'}
          boothColorRight={booth.boothColorRight || booth.boothColor || '#CF2F2F'}
        />
      </BackgroundContainer>
      <LogoContainer enableClick={!!(booth.logoDirectLink || booth.website)}>
        <LogoImgContainer>
          <GenericBoothBanner
            from="Banner 2"
            name={booth.name}
            isPreview={!showRepsImg}
            boothNumber={booth.id}
            handleClick={handleClick}
            website={booth?.website}
            bannerData={booth?.banner?.logoBanner}
          />
        </LogoImgContainer>
      </LogoContainer>
      <ShowDeskContainer>
        <DescriptionContainer>
          <GenericBoothBanner
            handleClick={handleClick}
            isPreview={!showRepsImg}
            name={booth.name}
            from="Banner 3"
            boothNumber={booth.id}
            bannerData={booth?.banner?.descriptionBanner}
            isDescriptionBanner
          />
        </DescriptionContainer>
      </ShowDeskContainer>
      <PosterContainer>
        <GenericBoothBanner
          name={booth.name}
          from="Banner 1"
          isPreview={!showRepsImg}
          boothNumber={booth.id}
          handleClick={handleClick}
          bannerData={booth?.banner?.verticalBanner}
          objectFit="cover"
        />
      </PosterContainer>
      <BrochureContainer enableClick={!!booth.attachments.length} onClick={() => handleClick('attachment')}>
        <TanShelf hasAttachment={!!booth.attachments.length} />
      </BrochureContainer>
      {
        showRepsImg && (
          <SaleContainer id="reps">
            <BoothSaleContainer booth={booth} sale="male-right" />
          </SaleContainer>
        )
      }
      {
        showBannerModal && (
          <Lightbox
            mainSrc={pullUpFile}
            onCloseRequest={() => setShowBannerModal(false)}
          />
        )
      }
    </BoothContainer>
  );
};

Booth001Template.propTypes = {
  booth: PropTypes.shape().isRequired,
  handleClick: PropTypes.func,
  showRepsImg: PropTypes.bool,
};

Booth001Template.defaultProps = {
  handleClick: () => {},
  showRepsImg: false,
};

export default Booth001Template;
