import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector, batch } from 'react-redux';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import ContactListItem from './ContactListItem';

const Container = styled.div`
  background-color: #FFF;
  width: 1020px;
  margin: auto;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
`;

const StyledTableCell = styled(TableCell)`
  border: none;
  color: '#999998';
  text-transform: capitalize;
`;

const tableHeaderText = [
  'Name',
  'Email',
  'Role',
  'Description',
  'Date Added',
];

const ContactListContainer = ({ contactItems, scrollableTarget }) => {
  const abc = 123;
  console.log(contactItems);

  return (
    <Container>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {tableHeaderText.map((header) => (
              <StyledTableCell headCell key={header}>{header}</StyledTableCell>
            ))}
            <StyledTableCell rowSpan={2} />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            contactItems && contactItems.map((row) => (
              <ContactListItem
                key={row.id}
                contactId={row.contact.uuid}
                name={row.contact.name}
                role={row.contact.role}
                email={row.contact.email}
                createdAt={row.createdAt}
                description={row.userDescription}
              />
            ))
          }
        </TableBody>
      </Table>
    </Container>
  );
};

ContactListContainer.propTypes = {
  contactItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  scrollableTarget: PropTypes.instanceOf(Element),
};

ContactListContainer.defaultProps = {
  scrollableTarget: document.body,
};

export default ContactListContainer;
