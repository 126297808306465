import React from 'react';
import styled from 'styled-components';
import FeaturedStarBadge from 'assets/booths/featured-star.svg';

const FeaturedStarImage = styled.img`
  position: absolute;
  border-top-left-radius: 8px;
  top: 0;
  left: 0;
  transform: translate(-4px, -4px);
`;

const FeaturedStar = () => (
  <FeaturedStarImage src={FeaturedStarBadge} alt="Featured Booth" />
);

export default FeaturedStar;
