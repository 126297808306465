import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import VideoLightbox from './VideoLightbox';
import IframeLightbox from './IframeLightbox';
import ImageLightbox from './ImageLightbox';

const isVideo = (src) => ['mp4', 'webm', 'ogg'].some((format) => src.endsWith(format));

const isImage = (src) => ['jpg', 'jpeg', 'png', 'gif', 'svg'].some((format) => src.endsWith(format));

const JustPopUp = ({ src, onClose }) => {
  useEffect(() => {
    window.open(src, '_blank');
    onClose();
  }, [src, onClose]);
  return null;
};

const DoNothing = () => null;

const Lightbox = ({ src, onClose, onlyImage }) => {
  const SelectedLightbox = (() => {
    if (!src) return DoNothing;
    const file = src.toLowerCase();
    switch (true) {
      case isImage(file): return ImageLightbox;
      case onlyImage: return JustPopUp;
      case isVideo(file): return VideoLightbox;
      default: return IframeLightbox;
    }
  })();

  return <SelectedLightbox src={src} onClose={onClose} />;
};

Lightbox.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onlyImage: PropTypes.bool,
};

Lightbox.defaultProps = {
  onlyImage: false,
};


export default Lightbox;
