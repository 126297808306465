import styled from 'styled-components';
import { BRAND_COLOR } from 'appenv';

const FilterOptionContainer = styled.div<{ selected: boolean }>`
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 0;
  transition: all 0.1s;
  display: block;
  color: ${({ selected }: { selected: boolean }) => (selected && (BRAND_COLOR || '#0CA58B'))};

  &:hover {
    color: ${BRAND_COLOR || '#0CA58B'};
  }
`;

export default FilterOptionContainer;
