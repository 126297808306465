import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';

const usePopoverStyles = makeStyles({
  root: {
    zIndex: '110 !important',
  },
});

const StyledMenu = withStyles(() => ({
  paper: {
    top: '70px !important',
  },
}))(Menu);

const StyledNavLink = styled(NavLink)`
  flex: 0 1 auto;
  color: #484848;
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 6px 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
`;

const StyledMenuLink = styled.div`
  flex: 0 1 auto;
  color: ${({ isActive, activeStyle }) => (isActive ? activeStyle.color : '#484848')};
  text-decoration: none;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: keep-all;
  cursor: pointer;
  background-color: ${({ isMenuOpen, menuOpenStyle }) => (isMenuOpen ? menuOpenStyle.backgroundColor : 'unset')};
`;

const StyledMenuItem = withStyles(() => ({
  root: {
    fontFamily: 'inherit',
  },
}))(MenuItem);

const TranslatedName = styled.div`
  margin-top: 5px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  white-space: nowrap;
`;

const AppBarMenuLink = ({
  name, translatedName, isActive, onClick,
  activeStyle, showMenu, subLinks, menuOpenStyle,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();
  const popoverClasses = usePopoverStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div onMouseEnter={handleClick} onMouseLeave={handleClose}>
      <StyledMenuLink
        onClick={handleClick}
        activeStyle={activeStyle}
        isActive={isActive(location)}
        isMenuOpen={Boolean(anchorEl)}
        menuOpenStyle={menuOpenStyle}
      >
        <NameContainer>
          <div>{name}</div>
          {translatedName && <TranslatedName>{translatedName}</TranslatedName>}
        </NameContainer>
        <ExpandMore />
      </StyledMenuLink>
      {showMenu && (
        <StyledMenu
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
          PopoverClasses={popoverClasses}
        >
          {subLinks.map((link) => (
            <StyledMenuItem key={link.name} onClick={handleClose}>
              <StyledNavLink
                onClick={link.onClick}
                to={link.to}
              >
                <div>{link.name}</div>
                {link.translatedName && <div>{link.translatedName}</div>}
              </StyledNavLink>
            </StyledMenuItem>
          ))}
        </StyledMenu>
      )}
    </div>
  );
};

AppBarMenuLink.propTypes = {
  name: PropTypes.string.isRequired,
  translatedName: PropTypes.string,
  isActive: PropTypes.func,
  activeStyle: PropTypes.shape({ color: PropTypes.string.isRequired }),
  onClick: PropTypes.func,
  showMenu: PropTypes.bool,
  subLinks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    translatedName: PropTypes.string,
    onClick: PropTypes.func,
    to: PropTypes.string,
  })),
  menuOpenStyle: PropTypes.shape({ backgroundColor: PropTypes.string.isRequired }),
};

AppBarMenuLink.defaultProps = {
  translatedName: '',
  isActive: () => false,
  activeStyle: {
    color: 'unset',
  },
  onClick: () => {},
  showMenu: true,
  subLinks: [],
  menuOpenStyle: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
};

export default AppBarMenuLink;
