import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import SvgIcon from '@material-ui/core/SvgIcon';

const useStyles = makeStyles({
  root: ({
    width,
    height,
    fontSize,
    color,
    colorDisabled,
    disabled,
  }) => ({
    width,
    height,
    fontSize,
    color: disabled ? colorDisabled : color,
  }),
});

const XtraSvgIcon = ({
  className,
  width,
  height,
  fontSize,
  viewBox,
  color,
  colorDisabled,
  disabled,
  children,
  ...props
}) => {
  const classes = useStyles({
    width,
    height,
    fontSize,
    color,
    colorDisabled,
    disabled,
  });

  return (
    <SvgIcon
      className={className}
      classes={classes}
      width={width}
      height={height}
      viewBox={viewBox}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      { children }
    </SvgIcon>
  );
};

XtraSvgIcon.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.string,
  viewBox: PropTypes.string,
  color: PropTypes.string,
  colorDisabled: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

XtraSvgIcon.defaultProps = {
  className: '',
  width: '18px',
  height: '18px',
  fontSize: '18px',
  viewBox: '0 0 18 18',
  color: '#A2AAB3',
  colorDisabled: '#DBDEE2',
  disabled: false,
};

export default XtraSvgIcon;
