import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import HTMLElementType from '@material-ui/utils/HTMLElementType';

import { useSelector } from 'react-redux';

import CircularLoadingIndicator from 'components/indicators/CircularLoadingIndicator';
import SearchResultList from './SearchResultList';

const StyledPopover = withStyles(() => ({
  root: {
    transform: 'translateY(14px)',
  },
  paper: {
    width: '540px',
    height: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
}))(Popover);

const SearchResultListPopover = ({
  anchorEl,
  open,
  onClose,
  onExited,
  navigateToAllResults,
}) => {
  const { loading } = useSelector((state) => state.searchResults);

  return (
    <StyledPopover
      id="search-result-list"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      onExited={onExited}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableEnforceFocus
      disableAutoFocus
    >
      {
        loading ? (
          <CircularLoadingIndicator size={24} />
        ) : (
          <SearchResultList
            navigateToAllResults={navigateToAllResults}
          />
        )
      }
    </StyledPopover>
  );
};

SearchResultListPopover.propTypes = {
  anchorEl: PropTypes.oneOfType([HTMLElementType, PropTypes.func]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  navigateToAllResults: PropTypes.func,
};

SearchResultListPopover.defaultProps = {
  anchorEl: null,
  open: false,
  onClose: () => {},
  onExited: () => {},
  navigateToAllResults: () => {},
};

export default SearchResultListPopover;
