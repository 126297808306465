import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  EVENT_NAME, PAGE_NAMES, APP,
} from 'appenv';
import { reedApps } from 'apps';
import { useTranslation } from 'react-i18next';

import BackToTopButton from 'components/BackToTopButton';
import Footer from 'components/footer/Footer';

import ParticipantListContainer from './ParticipantListContainer';

const RootContainer = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 960px) {
    background-color: #F0F2F5;
  }

  @media (max-width: 960px) {
    background-color: #F0F1F2;
  }
`;

const PageContentContainer = styled.div`
  flex: 1 0 auto;
`;

const ParticipantPageContainer = () => {
  const { t } = useTranslation();
  const [scrollableTarget, setScrollableTarget] = useState();
  const pageWrapperRef = useRef();

  useEffect(() => {
    setScrollableTarget(pageWrapperRef.current);
  }, []);

  const pageTitle = PAGE_NAMES.participant || t('page.participant', 'Participants');
  const eventName = useMemo(() => {
    if (reedApps.includes(APP)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  return (
    <RootContainer ref={pageWrapperRef}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | ${eventName}`}</title>
        <meta name="description" content={`${pageTitle} page of ${eventName}.`} />
      </Helmet>
      <PageContentContainer>
        <ParticipantListContainer
          scrollableTarget={scrollableTarget}
        />
      </PageContentContainer>
      <Footer hashState="/participant" />
      <BackToTopButton scrollableTarget={scrollableTarget} />
    </RootContainer>
  );
};

export default ParticipantPageContainer;
