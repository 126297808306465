import { request } from 'graphql-request';
import { API_URL } from 'appenv';

const videoRoomCreateMutation = `
  mutation videoCallRoomCreate($inviterId: String!, $inviteeIds: [String!]) {
    videoCallRoomCreate(input: {
      inviterId: $inviterId,
      inviteeIds: $inviteeIds,
    }) {
      success
      videoCallRoom {
        token
      }
      errors {
        path
        message
        type
        details {
          key
          value
        }
      }
    }
  }
`;

const createVideoCallRoom = (inviterId: string, inviteeIds: [string]): Promise<any> => request(
  API_URL,
  videoRoomCreateMutation,
  {
    inviterId,
    inviteeIds,
  },
).then(({ videoCallRoomCreate }) => videoCallRoomCreate);

export default createVideoCallRoom;
