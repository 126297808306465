import React from 'react';
import { BRAND_COLOR } from 'appenv';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TimeZoneSwitch from 'components/TimeZoneSwitch';
import { BREAKPOINT } from './constants';

const RootContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  border-bottom: 2px solid #DBDEE2;

  @media (max-width: ${BREAKPOINT}px) {
    z-index: 50;
  }
`;

const StyledTabsContainer = styled(Tabs)`
  flex: 1 0 auto;
  width: 100%;
  background-color: #FFF;
  padding: 0 24px;

  & .MuiTabs-indicator {
    background-color: ${BRAND_COLOR || '#0CA58B'};
    height: 4px;
  }
`;

const StyledTab = withStyles(() => ({
  root: {
    color: '#A2AAB3',
    fontSize: '18px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
}))(Tab);

const StyledLocalTimeSwitchLabel = styled(TimeZoneSwitch)`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;

  @media (max-width: 959px) {
    display: none;
  }
`;

const DaySelector = ({
  days,
  activeIndex,
  onDaySelected,
}) => (
  <RootContainer>
    <StyledTabsContainer
      value={activeIndex}
      onChange={(_, selectedIndex) => {
        onDaySelected(selectedIndex);
      }}
      variant="scrollable"
      scrollButtons="auto"
    >
      {
        days.map((day, index) => (
          <StyledTab
            key={index}
            label={day}
          />
        ))
      }
    </StyledTabsContainer>
    <StyledLocalTimeSwitchLabel />
  </RootContainer>
);

DaySelector.propTypes = {
  days: PropTypes.arrayOf(PropTypes.string),
  activeIndex: PropTypes.number,
  onDaySelected: PropTypes.func,
};

DaySelector.defaultProps = {
  days: [],
  activeIndex: 0,
  onDaySelected: () => {
  },
};

export default DaySelector;
