const bannerUtils = {
  toImage: (src: string, href = null) => ({
    src,
    href,
    type: 'image',
  }),
};

export const en = {
  entry: {
    to_exhibition_hall: 'Enter Exhibition Hall',
    how_to_use_oms: '<b>How to utilize</b><b>virtual expo?</b>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a9b32a85-3507-4cea-bcd5-1742147c2f15.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/56ff43a9-c805-4243-8562-a2cfb45eeeaf.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/21087b2a-1663-45cf-ad78-f8ac4cbbcc95.png',
        '/booth/83yDLVgo2TXYw9hHFhWFRShNDmzow8QPbVtcTjyDFead',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/10cf7982-1bbe-4ed3-95de-e480d3acf50a.jpg',
        'https://www.edix-expo.jp/en-gb.html',
      ),
    ],
    mobile: [],
  },
};

en.banners.mobile = [
  ...en.banners.middle,
  ...en.banners.left,
  ...en.banners.right,
];

export const ja = {
  entry: {
    to_exhibition_hall: '展示会場はこちら',
    how_to_use_oms: '<b>はじめに</b><i>オンライン来場・商談サービスの使い方</i>',
  },
  banners: {
    left: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/a9b32a85-3507-4cea-bcd5-1742147c2f15.png',
      ),
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/56ff43a9-c805-4243-8562-a2cfb45eeeaf.png',
      ),
    ],
    right: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/21087b2a-1663-45cf-ad78-f8ac4cbbcc95.png',
        '/booth/83yDLVgo2TXYw9hHFhWFRShNDmzow8QPbVtcTjyDFead',
      ),
    ],
    middle: [
      bannerUtils.toImage(
        'https://contents-virtual.eventxtra.com/uploads/reed902_staging/originals/5bb55e74-1c0b-4496-b30c-c0d76b073b13.jpg',
        'https://www.edix-expo.jp/ja-jp.html',
      ),
    ],
    mobile: [],
  },
};

ja.banners.mobile = [
  ...ja.banners.middle,
  ...ja.banners.left,
  ...ja.banners.right,
];
