import axios from 'axios';
import { FETCH_PRODUCT_URL } from 'appenv';

const productsByBoothId = async (boothId: string) => {
  try {
    const boothProductsResponse = await axios.get(`${FETCH_PRODUCT_URL}/products?filter={"boothId": "${boothId}"}&range=[0,1000]`, {
      headers: {
        'cache-control': 'no-cache',
        'x-apicache-bypass': true,
      },
    });
    return boothProductsResponse.data;
  } catch (_) {
    return null;
  }
};

export default productsByBoothId;
