import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ENABLED_FEATURES } from 'appenv';
import groupMemberIcon from 'assets/chatwindow/memberIcon.svg';
import VideoCallIcon from 'assets/video_call.svg';
import ChatWindowHeader from './ChatWindowHeader';
import GroupMemberList from './GroupMemberList';
import ChatContext from './ChatContext';
import isPersonalVideoCallDisabled from './utils/isPersonalVideoCallDisabled';
import ChatWindowInfo from './ChatWindowInfo';

const RootContainer = withStyles(() => ({
  root: {
    position: 'absolute',
    right: '20px',
    bottom: 0,
    zIndex: 1000,
    backgroundColor: '#F0F1F2',
    overflow: 'hidden',
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.16)',
  },
  rounded: {
    borderRadius: '4px 4px 0px 0px',
  },
}))(Paper);

const ChatWindowContainer = styled.div`
  height: 500px;
  width: 760px;
  background: #2D3D49;
`;

const DesktopWindowMainContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: #F0F1F2;
  height: calc(100% - 48px);
`;

const ConversationRootContainer = styled.div`
  background-color: #FFF;
  border-right: 1px solid #DBDEE2;
  width: 280px;
  overflow-y: auto;
`;

const DesktopWindowContainer = styled.div`
  display: flex;
  width: calc(100% - 280px);
  overflow: hidden;
  background-color: #F0F1F2;
  height: 100%;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  font-family: Lato;
  background-color: #FFF;
  color: #484848;
  margin: 0;
  height: 44px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const TitleText = styled.h3`
  font-family: Lato;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  font-size: 14px;
`;

const GroupMemberBtn = styled.div`
  position: absolute;
  right: 0;
  top: 8px;
  margin-right: 16px;
  cursor: pointer;
`;

const GroupMemberImg = styled.img`
  height: 24px;
  width: 24px;
`;

const ChatWindowPlaceHolderContainer = styled.div`
  text-align: center;
  padding: 140px 0;
`;

const StyledChatWindowPlaceHolderText = styled.div`
  width: 60%;
  margin: auto;
`;

const DesktopChatWindowBtn = styled.div`
  background: #2D3D49;
  width: 200px;
  height: 48px;
  box-shadow: 0 4px 16px #484848;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
`;

const CallBtn = styled.div`
  margin-right: 16px;

  img {
    height: 24px;
    width: 24px;
  }
`;

const CallBtnContainer = styled.div`
  margin-left: auto;
  display: flex;
  position: absolute;
  right: 0;
  cursor: pointer;
`;

const DesktopChatWindow = ({
  joiningChat,
  chatRoomTitle,
  isChatWindowShow,
  setIsChatWindowShow,
  handleAvatarOnclick,
  ChatWindowComponent,
  ChatConversationListComponent,
  showGroupMemberList,
  setShowGroupMemberList,
}) => {
  const { t } = useTranslation();
  const { targetId = '', type } = useSelector((state) => state.chatTarget);

  const handleGroupMemberBtnOnClick = () => {
    setShowGroupMemberList(!showGroupMemberList);
  };

  const isGroup = type === 'group';

  const { createVideoCall } = useContext(ChatContext);

  return (
    <RootContainer>
      {
        isChatWindowShow ? (
          <ChatWindowContainer>
            <ChatWindowHeader
              setIsChatWindowShow={() => setIsChatWindowShow(!isChatWindowShow)}
              isChatWindowShow={isChatWindowShow}
            />
            <DesktopWindowMainContainer>
              <ConversationRootContainer>
                {ChatConversationListComponent}
              </ConversationRootContainer>
              <DesktopWindowContainer>
                {
                  targetId ? (
                    <>
                      <TitleContainer>
                        <TitleText>
                          {chatRoomTitle}
                        </TitleText>
                        {
                          isGroup && (
                            <GroupMemberBtn onClick={handleGroupMemberBtnOnClick}>
                              <GroupMemberImg
                                src={groupMemberIcon}
                                alt="group-member-list"
                              />
                            </GroupMemberBtn>
                          )
                        }
                        {
                          ENABLED_FEATURES.personalVideoCall && type === 'private' && !isPersonalVideoCallDisabled() && (
                            <CallBtnContainer>
                              <CallBtn onClick={() => createVideoCall(chatRoomTitle, targetId, [targetId], window.RongIMLib.ConversationType.PRIVATE)}>
                                <img src={VideoCallIcon} alt="Video Call" />
                              </CallBtn>
                            </CallBtnContainer>
                          )
                        }
                      </TitleContainer>
                      {ChatWindowComponent}
                      {
                        isGroup && showGroupMemberList && (
                          <GroupMemberList
                            joiningChat={joiningChat}
                            handleAvatarOnclick={handleAvatarOnclick}
                          />
                        )
                      }
                    </>
                  ) : (
                    <ChatWindowPlaceHolderContainer>
                      <ChatWindowInfo />
                      <StyledChatWindowPlaceHolderText>
                        {t(
                          'chat.one_click_msg',
                          'Easily join chatroom and start chatting with people via 1-Click.',
                        )}
                      </StyledChatWindowPlaceHolderText>
                    </ChatWindowPlaceHolderContainer>
                  )
                }
              </DesktopWindowContainer>
            </DesktopWindowMainContainer>
          </ChatWindowContainer>
        ) : (
          <DesktopChatWindowBtn>
            <ChatWindowHeader
              setIsChatWindowShow={() => setIsChatWindowShow(!isChatWindowShow)}
              isChatWindowShow={isChatWindowShow}
            />
          </DesktopChatWindowBtn>
        )
      }
    </RootContainer>
  );
};

DesktopChatWindow.propTypes = {
  joiningChat: PropTypes.bool.isRequired,
  chatRoomTitle: PropTypes.string.isRequired,
  isChatWindowShow: PropTypes.bool.isRequired,
  setIsChatWindowShow: PropTypes.func.isRequired,
  handleAvatarOnclick: PropTypes.func.isRequired,
  ChatWindowComponent: PropTypes.node.isRequired,
  ChatConversationListComponent: PropTypes.node.isRequired,
  showGroupMemberList: PropTypes.bool.isRequired,
  setShowGroupMemberList: PropTypes.func.isRequired,
};

export default DesktopChatWindow;
