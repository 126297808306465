import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  EVENT_NAME,
  BRAND_COLOR,
  PAGE_NAMES,
  APP,
} from 'appenv';
import { reedApps } from 'apps';
import { UserSessionContext } from 'components/UserSession';
import Footer from 'components/footer/Footer';
import { SearchFieldStateProvider } from 'components/SearchFieldContext';
import BackToTopButton from 'components/BackToTopButton';

import SearchResultLoader from './SearchResultLoader';

const RootContainer = styled.div`
  height: 100%;
  overflow: hidden;
  background-color: #F0F2F5;
  display: flex;
  flex-direction: column;
`;

const StyledTabsContainer = withStyles(() => ({
  root: {
    flex: '0 0 auto',
    marginBottom: '6px',
    paddingTop: '16px',
  },
}))(Paper);

const StyledTabs = withStyles(() => ({
  indicator: {
    backgroundColor: BRAND_COLOR || '#0CA58B',
    height: '4px',
  },
}))(Tabs);

const StyledTab = withStyles(() => ({
  root: {
    color: '#A2AAB3',
    fontSize: '18px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  selected: {
    color: BRAND_COLOR || '#0CA58B',
  },
  wrapper: {
    display: 'block',
  },
}))(Tab);

const PageContentContainer = styled.div`
  flex: 1 1 auto;
  height: auto;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 960px) {
    margin: 0 5vw;
  }
`;

const SearchResultPageContainer = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [scrollableTarget, setScrollableTarget] = useState();

  const onTabClick = (_, selectedTab) => {
    setActiveTab(selectedTab);
  };

  const { canSee } = useContext(UserSessionContext);

  const pageWrapperRef = useRef();

  useEffect(() => {
    setScrollableTarget(pageWrapperRef.current);
  }, []);

  const pageTitle = PAGE_NAMES.search || t('page.search', 'Search Results');
  const eventName = useMemo(() => {
    if (reedApps.includes(APP)) return t('common:title', EVENT_NAME);
    return EVENT_NAME;
  }, [t]);

  const viewableTabs = [
    {
      label: t('all', 'All'),
      condition: true,
    },
    {
      label: PAGE_NAMES.exhibitors || t('page.exhibitors', 'Exhibitors'),
      condition: canSee('hall'),
    },
    {
      label: PAGE_NAMES.webinar || t('page.webinar', 'Webinars'),
      condition: canSee('webinar'),
    },
    {
      label: PAGE_NAMES.participant || t('page.participants', 'Participants'),
      condition: canSee('participant'),
    },
  ]
    .filter((it) => it.condition)
    .map(({ label }) => <StyledTab key={label} label={label} />);

  return (
    <RootContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${pageTitle} | ${eventName}`}</title>
        <meta name="description" content={`Search results for booths in ${EVENT_NAME}.`} />
      </Helmet>
      {viewableTabs.length > 2 && (
        <StyledTabsContainer elevation={3}>
          <StyledTabs
            centered
            value={activeTab}
            scrollButtons="auto"
            onChange={onTabClick}
          >
            {viewableTabs}
          </StyledTabs>
        </StyledTabsContainer>
      )}
      <PageContentContainer ref={pageWrapperRef}>
        <SearchFieldStateProvider>
          <SearchResultLoader
            scrollableTarget={scrollableTarget}
            activeTab={activeTab}
          />
        </SearchFieldStateProvider>
        <Footer />
      </PageContentContainer>
      <BackToTopButton scrollableTarget={scrollableTarget} />
    </RootContainer>
  );
};

export default SearchResultPageContainer;
