import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { EVENT_NAME } from 'appenv';
import { useHistory, Link } from 'react-router-dom';

const PageNoFoundContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: auto;
  overflow: auto;
`;

const PageNoFoundWrapper = styled.div`
  width: 70%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MessageContainer = styled.div`
  width: 100%;
  max-width: 505px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 3px 3px 20px 5px rgba(204, 204, 204, 1);
  border: 1px solid #AAA;
  text-align: center;
`;

const MessageTitle = styled.h2`
  color: #666;
  font-size: 160%;
  margin-top: 30px;
  margin-bottom: 20px;
`;

const MessageDetailsContainer = styled.div`
  padding: 8px;
`;

const MessageDetails = styled.p`
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
`;
const PageNoFound = () => {
  const history = useHistory();

  return (
    <PageNoFoundContainer>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Not Found | ${EVENT_NAME}`}</title>
        <meta name="description" content={`Page not found of ${EVENT_NAME}.`} />
      </Helmet>
      <PageNoFoundWrapper>
        <MessageContainer>
          <MessageTitle>
            Oops, you&apos;ve found a dead link.
          </MessageTitle>
          <MessageDetailsContainer>
            <MessageDetails>
              It seems we can&apos;t find what you&apos;re looking for.
            </MessageDetails>
            <MessageDetails>
              It might have been moved, expired or simply doesn&apos;t exist.
            </MessageDetails>
          </MessageDetailsContainer>
          <StyledLink onClick={() => { history.goBack(); }}>
            Go back to the previous page
          </StyledLink>
          <StyledLink to="/main-lobby">
            Go to the Home Page
          </StyledLink>
        </MessageContainer>
      </PageNoFoundWrapper>
    </PageNoFoundContainer>
  );
};

export default PageNoFound;
